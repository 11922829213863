// FAQ page using Acordeon component

import React, { useEffect } from 'react';
import { scroller } from 'react-scroll';

import { useTrackPageViews } from 'hooks';
import { Accordion } from 'components';
import { DOCUMENT_TITLE } from 'common/constants/html';
import { analyticsPageTypes } from 'common/enums/pages';

import { FAQSections } from 'common/enums/accordion';
import { Wrapper, Content, Title, Description, Delimeter } from './styled';

const FAQ = ({ history }) => {
  useTrackPageViews({ page: analyticsPageTypes.DOBBY_HO });

  const scrollTo = section => {
    scroller.scrollTo(section, {
      duration: 1000,
      delay: 100,
      smooth: true,
      offset: -150
    });
  };

  useEffect(() => {
    document.title = DOCUMENT_TITLE;
    window.scrollTo(0, 0);
    if (history.location.state?.section) {
      scrollTo(history.location.state.section);
    }
  }, [history.location.state]);

  return (
    <Wrapper>
      <Content>
        <Title>{"Have questions?\nWe've got you covered."}</Title>
        <Description>
          Check out our frequently asked questions to learn more.
        </Description>
        <Delimeter />
        {FAQSections.map((section, index) => (
          <Accordion
            key={index}
            index={index}
            title={section.name}
            items={section}
          />
        ))}
      </Content>
    </Wrapper>
  );
};

export default FAQ;
