import styled from 'styled-components';
import styles from 'common/enums/styles';
import { device } from 'common/enums/devices';

export const Wrapper = styled.div`
  width: 100%;
  display: grid;
  justify-items: center;
  align-items: baseline;
  align-content: baseline;
  padding: 40px 16px 40px;

  @media screen and ${device.TABLET} {
    justify-content: center;
    padding: 0 16px;
    margin: 80px 0 40px;
  }
`;

export const Table = styled.table`
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  max-width: 1160px;
  border-radius: 25px;
`;

export const Thead = styled.thead`
  transform: translateY(-0.5px);
`;

export const HRow = styled.tr`
  position: sticky;
  top: -1px;
  z-index: 1;
  box-shadow: ${styles.boxShadows.DARK3};
  border-radius: 8px 8px 0px 0px;
  background: ${styles.colors.CHARCOAL};
`;

export const Row = styled.tr`
  border-bottom: 1px solid ${styles.colors.DARK_BEIGE2}33;

  &:nth-child(2n) {
    background: ${styles.colors.CHARCOAL};
  }
`;

export const HCell = styled.th`
  text-align: left;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 18px;
  line-height: 1.2;
  font-size: 18px;
  text-transform: uppercase;
  color: ${styles.components.TEXT_INVERTED};
  :first-of-type {
    min-width: 144px;
    padding: 15px 14px;
    @media screen and ${device.TABLET} {
      width: 181px;
      padding: 16px 21px;
    }
  }
  :nth-child(2) {
    min-width: 200px;
    padding: 0 18px;
    @media screen and ${device.TABLET} {
      min-width: auto;
      padding: 16px 21px;
    }
  }
  :nth-child(3) {
    min-width: 150px;
    padding: 0 18px;
    @media screen and ${device.TABLET} {
      padding: 16px 21px;
    }
  }
  :nth-child(4) {
    width: 180px;
    min-width: 147px;
    padding: 0 18px;
    @media screen and ${device.TABLET} {
      padding: 16px 21px;
      min-width: 180px;
    }
  }
  :nth-child(5) {
    width: 180px;
    min-width: 147px;
    padding: 0 18px;
    @media screen and ${device.TABLET} {
      padding: 16px 21px;
      min-width: 180px;
    }
  }
  @media screen and ${device.TABLET} {
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
  }
`;

export const Cell = styled.td`
  color: ${styles.components.TEXT_INVERTED};
  text-align: left;
  padding: 10px 18px 13px 18px;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.5;
  border-collapse: collapse;
  vertical-align: top;
  @media screen and ${device.TABLET} {
    padding: 10px 1px 13px 24px;
  }
`;

export const Title = styled.h1`
  letter-spacing: 0;
  margin: auto;
  margin-bottom: 0;
  width: 100%;
  color: ${styles.components.HEADER};
  text-align: center;
  padding: 0 20px;
  @media screen and ${device.TABLET} {
    text-align: center;
    width: auto;
    padding: 0 40px;
  }
  @media screen and ${device.LAPTOP_L} {
    font-size: 50px;
    line-height: 1.3;
  }
`;

export const White = styled.span`
  color: ${styles.components.TEXT_INVERTED};
`;

export const Star = styled.img`
  width: 18px;
  height: 18px;
`;

export const Stars = styled.div`
  display: grid;
  gap: 5px;
  justify-content: flex-start;
  grid-auto-flow: column;
`;

export const Button = styled.button`
  margin: 0 auto;
  padding: 25px;
  border: none;
  background: transparent;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.5;

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 100%;
  max-width: calc(100vw - 32px);

  color: ${styles.components.TEXT_INVERTED};
  opacity: ${({ loading }) => (loading ? 0.4 : 1)};
  background: ${styles.components.BACKGROUND};
`;

export const Icon = styled.img`
  width: 16px;
  height: 16px;
`;

export const TableContainer = styled.div`
  border: none;
  margin-top: 33px;
  max-height: 565px;
  width: 100%;
  overflow: auto;
  @media screen and ${device.TABLET} {
    margin-top: 74px;
  }
  @media screen and ${device.LAPTOP} {
    width: auto;
  }
  border-radius: 25px;
  filter: drop-shadow(0px 4px 40px #7371ec);
  background: ${styles.components.BACKGROUND};
`;
