import styled from 'styled-components';
import styles from 'common/enums/styles';
import { device } from 'common/enums/devices';

export const ProfileLink = styled.svg`
  height: 56px;
  width: 56px;
  fill: ${styles.colors.WHITE_NEW};

  @media screen and ${device.TABLET} {
    height: 36px;
    width: 36px;
  }

  &:hover {
    cursor: pointer;
    path {
      fill: ${styles.colors.BLUE};
    }
  }
`;
