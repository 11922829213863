import imgMinstagram from 'static/images/icons/media-instagram.svg';
import imgMlinkedin from 'static/images/icons/media-linkedin.svg';
// import imgYoutube from 'static/images/icons/media-youtube.svg';
import imgFacebook from 'static/images/icons/media-facebook.svg';

export const contacts = {
  ADDRESS: {
    TYPE: 'address',
    VALUE: '1201 Wilson Boulevard, Arlington, VA 22209'
  },
  EMAIL: {
    TYPE: 'email',
    VALUE: 'hello@dobbyhome.com'
  }
};

export const media = {
  LINKEDIN: {
    TYPE: 'linkedin',
    IMG: imgMlinkedin,
    URL: 'https://www.linkedin.com/company/hellodobby/',
    ALT: 'linkedin'
  },
  INSTAGRAM: {
    TYPE: 'instagram',
    IMG: imgMinstagram,
    URL: 'https://www.instagram.com/dobbyhomes/',
    ALT: 'instagram'
  },
  FACEBOOK: {
    TYPE: 'facebook',
    IMG: imgFacebook,
    URL: 'https://www.facebook.com/dobbyhomes/',
    ALT: 'facebook'
  }
  // YOUTUBE: {
  //   TYPE: 'youtube',
  //   IMG: imgYoutube,
  //   URL: 'https://www.youtube.com/channel/UCG3H9K0SH83tLbnzZJusTTQ',
  //   ALT: 'YouTube'
  // }
};
