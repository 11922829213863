import React from 'react';

import { pageTypes } from 'common/enums/pages';
import { Text, TypeForm } from 'components';

import { Container, Heading, Section, TextWrapper } from './styled';

const JoinUs = () => {
  return (
    <Section>
      <Container>
        <Heading>Build with us</Heading>
        <TextWrapper>
          <Text description2 textAlign="center">
            {
              ' Are you interested in learning more about opportunities at Dobby? \nWe want to hear from you.'
            }
          </Text>
        </TextWrapper>
        <TypeForm pageType={pageTypes.ABOUT_US} />
      </Container>
    </Section>
  );
};

export default JoinUs;
