import styled from 'styled-components/macro';
import styles from 'common/enums/styles';
import { TEAM_DESKTOP_WIDTH, TEAM_TABLET_WIDTH } from './constants';

export const Section = styled.section`
  background-color: ${styles.components.BACKGROUND};
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const TeamContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  margin: 0 auto;
  padding: 48px 4px 29px;
  min-width: fit-content;
  max-width: 1024px;

  @media screen and (min-width: ${TEAM_TABLET_WIDTH}px) {
    padding: 48px 16px 77px;
    align-items: center;
    justify-content: space-around;
  }

  @media screen and (min-width: ${TEAM_DESKTOP_WIDTH}px) {
    justify-content: center;
    padding: 80px 4px 77px;
    max-width: ${TEAM_DESKTOP_WIDTH}px;
  }
`;

export const RowContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (min-width: ${TEAM_TABLET_WIDTH}px) {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-around;
  }

  @media screen and (min-width: ${TEAM_DESKTOP_WIDTH}px) {
    justify-content: center;
  }
`;
