import styled from 'styled-components/macro';
import styles from 'common/enums/styles';
import { device } from 'common/enums/devices';

export const Wrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: flex-start;
  gap: 10px;
  background-color: ${({ isLighter }) => (isLighter ? '#FCFCFA' : '#2E30450D')};
  padding: 30px 38px 25px 12px;
  width: 100%;
  max-width: 564px;
  min-height: 220px;
  border-radius: 8px;
  white-space: break-spaces;
  @media screen and ${device.TABLET} {
    min-height: auto;
  }
`;

export const Stars = styled.div`
  display: grid;
  gap: 5px;
  justify-content: flex-start;
  grid-auto-flow: column;
  margin-bottom: 16px;
`;
export const Star = styled.img`
  width: 24px;
  height: 24px;
`;

export const NameBox = styled.div`
  display: grid;
  gap: 5px;
  grid-auto-flow: column;
  justify-content: flex-start;
  margin-top: 11px;
`;

export const TextGray = styled.span`
  color: ${styles.colors.GRAY_NEW};
`;

export const ImageWrapper = styled.div`
  text-align: center;
  width: 112px;
`;

export const Image = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 40px;
  filter: drop-shadow(2px 2px 8px rgba(0, 0, 0, 0.1));
`;

export const ProName = styled.p`
  margin-top: 8px;
  font-size: 14px;
  line-height: 1.2;
  color: ${styles.colors.DARK_BLUE};
  font-weight: 600;
`;

export const ProPosition = styled.p`
  margin-top: 3px;
  font-size: 13px;
  line-height: 1.2;
  color: ${styles.colors.GRAY_NEW};
  font-weight: 600;
  @media screen and ${device.TABLET} {
    margin-top: 5px;
  }
`;

export const grayTextStyle = {
  color: styles.colors.GRAY_NEW
};
