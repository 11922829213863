import React from 'react';

import { GetApp } from 'components';
import { Card, Box, BoxText, BoxTitle, BoxContent, BoxImage } from './styled';
import quoteImg from 'static/images/icons/category-quote.svg';

const CategoryItemLast = ({ lastBoxConent, order, entryPoint }) => (
  <Card order={order}>
    <Box>
      <BoxImage src={quoteImg} />
      <BoxContent>
        <BoxTitle>{lastBoxConent.TITLE}</BoxTitle>
        <BoxText>{lastBoxConent.TEXT}</BoxText>
        <GetApp isCentered mobileOnly entryPoint={entryPoint} />
      </BoxContent>
    </Box>
  </Card>
);

export default CategoryItemLast;
