import actionTypes from 'common/enums/actionTypes';

const defaultState = {
  publications: [],
  publication: {},
  isLoadingPublication: false,
  isLoadingPublications: false
};

function reducer(state = defaultState, action) {
  switch (action.type) {
    case actionTypes.GET_PUBLICATION_FETCH: {
      return {
        ...state,
        isLoadingPublication: true
      };
    }
    case actionTypes.GET_PUBLICATIONS_FETCH: {
      return {
        ...state,
        isLoadingPublications: true
      };
    }
    case actionTypes.GET_PUBLICATIONS_SUCCESS: {
      return {
        ...state,
        isLoadingPublications: false,
        publications: action.payload
      };
    }
    case actionTypes.GET_PUBLICATION_SUCCESS: {
      return {
        ...state,
        isLoadingPublication: false,
        publication: action.payload
      };
    }
    case actionTypes.GET_PUBLICATION_ERROR: {
      return {
        ...state,
        isLoadingPublication: false,
        publication: action.payload
      };
    }
    case actionTypes.GET_PUBLICATIONS_ERROR: {
      return {
        ...state,
        isLoadingPublications: false
      };
    }
    default:
      return state;
  }
}

// selectors
export const selectPublications = state => state.attic.publications;
export const selectPublication = state => state.attic.publication;
export const selectLoadingPublication = state =>
  state.attic.isLoadingPublication;
export const selectLoadingPublications = state =>
  state.attic.isLoadingPublications;

export default reducer;
