export const values = {
  VALUE_1: {
    TYPE: 1,
    TITLE: 'Think community',
    TEXT:
      'We care about improving our local communities and uphold a "do well, do good" philosophy.'
  },
  VALUE_2: {
    TYPE: 2,
    TITLE: "Build bots, don't become a bot",
    TEXT:
      'Automate everything, but operate with love and compassion, critical thinking, and creativity.'
  },
  VALUE_3: {
    TYPE: 3,
    TITLE: 'Lead by serving others',
    TEXT:
      'We work for our team. We believe that trust, respect, and opportunity motivate people to shine.'
  },
  VALUE_4: {
    TYPE: 4,
    TITLE: 'Bring clarity and energy',
    TEXT:
      ' We promote clear thinking, clean writing, precise articulation, and a playful style in a safe environment that welcomes differences.'
  },
  VALUE_5: {
    TYPE: 5,
    TITLE: 'Care deeply',
    TEXT:
      'We believe that one can defy any odds by caring intensely about the work and customers.'
  }
};
