import styled from 'styled-components';

import styles from 'common/enums/styles';
import { device } from 'common/enums/devices';

export const Section = styled.section`
  padding-top: 100px;
  background-color: ${styles.components.BACKGROUND_LIGHT};
`;

export const Wrapper = styled.div`
  max-width: ${styles.layout.MAX_WIDTH};
  margin: 0 10px;
  padding-bottom: 60px;

  @media screen and ${device.LAPTOP} {
    margin: 0 8vh;
  }
`;

export const Title = styled.h1`
  color: ${styles.components.HEADER_INVERTED};
  margin-bottom: 10px;
  text-align: center;

  @media screen and ${device.LAPTOP} {
    margin-bottom: 24px;
    white-space: pre-line;
  }
`;

export const TableWrapper = styled.div`
  overflow: scroll;
  // always display horizontal scroll on mobile:
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    display: block;
    height: 5px;
    width: 5px;
  }

  ${({ isMobile }) =>
    isMobile &&
    `
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar {
      display: block;
      height: 5px;
      width: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background: ${styles.components.HIGHLIGHT};
      border-radius: 5px;
    }
  `}

  @media screen and ${device.LAPTOP} {
    overflow: none;
  }
`;

export const Table = styled.div`
  margin-top: 40px;
  display: grid;
  grid-template-columns: 1fr 1.5fr fr;

  min-width: 355px;
  margin: 20px 10px;

  @media screen and ${device.LAPTOP} {
    grid-template-columns: 1.6fr 2fr 1.5fr;
    margin: 30px 10vw 20px 8vw;
    min-width: 1024px;
    max-width: 100%;
  }
`;

export const Column = styled.div`
    display: grid;
    grid-template-rows: 0.4fr 0.9fr 0.6fr 0.9fr 1fr 1fr;
    background-color: ${({ background }) => background};
    color: ${({ color }) => color}
    font-size: 16px;
    line-height: 1.2;
    border-radius: 4px;
    grid-row: 1;

    ${({ isHighligted }) =>
      isHighligted
        ? `
      padding: 40px 0;
      z-index: 2;
      // box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.25);
    `
        : `
      margin: 40px 0;
    `}

    &:first-child {
      font-weight: bold;
      min-width: 100px;
    }

    &:last-child {
      margin-right: 0;
    }

    min-width: 120px;
    @media screen and ${device.LAPTOP} {
      grid-template-rows: 0.4fr 0.75fr 0.6fr 0.9fr 1fr 1.1fr;
      font-size: 24px;
    }
`;

export const Cell = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 1.5vw;

  ${({ fontSize }) =>
    fontSize &&
    `
  font-size:  => ${fontSize}px;
  `}

  div a svg {
    margin-top: 10px;
    height: 24px;
  }

  @media screen and ${device.LAPTOP} {
    font-size: ${({ fontSize = 24 }) => fontSize}px;
    min-width: 200px;

    div a svg {
      height: 64px;
    }
  }

  border-bottom: 1px dashed ${styles.colors.PURE_BLACK};

  justify-content: ${({ center }) => (center ? 'center' : 'start')};
  align-items: ${({ center }) => (center ? 'center' : 'start')};

  &:last-child {
    border: none;
  }
`;

export const HeaderCell = styled(Cell)`
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: bold;
  top: 0;
  padding: 0;

  ${({ background }) => background && `background-color: ${background};`}

  @media screen and ${device.LAPTOP} {
    padding: 20px 1.5vw;
  }
`;

export const CellText = styled.div`
  display: inline-block;
`;

export const Link = styled.a`
  color: ${styles.components.TEXT};
  text-decoration: underline;
`;

export const LogoWrapper = styled.div`
  margin: 0 auto;
  @media screen and ${device.LAPTOP} {
    margin: 0;
  }
`;

export const CategoryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  white-space: pre-wrap;
`;

export const Category = styled.span`
  font-weight: bold;
`;

export const SubCategory = styled.span`
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
`;
