import React, { useMemo } from 'react';

import { team } from './enums';
import { Section, TeamContainer, RowContainer } from './styled';
import { Member } from './components';

const Team = () => {
  const { firstPart, secondPart } = useMemo(() => {
    const arrTeam = Object.values(team);
    const firstPart = [];
    const secondPart = [];
    const halfSize = parseInt((arrTeam.length - 1) / 2);
    arrTeam.forEach((item, i) =>
      i < halfSize ? firstPart.push(item) : secondPart.push(item)
    );
    return { firstPart, secondPart };
  }, []);
  return (
    <Section>
      <TeamContainer>
        <RowContainer>
          {firstPart.map((member, i) => (
            <Member member={member} key={`member-${i}`} />
          ))}
        </RowContainer>
        <RowContainer>
          {secondPart.map((member, i) => (
            <Member member={member} key={`eng-member-${i}`} />
          ))}
        </RowContainer>
      </TeamContainer>
    </Section>
  );
};

export default Team;
