import React from 'react';
import PropTypes from 'prop-types';
import StyledLink from './styled';

const LinkWithArrow = ({ linkText, linkUrl }) => <StyledLink to={linkUrl}>{linkText}</StyledLink>;

LinkWithArrow.propTypes = {
    linkText: PropTypes.string.isRequired,
    linkUrl: PropTypes.string.isRequired
};

export default LinkWithArrow;
