import React from 'react';
import { BackgroundImage, Text } from 'components';
import styles from 'common/enums/styles';
import { pageTypes } from 'common/enums/pages';

import { ColorWrapper, Section, Container, Title } from './styled';

const Hero = ({
  alt = 'background',
  background,
  backgroundType,
  desktopHeight,
  children,
  imageSrc,
  mobileBackground,
  placeholderSrc,
  subTitle,
  title
}) => {
  const hasTopBar = pageTypes.HOME === backgroundType;
  return (
    <ColorWrapper
      background={background}
      type={backgroundType}
      hasTopBar={hasTopBar}
      id="mainSection">
      <Section desktopHeight={desktopHeight}>
        <BackgroundImage
          alt={alt}
          src1x={imageSrc}
          mobileBackground={mobileBackground}
          placeholderSrc={placeholderSrc}
          type={backgroundType}
        />
        <Container type={backgroundType}>
          {Boolean(title) && <Title type={backgroundType}>{title}</Title>}
          {Boolean(subTitle) && (
            <Text
              description1
              color={styles.colors.WHITE_NEW}
              textAlign="center">
              {subTitle}
            </Text>
          )}
          {children}
        </Container>
      </Section>
    </ColorWrapper>
  );
};

export default Hero;
