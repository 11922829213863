import { call, takeLatest, put } from 'redux-saga/effects';
import api_constants from 'common/enums/api';
import actionTypes from 'common/enums/actionTypes';
import Api from 'services/ApiService';

function* getArticle({ payload }) {
  const { slug } = payload;
  try {
    const response = yield call(
      Api.cmsGet,
      `?filters[slug][$eq]=${slug}&populate=*`
    );

    if (response.data.length > 0) {
      yield put({
        type: actionTypes.GET_ARTICLE_SUCCESS,
        payload: response.data[0].attributes
      });
    } else {
      yield put({
        type: actionTypes.GET_ARTICLE_ERROR
      });
    }
  } catch (err) {
    yield put({
      type: actionTypes.GET_ARTICLE_ERROR,
      payload: 'Something went wrong. Try again later'
    });
  }
}

export default function* watchGetArticle() {
  yield takeLatest(actionTypes.GET_ARTICLE_FETCH, getArticle);
}
