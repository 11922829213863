import { API } from 'aws-amplify';
import { cms_links } from 'common/constants';
import { APP_ENV } from 'common/constants/app';
import { appEnvs } from 'common/enums/app';

const apiEndpoint =
  APP_ENV === appEnvs.PRODUCT
    ? 'https://product.api.hellodobby.com/v0'
    : 'https://staging.api.hellodobby.com/v0';

const cmsEndpoint = cms_links[APP_ENV];

const statusHelper = response => {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }
  return Promise.reject(
    new Error(
      response.statusText || 'Something went wrong, please try again later'
    )
  );
};

class Api {
  static apiGet(path, params) {
    return fetch(`${apiEndpoint}${path}`, params)
      .then(res => statusHelper(res))
      .then(data => {
        return data.json();
      })
      .catch(err => {
        return err;
      });
  }

  static apiPost(path, data, headers = { 'Content-Type': 'application/json' }) {
    const params = {
      method: 'POST',
      headers
    };
    if (data) {
      params.body = JSON.stringify(data);
    }
    return fetch(`${apiEndpoint}${path}`, params)
      .then(res => statusHelper(res))
      .then(data => {
        return data.json();
      })
      .catch(err => {
        return err;
      });
  }

  // static apiPut(path, body, headers = { 'Content-Type': 'application/pdf' }) {
  //   return fetch(path, {
  //     method: 'PUT',
  //     headers,
  //     body
  //   })
  //     .then(res => statusHelper(res))
  //     .then(data => {
  //       return data;
  //     })
  //     .catch(err => {
  //       return err;
  //     });
  // }

  static amplifyPost(path, body) {
    return API.post('mainApi', path, { body })
      .then(data => {
        return data;
      })
      .catch(err => {
        throw new Error(err);
      });
  }

  static cmsGet(path) {
    console.log('cmsEndpoint', cmsEndpoint);

    return fetch(`${cmsEndpoint}${path}`)
      .then(res => statusHelper(res))
      .then(data => {
        return data.json();
      })
      .catch(err => {
        return err;
      });
  }
}

export default Api;
