import styled from 'styled-components/macro';
import styles from 'common/enums/styles';
import { device } from 'common/enums/devices';

export const Card = styled.article`
  position: relative;
  padding: 24px;
  background-color: ${styles.colors.WHITE_NEW};
  max-width: 343px;
  width: 100%;
  height: auto;
  display: grid;
  justify-items: baseline;
  align-content: baseline;
  &:first-of-type h2 {
    width: 100%;
  }

  @media screen and ${device.TABLET} {
    width: 343px;
    align-items: baseline;
    height: fit-content;
    padding: 40px 40px 55px;
  }
  @media screen and (min-width: 1160px) {
    width: 360px;
    max-width: none;
    ${({ order }) => order && `order: ${order}`};
  }

  &:first-of-type {
    @media screen and ${device.TABLET} {
      padding-bottom: 81px;
    }
  }
  &:nth-of-type(2) {
    @media screen and ${device.TABLET} {
      padding-bottom: 39px;
    }
  }
  &:nth-of-type(3) {
    @media screen and ${device.TABLET} {
      padding-bottom: 58px;
    }
  }
  &:nth-of-type(4) {
    @media screen and ${device.TABLET} {
      padding-bottom: 37px;
    }
  }
  &:nth-of-type(5) {
    @media screen and ${device.TABLET} {
      padding-bottom: 78px;
    }
  }
  &:nth-of-type(6) {
    @media screen and ${device.TABLET} {
      padding-bottom: 72px;
    }
  }
  &:nth-of-type(7) {
    @media screen and ${device.TABLET} {
      padding-bottom: 38px;
    }
  }
`;

export const CardImageWrap = styled.div`
  width: 100%;
  display: grid;
  justify-content: flex-start;
`;

export const CardImage = styled.img`
  width: 100px;
  height: 100px;
`;

export const CardTitle = styled.h2`
  width: 100%;
  font-weight: 700;
  letter-spacing: 1px;
  margin-bottom: 0;
  margin-top: 24px;
  font-size: 22px;
  line-height: 1.2;
  white-space: nowrap;
  @media screen and ${device.TABLET} {
    letter-spacing: 0;
    max-width: 189px;
    font-size: 32px;
    width: 100%;
    white-space: break-spaces;
  }
`;

export const CardContent = styled.div`
  display: grid;
  align-content: center;
  justify-items: baseline;
`;
export const Box = styled.div`
  display: grid;
  justify-items: baseline;
  @media screen and ${device.TABLET} {
    grid-auto-flow: column;
    gap: 47px;
  }
`;

export const ListWrapper = styled.div`
  margin-top: 18px;
  height: ${({ isActive, height }) => (isActive ? `${height}` : `${5 * 24}`)}px;
  overflow: hidden;
  transition: height 0.3s;
  @media screen and ${device.TABLET} {
    height: 100%;
  }
`;

export const List = styled.ul`
  padding-left: 23px;
  width: 290px;
  @media screen and ${device.TABLET} {
    width: 275px;
  }
`;

export const ListItem = styled.li`
  position: relative;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
  :before {
    content: '·';
    position: absolute;
    left: -16px;
    top: 0;
    z-index: 1;
    font-size: 27px;
    vertical-align: middle;
    line-height: 0.87;
  }
`;

export const More = styled.button`
  font-weight: 600;
  font-size: 18px;
  line-height: 1.2;
  margin-top: 16px;
  margin-left: 8px;
  background: transparent;
  border: none;
  outline: none;
  color: ${styles.colors.DARK_BLUE};
  @media screen and ${device.TABLET} {
    display: none;
  }
`;
export const MoreIcon = styled.img`
  ${({ isActive }) => isActive && 'transform: rotate(180deg)'};
`;
