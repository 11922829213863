import React from 'react';
import PropTypes from 'prop-types';

import IconClose from './IconClose';
import IconClose2 from './IconClose2';
import IconLocationPin from './IconLocationPin';
import IconChain from './IconChain';
import IconExclamation from './IconExclamation';
import IconPhone from './IconPhone';

import { Background, Image } from './styled';

const mapNameToIconSvg = {
  close: IconClose,
  close2: IconClose2,
  location: IconLocationPin,
  chain: IconChain,
  exclamation: IconExclamation,
  phone: IconPhone
};

const Icon = ({
  src = '',
  name = '',
  size,
  width,
  height,
  widthMobile,
  heightMobile,
  color,
  backgroundSize,
  backgroundSizeMobile,
  backgroundColor
}) => {
  const IconClass = mapNameToIconSvg[name];
  return (
    <Background
      backgroundColor={backgroundColor || size}
      backgroundSize={backgroundSize || size}
      backgroundSizeMobile={backgroundSizeMobile || backgroundSize || size}>
      {src ? (
        <Image
          color={color}
          height={height || size}
          heightMobile={heightMobile || height || size}
          size={size}
          src={src}
          width={width || size}
          widthMobile={widthMobile || width || size}
        />
      ) : IconClass ? (
        <IconClass
          color={color}
          height={height || size}
          heightMobile={heightMobile || height || size}
          size={size}
          width={width || size}
          widthMobile={widthMobile || width || size}
        />
      ) : null}
    </Background>
  );
};

Icon.propTypes = {
  backgroundColor: PropTypes.string,
  backgroundSize: PropTypes.number,
  color: PropTypes.string,
  name: PropTypes.string,
  size: PropTypes.number,
  src: PropTypes.string.isRequired
};

export default Icon;
