const routes = {
  HOME: '/',
  ARTICLE: '/articles/:slug',
  AUTH: '/auth',
  LANDING_BAY: '/welcome/bay-area',
  ABOUT_US: '/about-us',
  ASK_US_ANYTHING: '/home-explained',
  CATEGORIES: '/categories',
  PARTNERS: '/become-a-partner',
  REVIEWS: '/5-star-reviews',
  REVIEWS_5_STAR: '/5-star',
  PROVIDER: '/stars-of-the-show',
  PRIVACY_POLICY: '/privacy-policy',
  ATTIC: '/attic',
  CHECK_THE_PRICE: '/check-the-price',
  PLANNING_MODE: '/planning-mode',
  ATTIC_PUBLICATION: '/attic/:id',
  DOBBY_HO: '/dobby-homeowner',
  FAQ: '/faq',
  DOBBY_CARE: '/dobby-care',
  DOBBY_SUBSCRIPTION: '/dobby-care/subscription',
  // TERMS_OF_SERVICE: '/terms-of-service',
  UPLOAD_REPORT: '/upload-report',
  ROLE_DESCRIPTION: '/role-description',
  PRESS_RELEASE: '/press-release',
  GROW_TOGETHER: '/grow-together',
  GET_STARTED: '/get-started',
  SUCCESS_EMAIL_VERIFICATION: '/verify-success',
  GET_STARTED_REDIRECT: '/hi',
  LOVE_OUR_PLANET: '/love-our-planet',
  BUILD_TOGETHER: '/build-together',
  COMMUNITY: '/community',
  UNSUBSCRIBE: '/unsubscribe',
  MEET_DOBBY: '/meet-dobby',
  JOIN_US_DMV: '/join-us-dmv',
  JOIN_US_CHARLOTTE: '/join-us-charlotte',
  HANDYMAN_CONFIRMED: '/handyman_confirmed',
  SUCCESSFUL_BOOKING: '/successful-booking',
  // redirect links:
  DOWNLOAD: '/download',
  DOWNLOAD_DOBBY_APP: '/app',
  DOBBY_IN_APP_LINK: '/app/*',
  DOWNLOAD_DOBBY_PRO_APP: '/pro_app',
  REFERRAL_HOMEOWNER: '/(r|dobby_app)/:referralId',
  EMPTY_REFERRAL_HOMEOWNER1: '/dobby_app',
  EMPTY_REFERRAL_HOMEOWNER2: '/dobby-app',
  EMPTY_REFERRAL_HOMEOWNER3: '/dobby',
  REFERRAL_PROVIDER: '/(pro|service_pro_app)/:referralId',
  EMPTY_REFERRAL_PROVIDER: '/service_pro_app',
  REDIRECT: '/redirect',
  REDIRECT_HAPPY_HOMES: '/happyhomes',
  REDIRECT_DOBBY: '/link/app/:componentPath',
  REDIRECT_DOBBY_ARTICLE: '/link/app/article/:articleID',
  REDIRECT_DOBBY_CHECKLIST: '/link/app/checklist/:checklistID',
  REDIRECT_PROVIDER: '/link/pro/:componentPath',
  FOUNDERS_LETTER: '/founders-letter'
};

export default routes;
