import React from 'react';

import { Section } from './styled';

const WrapperSection = ({
  hasWhiteBackground,
  paddingMobile,
  paddingDesktop,
  children,
  style
}) => (
  <Section
    hasWhiteBackground={hasWhiteBackground}
    paddingDesktop={paddingDesktop}
    paddingMobile={paddingMobile}
    style={style}>
    {children}
  </Section>
);

export default WrapperSection;
