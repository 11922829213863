import React from 'react';

import { Section, Container, LogoImage } from './styled';

import { logos } from './enums';

const Companies = () => {
  return (
    <Section>
      <h1>Our Investors</h1>
      <Container>
        {Object.values(logos).map((logo, index) => (
          <a
            href={logo.LINK}
            target="_blank"
            rel="noopener noreferrer"
            key={`investor-${index}`}>
            <LogoImage src={logo.IMAGE} hScale={logo.SCALE} alt={logo.ALT} />
          </a>
        ))}
      </Container>
    </Section>
  );
};

export default Companies;
