import styled from 'styled-components';
import { device } from 'common/enums/devices';

const Container = styled.div`
  padding: 0 30px;
  @media screen and ${device.TABLET} {
    padding: 0 7.14%;
  }
`;

export default Container;
