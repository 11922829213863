import styled from 'styled-components';
import styles from 'common/enums/styles';
import { Link } from 'react-router-dom';

const StyledLink = styled(Link)`
  color: ${styles.colors.YELLOW};
  text-decoration: none;
  position: relative;
  padding-right: 30px;
  display: inline-block;
  &:before,
  &:after {
    transition: right 0.2s ease-in-out;
    position: absolute;
    right: 5px;
    display: block;
    content: '';
    border-top: 3px solid ${styles.colors.YELLOW};
    width: 14px;
  }
  &:before {
    transform: rotate(45deg);
    top: 10px;
  }
  &:after {
    transform: rotate(-45deg);
    top: 18px;
  }
  &:hover {
    &:before,
    &:after {
      right: 0;
    }
  }
`;

export default StyledLink;
