import links from 'common/constants/links';

export const getDownloadLink = () => {
  if (
    /* if we're on Android, open in Google*/
    navigator.userAgent.indexOf('Android') !== -1
  ) {
    return {
      downloadLink: links.MARKET_HO_APP_ANDROID,
      downloadLinkPlatform: 'Android'
    };
  }
  return {
    downloadLink: links.MARKET_HO_APP_IOS,
    downloadLinkPlatform: 'iOS'
  };
};

export const replaceSpaceWithDash = param => param.replace(/ /g, '_');

export const generateQueryString = queryParams => {
  const queryEntries = Object.entries(queryParams).filter(entry => entry[1]);
  if (!queryEntries.length) {
    return '';
  }
  const queryParamsStrings = queryEntries.map(
    entry => `${entry[0]}=${encodeURIComponent(replaceSpaceWithDash(entry[1]))}`
  );
  return queryParamsStrings.join('&');
};

export const getFirebaseDobbyDownloadLink = ({
  utmParamsString,
  pathname,
  desktopFallbackLink
}) => {
  const encodedLink = encodeURIComponent(
    `${links.WEBSITE_LINK}${pathname}?${utmParamsString}`
  );
  const { downloadLink: marketLink } = getDownloadLink();
  const dynamicLink = `${
    links.DYNAMIC_LINK_BASE
  }?link=${encodedLink}&apn=com.halosassistant&isi=1445563710&ibi=com.halos.insurance.HalosAssistant&ofl=${desktopFallbackLink ||
    marketLink}`;
  return dynamicLink;
};
