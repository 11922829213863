import actionTypes from 'common/enums/actionTypes';

const defaultState = {
  askUsAnythingLoading: false,
  askUsAnythingResponse: ''
};

function reducer(state = defaultState, action) {
  switch (action.type) {
    case actionTypes.ASK_US_ANYTHING_FETCH: {
      return {
        ...state,
        askUsAnythingLoading: true,
        askUsAnythingResponse: ''
      };
    }
    case actionTypes.ASK_US_ANYTHING_SUCCESS: {
      return {
        ...state,
        askUsAnythingLoading: false,
        askUsAnythingResponse: action.payload?.message || ''
      };
    }
    case actionTypes.ASK_US_ANYTHING_ERROR: {
      return {
        ...state,
        askUsAnythingLoading: false,
        askUsAnythingResponse: ''
      };
    }

    default:
      return state;
  }
}

// selectors
export const askUsAnythingLoading = state =>
  state.question.askUsAnythingLoading;
export const askUsAnythingResponse = state =>
  state.question.askUsAnythingResponse;

export default reducer;
