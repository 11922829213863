import React from 'react';
import imgArrowRight from 'static/images/icons/arrow-right-blue.svg';

import {
  Card,
  CardImage,
  CardTitle,
  CardContent,
  CardTextHover,
  Image
} from './styled';

const HomeItem = ({ item, handleClick }) => {
  return (
    <Card onClick={handleClick}>
      <CardContent>
        <CardImage src={item.IMAGE} />
        <CardTitle>{item.TITLE}</CardTitle>
        <CardTextHover>
          Learn more
          <Image src={imgArrowRight} />
        </CardTextHover>
      </CardContent>
    </Card>
  );
};

export default HomeItem;
