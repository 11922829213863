import React from 'react';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import { MapHolder } from './styled';
import marker from 'static/images/icons/marker.svg';

const center = {
  lat: 38.895359,
  lng: -77.071182
};

function Map() {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyAMU5XgMt3_L1IOgjhwPBjP-QxaOCCXI8s'
  });

  return isLoaded ? (
    <MapHolder>
      <GoogleMap
        mapContainerStyle={{ width: '100%', height: '100%' }}
        center={center}
        zoom={14}
        options={{
          mapTypeControl: false,
          streetViewControl: false,
          styles: [
            {
              stylers: [
                { saturation: -100 },
                { gamma: 0.8 },
                { lightness: 4 },
                { visibility: 'on' }
              ]
            }
          ]
        }}>
        <Marker icon={marker} position={{ lat: 38.895359, lng: -77.071182 }} />
      </GoogleMap>
    </MapHolder>
  ) : (
    <></>
  );
}

export default React.memo(Map);
