import styled from 'styled-components/macro';
import styles from 'common/enums/styles';
import { device } from 'common/enums/devices';

export const Card = styled.article`
  padding: 0 30px;
  background-color: ${styles.colors.WHITE_NEW};
  max-width: 343px;
  width: 100%;
  height: 343px;
  display: grid;
  align-content: center;
  align-items: center;

  h2 {
    width: auto;
  }
  &:nth-of-type(2) h2 {
    @media screen and ${device.LAPTOP_L} {
      max-width: 179px;
    }
  }

  @media screen and ${device.TABLET} {
    width: 343px;
    align-items: baseline;
  }
  @media screen and ${device.LAPTOP_L} {
    width: 370px;
    height: 370px;
    padding: 0 30px;
    max-width: none;
  }

  &:last-of-type {
    align-content: center;
    height: auto;
    padding: 24px 26px 40px;
    @media screen and ${device.TABLET} {
      height: 343px;
    }
    @media screen and ${device.LAPTOP_L} {
      grid-column: 2/4;
      width: 760px;
      padding: 0 97px 0 104px;
      min-height: 370px;
    }
  }
`;

export const Box = styled.div`
  display: grid;
  justify-items: center;
  @media screen and ${device.LAPTOP_L} {
    grid-auto-flow: column;
    gap: 31px;
    align-items: normal;
  }
`;
export const BoxTitle = styled.p`
  font-weight: 700;
  font-size: 24px;
  line-height: 1.2;
  letter-spacing: 0;
  margin-top: 24px;
  @media screen and ${device.LAPTOP_L} {
    font-weight: 700;
    font-size: 32px;
    margin-top: 0;
    padding: 0;
  }
`;

export const BoxText = styled.p`
  font-size: 18px;
  line-height: 1.2;
  margin-top: 9px;
  margin-bottom: 23px;
  @media screen and ${device.LAPTOP_L} {
    font-size: 16px;
    line-height: 1.5;
    margin-top: 5px;
    margin-bottom: 5px;
  }
`;
export const BoxContent = styled.div`
  display: grid;
  text-align: center;
  align-content: baseline;
  justify-items: center;
  text-align: center;
  @media screen and ${device.LAPTOP_L} {
    text-align: left;
    justify-items: baseline;
  }
`;
export const BoxImage = styled.img`
  width: 160px;
  height: 160px;
  @media screen and ${device.TABLET} {
    display: none;
  }
  @media screen and ${device.LAPTOP_L} {
    display: block;
    margin-top: 0;
  }
`;
