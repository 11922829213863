import styled from 'styled-components/macro';

import styles from 'common/enums/styles';
import { pageTypes } from 'common/enums/pages';
import { device } from 'common/enums/devices';

const imageStyles = {
  [pageTypes.ABOUT_US]: `
    background-size: cover;
    background-position: 74.9% 44%;
    @media screen and (max-width: 400px) and (max-height: 800px) {
      background-size: 358% auto;
      background-position: 74.9% 2%;
    }
    @media screen and (min-width: 1240px) {
      background-position: 75.5% 55%;
    }
  `,
  [pageTypes.HOME]: `
    background-size: auto 181%;
    background-position: 36% 46%;
    @media screen and (min-width: 1240px) {
      background-position: 0 83%;
    }
  `,
  [pageTypes.PARTNERS]: `
    background-position: 32.5% 48%;
    @media screen and (min-width: 1240px) {
      background-position: 75.5% 55%;
    }
    `,
  [pageTypes.PROVIDERS]: `
    background-position: 42.5% 48%;
    @media screen and (min-width: 1240px) {
      background-position: 75.5% 55%;
    }
    `,
  [pageTypes.LOVE_OUR_PLANET]: `
    background-position: 42.5% 48%;
    @media screen and (min-width: 1240px) {
      background-position: 76.5% 36%;
    }
    `,
  [pageTypes.PRESS_RELEASE]: `
    background-position: 89.5% 46%;
    @media screen and (min-width: 1240px) {
      background-position: 50% 46%;
    }
    `
};

export const BackgroundVideoHolder = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  // 50% transparent foreground
  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: ${styles.components.BACKGROUND};
    opacity: ${({ light }) => (light ? 0.4 : 0.6)};
    z-index: 3;
  }
`;

export const VideoContainer = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  z-index: 2;
  display: none;

  @media screen and ${device.LAPTOP} {
    display: block;
  }
`;

export const ImagePlaceholder = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url(${({ src }) => src});
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-color: transparent;

  @media screen and ${device.LAPTOP} {
    background-size: cover;
  }
  ${({ type }) => {
    if (imageStyles[type]) {
      return imageStyles[type];
    }
  }}
`;
