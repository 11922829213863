import React from 'react';
import PropTypes from 'prop-types';
import { LoadingSpinner } from '@sourcegraph/react-loading-spinner';
import '@sourcegraph/react-loading-spinner/lib/LoadingSpinner.css';
import { StyledButton, StyledLink } from './styled';

const OutdatedButton = ({
  onClick,
  title,
  width,
  fullWidth,
  widthMobPercentage,
  shadow,
  loading,
  disabled,
  secondary,
  type,
  transparent,
  notShowDisabled,
  href,
  mobileWithoutMargins,
  mobileSquareBorders,
  flexMobile,
  buttonStyle,
  disabledColor,
  disabledFontColor,
  margin,
  padding
}) =>
  type !== 'link' ? (
    <StyledButton
      onClick={!disabled ? onClick : () => {}}
      buttonStyle={type}
      disabled={disabled || loading}
      disabledColor={disabledColor}
      disabledFontColor={disabledFontColor}
      transparent={transparent}
      isLoading={loading}
      width={width}
      fullWidth={fullWidth}
      widthMobPercentage={widthMobPercentage}
      secondary={secondary}
      notShowDisabled={notShowDisabled}
      shadow={shadow}
      mobileWithoutMargins={mobileWithoutMargins}
      mobileSquareBorders={mobileSquareBorders}
      flexMobile={flexMobile}
      style={buttonStyle}
      margin={margin}
      padding={padding}>
      {loading ? <LoadingSpinner /> : title}
    </StyledButton>
  ) : (
    <StyledLink href={href} target="_blank">
      {title}
    </StyledLink>
  );

OutdatedButton.propTypes = {
  onClick: PropTypes.func,
  title: PropTypes.string.isRequired,
  type: PropTypes.oneOf([
    'small',
    'big',
    'secondary',
    'outline',
    'link',
    'white',
    'big-reversed',
    'brand'
  ]),
  href: PropTypes.string, // only if it is 'link'
  disabled: PropTypes.bool,
  transparent: PropTypes.bool,
  loading: PropTypes.bool
};

OutdatedButton.defaultProps = {
  type: 'small',
  onClick: () => {},
  href: '#',
  disabled: false,
  transparent: false,
  loading: false,
  shadow: '',
  flexMobile: true
};

export default OutdatedButton;
