import React, { useState } from 'react';

import { LinkedInIcon } from '../';
import {
  DesktopPhotoContainer,
  MobilePhotoContainer,
  PortraitImage,
  OverlayImage
} from './styled';

const Portrait = ({ linkedinUrl, imageUrl, overlayUrl, alt }) => {
  const [isOverlay, setIsOverlay] = useState(false);
  const handleClick = () =>
    setIsOverlay(value => Boolean(overlayUrl) && !value);
  return (
    <>
      <DesktopPhotoContainer>
        <PortraitImage src={imageUrl} alt={alt} />
        <LinkedInIcon link={linkedinUrl} />
      </DesktopPhotoContainer>
      <MobilePhotoContainer onClick={handleClick}>
        <PortraitImage src={imageUrl} alt={alt} />
        <OverlayImage isVisible={isOverlay} src={overlayUrl} alt={alt} />
      </MobilePhotoContainer>
    </>
  );
};

export default Portrait;
