import styled from 'styled-components/macro';
import styles from 'common/enums/styles';
import { device } from 'common/enums/devices';

export const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  background-color: ${styles.colors.GRAY_TRUE};
  padding: 38px 8px 38px;
  align-items: flex-start;
  -ms-overflow-style: none;
  scrollbar-width: none;
  @media screen and (min-width: 550px) {
    padding: 85px 8px 58px;
    align-items: center;
  }
`;

export const InnerBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0;
  max-width: 542px;
`;

export const WhiteBox = styled.div`
  margin-top: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${styles.colors.WHITE_NEW};
  padding: 40px 20px 40px 20px;
  width: 100%;
  @media screen and ${device.TABLET} {
    padding: 40px;
  }
`;

export const FormHeader = styled.h4`
  font-family: Helvetica, Arial, sans-serif;
  margin: 0;
  padding: 0;
  font-weight: 500;
  font-size: 18px;
  line-height: 1.2;
  letter-spacing: 0.58px;
  text-align: center;
  color: ${styles.colors.DARK_BLUE};
  @media screen and ${device.TABLET} {
    font-size: 24px;
  }
`;

export const FormNotation = styled.div`
  font-family: Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.5;
  color: ${styles.colors.DARK_BLUE};
  width: auto;
  margin-top: 12px;
  text-align: center;
  @media screen and ${device.TABLET} {
    width: 100%;
    margin-top: 11px;
    padding: 0 36px;
  }
`;

export const HeadBox = styled.div`
  display: grid;
  justify-items: center;
  margin: 0 0 38px;
`;
