import dataRobot from 'static/images/brands/about-us-data-robot.svg';
import deloitte from 'static/images/brands/about-us-deloitte.svg';
import libertyMutual from 'static/images/brands/about-us-liberty-mutual.svg';
import motive from 'static/images/brands/about-us-motive.svg';
import uber from 'static/images/brands/about-us-uber.svg';
import aig from 'static/images/brands/about-us-aig.svg';
import weWork from 'static/images/brands/about-us-we-work.svg';

export const logos = {
  WE_WORK: {
    IMAGE: weWork,
    ALT: 'weWork'
  },
  DATA_ROBOT: {
    IMAGE: dataRobot,
    ALT: 'Data Robot'
  },
  AIG: {
    IMAGE: aig,
    ALT: 'AIG'
  },
  // UBER: {
  //   IMAGE: uber,
  //   ALT: 'Uber'
  // },
  LIBERTY_MUTUAL: {
    IMAGE: libertyMutual,
    ALT: 'Liberty Mutual'
  },
  DELOITTE: {
    IMAGE: deloitte,
    ALT: 'Deloitte'
  }
  // MOTIVE: {
  //   IMAGE: motive,
  //   ALT: 'Motive'
  // }
};
