import { SquareButton } from 'components';
import {
  Wrapper,
  Title,
  SubTitle,
  InfoSection,
  ButtonsSection,
  InfoTitle,
  InfoText
} from './styled';
import React from 'react';
import {
  setSubscription,
  setSubscriptionStep
} from 'store/actions/subscription';
import { connect } from 'react-redux';
import {
  selectAuthUser,
  selectLoadingSubscription
} from 'store/reducers/subscription';
import { useHistory } from 'react-router-dom';
import { prettifyPhone } from 'common/helpers';

const NoUser = ({ setSubscriptionStep, setSubscription, user, isLoading }) => {
  const history = useHistory();
  const isUA = history.location.hash === '#ua';
  return (
    <Wrapper>
      <Title>Oops! We couldn't find your account</Title>
      <SubTitle>We'll text you to get you set up now</SubTitle>

      <InfoSection>
        <div>
          <InfoTitle>Your Phone Number</InfoTitle>
          <InfoText>{prettifyPhone(user.phone_number, isUA)}</InfoText>
        </div>
      </InfoSection>

      <ButtonsSection>
        <SquareButton
          loading={isLoading}
          fontSize={16}
          onClick={() => setSubscription({ id: user?.id })}>
          Send SMS & Proceed
        </SquareButton>

        <a href="#phone">
          <SquareButton
            white
            fontSize={16}
            onClick={() => setSubscriptionStep('')}>
            Edit phone number
          </SquareButton>
        </a>
      </ButtonsSection>
    </Wrapper>
  );
};

const mapStateToProps = state => ({
  isLoading: selectLoadingSubscription(state),
  user: selectAuthUser(state)
});
const mapDispatchToProps = {
  setSubscriptionStep,
  setSubscription
};

export default connect(mapStateToProps, mapDispatchToProps)(NoUser);
