import React from 'react';

import { TopLogo, IconList, Reviews } from 'components';
import { pageTypes } from 'common/enums/pages';
import { TextForm } from './components';
import { items } from './enums';
import { reviews, reviewsSection } from 'common/enums/reviews';

const MeetDobby = () => (
  <>
    <TopLogo />
    <TextForm isTop hasNudeBackground />
    <IconList
      type={pageTypes.MEET_DOBBY}
      noWaves
      items={items}
      title={'How Dobby works'}
    />
    <Reviews
      reviews={reviews}
      reviewsSection={reviewsSection}
      hasNudeBackground
      type={pageTypes.MEET_DOBBY}
    />
    <TextForm />
  </>
);

export default MeetDobby;
