import React, { useState } from 'react';
import { connect } from 'react-redux';
import NumberFormat from 'react-number-format';
import { useLocation } from 'react-router-dom';

import { captchaActions } from 'common/enums/pages';
import { sendMeTheLink } from 'store/actions/user';
import { selectSendMeTheLinkLoading } from 'store/reducers/loading';
import { selectModalData } from 'store/reducers/modal';
import { Modal } from 'modals/components';
import { Input, Button } from 'components';
import { CAPTCHA_SITE_KEY } from 'common/constants/html';

import { InputWrapper, InputPrefix, Container, InputStyles } from './styled';

const SendMeTheLink = ({ loading, sendMeTheLink, modalData }) => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const location = useLocation();

  const phoneNumberCode = location.hash === '#ua' ? '+38.' : '+1.';

  const handleClick = () => {
    const number = (phoneNumberCode + phoneNumber).replaceAll('.', '');
    // captchaRef.execute();
    window.grecaptcha
      .execute(CAPTCHA_SITE_KEY, {
        action: captchaActions.SEND_ME_THE_LINK.SUBMIT
      })
      .then(token => {
        sendMeTheLink({
          security_token: token,
          phone_number: number,
          data: modalData
        });
      });
  };

  const isDisabled = Boolean(
    !phoneNumber || (phoneNumber && phoneNumber.indexOf(' ') >= 0)
  );

  return (
    <Modal
      visible
      title="Let us manage your home"
      text={
        "Enter your phone number and we'll send you a link to\ndownload Dobby"
      }
      borderRadius={30}
      centeredContent={true}
      lightBackground={true}>
      <Container>
        <InputWrapper>
          <InputPrefix left={location.hash === '#ua' ? 39 : 48}>
            {phoneNumberCode}
          </InputPrefix>
          <NumberFormat
            type="tel"
            format={`###.###.####`}
            padding="3px 91px 2px 72px"
            customInput={Input}
            backgroundNude
            isPhone
            placeholder={`202.460.7470`}
            smallBottomMargin={true}
            value={phoneNumber}
            InputStyles={InputStyles}
            onValueChange={values => {
              const { formattedValue } = values;
              setPhoneNumber(formattedValue);
            }}
          />
        </InputWrapper>
        <Button
          title="Download Dobby"
          onClick={handleClick}
          disabled={isDisabled}
          loading={loading}
        />
      </Container>
    </Modal>
  );
};

const mapStateToProps = state => ({
  loading: selectSendMeTheLinkLoading(state),
  modalData: selectModalData(state)
});

const mapDispatchToProps = {
  sendMeTheLink
};

export default connect(mapStateToProps, mapDispatchToProps)(SendMeTheLink);
