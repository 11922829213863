import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import navigation from './navigation';
import modal from './modal';
import user from './user';
import loading from './loading';
import question from './question';
import review from './review';
import attic from './attic';
import article from './article';
import subscription from './subscription';
import planningMode from './planningMode';

const appPersistConfig = {
  key: 'user',
  storage: storage
};

const rootReducer = combineReducers({
  user: persistReducer(appPersistConfig, user),
  form: formReducer,
  modal,
  question,
  attic,
  review,
  loading,
  navigation,
  subscription,
  planningMode,
  article
});

export default rootReducer;
