import styled from 'styled-components';
import { device } from 'common/enums/devices';

export const Wrapper = styled.div`
  margin: auto;
  padding: 77px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
`;

export const EnterPhone = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 1.2;
  color: #2e3045;
  margin: 30px 0 8px 2px;
  text-align: center;
`;

export const EnterPhoneSection = styled.div`
  max-width: 514px;
  margin: 0 auto;
`;

export const PhoneInput = styled.div`
  display: flex;

  gap: 32px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  input {
    margin-right: unset;
    max-width: unset;
  }
`;

export const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 80px;
  @media screen and ${device.TABLET} {
    padding-top: 100px;
  }
`;
