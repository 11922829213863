import React from 'react';
import {
  MobileBottomContainer,
  MobileBottomText,
  ArrowImageWrapper
} from './styled';
import greenGradient from 'static/images/backgrounds/our-planet-bg-1.jpg';

const ArrowIcon = () => (
  <svg
    width="37"
    height="22"
    viewBox="0 0 37 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.5227 19.2298L18.5227 19.2299L19.2299 18.5227L19.2298 18.5227L34.0453 3.70711L33.3382 3L18.5227 17.8156L3.70711 3L3 3.70711L17.8156 18.5227L17.8155 18.5227L18.5226 19.2299L18.5227 19.2298Z"
      fill="white"
    />
    <path
      d="M18.5227 19.2299L17.4676 20.296L18.5282 21.3457L19.5834 20.2905L18.5227 19.2299ZM18.5227 19.2298L19.5778 18.1636L18.5227 17.1194L17.4675 18.1636L18.5227 19.2298ZM19.2299 18.5227L20.2905 19.5834L21.3457 18.5282L20.296 17.4676L19.2299 18.5227ZM19.2298 18.5227L18.1691 17.462L17.1139 18.5172L18.1636 19.5778L19.2298 18.5227ZM34.0453 3.70711L35.106 4.76777L36.1666 3.70711L35.106 2.64645L34.0453 3.70711ZM33.3382 3L34.3989 1.93934L33.3382 0.87868L32.2775 1.93934L33.3382 3ZM18.5227 17.8156L17.462 18.8762L18.5227 19.9369L19.5833 18.8762L18.5227 17.8156ZM3.70711 3L4.76777 1.93934L3.70711 0.87868L2.64645 1.93934L3.70711 3ZM3 3.70711L1.93934 2.64645L0.87868 3.70711L1.93934 4.76777L3 3.70711ZM17.8156 18.5227L18.8762 19.5833L19.9369 18.5227L18.8762 17.462L17.8156 18.5227ZM17.8155 18.5227L16.7548 17.4621L15.6941 18.5227L16.7548 19.5834L17.8155 18.5227ZM18.5226 19.2299L17.4619 20.2905L18.5171 21.3457L19.5777 20.296L18.5226 19.2299ZM19.5779 18.1637L19.5778 18.1636L17.4675 20.2959L17.4676 20.296L19.5779 18.1637ZM18.1692 17.4621L17.4621 18.1692L19.5834 20.2905L20.2905 19.5834L18.1692 17.4621ZM18.1636 19.5778L18.1637 19.5779L20.296 17.4676L20.2959 17.4675L18.1636 19.5778ZM20.2904 19.5833L35.106 4.76777L32.9847 2.64645L18.1691 17.462L20.2904 19.5833ZM35.106 2.64645L34.3989 1.93934L32.2775 4.06066L32.9847 4.76777L35.106 2.64645ZM32.2775 1.93934L17.462 16.7549L19.5833 18.8762L34.3989 4.06066L32.2775 1.93934ZM2.64645 4.06066L17.462 18.8762L19.5833 16.7549L4.76777 1.93934L2.64645 4.06066ZM4.06066 4.76777L4.76777 4.06066L2.64645 1.93934L1.93934 2.64645L4.06066 4.76777ZM18.8762 17.462L4.06066 2.64645L1.93934 4.76777L16.7549 19.5833L18.8762 17.462ZM16.7549 17.462L16.7548 17.4621L18.8761 19.5834L18.8762 19.5833L16.7549 17.462ZM16.7548 19.5834L17.4619 20.2905L19.5832 18.1692L18.8761 17.4621L16.7548 19.5834ZM19.5777 20.296L19.5778 20.2959L17.4675 18.1636L17.4674 18.1637L19.5777 20.296Z"
      fill="white"
    />
  </svg>
);

const ScrollToPlantTree = ({ onClick }) => (
  <MobileBottomContainer onClick={onClick} src={greenGradient}>
    <MobileBottomText>Plant a tree with Dobby this Earth Day</MobileBottomText>
    <ArrowImageWrapper>
      <ArrowIcon />
    </ArrowImageWrapper>
  </MobileBottomContainer>
);

export default ScrollToPlantTree;
