import styled from 'styled-components/macro';
import styles from 'common/enums/styles';
import { deviceSizes, device } from 'common/enums/devices';

export const WrapperSection = styled.div`
  width: 100%;
  background-color: ${styles.colors.NUDE};
`;

export const Wrapper = styled.div`
  max-width: 950px;
  position: relative;
  padding: 0 20px 0 20px;

  @media screen and ${device.LAPTOP} {
    padding-top: ${({ withHeader }) =>
      withHeader ? `${styles.layout.MENU_HEIGHT}px` : 0};
    margin: auto;
  }
  @media screen and ${device.LAPTOP_L} {
    max-width: 1346px;
    width: 90%;
  }
`;

export const BuildCommunitySection = styled.section`
  display: flex;
  align-items: center;
  padding: 114px 0 96px 0;
  @media screen and ${device.LAPTOP} {
    justify-content: center;
    padding: 103px 0 102px;
  }
`;

export const HowItWorksSectionWrapper = styled(Wrapper)`
  @media screen and ${device.LAPTOP} {
    margin: 0 auto;
  }
`;

export const ConfirmationButtonWrapper = styled.div`
  width: 200px;
  margin-top: 30px;
  @media screen and ${device.TABLET} {
    margin-top: 33px;
  }
`;

export const ContactsSection = styled.section`
  padding: 60px 0 41px 0;
  @media screen and ${device.LAPTOP} {
    padding: 80px 0;
  }
`;

export const BuildCommunityContent = styled.div`
  flex-basis: 100%;
  @media screen and ${device.LAPTOP} {
    flex-basis: 46%;
  }
`;

export const BuildCommunityPhoto = styled.div`
  display: none;
  @media screen and ${device.LAPTOP} {
    display: block;
  }
`;

export const ProgramSteps = styled.div`
  display: grid;
  justify-content: space-between;
  width: 240px;
  margin-top: 50px;
  @media screen and (min-width: 786px) {
    grid-auto-flow: column;
    justify-content: space-between;
    width: 100%;
    align-items: baseline;
    justify-content: start;
  }
  @media screen and ${device.LAPTOP} {
    justify-content: space-between;
  }
`;

export const ProgramStep = styled.div`
  display: grid;
  gap: 8px;
  margin-bottom: 30px;
  max-width: 227px;
  max-width: 420px;
  &:last-of-type {
    max-width: 300px;
    @media screen and ${device.LAPTOP} {
      max-width: 420px;
    }
  }
  @media screen and ${device.LAPTOP} {
    margin-bottom: 0;
    justify-items: center;
    text-align: center;
  }
`;

export const TopHeader = styled.h1`
  margin: 0;
  padding: 0;
  font-weight: 700;
  letter-spacing: 0;
  color: ${styles.colors.DARK_BLUE};
  line-height: ${({ lineHeight }) => lineHeight || 1.3};
  text-align: left;
  @media screen and ${device.MOBILE} {
    width: 335px;
  }
  @media screen and ${device.TABLET} {
    width: auto;
    margin-bottom: 0;
  }
`;

export const ColoredHeader = styled.span`
  color: ${styles.colors.GREEN};
`;

export const SubHeader = styled.h4`
  margin: 0;
  margin-top: 8px;
  padding: 0;
  font-weight: 600;
  font-size: 24px;
  ${({ centered }) =>
    centered &&
    `
    text-align: center;
    margin: 0 auto;
  `};
  ${({ width }) => width && `width ${width}%`};
  span {
    color: ${styles.colors.GREEN};
  }
`;

export const BuildCommunitySubHeader = styled(SubHeader)`
  font-size: 14px;
  letter-spacing: 0;
  @media screen and ${device.LAPTOP} {
    font-size: 24px;
  }
`;

export const Image = styled.img`
  max-width: 100%;
  height: auto;
  ${({ bordered }) => bordered && 'border-radius: 50%'};
`;

export const Link = styled.a`
  text-decoration: none;
  ${({ defaultColor }) => defaultColor && `color: ${styles.colors.DARK_BLUE}`}
`;

export const Text = styled.p`
  font-size: 16px;
  line-height: 1.5;
  font-weight: 400;
  color: ${styles.colors.GRAY_NEW};
  text-shadow: ${styles.textShadows.WHITE};
  margin-bottom: ${({ marginBottom }) => marginBottom}px;
`;

export const Divider = styled.div`
  opacity: 0.5;
  margin: 40px 0;
  border-bottom: 1px solid
    ${({ gray }) =>
      gray ? styles.colors.LIGHT_GRAY_2 : styles.colors.DARK_BLUE};
  @media screen and ${device.LAPTOP} {
    margin: 25px 0 40px;
  }
`;

export const Contacts = styled.div`
  display: grid;
  align-items: center;
  margin-top: 20px;
  @media screen and ${device.LAPTOP} {
    align-items: start;
    grid-auto-flow: column;
    justify-content: center;
    gap: 122px;
    margin-top: 50px;
  }
`;

export const ContactItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 45px;
  @media screen and ${device.LAPTOP} {
    margin-bottom: 0;
  }
`;

export const ContactPhoto = styled.div`
  width: 80px;
  height: 80px;
  margin-bottom: ${({ marginBottom }) => marginBottom || 15}px;
`;

export const ContactFullName = styled.div`
  font-weight: bold;
  font-size: 14px;
  line-height: 1.14;
  color: ${styles.colors.DARK_BLUE};
  margin-bottom: 2px;
  @media screen and (max-width: 786px) {
    font-size: 18px;
  }
`;

export const ContactPosition = styled.div`
  font-size: 14px;
  line-height: 1.14;
  margin-bottom: ${({ marginBottom }) => marginBottom || 0}px;
  @media screen and (max-width: 786px) {
    font-size: 14px;
  }
`;

export const ContactEmail = styled.div`
  font-size: 14px;
  line-height: 1.5;
`;

export const BonusFormHeader = styled.h3`
  margin: 0;
  padding: 0;
  font-weight: bold;
  font-size: 18px;
  line-height: 1.5;
  letter-spacing: 1px;
  color: ${styles.colors.DARK_BLUE};
  padding-top: 15px;
  @media screen and ${device.LAPTOP} {
    padding-top: 0;
    line-height: 1.2;
    font-size: 40px;
  }
`;

export const InputWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  @media screen and ${device.LAPTOP} {
    flex-direction: row;
  }
`;

export const InputGroup = styled.div`
  display: grid;
  margin-top: 37px;
  width: 100%;
  gap: 22px;
`;

export const InputPrefix = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: ${styles.colors.DARK_BLUE};
  position: absolute;
  left: ${({ left }) => left}px;
  @media screen and (max-width: ${deviceSizes.MOBILE}) {
    ${({ leftMob }) => leftMob && `left: ${leftMob}px`};
  }
`;

export const SendButtonWrapper = styled.div`
  margin-top: 32px;
`;

export const Footnote = styled.span`
  font-size: 12px;
  line-height: 1.5;
  color: ${styles.colors.DARK_BLUE};
`;

export const CopyLinkLabel = styled.h3`
  display: flex;
  align-items: center;
  line-height: 1.5;
  color: ${styles.colors.DARK_BLUE};
  margin-bottom: 8px;
  @media screen and (min-width: 786px) {
    margin-bottom: 13px;
  }
`;

export const LabelIconWraper = styled.div`
  margin-right: 10px;
`;

export const CopyLinkInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const CopyButtonWrapper = styled.div`
  margin-top: 16px;
  button {
    position: relative;
    top: 0;
    right: 0;
    width: 240px;
    margin: 0 auto;
    @media screen and ${device.TABLET} {
      position: absolute;
      width: auto;
      margin: 0;
      width: 200px;
    }
  }
`;

export const TextPreviewButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 23px 0 0 0;
`;

export const TextPreviewButton = styled.button`
  border: none;
  box-shadow: none;
  background: none;
  font-weight: bold;
  font-size: 14px;
  line-height: 1.5;
  color: ${styles.colors.GREEN};
  text-align: center;
  cursor: pointer;
  &:focus {
    outline: none;
  }
`;

export const ConfirmationModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ConfirmationModalHeader = styled.h2`
  color: ${styles.colors.DARK_BLUE};
  line-height: 1.5;
  margin: 0;
  @media screen and (min-width: 786px) {
    font-weight: bold;
    line-height: 1.2;
  }
`;

export const ConfirmationModalText = styled.div`
  font-weight: bold;
  font-size: 18px;
  line-height: 1.5;
  color: ${styles.colors.DARK_BLUE};
  @media screen and ${device.TABLET} {
    font-weight: normal;
    font-size: 24px;
    line-height: 1.2;
    margin-top: 16px;
  }
`;

export const CloseIconWrapper = styled.button`
  border: none;
  box-shadow: none;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  &:not([disabled]) {
    cursor: pointer;
  }
  padding: 0 5px 1px 10px;
  border-radius: 50%;
  &:focus {
    outline: none;
  }
`;

export const PhoneChip = styled.div`
  background-color: ${styles.colors.GREY_LABEL};
  display: inline-block;
  font-size: 16px;
  border-radius: 20px;
  height: 32px;
  padding: 0 4px 0 1rem;
  display: inline-flex;
  align-items: center;
  font-weight: 600;
  letter-spacing: 1px;

  & > span {
    line-height: 1;
    font-size: 16px;
    color: ${styles.colors.DARK_BLUE};
  }
`;
export const PhoneChipsWrapper = styled.div`
  padding: 0 2px 0 32px;
  display: grid;
  padding: 12px 12px 12px 36px;
  align-items: baseline;
  gap: 16px;
  background: ${styles.colors.NUDE};
  caret-color: ${styles.colors.GRAY};
  grid-template-columns: repeat(
    auto-fill,
    ${({ isUA }) => (isUA ? 200 : 194)}px
  );
  border-radius: 8px;
  box-shadow: ${styles.boxShadows.WHITE};
  font: inherit;
  @media screen and ${device.TABLET} {
    padding: 8px 2px 8px 36px;
    gap: 8px;
  }
`;

export const PhoneChipInputWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  ${({ isHiden }) => isHiden && `display: none;`}
`;

export const PhoneChipInput = styled.input`
  height: 28px;
  margin: 0;
  box-sizing: border-box;
  font: inherit;
  border-radius: 0.2rem;
  border: 0;
  color: ${styles.colors.DARK_BLUE};
  background-color: ${styles.colors.NUDE};
  -webkit-appearance: none;
  border-color: transparent;
  outline: none;
  padding: ${({ isUA }) => (isUA ? '0 0 0 33px' : '0 0 0 24px')};
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 1px;
  ${({ isAddMore }) =>
    isAddMore &&
    `
    padding: 0;
  `};
  ${({ isValid }) =>
    !isValid &&
    `
    color: ${styles.colors.RED_ERROR};
  `}
  @media screen and ${device.TABLET} {
    height: 32px;
  }
`;

export const PhoneChipError = styled.div`
  margin: 0;
  padding: 8px 0;
  font-size: 14px;
  color: ${styles.colors.RED_ERROR};
  display: flex;
  flex: 1;
  line-height: 1.5;
  @media screen and ${device.TABLET} {
    font-size: 16px;
  }
`;

export const PhoneChipInputPrefix = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: ${styles.colors.DARK_BLUE};
  position: absolute;
  left: 0px;
  ${({ isValid }) =>
    !isValid &&
    `
    color: ${styles.colors.RED_ERROR};
  `}
`;

export const FlexRow = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column-reverse;
  @media screen and ${device.TABLET} {
    flex-direction: row-reverse;
  }
  button {
    width: 240px;
    margin: 0 auto;
    @media screen and ${device.TABLET} {
      margin: 0;
    }
  }
`;

export const GetAppWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 80px 0 60px;
`;

export const Form = styled.form`
  @media screen and ${device.MOBILE_L} {
    max-width: 300px;
  }
`;

export const Title = styled.h2`
  margin-bottom: 37px;
`;

export const TextContainerWrapper = styled.div`
  width: 100%;
  word-break: break-word;
  @media screen and ${device.LAPTOP} {
    word-break: normal;
  }
`;

export const CopyLinkHolder = styled.div`
  background: #f9f8f1;
  border-radius: 8px;
  padding: 12px 70px 12px 24px;
  width: 100%;
  word-break: break-all;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5;
  color: ${styles.colors.DARK_BLUE};
  text-shadow: ${styles.textShadows.WHITE};
`;
