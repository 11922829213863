import React from 'react';

const IconPhone = ({ size = 18, color = '#404242' }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <circle cx="9" cy="9" r="9" fill="white" />
    <path
      d="M15.3633 2.63513C11.848 -0.87912 6.14938 -0.878277 2.63513 2.63706C-0.87912 6.1524 -0.878277 11.851 2.63706 15.3652C6.1524 18.8795 11.851 18.8786 15.3652 15.3633C17.053 13.675 18.0009 11.3854 18.0004 8.99825C17.9999 6.61145 17.0513 4.32259 15.3633 2.63513ZM13.6305 12.5371C13.6301 12.5375 13.6297 12.538 13.6293 12.5383V12.5354L13.1732 12.9883C12.5835 13.5856 11.7246 13.8313 10.9082 13.6364C10.0857 13.4162 9.3038 13.0658 8.59215 12.5984C7.93099 12.1758 7.31828 11.6818 6.76512 11.1253C6.25615 10.6201 5.7989 10.0653 5.40008 9.46928C4.96385 8.82795 4.61858 8.12931 4.37406 7.39323C4.09375 6.52851 4.32604 5.57966 4.97408 4.9422L5.50808 4.4082C5.65655 4.25906 5.8978 4.25853 6.0469 4.407C6.04728 4.40739 6.04771 4.40778 6.04809 4.4082L7.73411 6.09422C7.88325 6.24269 7.88378 6.48393 7.73531 6.63303C7.73492 6.63342 7.73453 6.63381 7.73411 6.63423L6.74409 7.62425C6.46003 7.90522 6.42431 8.35171 6.6601 8.67428C7.01818 9.1657 7.41443 9.62812 7.84514 10.0573C8.32535 10.5396 8.84739 10.9783 9.40516 11.3684C9.72744 11.5931 10.1644 11.5552 10.4432 11.2783L11.4002 10.3063C11.5486 10.1572 11.7899 10.1567 11.939 10.3051C11.9394 10.3055 11.9398 10.3059 11.9402 10.3063L13.6292 11.9984C13.7784 12.1468 13.7789 12.388 13.6305 12.5371Z"
      fill={color}
    />
  </svg>
);

export default IconPhone;
