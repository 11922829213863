import actionTypes from 'common/enums/actionTypes';

const defaultState = {
  selectSendMeTheLinkLoading: false
};

function reducer(state = defaultState, action) {
  switch (action.type) {
    case actionTypes.SEND_ME_THE_LINK_FETCH: {
      return {
        ...state,
        sendMeTheLinkLoading: true
      };
    }

    case actionTypes.SEND_ME_THE_LINK_SUCCESS:
    case actionTypes.SEND_ME_THE_LINK_ERROR: {
      return {
        ...state,
        sendMeTheLinkLoading: false
      };
    }
    default:
      return state;
  }
}

// selectors
export const selectSendMeTheLinkLoading = state =>
  state.loading.sendMeTheLinkLoading;

export default reducer;
