import React from 'react';
import { connect } from 'react-redux';
import { selectModalData } from 'store/reducers/modal';
import { Modal } from 'modals/components';

const Result = ({ modalData: { title, text } }) => (
  <Modal visible centeredContent={true} title={title} text={text} />
);

const mapStateToProps = state => ({
  modalData: selectModalData(state)
});

export default connect(mapStateToProps, null)(Result);
