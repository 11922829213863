import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import {
  Wrapper,
  Title,
  SubTitle,
  SectionTitle,
  SectionSubTitle,
  SectionDesc,
  BTNTitle,
  AverageSection,
  BTNSection,
  MobileDownload,
  Image,
  Summary,
  LoaderWrapper,
  MainDesc,
  DownloadImageWrapper
} from './styled';

import rehypeRaw from 'rehype-raw';
import { analyticsPageTypes } from 'common/enums/pages';
import { GetApp, DownloadAppImageText } from 'components';
import { getPublication } from 'store/actions/attic';
import {
  selectPublication,
  selectLoadingPublication
} from 'store/reducers/attic';
import { connect } from 'react-redux';
import { LoadingSpinner } from '@sourcegraph/react-loading-spinner';
import { Element } from 'react-scroll';

const Story = ({ publication, getPublication, isLoading }) => {
  const { id = '' } = useParams();
  const content = publication.content;
  const entryPoint = `${analyticsPageTypes.ATTIC} ${id}`;

  useEffect(() => {
    getPublication({ id });
    window.scrollTo({ top: 0 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return (
      <LoaderWrapper>
        <LoadingSpinner />
      </LoaderWrapper>
    );
  }
  return (
    <Wrapper>
      {content?.detailed_image_url && (
        <Image>
          <img src={content?.detailed_image_url} alt="Content" />
        </Image>
      )}
      <Title>{content?.title}</Title>
      <MainDesc id="mainSection">
        <SubTitle>{content?.subTitle}</SubTitle>
        {content?.desc?.map(text => (
          <SectionDesc rehypePlugins={[rehypeRaw]} children={text} />
        ))}
        <Element name="scrollBelowSecondSection" />
      </MainDesc>

      {content?.sections.map(
        ({ title, invest, timeline, sections, desc }, i) => {
          const isLast = content?.sections.length - 1 === i;
          const isSummaryEmpty =
            !content?.summary.title && content?.summary.desc[0] === '';
          return (
            <div>
              <SectionTitle>{title}</SectionTitle>

              <AverageSection>
                {invest && (
                  <SectionSubTitle>
                    Average Investment: {invest}
                  </SectionSubTitle>
                )}
                {timeline && (
                  <SectionSubTitle>
                    Average Timeline: {timeline}
                  </SectionSubTitle>
                )}
              </AverageSection>

              {sections.map(text => (
                <SectionDesc rehypePlugins={[rehypeRaw]} children={text} />
              ))}
              {isLast && isSummaryEmpty ? null : (
                <BTNSection mobileOnly isLast={isLast}>
                  {desc && <BTNTitle>{desc}</BTNTitle>}
                  <MobileDownload>
                    <GetApp light entryPoint={entryPoint} />
                  </MobileDownload>
                </BTNSection>
              )}
            </div>
          );
        }
      )}
      {content?.summary.title && (
        <SectionTitle>{content?.summary.title}</SectionTitle>
      )}

      <Summary>
        {content?.summary.desc.map(text => (
          <SectionDesc rehypePlugins={[rehypeRaw]} children={text} />
        ))}
      </Summary>

      <DownloadImageWrapper>
        <DownloadAppImageText entryPoint={entryPoint} />
      </DownloadImageWrapper>
    </Wrapper>
  );
};

const mapStateToProps = state => ({
  isLoading: selectLoadingPublication(state),
  publication: selectPublication(state)
});

const mapDispatchToProps = {
  getPublication
};

export default connect(mapStateToProps, mapDispatchToProps)(Story);
