import styled from 'styled-components';
import styles from 'common/enums/styles';
import { device } from 'common/enums/devices';

export const Quote = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 59px auto 0;
`;

export const QuoteAuthor = styled(Quote)`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  margin: 0 auto;
  margin-top: 34px;
  @media screen and ${device.LAPTOP} {
    max-width: 470px;
  }
`;

export const QuoteSymbol = styled.p`
  position: absolute;
  color: ${styles.colors.BLUE};
  font-size: 50px;
  font-weight: bold;
  line-height: 1.5;
  margin: 0;
  height: 44px;
  width: 20px;
  transform: scale(1, -1);
  &:first-of-type {
    top: -38px;
  }
  &:last-of-type {
    bottom: -28px;
  }
  &:last-child {
    align-self: flex-end;
    line-height: 1;
  }
`;

export const QuotedText = styled.p`
  color: ${styles.colors.BLACK_TRUE};
  font-weight: 700;
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
  margin: 0 20px 0 0;

  @media screen and ${device.LAPTOP} {
    font-size: 18px;
  }
`;

export const QuoteAuthorText = styled.p`
  color: ${styles.colors.BLACK_TRUE};
  font-weight: 700;
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
  margin: 0 34px;

  span {
    opacity: 0.4;
  }
`;
