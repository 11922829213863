import styled from 'styled-components';
import styles from 'common/enums/styles';
import { device } from 'common/enums/devices';

export const ContentHolder = styled.div`
  text-align: center;
`;

export const InnerPageHolder = styled.section`
  padding-top: 30px;
  margin-bottom: 40px;
  position: relative;
  margin-top: calc(${styles.layout.MOBILE_MENU_HEIGHT}px + 34px);

  @media screen and ${device.TABLET} {
    padding-top: 30px;
  }
  @media screen and ${device.LAPTOP} {
    padding-top: 45px;
    margin-top: calc(${styles.layout.MENU_HEIGHT}px + 38px);
  }
`;

export const InnerPageTitle = styled.h1`
  font-size: 36px;
  line-height: 1.4;
  text-align: center;
  margin-bottom: 16px;
  margin-top: 0;
  @media screen and ${device.TABLET} {
    font-size: 40px;
    line-height: 1.25;
  }
  @media screen and ${device.LAPTOP} {
    font-size: 50px;
    line-height: 1.2;
  }
`;

export const InnerPageSubtitle = styled.h4`
  font-size: 18px;
  line-height: 1.6;
  margin: 0 0 50px 0;
  text-align: center;
  @media screen and ${device.TABLET} {
    margin: 0 0 70px 0;
  }
  @media screen and ${device.LAPTOP} {
    margin: 0 0 90px 0;
  }
`;
