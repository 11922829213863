import React, { useEffect } from 'react';
import {
  AutocompleteList,
  AutocompleteListItem,
  Divider,
  IconButton,
  TopSection,
  Wrapper
} from './styled';
import IconBack from '../Icon/IconBack';
import CloseIconFilled from '../Icon/CloseIconFilled';
import PropTypes from 'prop-types';
import useScrollLock from '../../hooks/useScrollLock';
import { Spacer } from '../../pages/PlanningMode/styled';

const MobileAutocomplete = ({
  onClose,
  value,
  onChange,
  onClearInputValue,
  items,
  onClickAutocompleteItem
}) => {
  useScrollLock();
  return (
    <Wrapper>
      <TopSection>
        <IconButton onClick={onClose}>
          <IconBack />
        </IconButton>
        <input
          onChange={onChange}
          value={value}
          placeholder={'Start typing your home address'}
          autoFocus={true}
          type="text"
          autoComplete={'off'}
        />
        {value?.length > 0 && (
          <IconButton onClick={value?.length > 0 && onClearInputValue}>
            <CloseIconFilled />
          </IconButton>
        )}
      </TopSection>
      <Divider />
      {items?.length > 0 && (
        <AutocompleteList>
          {items.map((item, index) => {
            return (
              <div key={index}>
                <AutocompleteListItem
                  onClick={() => onClickAutocompleteItem(item)}>
                  <p>{item?.description}</p>
                </AutocompleteListItem>
                {index < items.length - 1 && <Spacer />}
              </div>
            );
          })}
        </AutocompleteList>
      )}
    </Wrapper>
  );
};

MobileAutocomplete.propTypes = {
  onClose: PropTypes.func,
  onChange: PropTypes.func,
  onClearInputValue: PropTypes.func,
  value: PropTypes.string,
  items: PropTypes.array
};

export default MobileAutocomplete;
