import quoteImage from 'static/images/quotes/quote-image-theresa.jpg';

export const imageQuoteContent = {
  IMAGE: quoteImage,
  TEXT:
    "Our Dobby Pro Kaine did really great work, and when things weren't quite perfect he even came back. Using Dobby creates an extra layer of accountability, which we didn't need, but it's comforting to know it's there.",
  NAME: 'Theresa M. 19 jobs on Dobby'
};

export const reviewsSection = {
  TITLE: 'An experience like nothing else'
};
