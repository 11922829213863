import styled from 'styled-components';
import styles from 'common/enums/styles';
import { device } from 'common/enums/devices';

export const Section = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
export const CompaniesContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  padding: 80px 16px;
  max-width: 934px;
  align-items: center;

  @media screen and ${device.LAPTOP} {
    padding: 144px 16px;
  }
`;

export const Heading = styled.h1`
  text-align: center;
  max-width: 276px;
  margin-bottom: 42px;
  color: ${styles.components.HEADER};
  @media screen and (min-width: 600px) {
    max-width: unset;
  }
  @media screen and ${device.LAPTOP} {
    margin-bottom: 48px;
  }
`;

export const CompanyLogos = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  gap: 44px 41px;
  justify-content: space-around;
  justify-items: center;

  & > :last-child {
    grid-column: 1 / span 2;
  }

  @media screen and (min-width: 530px) {
    gap: 44px 70px;
  }
  @media screen and ${device.LAPTOP} {
    max-width: 818px;
    grid-template-columns: repeat(5, 1fr);
    gap: 49px 49px;
    & > :last-child {
      grid-column: unset;
    }
  }
`;

export const CompanyImage = styled.img`
  display: block;
  height: auto;
  :first-of-type {
    height: 65px;
    @media screen and ${device.LAPTOP} {
      height: auto;
    }
  }
  :nth-of-type(2) {
    height: 69px;
    @media screen and ${device.LAPTOP} {
      height: auto;
    }
  }
  :nth-of-type(3) {
    height: 65px;
    @media screen and ${device.LAPTOP} {
      height: auto;
    }
  }
  :nth-of-type(4) {
    height: 67px;
    @media screen and ${device.LAPTOP} {
      height: auto;
    }
  }
  :nth-of-type(5) {
    height: 65px;
    @media screen and ${device.LAPTOP} {
      height: auto;
    }
  }
  :nth-of-type(6) {
    height: 65px;
    @media screen and ${device.LAPTOP} {
      height: auto;
    }
  }
`;
