import styled from 'styled-components';
import styles from 'common/enums/styles';
import { device } from 'common/enums/devices';

export const StyledButton = styled.button`
  display: grid;
  justify-content: center;
  align-content: center;
  width: ${({ otp }) => (otp ? '200px' : '100%')};
  padding: ${({ otp }) => (otp ? '16px 0' : '0 24px')};
  height: ${({ otp }) => (otp ? 'auto' : '48px')};
  border-radius: ${({ flat }) => (flat ? '0' : '8px')};
  background-color: ${({ type }) =>
    type === 'outline' ? styles.colors.WHITE_NEW : styles.colors.BLUE};
  color: ${({ type }) =>
    type === 'outline' ? styles.colors.DARK_BLUE : styles.colors.WHITE_NEW};
  border: ${({ type }) =>
    type === 'outline' ? `2px solid ${styles.colors.BLUE}` : 'none'};
  cursor: pointer;
  font-weight: 400;
  @media screen and ${device.LAPTOP} {
    width: ${({ fullWidth }) => (fullWidth ? '100%' : '200px')};
  }
  ${({ disabledHover, disabled }) =>
    !disabled &&
    !disabledHover &&
    `
  @media screen and (hover: hover) and (pointer: fine) {
    &:hover {
      transition: all 0.2s ease-in-out;
      transform: scale(1.1);
    }
  }`}

  ${({ isMobileActive, disabled }) =>
    !disabled &&
    isMobileActive &&
    `
  @media screen and (hover: none) {
    &:active {
      transition: all 0.2s ease-in-out;
      transform: scale(1.1);
    }
  }`}
  :disabled {
    opacity: 0.5;
    cursor: default;
  }
  .loading-spinner {
    border: 2px solid ${styles.colors.WHITE};
    border-top: 2px solid ${styles.colors.GRAY};
  }
`;
