import styled from 'styled-components';
import styles from 'common/enums/styles';
import { device } from 'common/enums/devices';

export const Wrapper = styled.div`
  background-color: ${styles.colors.WHITE_NEW};
  padding: 51px 8px 38px;
  @media screen and ${device.LAPTOP} {
    padding: 85px 8px 84px;
  }
`;
export const Container = styled.div`
  margin-top: 56px;
  padding: 0 8px 0 8px;
  @media screen and ${device.LAPTOP} {
    display: grid;
    grid-auto-flow: column;
    justify-content: center;
    gap: 5%;
    margin-top: 94px;
  }
  @media screen and ${device.LAPTOP_L} {
    padding: 0 224px 0 176px;
    gap: 134px;
  }
`;
export const TextWrapper = styled.div`
  margin-top: 56px;
  @media screen and ${device.LAPTOP} {
    max-width: 500px;
    margin-top: 0;
  }
`;
export const ImageWrapper = styled.div`
  padding: 0 24px;
  display: flex;
  justify-content: center;
  @media screen and ${device.LAPTOP} {
    max-width: 399px;
    padding: 0;
    padding-top: 4px;
  }
`;
export const Image = styled.img`
  width: 100%;
  max-height: 760px;
  @media screen and ${device.MOBILE_L} {
    width: auto;
    max-height: 673px;
  }
  @media screen and ${device.LAPTOP} {
    width: 100%;
  }
`;
export const WrapperNames = styled.div`
  margin-top: 24px;
  display: grid;
  gap: 16px;
  @media screen and ${device.LAPTOP} {
    margin-top: 29px;
    gap: 26px;
  }
`;

export const Content = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
  text-align: justify;
  @media screen and ${device.LAPTOP} {
    font-size: 18px;
  }
`;

export const TextName = styled.p`
  font-weight: 600;
  font-size: 18px;
  line-height: 1.2;
  @media screen and ${device.LAPTOP} {
    font-size: 18px;
    line-height: 1.5;
  }
`;

export const TextPosition = styled.p`
  font-size: 16px;
  line-height: 1.5;
  color: ${styles.colors.GRAY_NEW};
  @media screen and ${device.LAPTOP} {
    line-height: 0.7;
  }
`;

export const Title = styled.h1`
  letter-spacing: 0;
  margin: auto;
  margin-bottom: 0;
  max-width: 954px;
  color: ${styles.components.HEADER};
  text-align: center;
  padding-horizontal: 3%;
  @media screen and ${device.LAPTOP} {
    text-align: center;
    width: auto;
  }
  @media screen and ${device.LAPTOP_L} {
    font-size: 50px;
    line-height: 1.3;
  }
`;
