import { call, takeLatest, put } from 'redux-saga/effects';
import mixpanel from 'mixpanel-browser';

import api_constants from 'common/enums/api';
import actionTypes from 'common/enums/actionTypes';
import modalTypes from 'common/enums/modalTypes';

import Api from 'services/ApiService';
import { openModal } from 'store/actions/modal';

function* askUsAnything({ payload: { isAtticPage, data, type, ...body } }) {
  try {
    const response = yield call(Api.apiPost, api_constants.ASK_US_ANYTHING, {
      ...body,
      is_attic_page: Boolean(isAtticPage)
    });

    if (!response) {
      throw new Error('Something went wrong. Try again later');
    }

    mixpanel.track(`Ask Us Anything response`, {
      'Entry Point': data?.entryPoint,
      $email: data?.email,
      $question: body?.question
    });
    if (isAtticPage) {
      if (response.answer_found) {
        yield put(
          openModal(modalTypes.AI_RESULT, {
            message: response.message,
            question: data?.question
          })
        );
      } else {
        yield put(
          openModal(modalTypes.AI_NO_RESULT, {
            email: data?.email,
            question: data?.question
          })
        );
      }
    }
    yield put({
      type: actionTypes.ASK_US_ANYTHING_SUCCESS,
      payload: response
    });
  } catch (err) {
    yield put({
      type: actionTypes.ASK_US_ANYTHING_ERROR
    });
    yield put(
      openModal(modalTypes.ERROR, {
        title: 'Something went wrong. Try again later'
      })
    );
  }
}

export default function* watchAskUsAnything() {
  yield takeLatest(actionTypes.ASK_US_ANYTHING_FETCH, askUsAnything);
}
