import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { selectModalData } from 'store/reducers/modal';
import { Modal } from 'modals/components';
import {
  Text,
  Title,
  Resend,
  Input,
  Container,
  WrongCode,
  LoadingWrapper
} from './styled';
import { reset } from 'redux-form';
import ReactCodeInput from 'react-verification-code-input';

import { confirmSignIn, signIn } from 'store/actions/auth';
import {
  selectUser,
  selectWrongCode,
  selectConfirmSignInLoading
} from 'store/reducers/subscription';
import { LoadingSpinner } from '@sourcegraph/react-loading-spinner';

const ConfirmCode = ({
  modalData: { formattedPhoneNumber, phoneNumberCode, phone, onSuccess },
  user,
  confirmSignIn,
  signIn,
  confirmSignInLoading,
  wrongCode
}) => {
  const [counter, setCounter] = useState(30);
  const [code, setCode] = useState('');

  useEffect(() => {
    setInterval(() => setCounter(count => count - 1), 1000);
  }, []);
  useEffect(() => {
    if (code.length === 6) {
      confirmSignIn({ code, user, onSuccess });
    }
  }, [code]);

  return (
    <Modal visible centeredContent mobilePadding="64px 14px 40px 14px">
      <Container>
        <Title>Confirm your number</Title>
        <Text>
          Please enter the 6-digit confirmation code sent to{' '}
          <span>{formattedPhoneNumber}</span>
        </Text>
        <Input>
          <ReactCodeInput
            disabled={confirmSignInLoading}
            values={code}
            onChange={setCode}
          />
        </Input>
        {wrongCode && !confirmSignInLoading && (
          <WrongCode>
            <div>
              The code you entered didn’t match ours. Let’s check your messages
              & try again.
            </div>
          </WrongCode>
        )}
        {confirmSignInLoading ? (
          <LoadingWrapper>
            <LoadingSpinner />
          </LoadingWrapper>
        ) : counter > 0 ? (
          <Resend>
            00:{counter.toString().length === 1 ? `0${counter}` : counter}
          </Resend>
        ) : (
          <Resend
            underline
            onClick={() => {
              setCounter(30);
              signIn({ phone, phoneNumberCode });
            }}>
            Resend Code
          </Resend>
        )}
      </Container>
    </Modal>
  );
};

const mapStateToProps = state => ({
  modalData: selectModalData(state),
  user: selectUser(state),
  wrongCode: selectWrongCode(state),
  confirmSignInLoading: selectConfirmSignInLoading(state)
});

const mapDispatchToProps = {
  reset,
  confirmSignIn,
  signIn
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmCode);
