import styled from 'styled-components';
import { animated } from 'react-spring';
import { device } from 'common/enums/devices';

export const PhoneWrapper = styled.div`
  display: flex;
  position: relative;
  margin-top: 48px;
  order: 0;
  @media screen and ${device.LAPTOP} {
    margin-top: 0;
    order: 1;
  }
`;

export const ImageContainer = styled(animated.div)`
  position: relative;
  align-items: center;
  justify-content: center;
  display: flex;
  width: 182px;
  height: 365px;
  z-index: 1;

  @media screen and ${device.LAPTOP} {
    width: 285px;
    height: 579px;
    margin: 0 77px;
  }
`;
