import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import mixpanel from 'mixpanel-browser';
import {
  selectUserReferralLink,
  selectUserPhoneNumber,
  selectUserName,
  selectUserId,
  selectUserType,
  selectInviteUserByReferralLinkLoading,
  selectInviteUserByReferralLinkResult
} from 'store/reducers/user';
import {
  dismissInviteUserByReferralLinkResult,
  inviteUserByReferralLink
} from 'store/actions/user';

import { Icon, Button } from 'components';
import { Modal } from 'modals/components';
import PhoneNumberChips from './PhoneNumberChips';
import {
  Text,
  Divider,
  BonusFormHeader,
  ConfirmationButtonWrapper,
  CopyLinkLabel,
  CopyLinkHolder,
  LabelIconWraper,
  CopyButtonWrapper,
  CopyLinkInputWrapper,
  TextPreviewButton,
  TextPreviewButtonWrapper,
  ConfirmationModalContent,
  ConfirmationModalHeader,
  ConfirmationModalText,
  TextContainerWrapper,
  Title
} from './styled';

const InviteUserModal = ({
  isInviteFormShown,
  userPhoneNumber,
  userName,
  userId,
  type,
  toggleInviteForm,
  userReferralCode,
  inviteUserByReferralLink,
  inviteUserByReferralLinkLoading: loading,
  inviteUserByReferralLinkResult: result,
  dismissInviteUserByReferralLinkResult: dismissResult,
  userType,
  resetGetReferralLink
}) => {
  const history = useHistory();
  const phoneNumberCode = history.location.hash === '#ua' ? '+38.' : '+1.';
  const [isTextPreviewShown, setIsTextPreviewShown] = useState(false);
  const [phoneNumbers, setPhoneNumbers] = useState([]);
  const userReferralLink = `${window.location.protocol}//${
    window.location.host
  }/${
    type === 'homeowner' ? 'dobby_app' : 'service_pro_app'
  }/${userReferralCode || ''}`;

  useEffect(() => {
    if (result) {
      if (isInviteFormShown) {
        toggleInviteForm();
      }
      resetGetReferralLink();
      setPhoneNumbers([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result]);

  const toggleTextPreview = () =>
    setIsTextPreviewShown(isTextPreviewShown => !isTextPreviewShown);

  const submitInviteForm = phoneNumbers => {
    const formattedPhoneNumbers = phoneNumbers.map(number =>
      number.startsWith(phoneNumberCode)
        ? number
        : `${phoneNumberCode}${number}`
    );
    inviteUserByReferralLink({
      phone_number: userPhoneNumber,
      group_id:
        userType === 'homeowner'
          ? 1
          : userType === 'provider'
          ? 2
          : type === 'homeowner'
          ? 1
          : 2,
      given_name: userName,
      target_group_id: type === 'homeowner' ? 1 : 2,
      target_number: formattedPhoneNumbers
    });
    formattedPhoneNumbers.forEach(number => {
      mixpanel.track('Send invites to phone number', {
        $user_id: userId,
        $phone_number: number,
        'Entry Point': `Send invites modal ${
          type === 'homeowner' ? 'grow together' : 'build together'
        }`
      });
    });
  };

  const onInviteLinkCopied = (text, result) => {
    if (result) {
      mixpanel.track('Copied link $50 invite', {
        $user_id: userId,
        'Entry Point': `Send invites modal ${
          type === 'homeowner' ? 'grow together' : 'build together'
        }`
      });
    }
  };

  const onManualInviteLinkCopied = () => {
    navigator.clipboard.readText().then(copiedText => {
      if (userReferralLink === copiedText) {
        mixpanel.track('Copied link $50 invite', {
          $user_id: userId,
          'Entry Point': `Send invites modal ${
            type === 'homeowner' ? 'grow together' : 'build together'
          }`
        });
      }
    });
  };

  return (
    <>
      <Modal
        visible={isInviteFormShown}
        padding="47px 30px 24px 39px"
        margin="140px auto 0 auto"
        closeButton
        width={770}
        heading={<BonusFormHeader>Send invites</BonusFormHeader>}
        headingPaddingBottom={14}
        topPosition
        centeredContent
        onModalClose={toggleInviteForm}>
        <Title>Send invites</Title>

        <PhoneNumberChips
          submitInviteForm={submitInviteForm}
          phoneNumberCode={phoneNumberCode}
          loading={loading}
          phoneNumbers={phoneNumbers}
          setPhoneNumbers={setPhoneNumbers}
        />
        <Divider gray />
        <CopyLinkLabel>
          <LabelIconWraper>
            <Icon name="chain" size={24} />
          </LabelIconWraper>
          Copy your referral link
        </CopyLinkLabel>
        <CopyLinkInputWrapper>
          <CopyLinkHolder onCopy={onManualInviteLinkCopied}>
            {userReferralLink}
          </CopyLinkHolder>
          <CopyButtonWrapper>
            <CopyToClipboard
              text={userReferralLink}
              onCopy={onInviteLinkCopied}>
              <Button title="Copy" isMobileActive />
            </CopyToClipboard>
          </CopyButtonWrapper>
        </CopyLinkInputWrapper>
        <TextContainerWrapper>
          <TextPreviewButtonWrapper>
            <TextPreviewButton onClick={toggleTextPreview}>
              {isTextPreviewShown ? 'Hide' : 'View text preview'}
            </TextPreviewButton>
          </TextPreviewButtonWrapper>
          <div>
            {isTextPreviewShown && (
              <Text>
                {type === 'homeowner' ? (
                  <>
                    I’ve been using Dobby for my home and I love it! Dobby’s
                    vetted service pros make home maintenance, upgrades, and
                    repairs fast & easy with guaranteed 5-star quality. Use my
                    link to get started with $50 off your first project:
                  </>
                ) : (
                  <Text marginBottom={5}>
                    {userName || 'Your friend'} referred you to join Dobby’s
                    invite-only service provider community! Use this exclusive
                    invite to download the app and grow your business with us.
                  </Text>
                )}
                <br />
                {userReferralLink}
              </Text>
            )}
          </div>
        </TextContainerWrapper>
      </Modal>
      <Modal
        visible={result}
        closeButton={true}
        width={770}
        heading={false}
        topPosition
        onModalClose={dismissResult}>
        <ConfirmationModalContent>
          <ConfirmationModalHeader>
            {result === 'success' ? <>Nice work!</> : null}
          </ConfirmationModalHeader>
          <ConfirmationModalText>
            {result === 'success'
              ? 'Your invite is on the way.'
              : 'Something went wrong, please try again later'}
          </ConfirmationModalText>
          <ConfirmationButtonWrapper>
            <Button
              title={result === 'success' ? 'Got it' : 'Ok'}
              onClick={dismissResult}
            />
          </ConfirmationButtonWrapper>
        </ConfirmationModalContent>
      </Modal>
    </>
  );
};

const mapStateToProps = state => ({
  userReferralCode: selectUserReferralLink(state),
  userPhoneNumber: selectUserPhoneNumber(state),
  userName: selectUserName(state),
  userId: selectUserId(state),
  inviteUserByReferralLinkLoading: selectInviteUserByReferralLinkLoading(state),
  inviteUserByReferralLinkResult: selectInviteUserByReferralLinkResult(state),
  userType: selectUserType(state)
});

const mapDispatchToProps = {
  inviteUserByReferralLink,
  dismissInviteUserByReferralLinkResult
};

export default connect(mapStateToProps, mapDispatchToProps)(InviteUserModal);
