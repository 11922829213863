import actionTypes from 'common/enums/actionTypes';

const defaultState = {
  user: {},
  authUser: {},
  confirmSignInLoading: false,
  wrongCode: false,
  isSignInLoading: false,
  userSubscriptionStep: '',
  loadingSubscription: false
};

function reducer(state = defaultState, action) {
  switch (action.type) {
    case actionTypes.SIGN_IN_FETCH: {
      return {
        ...state,
        isSignInLoading: true
      };
    }
    case actionTypes.SIGN_IN_SUCCESS: {
      return {
        ...state,
        user: action.payload,
        isSignInLoading: false,
        wrongCode: false
      };
    }
    case actionTypes.SIGN_IN_ERROR: {
      return {
        ...state,
        isSignInLoading: false
      };
    }
    case actionTypes.SET_SUBSCRIPTION_FETCH: {
      return {
        ...state,
        loadingSubscription: true
      };
    }
    case actionTypes.SET_SUBSCRIPTION_ERROR:
    case actionTypes.SET_SUBSCRIPTION_SUCCESS: {
      return {
        ...state,
        loadingSubscription: false
      };
    }
    case actionTypes.SET_SUBSCRIPTION_STEP: {
      return {
        ...state,
        userSubscriptionStep: action.payload
      };
    }
    case actionTypes.CONFIRM_SIGN_IN_FETCH: {
      return {
        ...state,
        confirmSignInLoading: true,
        isSignInLoading: false
      };
    }

    case actionTypes.CONFIRM_SIGN_IN_SUCCESS: {
      return {
        ...state,
        authUser: action.payload,
        confirmSignInLoading: false,
        wrongCode: false
      };
    }
    case actionTypes.CONFIRM_SIGN_IN_ERROR: {
      return {
        ...state,
        confirmSignInLoading: false,
        wrongCode: true
      };
    }

    default:
      return state;
  }
}

// selectors

export const selectUser = state => state.subscription.user;
export const selectAuthUser = state => state.subscription.authUser;
export const selectSignInLoading = state => state.subscription.isSignInLoading;
export const selectConfirmSignInLoading = state =>
  state.subscription.confirmSignInLoading;
export const selectWrongCode = state => state.subscription.wrongCode;
export const selectSubscriptionStep = state =>
  state.subscription.userSubscriptionStep;
export const selectLoadingSubscription = state =>
  state.subscription.loadingSubscription;
export default reducer;
