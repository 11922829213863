import styled from 'styled-components/macro';
import styles from 'common/enums/styles';
import { device } from 'common/enums/devices';

export const Section = styled.section`
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 65px 0 78px;
  @media screen and ${device.LAPTOP} {
    padding: 73px 0 64px;
  }
  h1 {
    text-align: center;
    color: ${styles.components.HEADER};
  }
`;

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(3, 90px);
  gap: 17px 41px;
  grid-auto-flow: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 25px;
  padding: 0;
  text-align: center;
  justify-items: center;
  @media screen and (min-width: 450px) {
    justify-content: space-around;
    width: 60%;
  }
  @media screen and (min-width: 1250px) {
    width: 100%;
    max-width: 1213px;
    margin: 0 auto;
    padding: 0;
    margin-top: 55px;
    grid-template-columns: auto;
    grid-template-rows: auto;
    grid-auto-flow: column;
    grid-template-columns: repeat(5, 209px);
  }
  a {
    :nth-of-type(1) {
      grid-row: 1;
      grid-column: 1/3;
      width: 158px;
      height: auto;
    }
    :nth-of-type(2) {
      grid-row: 2;
      grid-column: 1/2;
      width: 143px;
      height: auto;
    }
    :nth-of-type(3) {
      grid-row: 2;
      grid-column: 2/2;
      height: 67px;
      width: 67px;
    }
    :nth-of-type(4) {
      grid-row: 3;
      grid-column: 1/2;
      width: 143px;
      height: auto;
    }
    :nth-of-type(5) {
      grid-row: 3;
      grid-column: 2/2;
      width: 143px;
      height: auto;
    }
    :nth-of-type(1),
    :nth-of-type(2),
    :nth-of-type(3),
    :nth-of-type(4),
    :nth-of-type(5) {
      @media screen and (min-width: 1250px) {
        grid-row: auto;
        grid-column: auto;
        width: auto;
        height: auto;
      }
    }
  }
`;

export const LogoImage = styled.img`
  min-width: 0px;
  height: 100%;
  @media screen and ${device.LAPTOP} {
    min-width: 100px;
    margin: 0;
    max-height: ${({ hScale }) => (hScale || 1) * 100}px;
    &:hover {
      filter: brightness(1.3);
    }
  }
`;
