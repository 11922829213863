import { call, takeLatest, put } from 'redux-saga/effects';
import mixpanel from 'mixpanel-browser';

import api_constants from 'common/enums/api';
import actionTypes from 'common/enums/actionTypes';
import Api from 'services/ApiService';

function* getReferralLink(action) {
  const { payload } = action;
  try {
    const response = yield call(
      Api.apiPost,
      api_constants.GET_REFERRAL_LINK,
      payload
    );
    if (response) {
      yield put({
        type: actionTypes.GET_REFERRAL_LINK_SUCCESS,
        payload: {
          referralLink: response.referral_link,
          name: payload.given_name,
          phoneNumber: payload.phone_number,
          type: payload.group_id === 1 ? 'homeowner' : 'provider',
          userId: response.id
        }
      });

      mixpanel.identify(response.id);
      mixpanel.people.set({
        USER_ID: response.id,
        $name: payload.given_name,
        $phone_number: payload.phone_number
      });
    } else {
      yield put({
        type: actionTypes.GET_REFERRAL_LINK_ERROR,
        payload: 'Something went wrong. Try again later'
      });
    }
  } catch (err) {
    yield put({
      type: actionTypes.GET_REFERRAL_LINK_ERROR,
      payload: 'Something went wrong. Try again later'
    });
  }
}

export default function* watchGetReferralLink() {
  yield takeLatest(actionTypes.GET_REFERRAL_LINK_FETCH, getReferralLink);
}
