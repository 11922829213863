import React from 'react';
import { connect } from 'react-redux';
import { selectModalData } from 'store/reducers/modal';
import { Modal } from 'modals/components';
import {
  BottomSection,
  ButtonWrapper,
  Container,
  DesktopView,
  MobileView,
  Question,
  Title
} from './styled';
import { reset } from 'redux-form';
import Button from '../../../components/Button';
import { closeModal } from '../../../store/actions/modal';

const CHECK_THE_PRICE_URL = '/check-the-price';

const AIResult = ({
  modalData: { message, question, onCloseAction },
  reset,
  closeModal
}) => (
  <Modal
    visible
    aiResult
    centeredContent
    contentDisplay="block"
    scrollable
    showDownload
    onClose={() => {
      reset('askUsAnything');
      onCloseAction && onCloseAction();
    }}>
    <Question>{question}</Question>
    <Container>{message}</Container>
    <DesktopView>
      <BottomSection>
        <Title>More questions?</Title>
        <ButtonWrapper>
          <Button
            title={'Ask Away'}
            onClick={() => {
              closeModal();
              onCloseAction && onCloseAction();
              reset('askUsAnything');
            }}
          />
        </ButtonWrapper>
      </BottomSection>
    </DesktopView>
    <MobileView>
      <Title noMargin>
        {window.location.href.includes(CHECK_THE_PRICE_URL)
          ? 'Use the Dobby app \nto ask more questions'
          : 'View full report for your home on the Dobby app'}
      </Title>
    </MobileView>
  </Modal>
);

const mapStateToProps = state => ({
  modalData: selectModalData(state)
});

const mapDispatchToProps = {
  reset,
  closeModal
};

export default connect(mapStateToProps, mapDispatchToProps)(AIResult);
