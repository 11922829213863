import { call, takeLatest, put } from 'redux-saga/effects';
import api_constants from 'common/enums/api';
import actionTypes from 'common/enums/actionTypes';
import Api from 'services/ApiService';

function* getArticles({ payload }) {
  try {
    const response = yield call(Api.cmsGet, '/articles');

    yield put({
      type: actionTypes.GET_ARTICLES_SUCCESS,
      payload: response
    });
  } catch (err) {
    yield put({
      type: actionTypes.GET_ARTICLES_ERROR,
      payload: 'Something went wrong. Try again later'
    });
  }
}

export default function* watchGetArticles() {
  yield takeLatest(actionTypes.GET_ARTICLES_FETCH, getArticles);
}
