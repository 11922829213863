import React from 'react';
import PropTypes from 'prop-types';
import { RadioInput, RadioLabel, RadioWrapper, StyledRadio } from './styled';
import styles from 'common/enums/styles';

const Radio = ({
  onChange,
  disabled = false,
  label = '',
  labelColor = '',
  selectedLabelColor = styles.colors.DARK_BLUE,
  selectedWeight = 400,
  name,
  value,
  tabIndex = 0,
  color
}) => (
  <RadioWrapper>
    <RadioLabel
      disabled={disabled}
      selectedLabelColor={selectedLabelColor}
      labelColor={labelColor}
      selectedWeight={selectedWeight}
      color={color}>
      <RadioInput
        tabIndex={tabIndex}
        name={name}
        value={value}
        disabled={disabled}
        type="radio"
        onChangeCapture={onChange}
      />
      <StyledRadio color={color} />
      <p>{label}</p>
    </RadioLabel>
  </RadioWrapper>
);

Radio.propTypes = {
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  value: PropTypes.string,
  tabIndex: PropTypes.number
};

export default Radio;
