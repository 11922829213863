import styled from 'styled-components';

import styles from 'common/enums/styles';
import { device } from 'common/enums/devices';

export const Wrapper = styled.section`
  background: ${styles.components.BACKGROUND};
  padding: ${styles.layout.MOBILE_MENU_HEIGHT}px 0 0;

  @media screen and ${device.TABLET} {
    padding: ${styles.layout.MENU_HEIGHT}px 0 0;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 30px 16px;

  @media screen and ${device.TABLET} {
    padding: 40px 20vw 70px;
  }
`;

export const Title = styled.h1`
  color: ${styles.components.HEADER_INVERTED};
  font-size: 36px;
  font-weight: 500;
  line-height: 1.2;
  text-align: center;
  white-space: pre-wrap;
  text-align: left;
  margin: 24px 20px;

  @media screen and ${device.TABLET} {
    font-size: 50px;
  }
`;

export const Description = styled.p`
  color: ${styles.components.TEXT_INVERTED};
  font-size: 24px;
  line-height: 1.2;
  text-align: center;
  white-space: pre-wrap;
  text-align: left;
  margin: 0 20px 0;
`;

export const Delimeter = styled.div`
  background: ${styles.components.BACKGROUND};
  height: 40px;
  margin: 0;
`;

export const Link = styled.a`
  color: ${styles.components.TEXT_INVERTED};
  text-align: center;
  white-space: pre-wrap;
  text-align: left;
  // underline stroke
  text-decoration: underline dotted;
  cursor: pointer;

  &:hover {
    color: ${styles.colors.BLUE};
    text-decoration: underline dotted;
  }
`;
