import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import NumberFormat from 'react-number-format';
import { LoadingSpinner } from '@sourcegraph/react-loading-spinner';
import { Logo } from 'components';

import { Button, Input } from 'components';
import {
  selectAuthUser,
  selectSignInLoading
} from 'store/reducers/subscription';

import { GlobalStyle } from 'navigation/components/WithNavBar/styled';
import { useAuthRedirect } from './hooks';
import {
  Wrapper,
  EnterPhoneSection,
  PhoneInput,
  EnterPhone,
  LoaderWrapper
} from './styled';
import { LogoWrapper } from 'components/CompareTable/styled';

const AuthRedirect = ({ location: { search, hash } }) => {
  const user = useSelector(selectAuthUser);
  const hasSignInLoading = useSelector(selectSignInLoading);
  const [phone, setPhone] = useState('');
  const [validationActive, setValidationActive] = useState(false);
  const {
    // onSendMagicLink,
    onSubmitPhoneNumber
  } = useAuthRedirect({ search });
  const phoneNumberCode = hash ? hash?.slice(1) : '+1.';

  if (user?.id) {
    return (
      <LoaderWrapper>
        <LoadingSpinner />
      </LoaderWrapper>
    );
    // return <>
    //   Signed in as {user?.given_name} {user.family_name}
    //   <Button title="Sign in to Add Glow" onClick={() => onSendMagicLink(user)} />
    // </>
  }

  const calculatePadding = phoneNumberCode => {
    // Define a base padding value
    const basePadding = 73;

    // Define a multiplier for additional padding based on the length of phoneNumberCode
    let multiplier;
    if (phoneNumberCode.length <= 3) {
      multiplier = 0;
    } else if (phoneNumberCode.length <= 4) {
      multiplier = 2;
    } else {
      multiplier = 4;
    }

    // Calculate additional padding based on the length of phoneNumberCode
    const removedPadding = phoneNumberCode.length * multiplier;

    // Calculate total padding
    const totalPadding = basePadding - removedPadding;

    return totalPadding;
  };

  return (
    <>
      <GlobalStyle />
      <Wrapper
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
        <LogoWrapper>
          <Logo navy />
        </LogoWrapper>
        <EnterPhoneSection>
          <PhoneInput>
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column'
              }}>
              <EnterPhone>
                Enter your mobile number for the one-time-passcode (OTP)
              </EnterPhone>
              <div style={{ position: 'relative' }}>
                <NumberFormat
                  type="tel"
                  format="###.###.####"
                  customInput={Input}
                  placeholder="202.460.7470"
                  padding={`12px 30px 12px ${19 +
                    (phoneNumberCode?.length || 0) * 9}px`}
                  smallBottomMargin={true}
                  value={phone}
                  onChange={e => setPhone(e.target.value)}
                  errorBorders={
                    validationActive &&
                    phoneNumberCode === '+1.' &&
                    phone.trim().length !== 12
                  }
                  onBlur={() => setValidationActive(true)}
                  style={{ paddingLeft: 98 }}
                />
                <div
                  style={{
                    position: 'absolute',
                    left: calculatePadding(phoneNumberCode),
                    top: 15.5,
                    fontSize: '16px',
                    lineHeight: 1.15
                  }}>
                  {phoneNumberCode}
                </div>
              </div>
            </div>
            <Button
              title={
                <p
                  style={{
                    fontWeight: 'bold',
                    color: '#FAF9F7',
                    fontSize: 24
                  }}>
                  Send OTP
                </p>
              }
              onClick={() => onSubmitPhoneNumber({ phoneNumberCode, phone })}
              loading={hasSignInLoading}
              flat
              otp
            />
          </PhoneInput>
        </EnterPhoneSection>
      </Wrapper>
    </>
  );
};

export default AuthRedirect;
