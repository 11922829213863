import styled from 'styled-components/macro';
import styles from 'common/enums/styles';
import { device, deviceSizes } from 'common/enums/devices';
import { keyframes } from 'styled-components';
export const ScreenContentWrapper = styled.div`
  position: relative;
  min-height: 100vh;
  background: linear-gradient(260deg, #090856 9.15%, #010111 96.55%);
  width: 100%;
  background-size: cover;
  display: flex;
  align-items: center;
`;

export const Wrapper = styled.div`
  position: relative;
  z-index: 3;
  width: 100%;
  max-width: 1028px;
  padding: ${styles.layout.MOBILE_MENU_HEIGHT + 54}px 16px 73px 16px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  @media screen and ${device.TABLET} {
    grid-template-columns: repeat(2, 1fr);
    padding: 0 16px;
  }
`;

export const HeaderWrapper = styled.div`
  z-index: 3;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
  @media screen and ${device.TABLET} {
    margin-bottom: 32px;
  }
`;

export const ColoredHeader = styled.span`
  color: ${styles.components.HEADER_COLOR};
`;
export const FormWrapper = styled.form`
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 624px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
`;

export const InputGroup = styled.div`
  z-index: 3;
  display: grid;
  width: 100%;
  column-gap: 15px;
  row-gap: 24px;
  justify-items: center;
  align-items: center;
  @media screen and ${device.TABLET} {
    display: flex;
    flex-direction: row;
  }
`;

export const InputPhoneWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  ${({ marginBottom }) => marginBottom && `margin-bottom: ${marginBottom}px`};
  input {
    background-color: ${styles.colors.DARK_BLUE2};
    border: 1px solid ${styles.colors.GRAY_BORDER_LIGHT};
    padding-top: 12px;
    padding-right: 12px;
    padding-bottom: 12px;
    ${({ leftMargin }) =>
      leftMargin ? `padding-left: ${leftMargin}px` : `38px`};
    margin-right: 0;
    color: #ffffff;
    max-width: 100%;
    ::placeholder {
      color: #ffffff;
      opacity: 0.3;
    }
    &:-webkit-autofill,
    :-webkit-autofill:focus {
      transition: background-color 600000s 0s, color 600000s 0s;
    }
  }
`;

export const InputWrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  @media screen and ${device.TABLET} {
    flex-direction: row;
    margin-bottom: 15px;
  }
  input {
    background-color: ${styles.colors.DARK_BLUE2};
    border: 1px solid ${styles.colors.GRAY_BORDER_LIGHT};
    padding-top: 12px;
    padding-right: 12px;
    padding-bottom: 12px;
    padding-left: 12px;
    ${({ leftMargin }) =>
      leftMargin ? `padding-left: ${leftMargin}px` : `38px`};
    margin-right: 0;
    color: #ffffff;
    max-width: 100%;
    ::placeholder {
      color: #ffffff;
      opacity: 0.3;
    }
  }
`;

export const SendButtonWrapper = styled.div`
  z-index: 3;
  button {
    width: 178px;
    font-size: 20px;
    :disabled {
      background-color: #3d3c9b !important;
      color: #82819d;
      opacity: 1;
    }
  }
`;

export const HeaderTitle = styled.div`
  font-weight: 700;
  margin-bottom: 24px;
  font-size: 32px;
  letter-spacing: 1px;
  color: #ffffff;
  text-align: center;
  max-width: 274px;
  @media screen and ${device.TABLET} {
    font-size: 50px;
    margin-bottom: 10px;
    max-width: 100%;
  }
`;

export const HeaderText = styled.div`
  font-weight: 700;
  font-size: 18px;
  text-align: center;
  letter-spacing: 1px;
  color: #ffffff;
  max-width: 356px;
  @media screen and ${device.TABLET} {
    font-size: 24px;
    max-width: 100%;
  }
`;

export const ImageBackground = styled.img`
  width: 278px;
  height: 342px;
  position: absolute;
  z-index: 1;
  right: 0;
  top: calc((100vh / 2) - 139px);
  display: none;
  @media screen and ${device.LAPTOP} {
    display: block;
  }
`;

export const Separator = styled.br`
  display: none;
  @media screen and ${device.TABLET} {
    display: block;
  }
`;

export const AutocompleteList = styled.div`
  position: absolute;
  width: 100%;
  background-color: #04042a;
  border: 1px solid #82829a;
  border-radius: 10px;
  z-index: 5;
  top: 55px;
  padding: 0 25px;
  max-height: 220px;
  overflow: scroll;
`;
export const AutocompleteListItem = styled.div`
  p {
    color: #ffffff;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    padding: 9px 4px;
    cursor: pointer;
  }
  :hover {
    p {
      color: #7371fc;
    }
  }
`;
export const Spacer = styled.div`
  width: 100%;
  height: 1px;
  background-color: #82829a;
`;

const fadeIn = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`;

export const LoaderScreen = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: ${props => (props.isVisible ? 100 : 0)};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  opacity: ${props => (props.isVisible ? 1 : 0)};
  transition: opacity 0.5s ease-in-out;
  animation: ${fadeIn} 0.5s;

  p {
    color: #7371f4;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 1px;
    z-index: 101;
  }
`;

export const BackgroundOpacity = styled.div`
  background-color: #d9d9d9;
  opacity: 0.89;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 100;
`;

export const IconLoader = styled.img`
  z-index: 101;
  width: 82px;
  height: 82px;
`;

export const WrapperCenter = styled.div`
  z-index: 101;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 16px;
  row-gap: 9px;
`;
