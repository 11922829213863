// Contains all styles used in the app

const primeColors = {
  PURE_BLACK: '#010111',
  GRAY_NEW: '#969696',
  GRAY_LIGHT: '#36373E',
  DARK_BEIGE2: '#D6D9E5',
  LIGHT_ASPHALT: '#8F8F8F',
  CHARCOAL: '#191D1F',
  DARK_CHARCOAL: '#1E1E26',
  DARK_GRAY: '#444444',
  PURE_WHITE: '#FEFEFE',
  EXTRA_WHITE: '#FAF9F7',
  NUDE: '#F9F8F1',
  GREEN: '#90A78F',
  BLUE: '#7371FC',
  DARK_BLUE: '#2E3045',
  BEIGE: '#FCFCFA',
  DARK_BEIGE: '#F4F4F3',
  YELLOW: '#FFAA2B'
};

const styles = {
  colors: {
    // TODO: (unassigned) rename colors to meaningful names
    // NEW COLORS
    PURE_BLACK: primeColors.PURE_BLACK,
    GRAY_NEW: '#969696',
    DARK_BEIGE2: primeColors.DARK_BEIGE2,
    CHARCOAL: primeColors.CHARCOAL,
    DARK_CHARCOAL: primeColors.DARK_CHARCOAL,
    GRAY_LIGHT: primeColors.GRAY_LIGHT,
    WHITE_NEW: primeColors.PURE_WHITE, // pure white
    EXTRA_WHITE: primeColors.EXTRA_WHITE, // extra white, used for background
    LIGHT_ASPHALT: primeColors.LIGHT_ASPHALT,
    NUDE: '#F9F8F1',
    GREEN: '#90A78F',
    BLUE: primeColors.BLUE,
    YELLOW: primeColors.YELLOW,
    DARK_BLUE: '#2E3045',
    BEIGE: '#FCFCFA',
    DARK_BEIGE: '#F4F4F3',

    // OLD COLORS (NOT USE COLORS BELOW)
    RED_ERROR: '#C3365A',
    WHITE: '#FFFFFF', // should be replaced with WHITE_NEW
    BLACK_TRUE: '#404242', // should be replaced with DARK_BLUE
    // YELLOW: '#F2AA49',
    BLACK: '#000000',
    // single usage colors (TO BE DELETED)
    // unsubscribe
    DIRTY_GRAY: '#F6F8FB',
    GREY_FIELD: '#F7F7F7',
    GREY_FIELD_PLACEHOLDER: '#D3D3D3',
    GRAY_TRUE: '#F6F6F6',
    // grow-together
    LIGHT_GRAY_2: '#BEC3CD',
    GREY_LABEL: '#F1EFED',
    YELLOW_DISABLED: '#FFEFB7',
    // other
    RED: '#E0125E',
    ASPHALT: '#F7F8FB',
    YELLOW_DARKER: '#D3A500',
    GREEN_PLANET: '#0D8703',
    LINK: '#3542B8',
    GRAY: '#C4C4C4',
    GRAY_2: '#EDEDED',
    EMAIL_LINK: '#007AFE',
    EMAIL_DARK: '#202124',
    EMAIL_WRAP_DARK: '#27292B',
    GRAY_BORDER: '#e7e7e7',
    WHITE_2: '#f9f9f9',
    GRAY_BORDER_LIGHT: '#82829A',
    DARK_BLUE2: '#04042A',
    PURPLE: '#7371F4',
    PURE_WHITE: '#FFFFFF'
  },
  components: {
    BACKGROUND: primeColors.PURE_BLACK,
    BACKGROUND_LIGHT: primeColors.PURE_WHITE,
    BACKGROUND_LIGHT_EXTRA: primeColors.EXTRA_WHITE,
    QUOTE: primeColors.YELLOW,
    HIGHLIGHT: primeColors.BLUE,
    PAD: primeColors.GRAY_LIGHT,
    LINK: primeColors.BLUE,
    HEADER: primeColors.BLUE,
    HEADER_COLOR: primeColors.BLUE,
    HEADER_INVERTED: primeColors.BLUE,
    TEXT: primeColors.PURE_BLACK,
    TEXT_COLOR: primeColors.BLUE,
    TEXT_INVERTED: primeColors.PURE_WHITE,
    HEADER_2: primeColors.BLUE,
    TIMELINE: primeColors.BLUE,
    DOT: primeColors.BLUE,
    SCROLL: primeColors.BLUE
  },
  layout: {
    MENU_HEIGHT: 80,
    MOBILE_MENU_HEIGHT: 72
  },
  boxShadows: {
    DARK: '0px 0px 50px rgba(0, 0, 0, 0.25)',
    DARK2: '2px 2px 8px rgba(0, 0, 0, 0.1)',
    DARK3: '0px 3px 6px rgba(0, 0, 0, 0.1)',
    SHADOW: '0px 4px 40px 0px rgba(0, 0, 0, 0.25)',
    GREEN: '0px 0px 20px #92A891',
    WHITE: '0px 4px 4px rgba(255, 255, 255, 0.25)',
    TABLE: '0px 4px 20px rgba(0, 0, 0, 0.1)',
    BUTTON:
      '0px 4px 4px rgba(144, 167, 143, 0.1), 0px 4px 20px rgba(0, 0, 0, 0.25)',
    TAB:
      '0px 4px 20px rgba(0, 0, 0, 0.1), 0px 5px 20px rgba(242, 170, 73, 0.56)'
  },
  gradients: {
    LIGHT:
      'linear-gradient(180deg, rgba(255, 255, 255, 0.064) 0%, #FFFFFFCC 80%)',
    WHITE: 'linear-gradient(270deg, #FFF 0.1%, #F7F7F7 99.89%)',
    BLACK: 'linear-gradient(270deg, #010111 0.1%, #191D1F 99.89%)'
  },
  textShadows: {
    RED: '0px 0px 20px rgba(255, 7, 91, 0.3)',
    GREY: '0px 0px 20px rgba(124, 139, 178, 0.2)',
    WHITE: '0px 4px 4px rgba(255, 255, 255, 0.25)'
  }
};

export default styles;
