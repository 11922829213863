import React, { useEffect, useRef } from 'react';

import { Section, Line, Point } from './styled';

const TimelineItem = ({ setObserver, id, isLast }) => {
  const timelineBeforeRef = useRef(null);
  const pointRef = useRef(null);
  const timelineAfterRef = useRef(null);

  useEffect(() => {
    setObserver(timelineBeforeRef.current);
    setObserver(pointRef.current);
    setObserver(timelineAfterRef.current);
  }, [setObserver]);

  return (
    <Section isLast={isLast}>
      <Line id={`timeline-before-${id}`} ref={timelineBeforeRef} before />
      <Point id={`circle-${id}`} ref={pointRef} />
      <Line id={`timeline-after-${id}`} ref={timelineAfterRef} />
    </Section>
  );
};
export default TimelineItem;
