import React from 'react';

const IconClose = ({ size = 20, color = '#253757' }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      clipRule="evenodd"
      fillRule="evenodd"
      d="M0.7013 12.2653C0.317241 12.6622 0.327638 13.2953 0.724523 13.6793C1.12141 14.0634 1.75449 14.053 2.13855 13.6561L6.99868 8.63369L11.8597 13.6571C12.2438 14.0539 12.8769 14.0643 13.2737 13.6803C13.6706 13.2962 13.681 12.6631 13.297 12.2663L8.39023 7.19567L13.297 2.12509C13.681 1.7282 13.6706 1.09512 13.2737 0.711066C12.8768 0.327007 12.2438 0.337404 11.8597 0.734288L6.99868 5.75765L2.13855 0.735217C1.75449 0.338333 1.12141 0.327936 0.724523 0.711995C0.327638 1.09605 0.317241 1.72913 0.7013 2.12602L5.60713 7.19567L0.7013 12.2653Z"
      fill={color}
    />
  </svg>
);

export default IconClose;
