import b5Capital from 'static/images/brands/about-us-b5-capital.svg';
import prrogression from 'static/images/brands/about-us-prrogression.svg';
import dundee from 'static/images/brands/about-us-dundee.svg';
import img1984 from 'static/images/brands/about-us-1984.svg';
import techstarts from 'static/images/brands/about-us-techstarts.svg';

export const logos = {
  Y1984: {
    ALT: '1984',
    IMAGE: img1984,
    LINK: 'https://1984.vc',
    SCALE: 1
  },
  PROGRESSION: {
    ALT: 'Progression',
    IMAGE: prrogression,
    LINK: 'https://www.progression.fund/',
    SCALE: 0.32
  },
  DUNDEE: {
    ALT: 'Dundee',
    IMAGE: dundee,
    LINK: 'https://dundeeventurecapital.com/',
    SCALE: 1
  },
  B5_CAPITAL: {
    ALT: 'B5 Capital',
    IMAGE: b5Capital,
    LINK: 'https://www.b5cap.com/',
    SCALE: 0.57
  },
  TECHSTARTS: {
    ALT: 'Techstarts',
    IMAGE: techstarts,
    LINK: 'https://www.techstars.com/',
    SCALE: 0.33
  }
};
