import styled from 'styled-components';
import styles from 'common/enums/styles';
import { device } from 'common/enums/devices';

export const Container = styled.div`
  font-size: 16px;
  line-height: 1.5;
  color: ${styles.colors.DARK_BLUE};
  margin: 12px 0 45px 0;
  text-align: left;
`;

export const Title = styled.div`
  font-size: 16px;
  line-height: 1.5;
  color: ${styles.colors.DARK_BLUE};
  text-align: left;
`;
export const BoldText = styled.span`
  font-weight: 700;
`;

export const Question = styled.div`
  font-size: 16px;
  line-height: 1.5;
  color: ${styles.colors.DARK_BLUE};
  margin-bottom: 20px;
  font-weight: bold;
`;

export const Download = styled.div`
  font-weight: 700;
  font-size: 20px;
  display: none;
  align-items: center;
  justify-content: center;
  color: ${styles.colors.WHITE_NEW};
  background: ${styles.colors.BLUE};
  border-radius: 8px;
  width: 200px;
  height: 48px;
  cursor: pointer;
  font-family: 'HKGrotesk', sans-serif;
  @media screen and ${device.LAPTOP} {
    display: flex;
  }
  &:hover {
    color: ${styles.colors.WHITE_NEW + 'B3'};
    box-shadow: none;
  }
`;

export const DownloadContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
`;

export const MobileDownload = styled.div`
  @media screen and ${device.LAPTOP} {
    display: none;
  }
`;
