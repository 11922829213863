import styled from 'styled-components';
import styles from 'common/enums/styles';
import { device } from 'common/enums/devices';

export const Wrapper = styled.div`
  background: #f9f8f1;
  min-height: 100vh;
  padding: ${styles.layout.MOBILE_MENU_HEIGHT + 36}px 16px 67px 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media screen and ${device.TABLET} {
    padding: ${styles.layout.MENU_HEIGHT}px 56px;
  }
`;

export const Title = styled.div`
  font-weight: 700;
  line-height: 1.2;
  color: #2e3045;
  font-size: 32px;

  @media screen and ${device.TABLET} {
    font-size: 50px;
  }
`;
export const SubTitle = styled.div`
  font-weight: 600;
  line-height: 1.2;
  color: #2e3045;
  margin: ${({ margin = '18px 0 42px 0' }) => margin};
  font-size: 18px;
  @media screen and ${device.TABLET} {
    font-size: 32px;
  }
`;
export const InfoSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
`;
export const InfoTitle = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 1.2;
  color: #969696;
  margin-bottom: 2px;
`;

export const InfoText = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 1.2;
  color: #2e3045;
`;
export const ButtonsSection = styled.div`
  margin-top: 56px;
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  flex: 1;
  a {
    text-decoration: none;
  }
  @media screen and ${device.TABLET} {
    flex-direction: row-reverse;
    justify-content: flex-end;
    flex: 0;
  }
`;
