import styled from 'styled-components/macro';
import styles from 'common/enums/styles';
import { deviceSizes, device } from 'common/enums/devices';

export const FContainer = styled.footer`
  display: grid;
  grid-auto-flow: row;
  justify-items: center;
  align-content: center;
  padding: 56px 0 46px;
  max-width: ${deviceSizes.LAPTOP_L};
  color: ${styles.colors.WHITE_NEW};
  @media screen and ${device.LAPTOP} {
    grid-auto-flow: column;
    padding: 95px 7% 111px 7%;
    align-content: initial;
    justify-content: space-between;
    justify-items: left;
    gap: 71px;
  }
  @media screen and (min-width: 1300px) {
    padding: 95px 69px 40px 138px;
    gap: 0;
    margin: auto;
  }
`;

export const Section = styled.div`
  background-color: ${styles.components.BACKGROUND};
`;

export const Image = styled.img`
  height: 32px;
  width: auto;
`;

export const Address = styled.ul`
  display: grid;
  grid-auto-flow: row;
  width: 80%;
  gap: 13px;
  padding-left: 10px;
  margin-top: 20px;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.36;
  @media screen and ${device.LAPTOP} {
    grid-auto-flow: column;
    width: 100%;
    gap: 14px;
    padding-left: 0;
    margin-top: 26px;
    font-size: 16px;
    line-height: 1.2;
  }
  @media screen and ${device.LAPTOP_L} {
    line-height: 1.5;
    margin-top: 24px;

    li {
      &:last-of-type {
        text-align: right;
      }
    }
  }
`;

export const ContainerLeft = styled.div`
  width: 86.8%;
  @media screen and ${device.LAPTOP} {
    width: auto;
    max-width: 484px;
  }
`;

export const Media = styled.div`
  display: grid;
  grid-auto-flow: column;
  gap: 26px;
  justify-content: flex-start;
  margin-top: 48px;
  @media screen and ${device.LAPTOP} {
    margin-top: 20px;
  }
`;
export const MediaImage = styled.img``;
export const MediaItem = styled.a`
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;

  @media screen and (hover: hover) and (pointer: fine) {
    &:hover {
      -webkit-transform: translateY(-8px);
      transform: translateY(-8px);
    }
  }
`;
export const Link = styled.a`
  font-size: 20px;
  font-weight: 600;
  line-height: 1.8;
  text-decoration: none;
  color: ${styles.colors.WHITE_NEW};
  &:hover {
    color: ${styles.colors.BLUE};
  }
  @media screen and ${device.LAPTOP} {
    line-height: 1.7;
  }
`;

export const LinksGrid = styled.div`
  display: grid;
  gap: 3.7px;
  justify-content: flex-start;

  @media screen and ${device.LAPTOP} {
    grid-auto-flow: row;
    grid-template-column: repeat(1, auto);
    gap: 0px 62px;
    margin-top: 44px;
  }
`;

export const LinkContainer = styled.div`
  display: grid;
  margin-top: 54px;
  @media screen and ${device.LAPTOP} {
    display: block;
    margin-top: 0;
  }
`;
export const ContainerRight = styled.div`
  width: 91%;
  margin-top: 58px;
  @media screen and ${device.LAPTOP} {
    width: 100%;
    margin-top: 0;
  }
  @media screen and ${device.LAPTOP_L} {
    width: 640px;
  }
`;
export const Copy = styled.div`
  font-size: 14px;
  line-height: 1.8;
  margin-top: 7px;
  font-weight: 400;
  color: ${styles.colors.WHITE_NEW};
  @media screen and ${device.LAPTOP} {
    margin-top: 35px;
    font-size: 16px;
    line-height: 1.2;
    text-align: right;
  }
`;

export const Title = styled.div`
  padding-left: 9.5px;
  font-weight: 600;
  font-size: 18px;
  color: ${styles.colors.WHITE_NEW};
  @media screen and ${device.LAPTOP} {
    font-size: 20px;
    padding-left: 0;
  }
`;
export const MapContainer = styled.div`
  height: auto;
  margin-top: 0;
  border-radius: 6px;
  margin-bottom: 7px;
  overflow: hidden;
  @media screen and ${device.LAPTOP} {
    height: 335px;
    margin-top: 35px;
    margin-bottom: 24px;
    border-radius: 0;
  }
`;

export const Buttons = styled.div`
  margin-top: 36px;
  @media screen and ${device.LAPTOP} {
    margin-top: 32px;
  }
`;
export const TextWrapper = styled.div`
  width: 268px;
  margin-top: 16px;
  @media screen and ${device.LAPTOP} {
    width: 100%;
  }
`;

export const footerTextStyle = {
  fontWeight: 400,
  color: styles.colors.WHITE_NEW
};
