import React, { Fragment } from 'react';

import { Text } from 'components';
import {
  Title,
  Image,
  ItemTitle,
  Section,
  GridContainer,
  textStyles
} from './styled';

const IconList = ({ items, title, noWaves, hasNudeBackground, type }) => (
  <Section noWaves={noWaves} hasNudeBackground={hasNudeBackground}>
    <Title>{title}</Title>
    <GridContainer type={type}>
      {Object.values(items).map(item => (
        <Fragment key={item.TYPE}>
          <Image src={item.IMAGE} />
          <ItemTitle type={type}>{item.TITLE}</ItemTitle>
          <Text description2 textAlign="center" style={textStyles}>
            {item.TEXT}
          </Text>
        </Fragment>
      ))}
    </GridContainer>
  </Section>
);

export default IconList;
