import { SquareButton } from 'components';
import { Wrapper, Title, SubTitle, ButtonsSection } from './styled';
import React from 'react';
import { connect } from 'react-redux';
import { setSubscriptionStep } from 'store/actions/subscription';

const Final = ({ setSubscriptionStep, onClose }) => {
  return (
    <Wrapper>
      <Title>You're all set!</Title>
      <SubTitle margin="18px 0 0 0">
        We’ve added you to DobbyCare Top 200 spots. We'll be in touch soon.
      </SubTitle>

      <ButtonsSection>
        <SquareButton
          fontSize={16}
          onClick={() => {
            setSubscriptionStep('');
            onClose();
          }}>
          To the Homepage
        </SquareButton>
      </ButtonsSection>
    </Wrapper>
  );
};

const mapDispatchToProps = {
  setSubscriptionStep
};

export default connect(null, mapDispatchToProps)(Final);
