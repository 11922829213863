import React from 'react';
import { useLocation } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import ScrollAnimation from 'react-animate-on-scroll';
import smoothscroll from 'smoothscroll-polyfill';

import { useTrackPageViews } from 'hooks';

import { links } from 'common/constants';
import { analyticsPageTypes } from 'common/enums/pages';
import arrowIcon from 'static/images/icons/get-started-arrow.svg';
import firstSectionBg from 'static/images/backgrounds/get-started-first-section.png';
import firstSectionBgMob from 'static/images/backgrounds/get-started-first-section-mob.png';
import { OutdatedButton } from 'components';

import { buttonTypes, howItWorks } from './enums';
import {
  Wrapper,
  ContentWrapper,
  Section,
  StyledAnchorLink,
  BackgroundImageWrapper,
  ImageWrapper,
  TopHeader,
  SubHeader,
  MainSubHeader,
  SubTitle,
  CircleSectionNumber
} from './styled';

smoothscroll.polyfill();

const GetStarted = () => {
  useTrackPageViews({ page: analyticsPageTypes.GET_STARTED });
  const location = useLocation();

  const params = new URLSearchParams(location.search);
  const isPageOpenedInApp = params.get('in_app');

  const handleTalkToUsClick = () => {
    if (isPageOpenedInApp) {
      window.open(links.SUPPORT_CHAT_DEEP);
    }

    if (isMobile) {
      window.location.href = links.SUPPORT_CHAT_DYNAMIC;
      return;
    }

    window.location.href = 'mailto:support@hellodobby.com';
  };

  const handleCreateJobClick = () => {
    if (isPageOpenedInApp) {
      window.open(links.CREATE_JOB_DEEP);
    }

    if (isMobile) {
      window.location.href = links.CREATE_JOB_DYNAMIC;
      return;
    }

    window.location.href = links.MARKET_HO_APP_IOS;
  };

  const handleButtonClick = type => () => {
    if (type === buttonTypes.START_JOB) {
      handleCreateJobClick();
      return;
    }

    if (type === buttonTypes.SUPPORT) {
      handleTalkToUsClick();
      return;
    }
  };

  return (
    <>
      <Wrapper>
        <ScrollAnimation animateIn="fadeIn" animateOnce delay={300}>
          <Section firstSection>
            <TopHeader marginBottom={24} marginBottomMob={31}>
              Welcome to&nbsp;Dobby!
            </TopHeader>
            <MainSubHeader>Ready to get started?</MainSubHeader>

            <StyledAnchorLink href="#getStarted">
              <ImageWrapper>
                <img src={arrowIcon} alt="Arrow icon" />
              </ImageWrapper>
            </StyledAnchorLink>
            <BackgroundImageWrapper>
              <img
                src={firstSectionBgMob}
                className="mob"
                alt="Section Background Mobile"
              />
              <img
                src={firstSectionBg}
                className="desktop"
                alt="Section Background"
              />
            </BackgroundImageWrapper>
          </Section>
        </ScrollAnimation>

        {Object.values(howItWorks).map((item, index) => (
          <ScrollAnimation
            animateIn="fadeIn"
            animateOnce
            delay={300}
            key={index}>
            <Section id="getStarted">
              <ContentWrapper>
                <CircleSectionNumber>{index + 1}</CircleSectionNumber>
                <ImageWrapper
                  marginBottom={19}
                  marginBottomMob={24}
                  widthDesktop={493}>
                  <img src={item.IMAGE} alt="App screen" />
                </ImageWrapper>
                <SubHeader marginBottom={12} marginBottomMob={8}>
                  {item.TITLE}
                </SubHeader>
                <SubTitle
                  marginBottom={item.BUTTON ? 28 : 0}
                  marginBottomMob={item.BUTTON ? 28 : 0}
                  maxWidth={item.MAX_WIDTH}>
                  {item.TEXT}
                </SubTitle>
                {Boolean(item.BUTTON) && (
                  <OutdatedButton
                    title={item.BUTTON.TITLE}
                    flexMobile={false}
                    width={160}
                    type="brand"
                    onClick={handleButtonClick(item.BUTTON.TYPE)}
                  />
                )}
              </ContentWrapper>
            </Section>
          </ScrollAnimation>
        ))}
      </Wrapper>
    </>
  );
};

export default GetStarted;
