import styled from 'styled-components';
import { device } from 'common/enums/devices';
import { directions } from '../../enums';

export const Icon = styled.img`
  width: 38px;
  padding: 10px;
  height: auto;
  transform: rotate(${({ direction }) =>
    direction === directions.NEXT ? '180deg' : 0});
  cursor: pointer;
  ${({ desktopOnly }) => desktopOnly && 'display:none;'}
  @media screen and ${device.LAPTOP} {
    display: flex;
  }
`;
