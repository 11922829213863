import { call, takeLatest, put } from 'redux-saga/effects';
import api_constants from 'common/enums/api';
import actionTypes from 'common/enums/actionTypes';
import Api from 'services/ApiService';

function* getPublications() {
  try {
    const response = yield call(Api.apiGet, `${api_constants.PUBLICATIONS}`);

    if (!response?.results) {
      throw new Error();
    }

    yield put({
      type: actionTypes.GET_PUBLICATIONS_SUCCESS,
      payload: response.results
    });
  } catch (err) {
    yield put({
      type: actionTypes.GET_PUBLICATIONS_ERROR,
      payload: 'Something went wrong. Try again later'
    });
  }
}

export default function* watchGetPublications() {
  yield takeLatest(actionTypes.GET_PUBLICATIONS_FETCH, getPublications);
}
