import styled from 'styled-components/macro';
import { device } from 'common/enums/devices';

export const GridContainer = styled.div`
  display: grid;
  margin-top: 40px;
  gap: 16px;
  justify-content: center;
  @media screen and ${device.TABLET} {
    grid-template-columns: auto auto;
  }
  @media screen and ${device.LAPTOP_L} {
    grid-template-columns: auto auto auto;
    gap: 20px;
    margin-top: 22px;
  }
`;
