import styled from 'styled-components';
import styles from 'common/enums/styles';
import { device } from 'common/enums/devices';

const StyledInput = styled.textarea`
  display: inline-block;
  margin-bottom: 16px;
  margin-right: ${({ isPhone }) => (isPhone ? '0' : '10px')};
  background: ${({ disabled }) =>
    !disabled ? styles.colors.WHITE : styles.colors.GRAY};
  font-family: 'HKGrotesk', sans-serif;
  font-size: 14px;
  font-weight: bold;
  line-height: 17px;
  letter-spacing: 1px;
  padding: 20px 27px 18px;
  width: 100%;
  box-sizing: border-box;
  color: ${styles.colors.BLACK_TRUE};
  border-radius: 2px;
  border: 1px solid rgba(101, 129, 163, 0.28);

  outline: none;
  resize: none;
  overflow: auto;
  transition: all 0.2s ease-out;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  min-width: 100%;

  ${({ errorBorders }) =>
    errorBorders &&
    `
        border: 1px solid ${styles.colors.RED_ERROR};
    `}

  &::placeholder {
    font-family: 'HKGrotesk', sans-serif;
    color: ${styles.colors.GRAY_NEW};
  }
  &:hover,
  &:focus {
    outline: none;
  }
  @media screen and ${device.LAPTOP} {
    width: 320px;
    min-width: 100%;
    border: none;
    ${({ errorBorders }) =>
      errorBorders &&
      `
        border: 1px solid ${styles.colors.RED_ERROR};
        `}
  }
`;

export default StyledInput;
