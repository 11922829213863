import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { scroller } from 'react-scroll';

import { useTrackPageViews } from 'hooks';
import { pageTypes } from 'common/enums/pages';
import { DOCUMENT_TITLE } from 'common/constants/html';
import { openModal } from 'store/actions/modal';
import { selectUserId } from 'store/reducers/user';
import {
  Reviews,
  HowDobbyWorks,
  TopBar,
  Categories,
  Accordion
} from 'components';
import { accordionItems } from 'common/enums/accordion';

import { reviewsSection, categoriesSection, items } from './enums';
import { TextVideoForm } from './components';
import { Wrapper } from './styled';

const JoinUs = ({ page = pageTypes.JOIN_US_DMV, location }) => {
  useTrackPageViews({ page: items[page].eventNameEntryPoint });
  const [reviews, setReviews] = useState(items[page].reviews);
  const initialSlide = location.hash.slice(1) === 'how-it-works-3' ? 3 : 1;

  useEffect(() => {
    const item = items[page];
    setReviews(item.reviews);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const scrollTo = section => {
    scroller.scrollTo(section, {
      duration: 1000,
      delay: 100,
      smooth: true,
      offset: -150
    });
  };

  useEffect(() => {
    document.title = DOCUMENT_TITLE;
    window.scrollTo(0, 0);
    const hash = location.hash.slice(1);
    if (hash) {
      setTimeout(() => scrollTo(hash), 100);
    } else {
      window.scrollTo(0, 0);
    }
  }, [location.hash]);

  return (
    <>
      <TopBar />
      <Wrapper>
        <TextVideoForm
          entryPoint={items[page].entryPoint}
          eventNameEntryPoint={items[page].eventNameEntryPoint}
        />
        <Accordion withSpace items={accordionItems} />
        <HowDobbyWorks initialSlide={initialSlide} />
        <Reviews reviews={reviews} reviewsSection={reviewsSection} />
        <Categories
          categoriesSection={categoriesSection}
          entryPoint={items[page].entryPoint}
          eventNameEntryPoint={items[page].eventNameEntryPoint}
        />
      </Wrapper>
    </>
  );
};

const mapDispatchToProps = {
  openModal
};

const mapStateToProps = state => ({
  userId: selectUserId(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(JoinUs);
