import styled from 'styled-components';
import styles from 'common/enums/styles';
import { device } from 'common/enums/devices';

export const PageFlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const MainSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const DesktopApplyNowSection = styled.div`
  min-width: 232px;
  position: relative;
  @media screen and (max-width: 1023px) {
    display: none;
  }
`;

export const MobileSection = styled.div`
  @media screen and ${device.LAPTOP} {
    display: none;
  }
  position: relative;
  align-items: flex-start;
  align-self: flex-start;
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
`;

export const MobileSectionFixed = styled(MobileSection)`
  position: fixed;
  padding-bottom: 20px;
  background: ${styles.colors.WHITE};
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1;
`;

export const ApplyNowStickyContainer = styled.div`
  align-items: center;
  justify-content: flex-start;
  display: flex;
  position: relative;
  flex-direction: column;
  min-width: 230px;
`;

export const RoleContainer = styled.div`
  align-items: flex-start;
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: nowrap;
  position: relative;
  margin-top: calc(${styles.layout.MOBILE_MENU_HEIGHT}px + 34px);
  margin-bottom: 30px;
  max-width: 1172px;

  @media screen and ${device.LAPTOP} {
    margin-top: calc(${styles.layout.MENU_HEIGHT}px + 158px);
    margin-bottom: 111px;
  }
`;

export const ImageWrapper = styled.div`
  align-items: center;
  align-self: center;
  display: flex;
  justify-content: center;
  margin-left: 16px;
  width: 160px;
  height: 160px;
  background: ${styles.colors.WHITE};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  margin-bottom: 34px;
  @media screen and ${device.LAPTOP} {
    margin-left: 0px;
    margin-bottom: 28px;
  }
`;

export const ApplyNowImage = styled.img`
  width: 100px;
  height: 100px;
`;

export const DescriptionSection = styled.div``;

export const TopHeading = styled.h2`
  text-align: left;
  font-weight: bold;
  font-size: 30px;
  line-height: 1.2;
  letter-spacing: 1px;
  color: ${styles.colors.BLACK_TRUE};
  padding-left: 16px;
  white-space: pre-wrap;

  @media screen and ${device.LAPTOP} {
    font-size: 50px;
    line-height: 1.3;
  }
`;

export const RowContainer = styled.div`
  flex-direction: column;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 5px 5px 5px 16px;
  @media screen and ${device.LAPTOP} {
    align-items: flex-start;
    padding: 5px 16px;
    flex-direction: row;
    justify-content: flex-start;
  }
`;

export const RowItem = styled.div`
  flex-direction: row;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 16px;
  flex: 1;

  @media screen and ${device.LAPTOP} {
    margin-bottom: 0;
  }
`;

export const IconWrapper = styled.div`
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${styles.colors.BLACK_TRUE};
  border-radius: 16px;
  width: 32px;
  height: 32px;
  margin: 0 11px 0 0;
`;

export const LightHeading = styled.h3`
  font-size: 24px;
  line-height: 1.66;
  font-weight: 600;
  text-align: center;
  letter-spacing: 1px;
  color: ${styles.colors.BLACK_TRUE};
  margin: 0;
  @media screen and ${device.LAPTOP} {
    line-height: 2.5;
  }
`;

export const Text = styled.p`
  color: ${styles.colors.BLACK_TRUE};
  font-weight: normal;
  font-size: 14px;
  line-height: 1.5;

  @media screen and ${device.LAPTOP} {
    font-size: 16px;
  }
`;

export const DescriptionText = styled(Text)`
  margin-top: 32px;
  padding: 0 16px;
  white-space: pre-wrap;
  @media screen and ${device.LAPTOP} {
    padding: 0 97px 0 16px;
  }
`;

export const SecondaryDescriptionText = styled(Text)`
  margin-top: 0;
  padding: 0 4.2vw 0 16px;
  white-space: pre-wrap;
`;

export const SubHeading = styled.h4`
  font-size: 24px;
  font-weight: bold;
  line-height: 1.2;
  color: ${styles.colors.BLACK_TRUE};
  padding: 13px 16px 0;
  @media screen and ${device.LAPTOP} {
    font-size: 30px;
  }
`;

export const ListItem = styled.li`
  color: #ededed;
  font-weight: normal;
  font-size: 34px;
  line-height: 0.62;
  list-style-position: outside;
  margin: 0 4vw 16px 15px;

  @media screen and ${device.LAPTOP} {
    line-height: 0.7;
  }
  span {
    color: ${styles.colors.BLACK_TRUE};
    font-weight: normal;
    font-size: 14px;
    height: 100%;
    vertical-align: middle;
    @media screen and ${device.LAPTOP} {
      font-size: 16px;
      line-height: 1.5;
    }
  }
`;

export const Icon = styled.img`
  display: block;
  margin-left: auto;
  margin-right: auto;
  vertical-align: middle;
  width: 50%;
  max-width: 60px;
  height: 100%;
`;

export const FormHolder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

export const FormCenterHolder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: normal;
  font-size: 24px;
  line-height: 1.46;

  text-align: center;
  span {
    padding-bottom: 30px;
    @media screen and ${device.LAPTOP} {
      padding: 0 50px 30px 50px;
    }
  }

  button {
    align-self: center;
    max-width: 250px;
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  @media screen and ${device.LAPTOP} {
    flex-direction: row;
    justify-content: center;
  }
`;

export const ValidationBlock = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  color: ${styles.colors.RED_ERROR};
  font-weight: bold;
  font-size: 12px;
  line-height: 1.5;
  letter-spacing: 1px;
  word-wrap: break-word;
  padding-bottom: 16px;

  @media screen and ${device.LAPTOP} {
    font-size: 16px;
    padding-left: 8px;
    min-width: 300px;
    max-width: ${({ wide }) => (wide ? '100%' : '270px')};
  }
`;

export const UploadLabel = styled.label`
  cursor: pointer;
`;

export const UploadButton = styled.div`
  border: 1px solid ${styles.colors.DARK_BEIGE2};
  box-sizing: border-box;
  border-radius: 2px;
  margin: 11px 0;
  padding: 12px 32px;
  min-width: 100%;
  text-align: center;
  ${({ errorBorders }) =>
    errorBorders &&
    `
  border: 1px solid ${styles.colors.RED_ERROR};
  `}
  &:hover {
    color: ${styles.colors.YELLOW};
  }
  @media screen and ${device.LAPTOP} {
    min-width: 320px;
    max-width: 100%;
  }
`;

export const UploadFileBlock = styled.div`
  @media screen and ${device.LAPTOP} {
    margin-bottom: 43px;
  }
`;

export const FileNameWraper = styled.div`
  display: flex;
`;

export const FileName = styled.div`
  max-width: 250px;
  p {
    font-weight: 600;
    font-size: 18px;
    line-height: 1.2;
    padding-right: 6px;
    width: 100%;
    color: ${styles.colors.BLACK_TRUE};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const ButtonWrapper = styled.div`
  margin-top: 23px;
`;

export const DeleteButton = styled.button`
  position: relative;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: none;
  outline: 0;
  transition: background 0.3s;

  &:focus {
    outline: 0;
  }
  &:hover {
    background: ${styles.colors.ASPHALT};
  }
  &:after,
  &:before {
    display: block;
    content: '';
    width: 11px;
    border-bottom: 1px solid ${styles.colors.BLACK_TRUE};
    position: absolute;
    top: 8.4px;
    left: 3.5px;
  }
  &:after {
    transform: rotate(45deg);
  }
  &:before {
    transform: rotate(-45deg);
  }
`;
