import { call, takeLatest, put } from 'redux-saga/effects';
import api_constants from 'common/enums/api';
import actionTypes from 'common/enums/actionTypes';
import Api from 'services/ApiService';

function* getPublication({ payload }) {
  try {
    const response = yield call(
      Api.apiGet,
      `${api_constants.PUBLICATIONS}/${payload.id}`
    );

    if (!response?.content) {
      throw new Error();
    }

    yield put({
      type: actionTypes.GET_PUBLICATION_SUCCESS,
      payload: response
    });
  } catch (err) {
    yield put({
      type: actionTypes.GET_PUBLICATION_ERROR,
      payload: 'Something went wrong. Try again later'
    });
  }
}

export default function* watchGetPublication() {
  yield takeLatest(actionTypes.GET_PUBLICATION_FETCH, getPublication);
}
