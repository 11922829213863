import styles from 'common/enums/styles';
import styled from 'styled-components';
import { device } from '../../common/enums/devices';

export const ImageWrapper = styled.div`
  background: #fefefc;
  display: flex;
  justify-content: center;
  img {
    width: 194px;
  }
  @media screen and ${device.TABLET} {
    display: none;
  }
`;

export const Wrapper = styled.div`
  padding: ${styles.layout.MOBILE_MENU_HEIGHT}px 0 0 0;
  margin: 0 auto;
`;

export const Container = styled.div`
  padding: ${({ padding = '0 16px' }) => padding};
  max-width: ${({ maxWidth = 1080 }) => maxWidth}px;
  margin: 0 auto;
`;

export const MainSection = styled.div`
  background: ${styles.colors.NUDE};
  padding: 43px 0 76px 0;
`;

export const Title = styled.div`
  font-size: 32px;

  @media screen and ${device.TABLET} {
    font-size: 50px;
    margin-bottom: 0;
    margin-top: 50px;
  }
  font-weight: 700;
  margin-bottom: 34px;
  line-height: 1.18;

  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth}`};
  ${({ textAlign }) => textAlign && `text-align: ${textAlign}`}
`;

export const ColoredText = styled.span`
  color: #f2aa49;
`;

export const SubMessageText = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 66px;
  text-align: right;
  margin-right: 10px;
`;

export const HowItWorks = styled.div`
  padding: 104px 0 96px 0;
  background: #fefefc;
  @media screen and ${device.TABLET} {
    padding: 112px 0 184px 0;
  }
`;

export const Message = styled.div`
  position: relative;
  max-width: 500px;
  img {
    height: 192px;
    max-width: 105%;
    min-width: 105%;
    margin-left: -20px;
  }
  @media screen and ${device.TABLET} {
    margin-right: 0px;
  }
  margin-right: 14px;
`;

export const MessageText = styled.div`
  font-style: italic;
  font-weight: 700;

  font-size: 18px;
  @media screen and ${device.TABLET} {
    font-size: 24px;
    padding: 30px;
  }
  line-height: 1.2;
  padding: 35px 30px 35px 20px;
  color: #000000;
  position: absolute;
  top: 0;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
export const Header = styled.div`
  @media screen and ${device.TABLET} {
    display: flex;
    align-items: center;
  }
`;

export const Phones = styled.div`
  @media screen and ${device.TABLET} {
    display: block;
    margin-left: 20px;
  }
  display: none;
`;

export const Customize = styled.div`
  padding: 100px 0;
  @media screen and ${device.TABLET} {
    display: flex;
    align-items: center;
  }
  background: #f9f8f1;
`;

export const Tabs = styled.div`
  background: #fefefc;
  border: 1px solid #e7e7e7;
  border-radius: 100px;
  height: 46px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  max-width: 620px;
  margin: 0 auto 64px auto;

  @media screen and ${device.TABLET} {
    height: 52px;
    margin: 0 auto 80px auto;
  }
`;

export const Tab = styled.div`
  font-weight: 700;
  font-size: 14px;
  color: #2e3045;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-top: -4px;
  @media screen and ${device.TABLET} {
    font-size: 24px;
  }
  ${({ active }) =>
    active &&
    `
  cursor: default;
  border-radius: 100px;
  color: #FEFEFC;
  background: #F2AA49;
  height: 52px;
  border: 1px solid #E7E7E7;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1), 0px 5px 20px rgba(242, 170, 73, 0.56);
  @media screen and ${device.TABLET} {
    height: 58px;
  }
  `};
`;

export const TableHeader = styled.div`
  padding: 21px 0 10px 0;
  background: #f9f9f9;
  font-weight: 700;
  font-size: 24px;
  text-align: center;
  color: #2e3045;
`;

export const TableItem = styled.div`
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  color: #2e3045;
  height: 30px;
  cursor: pointer;
  padding: 0 16px;
  white-space: nowrap;
  @media screen and ${device.TABLET} {
    padding: 0 24px;
    gap: 16px;
  }
  background: ${({ even }) => (even ? '#FEFEFC' : '#F9F9F9')};
  gap: 12px;
`;
export const TableFooter = styled.div`
  text-align: center;
  font-weight: 700;
  font-size: 24px;
  color: #2e3045;

  padding: 17px 0 14px 0;
  background: #fefefc;
`;
export const Table = styled.div`
  margin: 20px auto 0 auto;
  max-width: 521px;
  @media screen and ${device.TABLET} {
    margin: 28px auto 45px auto;
    border: 1px solid #e7e7e7;
  }
`;

export const MobileRoad = styled.div`
  @media screen and ${device.TABLET} {
    display: none;
  }
  display: flex;
  justify-content: center;
`;
export const Road = styled.div`
  @media screen and ${device.TABLET} {
    display: flex;
    justify-content: center;
  }
  display: none;
`;

export const ReviewTitle = styled.div`
  font-weight: 700;
  font-size: 36px;
  @media screen and ${device.TABLET} {
    font-size: 50px;
    margin-bottom: 50px;
  }
  text-align: center;
  color: #2e3045;
`;

export const Footer = styled.div`
  background: #f9f8f1;
  padding: 36px 0 80px 0;
  @media screen and ${device.TABLET} {
    padding: 52px 0 100px 0;
  }
`;

export const FooterText = styled.div`
  font-weight: 700;
  font-size: 32px;
  line-height: 1.2;
  color: #2e3045;
  margin: 0 40px 0 40px;
  margin-bottom: 14px;
  @media screen and ${device.TABLET} {
    margin-bottom: 24px;
  }
  text-align: center;
  font-size: 24px;
`;
export const MobileImage = styled.div`
  @media screen and ${device.TABLET} {
    display: none;
  }
`;

export const DesktopImage = styled.div`
  display: none;
  margin-left: 20px;
  @media screen and ${device.TABLET} {
    display: block;
  }
`;
export const FooterContent = styled.div`
  @media screen and ${device.TABLET} {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

export const Connext = styled.div`
  font-weight: 700;
  font-size: 32px;
  line-height: 1.2;
  color: #2e3045;
  text-align: left;
  @media screen and ${device.TABLET} {
    text-align: center;
  }
  font-size: 24px;
`;

export const MainText = styled.div`
  font-weight: 700;
  font-size: 32px;
  line-height: 1.2;
  letter-spacing: 1px;
  margin: 40px 0 20px 0;
  text-align: left;
  @media screen and ${device.TABLET} {
    margin: 56px 0 28px 0;
    font-size: 50px;
    text-align: center;
  }
`;

export const EnterPhone = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 1.2;
  color: #2e3045;
  margin: 30px 0 8px 2px;
`;

export const EnterPhoneSection = styled.div`
  max-width: 514px;
  margin: 0 auto;
`;

export const PhoneInput = styled.div`
  display: flex;

  gap: 32px;
  flex-direction: column;
  align-items: center;
  @media screen and ${device.TABLET} {
    flex-direction: row;
    align-items: flex-end;
  }
  input {
    margin-right: unset;
    max-width: unset;
  }
`;
