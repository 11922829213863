export const setBodyOverflowHiddenFunctional = currentValue => {
  if (currentValue === true) {
    document.body.style.overflow = 'hidden';
  } else if (currentValue === false) {
    document.body.style.overflow = 'auto';
  }
};

export const setBodyOverflowHidden = (prevValue, currentValue) => {
  if (prevValue !== currentValue) {
    setBodyOverflowHiddenFunctional(currentValue);
  }
};

export const isSafari = () => {
  const userAgent = navigator.userAgent.toLowerCase();
  return userAgent.includes('safari') && !userAgent.includes('chrome');
};
