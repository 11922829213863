import styled from 'styled-components/macro';
import styles from 'common/enums/styles';
import { device } from 'common/enums/devices';

export const Section = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 20px 0;
  background-color: ${({ inverted }) =>
    inverted ? styles.components.BACKGROUND : styles.colors.WHITE_NEW};
  @media screen and ${device.LAPTOP} {
    padding: 72px 0 142px;
  }
`;

export const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
  padding-left: ${({ longTitle }) => (longTitle ? '16' : '0')}px;

  @media screen and ${device.TABLET} {
    padding-left: ${({ longTitle }) => (longTitle ? '80' : '148')}px;
    padding-right: 0;
  }

  @media screen and ${device.LAPTOP} {
    padding-left: 148px;
    padding-right: 0;
  }
`;

export const Container = styled.div`
  display: grid;
  grid-auto-flow: row;
  width: auto;
  justify-items: center;
  gap: 10px;

  @media screen and ${device.LAPTOP} {
    gap: 0 40px;
    grid-template-rows: 200px auto;
    grid-template-columns: 200px auto;
    width: 100%;
    max-width: 1167px;
    justify-content: center;
    justify-items: baseline;
  }
`;

export const TextContainer = styled.div`
  margin: auto;
  margin-top: 40px;
  width: 86%;
  white-space: break-spaces;
  @media screen and ${device.LAPTOP} {
    padding-top: 5px;
    margin: 0;
    width: 100%;
    max-width: 550px;
  }
  @media screen and (min-width: 1200px) {
    max-width: 670px;
  }
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 50%;
`;

export const Title = styled.h1`
  text-align: left;
  margin: 0;
  white-space: pre-wrap;
  ${({ longTitle }) => longTitle && 'padding-right: 4px;'}
  color: ${({ inverted }) =>
    inverted
      ? styles.components.HEADER_INVERTED
      : styles.components.HEADER_COLOR};
  @media screen and ${device.LAPTOP} {
    ${({ longTitle }) => longTitle && 'width: 800px;'}
    white-space: pre-wrap;
    padding-right: 0;
  }
`;

export const TextWrapper = styled.div`
  margin: 28px;
  align-self: center;
  position: relative;
  padding: 0 24px;
  white-space: pre-wrap;

  @media screen and ${device.LAPTOP} {
    padding: 0;
  }
  p {
    text-align: justify;
    @media screen and ${device.LAPTOP} {
      text-align: left;
      padding: 4px;
    }
  }
`;

export const textStyle = {
  textAlign: 'justify'
};

export const Name = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 1.8;
  color: ${({ inverted }) =>
    inverted ? styles.components.TEXT_INVERTED : styles.colors.TEXT};
  margin: 0;
  @media screen and ${device.LAPTOP} {
    font-weight: 700;
    font-size: 24px;
    line-height: 1.2;
  }
`;
export const Position = styled.p`
  font-size: 14px;
  line-height: 1.2;
  color: ${({ inverted }) =>
    inverted ? styles.components.TEXT_INVERTED : styles.colors.GRAY_NEW};
  @media screen and ${device.LAPTOP} {
    margin-top: 4px;
    font-size: 16px;
  }
`;

export const NameBox = styled.div`
  text-align: center;

  ${({ quoteName }) =>
    quoteName &&
    `
    grid-row: 2;
  `}

  @media screen and ${device.LAPTOP} {
    text-align: left;
    padding: 0 4px;
    margin-left: 28px;

    grid-column: 2;
    grid-row: 2;
  
    ${({ quoteName }) =>
      quoteName &&
      `
      width: 100%;
      margin: 10px 0 0 0;
      text-align: center;
      grid-column: 1;
    `}
  }
`;

const Icon = styled.img`
  position: absolute;
  height: 24px;
  width: auto;
  @media screen and ${device.LAPTOP} {
    height: 24px;
  }
`;

export const IconUp = styled(Icon)`
  right: calc(100% - 11px);
  bottom: calc(100% + 2px);
  transform: scale(1, 1);
  @media screen and ${device.LAPTOP} {
    left: -25px;
    top: -25px;
  }
`;

export const IconDown = styled(Icon)`
  right: -9px;
  bottom: -25px;
  transform: scale(-1, -1);
  @media screen and ${device.LAPTOP} {
    right: -25px;
    bottom: -25px;
  }
`;

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 180px;
  height: 180px;

  @media screen and ${device.LAPTOP} {
    margin-bottom: 0;
    width: 200px;
    height: 200px;
  }
`;

export const QuoteNameBox = styled.div`
  text-align: center;
  @media screen and ${device.LAPTOP} {
    margin-top: 10px;
    text-align: right;
    width: 100%;
    padding-right: 28px;
  }
`;

export const QuoteName = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 1.8;
  color: ${({ inverted }) =>
    inverted ? styles.components.TEXT_INVERTED : styles.colors.TEXT};
  margin: 0;
  @media screen and ${device.LAPTOP} {
    font-weight: 700;
    font-size: 24px;
    line-height: 1.2;
  }
`;

export const QuoteSub = styled.p`
  font-size: 14px;
  line-height: 1.2;
  color: ${({ inverted }) =>
    inverted ? styles.components.TEXT_INVERTED : styles.colors.GRAY_NEW};
  @media screen and ${device.LAPTOP} {
    margin-top: 4px;
    font-size: 16px;
  }
`;
