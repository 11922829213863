import React from 'react';
import PropTypes from 'prop-types';
import StyledInput from './styled';

const TextArea = ({
  type,
  placeholder,
  value,
  onUpdate,
  errorBorders,
  rows,
  onBlur,
  ...rest
}) => (
  <StyledInput
    type={type}
    placeholder={placeholder}
    value={value}
    rows={rows}
    errorBorders={errorBorders}
    onBlur={onBlur}
    {...rest}
  />
);

TextArea.propTypes = {
  type: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onUpdate: PropTypes.func // dirty fix
};

TextArea.defaultProps = {
  type: 'text',
  placeholder: '',
  value: '',
  rows: 6
};

export default TextArea;
