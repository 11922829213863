import styled from 'styled-components';
import styles from 'common/enums/styles';

export const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ImageWrapper = styled.div`
  margin-bottom: 12px;
  img {
    max-width: 100%;
    height: auto;
  }
`;

export const InnerBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: ${styles.colors.DIRTY_GRAY};
  border-radius: 8px;
  padding: 39px 106px 62px 106px;
`;

export const TopHeader = styled.h4`
  margin: 0;
  font-weight: 800;
  font-size: 32px;
  line-height: 1.2;
  letter-spacing: 1px;
  color: ${styles.colors.BLACK_TRUE};
  margin-bottom: 12px;
`;

export const SubHeader = styled.h6`
  margin: 0;
  font-weight: 600;
  font-size: 24px;
  line-height: 1.5;
  text-align: center;
  color: ${styles.colors.BLACK_TRUE};

  span {
    font-weight: 700;
  }
`;
