import step1 from 'static/images/gifs/app_example_1.gif';
import step2 from 'static/images/gifs/app_example_2.gif';
import step3 from 'static/images/gifs/app_example_3.gif';

export const howDobbyWorksSlides = {
  SLIDE_1: {
    TYPE: 1,
    TITLE: 'Send us your job',
    TEXT: `Tell us your project needs, we'll match you with the right pro from our\npre-vetted network.`,
    IMAGE: step1
  },
  SLIDE_2: {
    TYPE: 2,
    TITLE: 'Schedule the visit',
    TEXT: `You will start with discussing pricing. The pro will come to your home to get the job done at a time that works for you.`,
    IMAGE: step2
  },
  SLIDE_3: {
    TYPE: 3,
    TITLE: 'Pay and review',
    TEXT: `Pay securely in the app once the job is complete. Write a review, move on to the next project.`,
    IMAGE: step3
  }
};
