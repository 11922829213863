import React from 'react';

import {
  AbsoluteWrapper,
  Hamburger,
  TopLine,
  MiddleLine,
  SecondMiddleLine,
  BottomLine
} from './styled';

export const HamburgerButton = ({ isNavigationOpened, onClick }) => (
  <AbsoluteWrapper onClick={onClick}>
    <Hamburger>
      <TopLine open={isNavigationOpened} />
      <MiddleLine open={isNavigationOpened} />
      <SecondMiddleLine open={isNavigationOpened} />
      <BottomLine open={isNavigationOpened} />
    </Hamburger>
  </AbsoluteWrapper>
);
