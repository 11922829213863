import styled, { css } from 'styled-components/macro';
import styles from 'common/enums/styles';
import {
  TEAM_DESKTOP_WIDTH,
  PORTRAIT_DESKTOP_SIZE,
  PORTRAIT_MOBILE_SIZE,
  FADE_ANIMATION_DURATION
} from '../../constants';

export const PhotoContainer = css`
  position: relative;
  align-items: center;
  flex-direction: column;
  background-color: ${styles.colors.GRAY};
`;

export const DesktopPhotoContainer = styled.div`
  ${PhotoContainer}
  display: none;
  flex: 1;
  max-height: ${PORTRAIT_DESKTOP_SIZE}px;
  width: ${PORTRAIT_DESKTOP_SIZE}px;
  height: ${PORTRAIT_DESKTOP_SIZE}px;
  max-width: ${PORTRAIT_DESKTOP_SIZE}px;

  @media screen and (min-width: ${TEAM_DESKTOP_WIDTH}px) {
    display: flex;
  }
`;

export const MobilePhotoContainer = styled.div`
  ${PhotoContainer}
  display: flex;
  flex: 1;
  max-height: ${PORTRAIT_MOBILE_SIZE}px;
  width: ${PORTRAIT_MOBILE_SIZE}px;
  height: ${PORTRAIT_MOBILE_SIZE}px;
  max-width: ${PORTRAIT_MOBILE_SIZE}px;
  @media screen and (min-width: ${TEAM_DESKTOP_WIDTH}px) {
    display: none;
  }
`;

export const PortraitImage = styled.img`
  max-height: ${PORTRAIT_MOBILE_SIZE}px;
  width: ${PORTRAIT_MOBILE_SIZE}px;
  height: ${PORTRAIT_MOBILE_SIZE}px;
  max-width: ${PORTRAIT_MOBILE_SIZE}px;
  object-fit: cover;

  @media screen and (min-width: ${TEAM_DESKTOP_WIDTH}px) {
    height: 100%;
    max-height: 100%;
    min-height: ${PORTRAIT_DESKTOP_SIZE}px;
    min-width: ${PORTRAIT_DESKTOP_SIZE}px;
    width: 100%;
  }
`;

export const OverlayImage = styled.img`
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: absolute;
  height: ${PORTRAIT_MOBILE_SIZE}px;
  max-width: ${PORTRAIT_MOBILE_SIZE}px;
  opacity: 0;

  ${({ isVisible }) => {
    if (isVisible) {
      return `
        visibility: visible;
        opacity: 1;
        transition: opacity ${FADE_ANIMATION_DURATION} ease-out;`;
    }
    return `
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s ${FADE_ANIMATION_DURATION}, opacity ${FADE_ANIMATION_DURATION} ease-in;
  `;
  }}
`;
