import React from 'react';

import arrowIcon from 'static/images/icons/arrow-gray.svg';
import { Icon } from './styled';

const ArrowButton = ({ direction, onClick, desktopOnly }) => (
  <Icon
    key={direction}
    src={arrowIcon}
    alt={direction}
    direction={direction}
    desktopOnly={desktopOnly}
    onClick={onClick}
  />
);

export default ArrowButton;
