export const deviceSizes = {
  MOBILE: '375px',
  MOBILE_L: '425px',
  TABLET: '768px',
  LAPTOP: '1024px',
  LAPTOP_L: '1440px',
  DESKTOP: '2560px'
};

export const device = {
  MOBILE: `(min-width: ${deviceSizes.MOBILE})`,
  MOBILE_L: `(min-width: ${deviceSizes.MOBILE_L})`,
  TABLET: `(min-width: ${deviceSizes.TABLET})`,
  LAPTOP: `(min-width: ${deviceSizes.LAPTOP})`,
  LAPTOP_L: `(min-width: ${deviceSizes.LAPTOP_L})`,
  DESKTOP: `(min-width: ${deviceSizes.DESKTOP})`
};

export const service = {
  CAPTCHA: '60px'
};
