import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { selectUserId } from 'store/reducers/user';
import { Element, scroller } from 'react-scroll';

import { useTrackPageViews } from 'hooks';
import styles from 'common/enums/styles';

import { DOCUMENT_TITLE } from 'common/constants/html';

import { pageTypes } from 'common/enums/pages';
import {
  Hero,
  HeroVideo,
  IconList,
  GetApp,
  ImageQuote,
  TopBar,
  Reviews,
  HowDobbyWorks,
  CompareTable,
  Categories,
  DownloadQR
} from 'components';

import routes from 'common/enums/routes';
import { analyticsPageTypes } from 'common/enums/pages';

import hero from 'static/images/hero/hero-home@2x.png';
import heroPlaceholder from 'static/images/backgrounds/home.jpg';

import { CommunityForm, HomeCare } from './components';
import { categoriesSection, items, imageQuoteContent } from './enums';
import { reviews, reviewsSection } from 'common/enums/reviews';
import { openModal } from 'store/actions/modal';

import { Section, HeroButtons } from './styled';
import { getArticles } from 'store/actions/article';

const Home = ({
  isCommunityFormShown,
  history,
  location,
  openModal,
  userId
}) => {
  useTrackPageViews({ page: analyticsPageTypes.HOME });
  const onModalClose = () => {
    history.push(routes.HOME);
  };

  const dispatch = useDispatch();

  const scrollTo = section => {
    scroller.scrollTo(section, {
      duration: 1000,
      delay: 100,
      smooth: true,
      offset: -150
    });
  };

  const scrollToMenuOffset = section => {
    scroller.scrollTo(section, {
      duration: 1000,
      delay: 100,
      smooth: true,
      offset:
        document.body.offsetWidth < 1024
          ? -styles.layout.MOBILE_MENU_HEIGHT
          : -styles.layout.MENU_HEIGHT
    });
  };

  useEffect(() => {
    document.title = DOCUMENT_TITLE;
    window.scrollTo(0, 0);
    const hash = location.hash.slice(1);
    if (hash) {
      setTimeout(() => scrollTo(hash), 100);
    } else {
      window.scrollTo(0, 0);
    }

    if (location.state?.section) {
      setTimeout(() => scrollTo(location.state?.section), 100);
    }

    if (location.pathname === routes.REDIRECT_HAPPY_HOMES) {
      setTimeout(() => scrollToMenuOffset('scrollBelowThirdSection'), 100);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.hash, location.state]);

  useEffect(() => {
    dispatch(getArticles());
  }, []);

  const initialSlide = location.hash.slice(1) === 'how-it-works-3' ? 3 : 1;

  return (
    <Section>
      <TopBar />
      {window.innerWidth < 1024 ? (
        <Hero
          title={`Done-for-you app\nfor home services & renovations`}
          subTitle="Drop your request on the app and we will manage it all the way through the 5-star completion. Guaranteed quality. Transparent pricing. Insanely time-saving."
          imageSrc={hero}
          placeholderSrc={heroPlaceholder}
          backgroundType={pageTypes.HOME}>
          <GetApp
            isCentered
            light
            mobileOnly
            entryPoint={analyticsPageTypes.HOME}
          />
        </Hero>
      ) : (
        <HeroVideo
          light
          title={`Done-for-you app\nfor home services & renovations`}
          subTitle="Drop your request on the app and we will manage it all the way through the 5-star completion. Guaranteed quality. Transparent pricing. Insanely time-saving."
          srcFull={
            'https://dobby-product-static.s3.amazonaws.com/website/output.webm'
          }
          srcMobile={hero}
          srcPlaceholder={heroPlaceholder}
          backgroundType={pageTypes.HOME}>
          <HeroButtons>
            <DownloadQR desktopOnly isHero />
            <GetApp
              isCentered
              light
              mobileOnly
              entryPoint={analyticsPageTypes.HOME}
            />
          </HeroButtons>
        </HeroVideo>
      )}
      <IconList
        items={items}
        title={`Pre-vetted, top 1% service pros across 30+ categories including handyman, HVAC, plumbing, electrical, renovations.`}
      />
      <Element name="scrollBelowSecondSection" />
      <HowDobbyWorks initialSlide={initialSlide} />
      <CompareTable />
      <Reviews
        reviews={reviews}
        reviewsSection={reviewsSection}
        type={pageTypes.HOME}
      />
      <Element name="scrollBelowThirdSection" />
      <Categories
        name="categories"
        isHome
        categoriesSection={categoriesSection}
        entryPoint={analyticsPageTypes.HOME}
      />
      <HomeCare />
      <ImageQuote
        title={imageQuoteContent.TITLE}
        image={imageQuoteContent.IMAGE}
        text={imageQuoteContent.TEXT}
        name={imageQuoteContent.NAME}
        position={imageQuoteContent.POSITION}
      />
      {isCommunityFormShown && <CommunityForm onModalClose={onModalClose} />}
    </Section>
  );
};

const mapDispatchToProps = {
  openModal
};

const mapStateToProps = state => ({
  userId: selectUserId(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
