import styled from 'styled-components/macro';
import styles from 'common/enums/styles';
import { device } from 'common/enums/devices';

export const CardTextHover = styled.div`
  display: grid;
  align-items: center;
  grid-auto-flow: column;
  font-size: 18px;
  line-height: 1.5;
  margin-top: 11px;
  opacity: 1;
  @media screen and ${device.LAPTOP} {
    align-items: center;
    grid-auto-flow: column;
    font-weight: 600;
    font-size: 14px;
    opacity: 0;
  }
`;
export const Image = styled.img`
  width: 16px;
  height: 16px;
`;

export const Card = styled.article`
  background-color: ${styles.colors.WHITE_NEW};
  max-width: 343px;
  width: 100%;
  height: 343px;
  display: grid;
  justify-items: center;
  align-content: center;
  padding: 0 30px;

  cursor: pointer;
  padding-top: 32px;
  transition: transform 0.2s;
  &:hover {
    @media screen and ${device.LAPTOP} {
      ${CardTextHover} {
        opacity: 1;
      }
    }
  }

  h2 {
    width: auto;
  }
  &:nth-of-type(2) h2 {
    @media screen and ${device.LAPTOP_L} {
      max-width: 179px;
    }
  }

  @media screen and ${device.TABLET} {
    width: 343px;
    align-items: baseline;
  }
  @media screen and ${device.LAPTOP_L} {
    width: 370px;
    height: 370px;
    max-width: none;
  }
`;

export const CardImage = styled.img`
  width: 200px;
  height: 200px;
`;

export const CardTitle = styled.h2`
  font-weight: 700;
  font-size: 22px;
  letter-spacing: 0;
  margin-top: 16px;
  margin-bottom: 0;
  line-height: 1.2;
  text-align: center;
  @media screen and ${device.LAPTOP_L} {
    max-width: 189px;
    font-size: 20px;
  }
`;

export const CardContent = styled.div`
  display: grid;
  align-content: center;
  justify-items: center;
`;
export const Box = styled.div`
  display: grid;
  justify-items: center;
  @media screen and ${device.LAPTOP_L} {
    grid-auto-flow: column;
    gap: 31px;
    align-items: center;
  }
`;
