import React, { Component } from 'react';
import { MainContent } from 'components';
import {
  Category,
  CommonSection,
  HeaderPadding,
  Heading,
  MainContentstyles,
  Strong,
  SubHeading,
  WellContainer
} from './styled';

class PrivacyPolicy extends Component {
  componentDidMount() {
    document.title =
      'Dobby | The most future-forward home services and renovation company';
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <MainContent MainContentstyles={MainContentstyles}>
        <HeaderPadding />
        <CommonSection>
          <WellContainer>
            <Heading>Privacy Policy</Heading>
            <SubHeading>Halos Insurance, Inc</SubHeading>
          </WellContainer>
          <WellContainer>
            <p>Effective date: April 23, 2019</p>
            <p>
              Halos Insurance, Inc. ("us", "we", or "our") operates the
              CallDobby mobile application (hereinafter referred to as the
              "Service").
            </p>
            <p>
              This page informs you of our policies regarding the collection,
              use and disclosure of personal data when you use our Service and
              the choices you have associated with that data.
            </p>
            <p>
              We use your data to provide and improve the Service. By using the
              Service, you agree to the collection and use of information in
              accordance with this policy. Unless otherwise defined in this
              Privacy Policy, the terms used in this Privacy Policy have the
              same meanings as in our Terms and Conditions.
            </p>
          </WellContainer>
        </CommonSection>
        <CommonSection>
          <WellContainer>
            <Heading>Definitions</Heading>
          </WellContainer>
          <WellContainer>
            <SubHeading>Service</SubHeading>
            <p>
              Service is the CallDobby mobile application operated by Halos
              Insurance, Inc.
            </p>
          </WellContainer>
          <WellContainer>
            <SubHeading>Personal Data</SubHeading>
            <p>
              Personal Data means data about a living individual who can be
              identified from those data (or from those and other information
              either in our possession or likely to come into our possession).
            </p>
          </WellContainer>
          <WellContainer>
            <SubHeading>Usage Data</SubHeading>
            <p>
              Usage Data is data collected automatically either generated by the
              use of the Service or from the Service infrastructure itself (for
              example, the duration of a page visit).
            </p>
          </WellContainer>
          <WellContainer>
            <SubHeading>Cookies</SubHeading>
            <p>
              Cookies are small files stored on your device (computer or mobile
              device).
            </p>
          </WellContainer>
        </CommonSection>
        <CommonSection>
          <WellContainer>
            <Heading>Information Collection and Use</Heading>
            <p>
              We collect several different types of information for various
              purposes to provide and improve our Service to you.
            </p>
          </WellContainer>
          <WellContainer>
            <SubHeading>Types of Data Collected</SubHeading>
            <Category>Personal Data</Category>
            <p>
              While using our Service, we may ask you to provide us with certain
              personally identifiable information that can be used to contact or
              identify you ("Personal Data"). Personally identifiable
              information may include, but is not limited to:
            </p>
            <p>
              - Email address <br></br>- First name and last name <br></br>-
              Phone number <br></br>- Address, State, Province, ZIP/Postal code,
              City <br></br>- Cookies and Usage Data <br></br>
            </p>
            <p>
              We may use your Personal Data to contact you with newsletters,
              marketing or promotional materials and other information that may
              be of interest to you. You may opt out of receiving any, or all,
              of these communications from us by following the unsubscribe link
              or the instructions provided in any email we send.
            </p>
            <Category>Usage Data</Category>
            <p>
              When you access the Service with a mobile device, we may collect
              certain information automatically, including, but not limited to,
              the type of mobile device you use, your mobile device unique ID,
              the IP address of your mobile device, your mobile operating
              system, the type of mobile Internet browser you use, unique device
              identifiers and other diagnostic data ("Usage Data").
            </p>
            <Category>Location Data</Category>
            <p>
              We may use and store information about your location if you give
              us permission to do so ("Location Data"). We use this data to
              provide features of our Service, to improve and customise our
              Service.
            </p>
            <p>
              You can enable or disable location services when you use our
              Service at any time by way of your device settings.
            </p>
            <Category>Tracking & Cookies Data</Category>
            <p>
              We use cookies and similar tracking technologies to track the
              activity on our Service and we hold certain information.
            </p>
            <p>
              Cookies are files with a small amount of data which may include an
              anonymous unique identifier. Cookies are sent to your browser from
              a website and stored on your device. Other tracking technologies
              are also used such as beacons, tags and scripts to collect and
              track information and to improve and analyse our Service.
            </p>
            <p>
              You can instruct your browser to refuse all cookies or to indicate
              when a cookie is being sent. However, if you do not accept
              cookies, you may not be able to use some portions of our Service.
            </p>
            <p>Examples of Cookies we use:</p>
            <p>
              - <Strong>Session Cookies.</Strong> We use Session Cookies to
              operate our Service. <br></br>-{' '}
              <Strong>Preference Cookies.</Strong> We use Preference Cookies to
              remember your preferences and various settings. <br></br>-{' '}
              <Strong>Security Cookies.</Strong> We use Security Cookies for
              security purposes. <br></br>
            </p>
          </WellContainer>
        </CommonSection>
        <CommonSection>
          <WellContainer>
            <Heading>Use of Data</Heading>
            <p>
              Halos Insurance, Inc. uses the collected data for various
              purposes:
            </p>
            <p>
              - To provide and maintain our Service <br></br>- To notify you
              about changes to our Service <br></br>- To allow you to
              participate in interactive features of our Service when you choose
              to do so <br></br>- To provide customer support <br></br>- To
              gather analysis or valuable information so that we can improve our
              Service <br></br>- To monitor the usage of our Service <br></br>-
              To detect, prevent and address technical issues <br></br>- To
              provide you with news, special offers and general information
              about other goods, services and events which we offer that are
              similar to those that you have already purchased or enquired about
              unless you have opted not to receive such information
            </p>
          </WellContainer>
        </CommonSection>
        <CommonSection>
          <WellContainer>
            <Heading>Transfer of Data</Heading>
            <p>
              Your information, including Personal Data, may be transferred to -
              and maintained on - computers located outside of your state,
              province, country or other governmental jurisdiction where the
              data protection laws may differ from those of your jurisdiction.{' '}
            </p>
            <p>
              If you are located outside United States and choose to provide
              information to us, please note that we transfer the data,
              including Personal Data, to United States and process it there.{' '}
            </p>
            <p>
              Your consent to this Privacy Policy followed by your submission of
              such information represents your agreement to that transfer.{' '}
            </p>
            <p>
              Halos Insurance, Inc. will take all the steps reasonably necessary
              to ensure that your data is treated securely and in accordance
              with this Privacy Policy and no transfer of your Personal Data
              will take place to an organisation or a country unless there are
              adequate controls in place including the security of your data and
              other personal information.
            </p>
          </WellContainer>
        </CommonSection>
        <CommonSection>
          <WellContainer>
            <Heading>Disclosure of Data</Heading>
          </WellContainer>
          <WellContainer>
            <SubHeading>Disclosure for Law Enforcement</SubHeading>
            <p>
              Under certain circumstances, Halos Insurance, Inc. may be required
              to disclose your Personal Data if required to do so by law or in
              response to valid requests by public authorities (e.g. a court or
              a government agency).
            </p>
          </WellContainer>
          <WellContainer>
            <SubHeading>Legal Requirements</SubHeading>
            <p>
              Halos Insurance, Inc. may disclose your Personal Data in the good
              faith belief that such action is necessary to:
            </p>
            <p>
              - To comply with a legal obligation <br></br>- To protect and
              defend the rights or property of Halos Insurance, Inc. <br></br>-
              To prevent or investigate possible wrongdoing in connection with
              the Service <br></br>- To protect the personal safety of users of
              the Service or the public <br></br>- To protect against legal
              liability <br></br>
            </p>
          </WellContainer>
        </CommonSection>
        <CommonSection>
          <WellContainer>
            <Heading>Security of Data</Heading>
            <p>
              The security of your data is important to us but remember that no
              method of transmission over the Internet or method of electronic
              storage is 100% secure. While we strive to use commercially
              acceptable means to protect your Personal Data, we cannot
              guarantee its absolute security.
            </p>
          </WellContainer>
        </CommonSection>
        <CommonSection>
          <WellContainer>
            <Heading>
              Our Policy on "Do Not Track" Signals under the California Online
              Protection Act (CalOPPA)
            </Heading>
            <p>
              We do not support Do Not Track ("DNT"). Do Not Track is a
              preference you can set in your web browser to inform websites that
              you do not want to be tracked.
            </p>
            <p>
              You can enable or disable Do Not Track by visiting the Preferences
              or Settings page of your web browser.
            </p>
          </WellContainer>
        </CommonSection>
        <CommonSection>
          <WellContainer>
            <Heading>Service Providers</Heading>
            <p>
              We may employ third party companies and individuals to facilitate
              our Service ("Service Providers"), provide the Service on our
              behalf, perform Service-related services or assist us in analysing
              how our Service is used.{' '}
            </p>
            <p>
              These third parties have access to your Personal Data only to
              perform these tasks on our behalf and are obligated not to
              disclose or use it for any other purpose.
            </p>
          </WellContainer>
        </CommonSection>
        <CommonSection>
          <WellContainer>
            <Heading>Links to Other Sites</Heading>
            <p>
              Our Service may contain links to other sites that are not operated
              by us. If you click a third party link, you will be directed to
              that third party's site. We strongly advise you to review the
              Privacy Policy of every site you visit.
            </p>
            <p>
              We have no control over and assume no responsibility for the
              content, privacy policies or practices of any third party sites or
              services.
            </p>
          </WellContainer>
        </CommonSection>
        <CommonSection>
          <WellContainer>
            <Heading>Children's Privacy</Heading>
            <p>
              Our Service does not address anyone under the age of 18
              ("Children").
            </p>
            <p>
              We do not knowingly collect personally identifiable information
              from anyone under the age of 18. If you are a parent or guardian
              and you are aware that your Child has provided us with Personal
              Data, please contact us. If we become aware that we have collected
              Personal Data from children without verification of parental
              consent, we take steps to remove that information from our
              servers.
            </p>
          </WellContainer>
        </CommonSection>
        <CommonSection>
          <WellContainer>
            <Heading>Changes to This Privacy Policy</Heading>
            <p>
              We may update our Privacy Policy from time to time. We will notify
              you of any changes by posting the new Privacy Policy on this page.
            </p>
            <p>
              We will let you know via email and/or a prominent notice on our
              Service, prior to the change becoming effective and update the
              "effective date" at the top of this Privacy Policy.
            </p>
            <p>
              You are advised to review this Privacy Policy periodically for any
              changes. Changes to this Privacy Policy are effective when they
              are posted on this page.
            </p>
          </WellContainer>
        </CommonSection>
      </MainContent>
    );
  }
}

export default PrivacyPolicy;
