import styled from 'styled-components/macro';
import { animated } from 'react-spring';

import styles from 'common/enums/styles';
import { device } from 'common/enums/devices';

export const FadeTextWrapper = styled(animated.div)`
  flex-direction: column;
  width: 278px;
  text-align: center;
`;

export const HowDobbyWorksFormTitle = styled.div`
  margin: 16px auto 0;
  text-align: center;
  white-space: pre-line;
  @media screen and ${device.LAPTOP} {
    text-align: left;
    margin: 0;
    min-width: 312px;
  }

  p {
    font-weight: 400;
  }
`;

export const SelectedSlideTitle = styled.h2`
  font-weight: 600;
  font-size: 22px;
  line-height: 1.2;
  text-align: center;
  width: 100%;
  color: ${styles.colors.WHITE_NEW};
  @media screen and ${device.LAPTOP} {
    display: none;
  }
`;
