import React, { useState, useEffect } from 'react';
import { useTransition } from 'react-spring';

import { Text } from 'components';
import styles from 'common/enums/styles';

import {
  FadeTextWrapper,
  HowDobbyWorksFormTitle,
  SelectedSlideTitle
} from './styled';

const FadeText = ({ activeSlide, slides }) => {
  const [localActiveType, setLocalActiveType] = useState(activeSlide);
  useEffect(() => {
    if (activeSlide !== localActiveType) {
      setLocalActiveType(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSlide]);

  const transitions = useTransition(
    Object.values(slides).find(({ TYPE }) => TYPE === localActiveType),
    item => item?.TYPE || null,
    {
      from: { opacity: 0 },
      enter: { opacity: 1 },
      leave: { opacity: 0 },
      onRest: (item, action) => {
        if (!localActiveType && action === 'leave') {
          setLocalActiveType(activeSlide);
        }
      }
    }
  );

  return transitions.map(
    ({ item, props, key }) =>
      Boolean(item?.TEXT) && (
        <FadeTextWrapper key={key} style={props}>
          <SelectedSlideTitle>{item.TITLE}</SelectedSlideTitle>
          <HowDobbyWorksFormTitle>
            <Text description2 color={styles.colors.WHITE_NEW}>
              {item.TEXT}
            </Text>
          </HowDobbyWorksFormTitle>
        </FadeTextWrapper>
      )
  );
};

export default FadeText;
