import umbrellaImage from 'static/images/icons/umbrella.svg';
import medalImage from 'static/images/icons/medal.svg';
import peopleImage from 'static/images/icons/people.svg';

export const items = {
  WEEKENDS: {
    TYPE: 0,
    IMAGE: umbrellaImage,
    TITLE: 'Share your link',
    TEXT:
      'Invite quality service providers to join Dobby by sharing your personal referral link with them through emails, texts, or social media.'
  },
  WORK: {
    TYPE: 1,
    IMAGE: medalImage,
    TITLE: 'Sit back',
    TEXT:
      'We will begin the vetting and onboarding processes for your invitees and make sure your referrals are in good hands.'
  },
  PEOPLE: {
    TYPE: 2,
    IMAGE: peopleImage,
    TITLE: 'Get paid',
    TEXT:
      'When your referral completes their first job, you get $100! They will get $50 after their first job, plus $50 after their second job!'
  }
};
