import { all, fork } from 'redux-saga/effects';
import {
  watchAddGlowMagicLink,
  watchConfirmSignIn,
  watchSignIn,
  watchUserUtmTrack
} from './auth';
import {
  watchAskUsAnything,
  watchGetReferralLink,
  watchInviteUserByReferralLink,
  watchUnsubscribeFromEmail,
  // watchPlantTree,
  watchSendCV,
  watchSendMeTheLink
} from './user';
import { watchGetReviews } from './review';
import { watchGetPublications, watchGetPublication } from './attic';
import { watchSetSubscription } from './subscription';
import watchAskPlanningMode from './user/askPlanningMode';
import { watchGetArticles, watchGetArticle } from './article';

export default function* rootSaga() {
  yield all([
    // auth
    fork(watchAddGlowMagicLink),
    fork(watchConfirmSignIn),
    fork(watchSignIn),
    fork(watchUserUtmTrack),
    // subscription
    fork(watchSetSubscription),
    fork(watchGetReviews),
    fork(watchGetReviews),
    fork(watchGetPublications),
    fork(watchGetPublication),
    fork(watchAskUsAnything),
    fork(watchSendCV),
    fork(watchSendMeTheLink),
    fork(watchGetReferralLink),
    fork(watchInviteUserByReferralLink),
    fork(watchUnsubscribeFromEmail),
    fork(watchAskPlanningMode),
    // fork(watchPlantTree)
    // articles
    fork(watchGetArticles),
    fork(watchGetArticle)
  ]);
}
