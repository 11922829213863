import { useEffect } from 'react';

import links from 'common/constants/links';

const RedirectToDobby = ({ location: { search }, match }) => {
  useEffect(() => {
    const params = new URLSearchParams(search);
    const sid = params.get('sid');
    let link = links.HO_DEEP_LINK_BASE;
    if (sid) {
      link = `${links.HO_DEEP_LINK_BASE}task/${sid}`;
    }

    window.location.href = link;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default RedirectToDobby;
