import React from 'react';

import {
  CompaniesContainer,
  CompanyLogos,
  CompanyImage,
  Heading,
  Section
} from './styled';

import { logos } from './enums';

const Companies = () => {
  return (
    <Section>
      <CompaniesContainer>
        <Heading>Where We Worked Before</Heading>
        <CompanyLogos>
          {Object.values(logos).map((logo, index) => (
            <div key={`company-${index}`}>
              <CompanyImage src={logo.IMAGE} alt={logo.ALT} />
            </div>
          ))}
        </CompanyLogos>
      </CompaniesContainer>
    </Section>
  );
};

export default Companies;
