import actionTypes from 'common/enums/actionTypes';

const defaultState = {
  referralLink: '',
  userId: null,
  phoneNumber: '',
  name: '',
  type: '',
  getUserReferralLinkLoading: false,
  inviteUserByReferralLinkLoading: false,
  inviteUserByReferralLinkResult: null,
  CVLoading: false,
  CVError: false,
  locationByIP: null,
  signUpFormSent: false,
  unsubscribeLoading: false,
  activeListJob: null
  // plantTreeloading: false
};

function reducer(state = defaultState, action) {
  switch (action.type) {
    case actionTypes.SET_ACTIVE_JOB: {
      return {
        ...state,
        activeListJob: action.payload
      };
    }

    case actionTypes.GET_REFERRAL_LINK_FETCH: {
      return {
        ...state,
        getUserReferralLinkLoading: true
      };
    }

    case actionTypes.GET_REFERRAL_LINK_SUCCESS: {
      return {
        ...state,
        referralLink: action.payload.referralLink,
        phoneNumber: action.payload.phoneNumber,
        name: action.payload.name,
        type: action.payload.type,
        userId: action.payload.userId,
        getUserReferralLinkLoading: false
      };
    }
    case actionTypes.GET_REFERRAL_LINK_ERROR: {
      return {
        ...state,
        getUserReferralLinkLoading: false
      };
    }

    case actionTypes.INVITE_USER_BY_REFERRAL_LINK_FETCH: {
      return {
        ...state,
        inviteUserByReferralLinkLoading: true
      };
    }

    case actionTypes.INVITE_USER_BY_REFERRAL_LINK_SUCCESS: {
      return {
        ...state,
        inviteUserByReferralLinkResult: 'success',
        inviteUserByReferralLinkLoading: false
      };
    }

    case actionTypes.INVITE_USER_BY_REFERRAL_LINK_ERROR: {
      return {
        ...state,
        inviteUserByReferralLinkResult: 'error',
        inviteUserByReferralLinkLoading: false
      };
    }

    case actionTypes.DISMISS_INVITE_USER_BY_REFERRAL_LINK_RESULT: {
      return {
        ...state,
        inviteUserByReferralLinkResult: null
      };
    }

    case actionTypes.SEND_CV_FETCH: {
      return {
        ...state,
        CVLoading: true,
        CVError: false
      };
    }

    case actionTypes.SEND_CV_SUCCESS: {
      return {
        ...state,
        CVLoading: false
      };
    }

    case actionTypes.SEND_CV_ERROR: {
      return {
        ...state,
        CVLoading: false,
        CVError: true
      };
    }

    case actionTypes.SET_LOCATION_BY_IP: {
      return {
        ...state,
        // FOR TEST: uncomment for test signUp form
        // signUpFormSent: false,
        locationByIP: action.payload
      };
    }

    // case actionTypes.PLANT_TREE_FETCH: {
    //   return {
    //     ...state,
    //     plantTreeloading: true
    //   };
    // }
    // case actionTypes.PLANT_TREE_ERROR:
    // case actionTypes.PLANT_TREE_SUCCESS: {
    //   return {
    //     ...state,
    //     plantTreeloading: false
    //   };
    // }

    case actionTypes.UNSUBSCRIBE_FROM_EMAIL_FETCH: {
      return {
        ...state,
        unsubscribeLoading: true
      };
    }
    case actionTypes.UNSUBSCRIBE_FROM_EMAIL_ERROR:
    case actionTypes.UNSUBSCRIBE_FROM_EMAIL_SUCCESS: {
      return {
        ...state,
        unsubscribeLoading: false
      };
    }

    default:
      return state;
  }
}

// selectors
export const selectActiveJob = state => state.user.activeListJob;
export const selectCVLoading = state => state.user.CVLoading;
export const selectCVError = state => state.user.CVError;
export const selectLocationByIP = state => state.user.locationByIP;
export const selectSignUpFormSent = state => state.user.signUpFormSent;
export const selectUserReferralLink = state => state.user.referralLink;
export const selectUserName = state => state.user.name;
export const selectUserId = state => state.user.userId || null;
export const selectUserPhoneNumber = state => state.user.phoneNumber;
export const selectUserType = state => state.user.type;
export const selectGetUserReferralLinkLoading = state =>
  state.user.getUserReferralLinkLoading;
export const selectInviteUserByReferralLinkLoading = state =>
  state.user.inviteUserByReferralLinkLoading;
export const selectInviteUserByReferralLinkResult = state =>
  state.user.inviteUserByReferralLinkResult;
export const selectUnsubscribeLoading = state => state.user.unsubscribeLoading;
// export const selectPlantTreeLoading = state => state.user.plantTreeloading;

export default reducer;
