import React from 'react';
import styles from 'common/enums/styles';
import { ProfileLink } from './styled';

const LinkedInIcon = ({
  link,
  desktopOnly,
  mobileOnly,
  relative,
  background = styles.colors.DARK_BLUE,
  color = styles.colors.WHITE_NEW,
  hasReverseHover,
  size
}) => (
  <a href={link} target="_blank" rel="noopener noreferrer">
    <ProfileLink
      desktopOnly={desktopOnly}
      hasReverseHover={hasReverseHover}
      size={size}
      mobileOnly={mobileOnly}
      relative={relative}
      viewBox="0 0 63 63"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect width="63" height="63" fill={background} />
      <path
        d="M22.3465 49.9426H14.6748V23.9734H22.3465V49.9426ZM18.5108 20.4273H18.4609C15.8865 20.4273 14.2215 18.4333 14.2215 15.9412C14.2215 13.3929 15.9374 11.4541 18.5618 11.4541C21.1862 11.4541 22.8012 13.3929 22.8511 15.9412C22.8511 18.4333 21.1862 20.4273 18.5108 20.4273ZM50.009 49.9426H42.3383V36.0497C42.3383 32.5582 41.2275 30.177 38.4517 30.177C36.3325 30.177 35.0703 31.7832 34.5156 33.3337C34.3128 33.8886 34.2633 34.6641 34.2633 35.4402V49.9426H26.5921C26.5921 49.9426 26.6925 26.4097 26.5921 23.9734H34.2633V27.6503C35.2828 25.8808 37.1068 23.3639 41.177 23.3639C46.2244 23.3639 50.009 27.0755 50.009 35.0519V49.9426Z"
        fill={color}
      />
    </ProfileLink>
  </a>
);

export default LinkedInIcon;
