import React from 'react';

const IconExclamation = ({ size = 20, color = '#E0125E' }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 19C14.9706 19 19 14.9706 19 10C19 5.02944 14.9706 1 10 1C5.02944 1 1 5.02944 1 10C1 14.9706 5.02944 19 10 19ZM10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20ZM11.1117 15.5557H8.88951V8.88904H11.1117V15.5557ZM11.1117 5.55525H8.88951V7.77747H11.1117V5.55525Z"
      fill={color}
    />
  </svg>
);

export default IconExclamation;
