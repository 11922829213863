import React from 'react';

import { Logo } from 'components';

import { HamburgerButton, TopNavigation } from '../';
import {
  Navigation,
  TopNavBar,
  DesktopNavigation,
  DarkerBackground,
  LogoWrapper,
  MobileNavigation
} from './styled';
import { connect } from 'react-redux';
import { selectSubscriptionStep } from 'store/reducers/subscription';

const NavBar = ({
  isNavigationOpened,
  closeNavigation,
  openNavigation,
  subscriptionStep
}) => {
  const handleNavigation = () => {
    if (isNavigationOpened) {
      closeNavigation();
      return;
    }
    openNavigation();
  };

  return (
    <Navigation>
      <DarkerBackground
        isMobileNavBar={isNavigationOpened}
        onClick={handleNavigation}
      />
      <MobileNavigation isNavigationOpened={isNavigationOpened}>
        <TopNavigation isNavigationOpened={isNavigationOpened} mobileMenu />
      </MobileNavigation>
      <TopNavBar>
        {!subscriptionStep && (
          <HamburgerButton
            onClick={handleNavigation}
            isNavigationOpened={isNavigationOpened}
          />
        )}

        <LogoWrapper>
          <Logo white handleClick={closeNavigation} />
        </LogoWrapper>
        {!subscriptionStep && (
          <DesktopNavigation>
            <TopNavigation />
          </DesktopNavigation>
        )}
      </TopNavBar>
    </Navigation>
  );
};

const mapStateToProps = state => ({
  subscriptionStep: selectSubscriptionStep(state)
});

export default connect(mapStateToProps)(NavBar);
