import { useEffect } from 'react';

const ReferralRedirect = ({ match, appName }) => {
  useEffect(() => {
    const referralId = match.params.referralId;

    const linkUrl = encodeURIComponent(
      `https://hellodobby.com/?ref=${referralId || ''}`
    );

    const linkParams = {
      hellodobby:
        '&apn=com.halosassistant&ibi=com.halos.insurance.HalosAssistant&isi=1445563710',
      dobbypro:
        '&apn=com.halosservices&ibi=com.halos.insurance.HalosServices&isi=1449612135'
    };

    const dynamicLink = `https://${appName}.page.link?link=${linkUrl}${linkParams[appName]}`;
    window.location = dynamicLink;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default ReferralRedirect;
