export const formatDate = date => {
  const formatData = {
    months: [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ],
    dates: {
      FIRST: {
        values: [1, 21, 31],
        end: 'st'
      },
      SECOND: {
        values: [2, 22],
        end: 'nd'
      },
      THIRD: {
        values: [3],
        end: 'rd'
      },
      OTHER: {
        end: 'th'
      }
    }
  };

  const d = new Date(date);
  const month = d.getMonth();
  const day = d.getDate();
  const year = d.getFullYear();

  const monthName = formatData.months[month];
  const formatedDateEnding =
    Object.values(formatData.dates).find(item => item.values?.includes(day))
      ?.end || 'th';
  const dayName = `${day}${formatedDateEnding}`;

  return `${monthName} ${dayName}, ${year}`;
};
