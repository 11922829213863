import styled from 'styled-components/macro';
import styles from 'common/enums/styles';
import { device } from 'common/enums/devices';

export const Button = styled.button`
  display: ${({ desktopOnly }) => (desktopOnly ? 'none' : 'block')};
  color: ${styles.colors.WHITE_NEW};
  background: ${({ isDark }) =>
    isDark ? styles.colors.DARK_BLUE : styles.colors.BLUE};
  border-radius: 8px;
  padding: 12px ${({ isDark }) => (isDark ? 24 : 46)}px;
  font-size: 20px;
  font-weight: ${({ fontWeight = 600 }) => fontWeight};
  border: none;
  cursor: pointer;
  text-decoration: none;
  font-family: 'HKGrotesk', sans-serif;
  @media screen and (hover: hover) and (pointer: fine) {
    &:hover {
      transition: all 0.2s ease-in-out;
      transform: scale(1.1);
    }
  }

  @media screen and ${device.LAPTOP} {
    display: block;
  }

  @media screen and ${device.LAPTOP_L} {
    ${({ isHero }) =>
      isHero &&
      `
      border-radius: 8px;
      padding: 0.8vw 3.4vw;
      font-size: 1.3vw;
    `}
  }
  @media screen and ${device.DESKTOP} {
    ${({ isAllProjects }) =>
      isAllProjects &&
      `
      width: 274.5px;
      height: 60px;
    `}
  }
`;

export const QRWrapper = styled.div`
  width: fit-content;
  display: flex;
  justify-content: center;
  display: none;
  border-radius: 8px;
  background: ${styles.components.HIGHLIGHT};
  padding: 0.5vw;

  opacity: ${({ isAnimate }) => (isAnimate ? 0 : 1)};

  ${({ desktopOnly }) =>
    desktopOnly &&
    `
    @media screen and ${device.LAPTOP} {
      display: block;
    }
  `}
`;

export const QRCode = styled.img`
  width: ${({ isHero }) => (isHero ? '15vw' : '10vw')};
  height: ${({ isHero }) => (isHero ? '15vw' : '10vw')};
`;
