export const unsubscribeImage =
  'https://dobby-product-static.s3.amazonaws.com/emails/images/unsubscribe-header.png';
export const reasonTypes = {
  OTHER: {
    DESCRIPTION: 'Other',
    TYPE: 1
  },
  DONT_WANT_TO_RECIEVE: {
    DESCRIPTION: 'I no longer want to receive these emails.',
    TYPE: 2
  },
  NEVER_SIGN_UP_TO_LIST: {
    DESCRIPTION: 'I never signed up for this mailing list.',
    TYPE: 3
  },
  INAPPROPRIATE: {
    DESCRIPTION: 'The emails are inappropriate',
    TYPE: 4
  }
};
