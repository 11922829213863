import actionTypes from 'common/enums/actionTypes';

const defaultState = {
  askPlanningModeLoading: false,
  askPlanningModeResponse: ''
};

function reducer(state = defaultState, action) {
  switch (action.type) {
    case actionTypes.ASK_PLANNING_MODE_FETCH: {
      return {
        ...state,
        askPlanningModeLoading: true,
        askPlanningModeResponse: ''
      };
    }
    case actionTypes.ASK_PLANNING_MODE_SUCCESS: {
      return {
        ...state,
        askPlanningModeLoading: false,
        askPlanningModeResponse: action.payload?.message || ''
      };
    }
    case actionTypes.ASK_PLANNING_MODE_ERROR: {
      return {
        ...state,
        askPlanningModeLoading: false,
        askPlanningModeResponse: ''
      };
    }

    default:
      return state;
  }
}

// selectors
export const askPlanningModeLoading = state =>
  state.planningMode.askPlanningModeLoading;
export const askPlanningModeResponse = state =>
  state.planningMode.askPlanningModeResponse;

export default reducer;
