import actionTypes from 'common/enums/actionTypes';

const defaultState = {
  count: 0,
  loading: false,
  reviews: [],
  page: 1
};

function reducer(state = defaultState, action) {
  switch (action.type) {
    case actionTypes.GET_REVIEWS_FETCH: {
      return {
        ...state,
        loading: true
      };
    }

    case actionTypes.GET_REVIEWS_SUCCESS: {
      const { results, count, page } = action.payload;
      return {
        ...state,
        reviews: page === 1 ? results : [...state.reviews, ...results],
        page,
        count,
        loading: false
      };
    }

    case actionTypes.GET_REVIEWS_ERROR: {
      return {
        ...state,
        loading: false
      };
    }
    default: {
      return state;
    }
  }
}

// selectors
export const selectReviews = state => {
  return state.review.reviews;
};
export const selectReviewsPage = state => state.review.page;
export const selectReviewsCount = state => state.review.count;
export const selectReviewsLoading = state => state.review.loading;

export default reducer;
