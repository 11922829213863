import actionTypes from 'common/enums/actionTypes';

export const getPublications = () => ({
  type: actionTypes.GET_PUBLICATIONS_FETCH
});

export const getPublication = payload => ({
  type: actionTypes.GET_PUBLICATION_FETCH,
  payload
});
