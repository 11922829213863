import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

import styles from 'common/enums/styles';
import { device } from 'common/enums/devices';

export const Section = styled.section`
  background: ${styles.components.BACKGROUND};
  padding-top: 10px;

  @media screen and ${device.TABLET} {
    padding-top: 40px;
  }
`;

export const Content = styled.div`
  background: ${styles.components.BACKGROUND_LIGHT};
  padding: 10px 0;

  @media screen and ${device.TABLET} {
    padding: 30px 0;
  }
`;

export const heroBackground = styles.components.BACKGROUND;

export const ValuesWrapper = styled.div`
  background: ${styles.components.BACKGROUND_LIGHT_EXTRA};
  padding: 5px 20px 20px;
  border-radius: 2px;
  text-align: center;

  @media screen and ${device.TABLET} {
    max-width: 967px;
    padding: 10px 48px 48px;
    margin: 10px auto 40px;
  }
`;

export const Title = styled.h1`
  color: ${styles.components.HEADER_INVERTED};
  font-size: 36px;
  font-weight: 700;
  line-height: 1.2;
  text-align: center;
  white-space: pre-wrap;
  margin: 16px 20px;

  @media screen and ${device.TABLET} {
    font-size: 50px;
  }
`;

export const Subheader = styled.div`
  color: ${styles.components.TEXT_COLOR};
  font-size: 24px;
  line-height: 1.2;
  font-weight: 700;
  margin-top: 48px;
  margin-bottom: 6px;

  @media screen and ${device.TABLET} {
    font-size: 36px;
  }
`;

export const HOValue = styled.div`
  color: ${styles.components.TEXT};
  font-size: 18px;
  line-height: 1.2;

  @media screen and ${device.TABLET} {
    font-size: 24px;
  }
`;

export const Button = styled(NavLink)`
  display: block;
  align-items: center;
  margin-top: 24px;
  padding: 12px 24px;
  border: none;
  border-radius: 8px;
  background-color: ${styles.colors.BLUE};
  color: ${styles.colors.WHITE_NEW};
  text-decoration: none;
  cursor: pointer;
  margin: 20px 50px 10px;

  font-size: 20px;
  line-height: 1.2;
  font-weight: 700;

  @media screen and (hover: hover) and (pointer: fine) {
    &:hover {
      transition: all 0.2s ease-in-out;
      transform: scale(1.1);
    }
  }
  @media screen and ${device.LAPTOP} {
    margin: 48px 0 0;
    margin-top: 48px;
    width: 240px;
  }
`;

export const ReviewsWrapper = styled.div`
  background-color: ${styles.colors.WHITE_NEW};
  padding: 20px 10px;
  height: 500px;
`;
