import actionTypes from 'common/enums/actionTypes';

export const sendCV = payload => ({
  type: actionTypes.SEND_CV_FETCH,
  payload
});

export const setLocationByIP = payload => ({
  type: actionTypes.SET_LOCATION_BY_IP,
  payload
});

export const getReferralLink = payload => ({
  type: actionTypes.GET_REFERRAL_LINK_FETCH,
  payload
});

export const inviteUserByReferralLink = payload => ({
  type: actionTypes.INVITE_USER_BY_REFERRAL_LINK_FETCH,
  payload
});

export const dismissInviteUserByReferralLinkResult = payload => ({
  type: actionTypes.DISMISS_INVITE_USER_BY_REFERRAL_LINK_RESULT,
  payload
});

export const unsubscribeFromEmail = payload => ({
  type: actionTypes.UNSUBSCRIBE_FROM_EMAIL_FETCH,
  payload
});

export const sendMeTheLink = payload => ({
  type: actionTypes.SEND_ME_THE_LINK_FETCH,
  payload
});

export const setActiveJob = payload => ({
  type: actionTypes.SET_ACTIVE_JOB,
  payload
});

// export const plantTree = payload => ({
//   type: actionTypes.PLANT_TREE_FETCH,
//   payload
// });

export const askUsAnything = payload => ({
  type: actionTypes.ASK_US_ANYTHING_FETCH,
  payload
});

export const askPlanningMode = payload => ({
  type: actionTypes.ASK_PLANNING_MODE_FETCH,
  payload
});
