import styles from 'common/enums/styles';
import styled from 'styled-components';
import { device } from '../../common/enums/devices';

export const ImageWrapper = styled.div`
  background: $ ${styles.colors.WHITE_NEW};
  display: flex;
  justify-content: center;
  img {
    height: 180px;
  }
  @media screen and ${device.TABLET} {
    display: none;
  }
`;

export const Wrapper = styled.div`
  padding: ${styles.layout.MOBILE_MENU_HEIGHT}px 0 0 0;
  margin: 0 auto;
  a {
    text-decoration: none;
  }
`;

export const Container = styled.div`
  padding: ${({ padding = '0 16px' }) => padding}
  max-width: ${({ maxWidth = 1080 }) => maxWidth}px;
  margin: 0 auto;
`;

export const MainSection = styled.div`
  background: ${styles.colors.NUDE};
  padding: 43px 0 76px 0;
`;

export const Title = styled.div`
  font-size: 32px;

  @media screen and ${device.TABLET} {
    font-size: 50px;
    margin-bottom: 0;
    margin-top: 50px;
  }
  font-weight: 700;
  margin-bottom: 34px;
  line-height: 1.18;

  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth}`};
  ${({ textAlign }) => textAlign && `text-align: ${textAlign}`}
`;

export const ColoredText = styled.span`
  color: ${styles.colors.YELLOW};
`;

export const SubMessageText = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 66px;
  text-align: right;
  margin-right: 10px;
`;

export const HowItWorks = styled.div`
  padding: 104px 0 96px 0;
  background: ${styles.colors.WHITE_NEW};
  @media screen and ${device.TABLET} {
    padding: 112px 0 184px 0;
  }
`;

export const Message = styled.div`
  position: relative;
  max-width: 500px;
  img {
    height: 192px;
    max-width: 105%;
    min-width: 105%;
    margin-left: -20px;
  }
  @media screen and ${device.TABLET} {
    margin-right: 0px;
  }
  margin-right: 14px;
`;

export const MessageText = styled.div`
  font-style: italic;
  font-weight: 700;

  font-size: 18px;
  @media screen and ${device.TABLET} {
    font-size: 24px;
    padding: 30px;
  }
  line-height: 1.2;
  padding: 35px 30px 35px 20px;
  color: ${styles.colors.DARK_BLUE};
  position: absolute;
  top: 0;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 32px;
  flex-direction: column;
  align-items: center;
  max-width: 514px;
  margin: 0 auto;
  @media screen and ${device.TABLET} {
    display: flex;
    align-items: flex-end;
    flex-direction: row;
  }
`;

export const Header = styled.div`
  @media screen and ${device.TABLET} {
    display: flex;
    align-items: center;
  }
`;

export const Phones = styled.div`
  @media screen and ${device.TABLET} {
    display: block;
    margin-left: 20px;
  }
  display: none;
  img {
    max-width: 335px;
  }
`;

export const Customize = styled.div`
  padding: 50px 0 80px 0;
  @media screen and ${device.TABLET} {
    display: flex;
    align-items: center;
  }
  background: ${styles.colors.NUDE};
`;

export const Tabs = styled.div`
  background: ${styles.colors.WHITE_NEW};
  border: 1px solid ${styles.colors.GRAY_BORDER};
  border-radius: 100px;
  height: 46px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin: 33px auto 0 auto;
  max-width: 620px;
  @media screen and ${device.TABLET} {
    margin-top: 48px;
    height: 52px;
  }
`;

export const Tab = styled.div`
  font-weight: 700;
  font-size: 14px;
  color: ${styles.colors.DARK_BLUE};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-top: -4px;
  @media screen and ${device.TABLET} {
    font-size: 24px;
  }
  ${({ active }) =>
    active &&
    `
  cursor: default;
  border-radius: 100px;
  color: ${styles.colors.WHITE_NEW};
  background: ${styles.colors.YELLOW};
  height: 52px;
  border: 1px solid ${styles.colors.GRAY_BORDER};
  box-shadow: ${styles.boxShadows.TAB};
  @media screen and ${device.TABLET} {
    height: 58px;
  }
  `};
`;

export const TableHeader = styled.div`
  padding: 21px 0 10px 0;
  background: ${styles.colors.WHITE_2};
  font-weight: 700;
  font-size: 24px;
  text-align: center;
  color: ${styles.colors.DARK_BLUE};
`;

export const TableItem = styled.div`
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  color: ${styles.colors.DARK_BLUE};
  height: 30px;
  padding-left: 14px;
  background: ${({ even }) =>
    even ? styles.colors.WHITE_NEW : styles.colors.WHITE_2};
  gap: 12px;
`;
export const TableFooter = styled.div`
  text-align: center;
  font-weight: 700;
  font-size: 24px;
  color: ${styles.colors.DARK_BLUE};

  padding: 17px 0 14px 0;
  background: ${styles.colors.WHITE_NEW};
`;
export const Table = styled.div`
  box-shadow: ${styles.boxShadows.TABLE};
  border: 1px solid ${styles.colors.GRAY_BORDER};
  border-radius: 20px;
  overflow: hidden;
  margin: 20px auto 25px auto;
  width: 306px;
  @media screen and ${device.TABLET} {
    margin: 28px auto 40px auto;
  }
`;

export const MobileRoad = styled.div`
  @media screen and ${device.TABLET} {
    display: none;
  }
  display: flex;
  justify-content: center;
  img {
    width: 100%;
    max-width: 400px;
  }
`;
export const Road = styled.div`
  @media screen and ${device.TABLET} {
    display: flex;
    justify-content: center;
  }
  display: none;
`;

export const ReviewTitle = styled.div`
  font-weight: 700;
  font-size: 36px;
  margin: 100px 0 0 0;
  @media screen and ${device.TABLET} {
    font-size: 50px;
    margin: 48px 0;
  }
  text-align: center;
  color: ${styles.colors.DARK_BLUE};
`;

export const Footer = styled.div`
  background: ${styles.colors.NUDE};
  padding: 36px 10px 10px 10px;
  margin-top: 90px;
  @media screen and ${device.TABLET} {
    padding: 75px 0 100px 0;
  }
`;

export const FooterText = styled.div`
  font-weight: 700;
  font-size: 32px;
  line-height: 1.2;
  color: ${styles.colors.DARK_BLUE};
  margin: 40px 0 24px 0;
  text-align: center;
  max-width: 640px;
  @media screen and ${device.TABLET} {
    font-size: 50px;
    margin: 0 60px 33px 0;
    text-align: left;
  }
`;
export const MobileImage = styled.div`
  display: flex;
  justify-content: center;
  @media screen and ${device.TABLET} {
    display: none;
  }
`;

export const DesktopImage = styled.div`
  display: none;
  margin-left: 20px;
  @media screen and ${device.TABLET} {
    display: block;
  }
  img {
    max-width: 335px;
  }
`;
export const FooterContent = styled.div`
  @media screen and ${device.TABLET} {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

export const TableText = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 1.2;
  color: ${styles.colors.DARK_BLUE};
  text-align: center;
  margin: 24px 0 80px 0;
  @media screen and ${device.TABLET} {
    margin: 35px 0 76px 0;
  }
`;
export const DobbyCareText = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 1.2;
  text-align: left;
  color: ${styles.colors.DARK_BLUE};
  margin-bottom: 30px;
  @media screen and ${device.TABLET} {
    text-align: center;
  }
`;

export const Phone = styled.div`
  margin-bottom: 8px;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.2;
  color: ${styles.colors.DARK_BLUE};
`;

export const FooterButton = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 80px;
  @media screen and ${device.TABLET} {
    justify-content: flex-start;
    margin-bottom: 0;
  }
`;

export const PhoneSection = styled.div`
  width: 100%;
`;

export const PhoneContainer = styled.label`
  position: relative;
  display: flex;
  align-items: center;
  input {
    max-width: unset;
  }
`;

export const PhoneCountry = styled.div`
  position: absolute;
  left: 20px;
  font-size: 16px;
`;

export const WorksTitle = styled.div`
  text-align: center;
  font-weight: 700;
  text-align: center;
  letter-spacing: 1px;
  color: #404242;
  margin-bottom: 27px;
  font-size: 32px;

  @media screen and ${device.TABLET} {
    margin-bottom: 118px;
    font-size: 50px;
  }
`;
