import { useEffect } from 'react';

function useScrollLock() {
  useEffect(() => {
    const originalStyle = window.getComputedStyle(document.body).overflow;
    const originalOverflowX = window.getComputedStyle(document.body).overflowX;
    const originalOverflowY = window.getComputedStyle(document.body).overflowY;

    document.body.style.overflow = 'hidden';
    document.body.style.overflowX = 'hidden';
    document.body.style.overflowY = 'hidden';

    const handleTouchMove = event => event.preventDefault();
    document.addEventListener('touchmove', handleTouchMove, { passive: false });

    return () => {
      document.body.style.overflow = originalStyle;
      document.body.style.overflowX = originalOverflowX;
      document.body.style.overflowY = originalOverflowY;
      document.removeEventListener('touchmove', handleTouchMove);
    };
  }, []);
}

export default useScrollLock;
