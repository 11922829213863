import React from 'react';

import { Widget } from '@typeform/embed-react';
import { typeFormIds } from 'common/enums/forms';
import { isMobile } from 'react-device-detect';
import { TypeFormWrapper } from './styled';

const TypeForm = ({ pageType }) => {
  return (
    Boolean(typeFormIds[pageType]) && (
      <TypeFormWrapper>
        <Widget
          id={typeFormIds[pageType]}
          opacity={0}
          inlineOnMobile
          width={isMobile ? 375 : 600}
          autoResize
          hideFooter
          hideHeaders
          disableAutoFocus
        />
      </TypeFormWrapper>
    )
  );
};

export default TypeForm;
