import React from 'react';
import { connect } from 'react-redux';
import { setActiveJob } from 'store/actions/user';
import routes from 'common/enums/routes';
import { useHistory } from 'react-router-dom';

import { GridContainer } from './styled';
import { HomeItem, HomeItemLast } from './components';
import { items } from 'common/enums/categories';

const CategoryGridHome = ({
  setActiveJob,
  lastBoxConent,
  entryPoint,
  eventNameEntryPoint
}) => {
  const history = useHistory();

  const handleClick = type => {
    setActiveJob(type);
    history.push(routes.CATEGORIES);
    window.scrollTo(0, 0);
  };
  return (
    <GridContainer>
      {Object.values(items)
        .filter(item => !item.IS_HIDE_HOME)
        .map(item => (
          <HomeItem
            key={item.TYPE}
            item={item}
            handleClick={() => handleClick(item.TYPE)}
          />
        ))}
      {/* <HomeItemLast
        lastBoxConent={lastBoxConent}
        entryPoint={entryPoint}
        eventNameEntryPoint={eventNameEntryPoint}
      /> */}
    </GridContainer>
  );
};

const mapDispatchToProps = {
  setActiveJob
};

export default connect(null, mapDispatchToProps)(CategoryGridHome);
