import styled from 'styled-components/macro';
import styles from 'common/enums/styles';
import { device, deviceSizes } from 'common/enums/devices';
export const ScreenContentWrapper = styled.div`
  position: relative;
  min-height: 100vh;
  background: linear-gradient(260deg, #090856 9.15%, #010111 96.55%);
  width: 100%;
  background-size: cover;
  display: flex;
  align-items: center;
`;

export const WrapperSection = styled.div`
  width: 100%;
  max-width: 1240px;
  margin: 0 auto 56px auto;
  @media screen and ${device.TABLET} {
    margin: 0 auto 100px auto;
  }
`;

export const Wrapper = styled.div`
  z-index: 3;
  width: 100%;
  max-width: 824px;
  padding: ${styles.layout.MOBILE_MENU_HEIGHT + 54}px 16px 73px 16px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  @media screen and ${device.TABLET} {
    grid-template-columns: repeat(2, 1fr);
    padding: 0 16px;
  }
`;

export const HeaderWrapper = styled.div`
  z-index: 3;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
  @media screen and ${device.TABLET} {
    margin-bottom: 32px;
  }
`;

export const ColoredHeader = styled.span`
  color: ${styles.components.HEADER_COLOR};
`;
export const FormWrapper = styled.div`
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
`;

export const InputGroup = styled.div`
  z-index: 3;
  display: grid;
  width: 100%;
  column-gap: 15px;
  row-gap: 24px;
  justify-items: center;
  align-items: center;
  flex-direction: column;
  @media screen and ${device.TABLET} {
    display: flex;
    flex-direction: row;
  }
`;

export const InputPhoneWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  ${({ marginBottom }) => marginBottom && `margin-bottom: ${marginBottom}px`};
  input {
    background-color: ${styles.colors.DARK_BLUE2};
    border: 1px solid ${styles.colors.GRAY_BORDER_LIGHT};
    padding-top: 12px;
    padding-right: 12px;
    padding-bottom: 12px;
    ${({ leftMargin }) =>
      leftMargin ? `padding-left: ${leftMargin}px` : `38px`};
    margin-right: 0;
    color: #ffffff;
    max-width: 100%;
    ::placeholder {
      color: #ffffff;
      opacity: 0.3;
    }
    &:-webkit-autofill,
    :-webkit-autofill:focus {
      transition: background-color 600000s 0s, color 600000s 0s;
    }
  }
`;

export const InputWrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  @media screen and ${device.TABLET} {
    flex-direction: row;
    margin-bottom: 15px;
  }

  input {
    background-color: ${styles.colors.NUDE};
    padding: 12px 24px;
    margin: 0;
    @media screen and ${device.TABLET} {
      padding: 12px 48px;
    }
  }

  textarea {
    transition: all 0.2s ease-out;
    font-family: 'HKGrotesk', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: 1px;
    padding: 12px 16px;
    background-color: ${styles.colors.DARK_BLUE2};
    color: #ffffff;
    border-radius: 8px;
    box-shadow: 0px 0px 0px;
    border: 1px solid ${styles.colors.GRAY_BORDER_LIGHT};
    margin-right: 0px;
    margin-bottom: 0;
    height: 100px;
    ::placeholder {
      color: #ffffff;
      opacity: 0.3;
    }
  }
`;

export const SendButtonWrapper = styled.div`
  z-index: 3;
  button {
    width: 178px;
    font-size: 20px;
    :disabled {
      background-color: #3d3c9b !important;
      color: #82819d;
      opacity: 1;
    }
  }
`;

export const HeaderTitle = styled.div`
  font-weight: 700;
  margin-bottom: 24px;
  font-size: 32px;
  letter-spacing: 1px;
  color: #ffffff;
  text-align: center;
  max-width: 274px;
  @media screen and ${device.TABLET} {
    font-size: 50px;
    margin-bottom: 10px;
    max-width: 100%;
  }
`;

export const HeaderText = styled.div`
  font-weight: 700;
  font-size: 18px;
  text-align: center;
  letter-spacing: 1px;
  color: #ffffff;
  max-width: 356px;
  @media screen and ${device.TABLET} {
    font-size: 24px;
    max-width: 100%;
  }
`;

export const ImageBackground = styled.img`
  width: 278px;
  height: 342px;
  position: absolute;
  z-index: 1;
  right: 0;
  top: calc((100vh / 2) - 139px);
  display: none;
  @media screen and ${device.LAPTOP} {
    display: block;
  }
`;

export const StoryInfo = styled.div`
  padding: 0 0 0 22px;
  color: ${styles.colors.DARK_BLUE};
  display: flex;
  flex-direction: column;

  @media screen and ${device.TABLET} {
    padding: 32px;
    ${({ reverse }) => reverse && 'grid-row-start: 1'};
    background: ${styles.components.BACKGROUND_LIGHT_EXTRA};
    justify-content: space-between;
  }
`;

export const Story = styled.div`
  line-height: 1.2;
  letter-spacing: 1px;
  display: grid;
  grid-template-columns: 130px 1fr;
  cursor: pointer;
  @media screen and ${device.TABLET} {
    grid-template-columns: repeat(2, 1fr);

    &:hover {
      ${StoryInfo} {
        background: ${styles.colors.GRAY_2} !important;
      }
    }
  }
  @media screen and (max-width: ${deviceSizes.TABLET}) {
    padding: 16px 0;
    border-bottom: 1px solid ${styles.colors.GRAY_2};
  }
`;

export const StoryTitle = styled.div`
  font-weight: 700;
  font-size: 18px;
  @media screen and ${device.TABLET} {
    font-size: 32px;
  }
  margin-bottom: 8px;
`;

export const StoryDesc = styled.div`
  font-weight: 400;
  font-size: 24px;
  @media screen and (max-width: ${deviceSizes.TABLET}) {
    display: none;
  }
`;

export const ReadStory = styled.div`
  font-weight: 400;
  font-size: 24px;
  display: flex;
  align-items: center;
  svg {
    margin-left: 8px;
  }
  @media screen and (max-width: ${deviceSizes.TABLET}) {
    display: none;
  }
`;

export const Image = styled.div`
  background-size: cover;

  height: 70px;
  overflow: hidden;

  @media screen and ${device.TABLET} {
    height: 336px;
  }
  img {
    width: 100%;
    height: 100%;
  }
`;

export const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 80px;
  @media screen and ${device.TABLET} {
    padding-top: 100px;
  }
`;

export const StorySection = styled.div`
  padding: 80px 16px 0 16px;
  @media screen and ${device.TABLET} {
    padding: 100px 16px 0 16px;
  }
`;

export const MainContent = styled.div`
  margin-bottom: 80px;
  @media screen and ${device.TABLET} {
    margin-bottom: 100px;
  }
`;

export const Separator = styled.br`
  display: none;
  @media screen and ${device.TABLET} {
    display: block;
  }
`;
