import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import NumberFormat from 'react-number-format';
import styles from 'common/enums/styles';

import {
  selectUserId,
  selectGetUserReferralLinkLoading
} from 'store/reducers/user';
import { getReferralLink } from 'store/actions/user';
import { Button, Input, Radio } from 'components';
import { Modal } from 'modals/components';

import {
  BonusFormHeader,
  BonusForm,
  Container,
  InputWrapper,
  InputPrefix,
  InputGroup,
  RadioGroup,
  ButtonGroup
} from './styled';
import routes from 'common/enums/routes';

const CommunityForm = ({
  errors,
  values,
  fields,
  getReferralLink,
  getUserReferralLinkLoading,
  onModalClose
}) => {
  const [showValidation, setShowValidation] = useState({});
  const [targetType, setTargetType] = useState(null);
  const history = useHistory();
  const locationUA = history.location.hash === '#ua';
  const phoneNumberCode = locationUA ? '+38.' : '+1.';

  const isFormFilled = Boolean(
    values.name && values.phone_number && values.user_type
  );

  useEffect(() => {
    if (isFormFilled && !getUserReferralLinkLoading) {
      setShowValidation({});
      history.push(
        targetType === 'homeowner'
          ? routes.GROW_TOGETHER
          : routes.BUILD_TOGETHER
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getUserReferralLinkLoading]);

  const isFieldError = field => errors[field] && showValidation[field];

  const activateValidation = field => () => {
    if (!showValidation[field]) {
      setShowValidation(showValidation => ({
        ...showValidation,
        [field]: true
      }));
    }
  };

  const onFormSubmit = targetType => event => {
    event.preventDefault();
    setTargetType(targetType);
    getReferralLink({
      phone_number: `${phoneNumberCode}${values.phone_number}`,
      group_id: values.user_type === 'homeowner' ? 1 : 2,
      given_name: values.name
    });
  };

  const isSubmitButtonDisabled =
    isFieldError('name') ||
    isFieldError('phone_number') ||
    isFieldError('user_type') ||
    !values.name ||
    !values.phone_number ||
    !values.user_type ||
    getUserReferralLinkLoading;

  return (
    <Modal
      visible
      padding="16px 20px 23px 20px"
      closeButton
      width={360}
      borderRadius={20}
      onModalClose={onModalClose}>
      <BonusForm>
        <BonusFormHeader>Share the magic of Dobby</BonusFormHeader>
        <Container>
          <InputGroup>
            <InputWrapper>
              <Input
                errorBorders={isFieldError('name')}
                type="text"
                placeholder="Your Name"
                backgroundNude
                disabled={getUserReferralLinkLoading}
                {...fields.name}
                onBlur={activateValidation('name')}
              />
            </InputWrapper>
            <InputWrapper>
              <InputPrefix left={locationUA ? 40 : 47}>
                {phoneNumberCode}
              </InputPrefix>
              <NumberFormat
                type="tel"
                format="###.###.####"
                customInput={Input}
                placeholder="202.460.7470"
                padding="0 48px 0 72px"
                smallBottomMargin
                lineHeight={1}
                backgroundNude
                errorBorders={isFieldError('phone_number')}
                {...fields.phone_number}
                onBlur={activateValidation('phone_number')}
                disabled={getUserReferralLinkLoading}
              />
            </InputWrapper>
          </InputGroup>
          <RadioGroup>
            <InputWrapper>
              <Radio
                {...fields.user_type}
                disabled={getUserReferralLinkLoading}
                name="user_type"
                value="homeowner"
                label="I’m a homeowner"
                color={styles.colors.YELLOW}
                selectedLabelColor={styles.colors.YELLOW}
              />
            </InputWrapper>
            <InputWrapper>
              <Radio
                {...fields.user_type}
                disabled={getUserReferralLinkLoading}
                name="user_type"
                value="provider"
                label="I’m a service provider"
                color={styles.colors.YELLOW}
                selectedLabelColor={styles.colors.YELLOW}
              />
            </InputWrapper>
          </RadioGroup>
          <ButtonGroup>
            <Button
              title="Invite Homeowners"
              disabled={isSubmitButtonDisabled}
              loading={targetType === 'homeowner' && getUserReferralLinkLoading}
              fullWidth
              onClick={onFormSubmit('homeowner')}
            />
            <Button
              title="Invite Service Providers"
              type="outline"
              fullWidth
              disabled={isSubmitButtonDisabled}
              loading={targetType === 'provider' && getUserReferralLinkLoading}
              onClick={onFormSubmit('provider')}
            />
          </ButtonGroup>
        </Container>
      </BonusForm>
    </Modal>
  );
};

const validate = values => {
  const errors = {};

  if (!values.name || values?.name.trim() === '') {
    errors.name = 'required';
  }

  if (!values.phone_number) {
    errors.phone_number = 'required';
  } else if (values.phone_number && values.phone_number.indexOf(' ') >= 0) {
    errors.phone_number = 'wrongFormat';
  }

  if (!values.user_type) {
    errors.user_type = 'required';
  }

  return errors;
};

const mapStateToProps = state => ({
  userId: selectUserId(state),
  getUserReferralLinkLoading: selectGetUserReferralLinkLoading(state)
});

const mapDispatchToProps = {
  getReferralLink
};

export default reduxForm({
  form: 'getReferralLink',
  fields: ['name', 'phone_number', 'user_type'],
  validate
})(connect(mapStateToProps, mapDispatchToProps)(CommunityForm));
