export default {
  ADD_GLOW_AUTH_LINK: '/app/addglow-auth',
  CV: '/job_application',
  GET_REFERRAL_LINK: '/referral/user',
  INVITE_USER_BY_REFERRAL_LINK: '/referral/invite',
  PLANT_TREE: '/website/plant/tree',
  UNSUBSCRIBE: '/email/unsubscribe',
  SEND_ME_THE_LINK: '/app_request',
  ASK_US_ANYTHING: '/website/ask_us',
  REVIEW: '/review',
  PUBLICATIONS: '/attic_publication',
  ME: '/user/me',
  SUBSCRIPTION: '/subscription/pilot',
  USER_UTM_TRACK: '/user/track',
  ADDRESS_INFO: '/website/address_info'
};
