import styled from 'styled-components/macro';
import styles from 'common/enums/styles';
import { device } from 'common/enums/devices';

export const Section = styled.section`
  background-size: cover;
  background-color: ${styles.components.BACKGROUND};
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 54px 16px;

  @media screen and ${device.LAPTOP} {
    padding: 80px 0;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
  position: relative;
  @media screen and ${device.LAPTOP} {
    align-items: center;
  }
  > h1 {
    margin-bottom: 0;
    color: ${styles.components.HEADER_INVERTED};
  }
  > p {
    margin-left: 0;
    margin-top: 18px;
    color: ${styles.colors.WHITE_NEW};

    @media screen and ${device.LAPTOP} {
      margin-top: 26px;
      text-align: center;
    }
  }
`;

export const ValuesContainer = styled.div`
  display: grid;
  margin: 23px 0 0;
  gap: 10px;
  @media screen and ${device.LAPTOP} {
    grid-template-columns: repeat(2, 1fr);
    max-height: 500px;
    margin-top: 41px;
    max-width: 1155px;
    gap: 56px 18px;
    @media screen and (min-width: 1100px) {
      gap: 56px 112px;
    }
  }
  > h2 {
    @media screen and ${device.LAPTOP} {
      margin-left: 46px;
    }
  }
`;

export const TitleRow = styled.div`
  display: flex;
  align-items: center;
  > h2 {
    margin-left: 12px;
    margin-bottom: 5px;
    color: ${styles.components.TEXT_INVERTED};
    @media screen and ${device.LAPTOP} {
      margin-left: 2px;
      margin-bottom: 9px;
    }
  }
  p {
    margin-top: 10px;
  }
`;

export const Number = styled.h1`
  color: ${styles.components.HEADER_COLOR};
  margin-right: 4px;
  @media screen and ${device.LAPTOP} {
    margin-right: 15px;
  }
`;

export const ValueCard = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0 100px;
  padding: 16px 0;
  @media screen and ${device.LAPTOP} {
    flex: 1 1 162px;
    max-width: 473px;
    margin: 0;
    padding: 0;
    height: 101px;
  }
  color: ${styles.components.TEXT_INVERTED};

  &:hover h2 {
    color: ${styles.components.HEADER_COLOR};
  }
  > p {
    @media screen and ${device.LAPTOP} {
      margin-left: 54px;
    }
  }
`;
