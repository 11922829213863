import styled from 'styled-components/macro';

import styles from 'common/enums/styles';
import { pageTypes } from 'common/enums/pages';
import { device } from 'common/enums/devices';

const imageStyles = {
  [pageTypes.ABOUT_US]: `
    background-size: auto 150%;
    background-position: 30% 40%;
    @media screen and (min-width: 1240px) {
      background-position: 0 93%;
    }
  `,
  [pageTypes.HOME]: `
    background-size: auto 150%;
    background-position: 30% 40%;
    @media screen and (min-width: 1240px) {
      background-position: 0 93%;
    }
  `,
  [pageTypes.PARTNERS]: `
    background-position: 32.5% 48%;
    @media screen and (min-width: 1240px) {
      background-position: 75.5% 55%;
    }
    `,
  [pageTypes.PROVIDERS]: `
    background-size: cover;
    background-position: 50% 46%;
    @media screen and (min-width: 1240px) {
      background-position: 75.5% 55%;
    }

    :before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background: ${styles.components.BACKGROUND};
      opacity: 0.6;
      z-index: 3;
    }
    `,
  [pageTypes.LOVE_OUR_PLANET]: `
    background-position: 42.5% 48%;
    @media screen and (min-width: 1240px) {
      background-position: 76.5% 36%;
    }
    `,
  [pageTypes.PRESS_RELEASE]: `
    background-position: 89.5% 46%;
    @media screen and (min-width: 1240px) {
      background-position: 50% 46%;
    }
    `,
  [pageTypes.DOBBY_HO]: `
    background-position: 50% 46%;
    background-size: auto 100%;

    @media screen and (min-width: 1240px) {
      background-position: 50% 50%;
      background-size: 100% auto;
      background-repeat: no-repeat;
    }
  `
};

export const BackgroundImageHolder = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

export const Image = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url(${({ src }) => src});
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-color: transparent;
  z-index: 3;
  @media screen and ${device.LAPTOP} {
    background-size: cover;
  }
  ${({ type }) => {
    if (imageStyles[type]) {
      return imageStyles[type];
    }
  }}
`;

export const ImagePlaceholder = styled(Image)`
  z-index: 2;
`;
