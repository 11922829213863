import React from 'react';

import { Text, CategoryGrid, GetApp } from 'components';
import styles from 'common/enums/styles';

import { Container, Title, TextWrapper, GetAppWrapTop } from './styled';
import { lastBoxConent } from './enum';

const Categories = ({
  categoriesSection,
  name,
  entryPoint,
  eventNameEntryPoint,
  isHome
}) => (
  <Container name={name}>
    <Title>{categoriesSection.TITLE}</Title>
    <TextWrapper>
      <Text description2 color={styles.colors.WHITE_NEW}>
        {categoriesSection.SUBTITLE}
      </Text>
      <GetAppWrapTop>
        <GetApp
          isCentered
          mobileOnly
          entryPoint={entryPoint}
          eventNameEntryPoint={eventNameEntryPoint}
        />
      </GetAppWrapTop>
    </TextWrapper>
    <CategoryGrid
      isHome={isHome}
      lastBoxConent={lastBoxConent}
      entryPoint={entryPoint}
      eventNameEntryPoint={eventNameEntryPoint}
    />
  </Container>
);

export default Categories;
