import React, { useEffect } from 'react';
import { Element } from 'react-scroll';
import { Hero, MainContent } from 'components';
import pressReleaseBG1x from 'static/images/backgrounds/press-release-1x.png';
import pressReleaseBGLowResolution from 'static/images/backgrounds/press-release-low-resolution.png';
import corner from 'static/images/icons/corner.svg';
import { pageTypes } from 'common/enums/pages';

import {
  AboutDobbyContainer,
  AboutDobbyText,
  AboutDobbyTitle,
  BoldLink,
  Content,
  Corner,
  Email,
  LinkedInSvg,
  MediaContact,
  OriginalSourceLink,
  Quote,
  QuoteAuthor,
  QuoteAuthorText,
  QuoteSection,
  QuoteSymbol,
  QuoteText,
  RowContainer,
  Text
} from './styled';
import styles from 'common/enums/styles';

const LinkedInLogo = () => (
  <LinkedInSvg
    viewBox="0 0 63 63"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <rect width="63" height="63" fill={styles.colors.BLACK_TRUE} />
    <path
      d="M22.3465 49.9426H14.6748V23.9734H22.3465V49.9426ZM18.5108 20.4273H18.4609C15.8865 20.4273 14.2215 18.4333 14.2215 15.9412C14.2215 13.3929 15.9374 11.4541 18.5618 11.4541C21.1862 11.4541 22.8012 13.3929 22.8511 15.9412C22.8511 18.4333 21.1862 20.4273 18.5108 20.4273ZM50.009 49.9426H42.3383V36.0497C42.3383 32.5582 41.2275 30.177 38.4517 30.177C36.3325 30.177 35.0703 31.7832 34.5156 33.3337C34.3128 33.8886 34.2633 34.6641 34.2633 35.4402V49.9426H26.5921C26.5921 49.9426 26.6925 26.4097 26.5921 23.9734H34.2633V27.6503C35.2828 25.8808 37.1068 23.3639 41.177 23.3639C46.2244 23.3639 50.009 27.0755 50.009 35.0519V49.9426Z"
      fill="#FCFCFD"
    />
  </LinkedInSvg>
);

const PressRelease = ({ history }) => {
  useEffect(() => {
    document.title =
      'Dobby | The most future-forward home services and renovation company';
  }, [history]);

  return (
    <MainContent>
      <div id="mainSection">
        <Hero
          title="Dobby, The AI‑powered Home Maintenance Platform, Secures
          $1.7 Million in Seed Round"
          subTitle="Funding round will support rapid growth in the first market as Dobby
          aims to put homeownership on auto‑pilot"
          imageSrc={pressReleaseBG1x}
          placeholderSrc={pressReleaseBGLowResolution}
          backgroundType={pageTypes.PRESS_RELEASE}
        />
      </div>
      <Element name="scrollBelowSecondSection" />
      <QuoteSection>
        <RowContainer>
          <Corner src={corner} />
          <Content>
            <Text>
              <BoldLink
                href="https://hellodobby.com/"
                rel="noopener noreferrer"
                target="_blank">
                Dobby,
              </BoldLink>{' '}
              an AI-powered home maintenance platform, today announced its seed
              capital raise of $1.7 million. The capital will strengthen the
              company’s growth initiatives, and spotlight its proprietary,
              automated, AI-first experience to homeowners, service providers,
              and ancillary beneficiaries such as real estate agents.
            </Text>
            <br />
            <br />
            <Text>
              Financing was led by{' '}
              <BoldLink
                href="https://dundeeventurecapital.com/"
                rel="noopener noreferrer"
                target="_blank">
                Dundee VC
              </BoldLink>{' '}
              with <strong>B5 Capital</strong>, the family office of Mark
              Bailey, founder/ partner at DFJ Growth; Jeremy Achin,
              co-founder/CEO at DataRobot; Maury Devine, board member at
              ConocoPhillips and early investor in Sweetgreen; and Andrew
              Macdonald, head of Uber Mobility as other major participants. This
              equity finance raise comes as Dobby scales to meet strong demand
              in the home maintenance sector, which, according to a Bank of
              America spending report, is seeing home maintenance consumer
              spending 60% above the Jan-2020 level.
            </Text>
            <Quote>
              <QuoteSymbol>“</QuoteSymbol>
              <QuoteText>
                During my days at{' '}
                <BoldLink
                  href="https://www.crunchbase.com/organization/datarobot"
                  rel="noopener noreferrer"
                  target="_blank">
                  DataRobot,
                </BoldLink>{' '}
                I saw an opportunity to build an AI-first company from the
                ground up in a large and unsexy industry.
                <br />
                <br />I chose home maintenance because this $400B industry has
                not had a tech makeover for more than a decade and suffers
                terribly low NPS across the board. Dobby's AI-first design
                enables us to become embedded in the homeownership journey, help
                homeowners increase the property value, and yes, make home
                maintenance fun.
              </QuoteText>
              <QuoteSymbol>“</QuoteSymbol>
            </Quote>
            <QuoteAuthor>
              <QuoteSymbol></QuoteSymbol>
              <QuoteAuthorText>
                <BoldLink
                  href="https://www.linkedin.com/in/satadrusg/"
                  rel="noopener noreferrer"
                  target="_blank">
                  Satadru Sangupta <LinkedInLogo />
                </BoldLink>
                <br />
                <span>Dobby’s CEO and co-founder</span>
              </QuoteAuthorText>
              <QuoteSymbol></QuoteSymbol>
            </QuoteAuthor>
            <Text>
              An AI-first design has also helped Dobby increase operational
              efficiencies, lower costs, and flip the business model in the home
              maintenance industry. Existing maintenance marketplaces squeeze
              profit margins from service providers by selling them leads and
              creating artificial competition. Dobby only takes commissions when
              the job is completed.
            </Text>
            <Quote>
              <QuoteSymbol>“</QuoteSymbol>
              <QuoteText>
                We don't pay for ads on Dobby. The chat-bot makes it super easy
                to communicate virtually and minimize the number of visits. And
                we get paid within 24 hours, this is the game-changing.
              </QuoteText>
              <QuoteSymbol>“</QuoteSymbol>
            </Quote>
            <QuoteAuthor>
              <QuoteSymbol></QuoteSymbol>
              <QuoteAuthorText>
                <strong>Zela from LMK Plumbing</strong>
                <br />
                <span>a family-owned small business in Maryland.</span>
              </QuoteAuthorText>
              <QuoteSymbol></QuoteSymbol>
            </QuoteAuthor>
            <Text>
              Dobby has been enjoying double-digit monthly growth in the DC
              metro area. With this new funding, Dobby will continue expanding
              operations in this region and building the playbook for geographic
              expansion by mid 2021.
            </Text>
            <Quote>
              <QuoteSymbol>“</QuoteSymbol>
              <QuoteText>
                I have seen incumbents and startups trying to solve the home
                maintenance problem, but I haven't seen the product-led vision
                that Dobby is executing on.
                <br />
                The current market is a lead-generation business. Dobby is a
                value-creation business. From job specification to payment,
                Dobby automates home maintenance with a fully-digital AI-powered
                app.
              </QuoteText>
              <QuoteSymbol>“</QuoteSymbol>
            </Quote>
            <QuoteAuthor>
              <QuoteSymbol></QuoteSymbol>
              <QuoteAuthorText>
                <BoldLink
                  href="https://www.linkedin.com/in/ronaldwatson23/"
                  rel="noopener noreferrer"
                  target="_blank">
                  Ron Watson <LinkedInLogo />
                </BoldLink>
                <br />
                <span>a Partner at Dundee VC and Dobby board member.</span>
              </QuoteAuthorText>
              <QuoteSymbol></QuoteSymbol>
            </QuoteAuthor>
          </Content>
          <Corner src={corner} />
        </RowContainer>
        <AboutDobbyContainer>
          <AboutDobbyTitle>About Dobby</AboutDobbyTitle>
          <AboutDobbyText>
            Dobby unlocks the home maintenance marketplace with an AI-first,
            fully digital productivity platform for homeowners, and invite-only
            service providers community. Starting with home maintenance, Dobby
            is pioneering digitization & AI in the $16T residential property
            market. Dobby's vision is "to become the operating system for
            American homes" by creating customized experiences for modern
            homeowners.
          </AboutDobbyText>
          <MediaContact>Media Contact</MediaContact>
          <Email
            href="mailto:social@hellodobby.com"
            rel="noopener noreferrer"
            target="_blank">
            social@hellodobby.com
          </Email>
          <MediaContact>Original Sourse</MediaContact>
          <OriginalSourceLink
            href="https://www.prnewswire.com/news-releases/dobby-the-ai-powered-home-maintenance-platform-secures-1-7-million-in-seed-round-301185924.html"
            rel="noopener noreferrer"
            target="_blank">
            prnewswire.com
          </OriginalSourceLink>
        </AboutDobbyContainer>
      </QuoteSection>
    </MainContent>
  );
};

export default PressRelease;
