import { NUMBER_OF_JOBS } from 'common/constants/content';

export const accordionItems = {
  name: 'Join us',
  qa: [
    {
      question: 'Why should I trust your Pros?',
      answer: `Over the last two years, Dobby service pros have maintained 98% positive reviews from our ${NUMBER_OF_JOBS} homeowners in two markets! We read thousands of reviews written by your neighbors and then hand-pick only the 5-star providers from your community. Then we spend a lot of time with them, often in person, to ensure they are ready for Dobby's hospitality standard. From highest quality craftsmanship to prompt responses to fair pricing - our service providers are known for providing an exceptional customer experience.`
    },
    {
      question: 'How do I know the price?',
      answer:
        'Once you submit your job, we assign one of our pre-vetted, top-quality pros to get it done. And they respond in the app to give you a detailed quote. Everything is on the app to verify and validate. You can ask as many questions as you want.'
    },
    {
      question:
        'What makes Dobby different from other home services companies?',
      answer:
        "As soon as you book a job on Angi, Thumbtack, or any home services matchmaking website, they make money by selling your job to multiple pros. So, they have zero incentive to find the best service pro to complete your job with 5-star quality. They sell your personal data, they don't solve your problem, and they exploit small business owners from your community. We founded Dobby to bring delightful experiences to homeowners and stop the exploitations of service pros. We've made managing your home faster, easier, and smarter."
    }
  ]
};

const aboutSection = {
  name: 'About Dobby',
  qa: [
    {
      question: 'What is Dobby? ',
      answer:
        "Dobby is the most loved, most tech-advanced, and most future-forward home services company in America. We solve homeowners' problems with our insanely time-saving app powered by our invite-only community of local, pre-vetted service providers and small businesses covering 30+ service categories. If you own a home, all you need is Dobby."
    },
    {
      question: 'Who is Dobby for?',
      answer:
        "Dobby is for any homeowner who values their time and cares about quality. We do all the homework, research, and vetting for you, so you can maintain and upgrade your home with just a few taps. Whether you are a first-time buyer or someone who's owned their home for 20 years, we built Dobby to help at every stage of homeownership."
    },
    {
      question: 'How is Dobby different?',
      answer:
        "Simply put, we're revolutionizing an outdated market.\n\n" +
        "The traditional home maintenance platforms haven't gotten a business model upgrade in a decade. They let you book a pro, but that's where their help ends. Dobby takes the unknowns out of the process with top-quality pros, live support when you need it, and quality guarantee. We're here to help every step of the way. And we realize that it is not about a one-off maintenance job - but fully taking care of your home from hanging a picture to the kitchen upgrade. That's why we're the first company to build a property management platform for your home.\n\n" +
        "Second, we take care of our communities and our planet. Unlike other companies, we don't sell your contact information to multiple pros and squeeze them for every penny. We assign one pro to your job and only make money when we know the job is done (and done well). This forward-looking business model protects your privacy, lowers your cost, and allows local small businesses to thrive. Dobby is the first company in this $500B industry to invest a portion of the revenue to offset its carbon footprint (service pros are driving door to door). And every time you complete a job on Dobby, we plant a tree on your behalf!"
    },
    {
      question:
        'What kinds of home maintenance services are available on Dobby?',
      answer:
        "Our Dobby Pros cover 30+ service categories. Everything from small repairs to large upgrades to turn your house into your dream home. Some of our most popular jobs include carpentry, HVAC, landscaping, painting, plumbing, electrical and handyman services - but that's just the tip of the iceberg. You can read more",
      link: {
        text: 'here',
        url: '/#categories'
      }
    },
    {
      question: 'What are your climate initiatives?',
      answer:
        'We value giving back to our local and global communities. For every job completed on Dobby, we plant a tree in Appalachia. We also contribute part of our revenue to carbon offsets. In the last 12 months, our contribution helped four companies working on carbon reduction technologies: Project Vesta, Charm Industrial, Carbon Cure, and Climeworks.'
    }
  ]
};

const joiningSection = {
  name: 'Joining Dobby',
  qa: [
    {
      question: 'What cities is Dobby available in?',
      answer:
        'Currently, we operate in the Washington, DC metro area (fondly known as the DMV area), Charlotte metro, and the San Francisco Bay Area. Not in one of our locations yet? Download the app to try out Check the Price™ feature for free, and let us know your neighborhood is ready for Dobby!'
    },
    {
      question: 'Is there a fee to join Dobby?',
      answer:
        'There are no subscription or fees to join Dobby. We include a 3% fee on your invoice which goes directly to payment processing.'
    },
    {
      question: 'How does Dobby help first time homebuyers?',
      answer:
        "During the first year of home ownership, average homeowners spend more than $12,000 and 100+ hours on home maintenance and upgrades, which is exhausting! And if you are new to an area or a first-time homeowner, you probably don’t have a plumber on speed dial and you don't know what to do and when. With Dobby, you have our invite-only community of local, pre-vetted service providers and small businesses ready to help you across 30+ service categories."
    },
    {
      question: 'How do I sign up for Dobby?',
      answer:
        "Simply download the app, share your address and get started with your first job. We weren't kidding about being insanely time-saving..."
    }
  ]
};

const usingSection = {
  name: 'Using Dobby',
  qa: [
    {
      question: 'What if I have to cancel my request?',
      answer:
        'We know plans can change, so Dobby offers cancellation flexibility. We have already done the homework for you, so we know our pros offer competitive and fair pricing, so there is no need to shop around with us. And remember: we have an easy-to-reschedule feature that allows homeowners or service pros to reschedule the visit if something comes up.'
    },
    {
      question: 'Can I get a quote before my project starts?',
      answer:
        'Of course, we offer up-front pricing and multiple quotes for larger projects.'
    },
    {
      question: 'How do I communicate with the service pro?',
      answer:
        'Our in-app chat makes it easy for you to chat with your pro, and share pictures, quotations, and invoices. And with the only 3-way chat in the industry, Dobby support is always available to make sure everything runs smoothly.'
    },
    {
      question: 'What if something goes wrong?',
      answer:
        'We believe the best companies shine when things don’t go as planned. That’s why we’re the first to offer a quality guarantee. If something comes up, let us know. We’ll make it right.'
    },
    {
      question: 'How long does it take for Dobby to send a service pro?',
      answer:
        'Once you share your job with us, our smart pro assignment connects you directly with the best provider for your job. This can take as little as 30 minutes, or up to a few hours depending on the job and time you submit your request.'
    }
  ]
};

const providerSection = {
  name: 'For Service Providers',
  qa: [
    {
      question: 'How do you find and vet service providers',
      answer:
        'Every Dobby provider in our network is here by invitation only! We have dedicated resources to research and find stellar referrals from our trusted network. We review their portfolio of previous work and reviews and spend time getting to know them before adding them to our community. We also continually update our network based on Dobby homeowner reviews to ensure our providers are continuously meeting our standard of excellence. From highest quality craftsmanship to prompt responses to fair pricing - our service providers are known for providing an exceptional customer experience. No wonder, they are maintaining 98% positive reviews from our homeowners!'
    },
    {
      question:
        'Will I always get a different service provider, even within the same maintenance category?',
      answer:
        'Not necessarily! We want our homeowners to build relationships with service providers they trust, so Dobby gives you the option of re-booking the same service provider for future projects. Check out our testimonials to see how other homeowners are already building those connections.'
    },
    {
      question:
        'What if I have a service provider I already like working with? Can they join Dobby?',
      answer:
        'Yes! We built our 5-star network of service providers with the help of our homeowners. And it gets better when you refer your trusted service pro to Dobby - you get a $50 home maintenance credit, and they can get access to our platform to support and grow their business.'
    },
    {
      question: 'How does Dobby help service providers grow their business?',
      answer:
        "Dobby is a great way to expand your customer base and grow your business. We also help service providers with scheduling, communication, and payment processing. And we're always here to help if you have questions."
    }
  ]
};

export const FAQSections = [
  aboutSection,
  joiningSection,
  usingSection,
  providerSection
];
