import { useEffect } from 'react';
import mixpanel from 'mixpanel-browser';

export const useTrackPageViews = ({ page }) => {
  useEffect(() => {
    mixpanel.track(`Page views ${page}`, {
      'Page Name': page
    });
  }, [page]);
};
