import styled from 'styled-components';
import styles from 'common/enums/styles';
import { device } from 'common/enums/devices';

export const Section = styled.section`
  background-color: ${styles.colors.WHITE_NEW};
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  padding: 56px 16px 0;
  max-width: 1008px;
  align-items: center;
  white-space: pre-line;
  @media screen and ${device.LAPTOP} {
    padding: 123px 0 0;
    white-space: normal;
    max-width: unset;
  }
`;

export const Heading = styled.h1`
  text-align: center;
  margin-bottom: 8px;
  color: ${styles.components.HEADER_COLOR};
  @media screen and ${device.LAPTOP} {
    margin-bottom: 8px;
  }
`;

export const TextWrapper = styled.div`
  display: flex;
  max-width: 343px;
  white-space: pre-line;
  @media screen and ${device.LAPTOP} {
    max-width: unset;
  }
`;
