import { useEffect } from 'react';
import { isDesktop } from 'react-device-detect';

import links from 'common/constants/links';

const RedirectToProvider = ({ location: { search }, match }) => {
  useEffect(() => {
    const { componentPath } = match.params;
    const queryParams = new URLSearchParams(search);
    const sid = queryParams.get('sid');
    let link = links.PRO_DEEP_LINK_BASE;

    if (isDesktop) {
      window.open(links.MARKET_SP_APP_IOS);
    } else if (componentPath === 'chat' && Boolean(sid)) {
      link = `${links.PRO_DEEP_LINK_BASE}job/${sid}/chat`;
    } else if (componentPath === 'chat' && !sid) {
      link = `${links.PRO_DEEP_LINK_BASE}chat`;
    } else if (componentPath === 'job' && Boolean(sid)) {
      link = `${links.PRO_DEEP_LINK_BASE}job/${sid}`;
    } else if (componentPath === 'new_job' && Boolean(sid)) {
      link = `${links.PRO_DEEP_LINK_BASE}new_job/${sid}`;
    }

    window.location.href = link;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default RedirectToProvider;
