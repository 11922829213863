import React from 'react';

import {
  BackgroundVideoHolder,
  VideoContainer,
  ImagePlaceholder
} from './styled';

const BackgroundVideo = ({
  light,
  srcFull,
  srcMobile,
  backgroundType,
  srcPlaceholder
}) => {
  return (
    <BackgroundVideoHolder light={light}>
      <VideoContainer
        desktopOnly
        src={window.innerWidth < 1024 && srcMobile ? srcMobile : srcFull}
        type={backgroundType}
        autoPlay
        muted
        loop
        playsInline
      />
      <ImagePlaceholder
        src={window.innerWidth < 1024 && srcMobile ? srcMobile : srcPlaceholder}
      />
    </BackgroundVideoHolder>
  );
};

export default BackgroundVideo;
