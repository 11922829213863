import { call, takeLatest, put } from 'redux-saga/effects';

import Api from 'services/ApiService';
import actionTypes from 'common/enums/actionTypes';
import api_constants from 'common/enums/api';

function* addGlowMagicLink({ payload } = {}) {
  try {
    const response = yield call(
      Api.amplifyPost,
      api_constants.ADD_GLOW_AUTH_LINK,
      payload
    );

    if (!response?.magicLink) {
      return;
    }

    if (payload.onSuccess && response?.magicLink) {
      payload.onSuccess(response);
    }

    yield put({
      type: actionTypes.ADD_GLOW_MAGIC_LINK_SUCCESS
    });
  } catch (err) {
    yield put({
      type: actionTypes.ADD_GLOW_MAGIC_LINK_ERROR
    });
  }
}

export default function* watchAddGlowMagicLink() {
  yield takeLatest(actionTypes.ADD_GLOW_MAGIC_LINK_FETCH, addGlowMagicLink);
}
