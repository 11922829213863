import styled from 'styled-components/macro';
import styles from 'common/enums/styles';
import { device } from 'common/enums/devices';

export const Top = styled.section`
  padding: 26px 0;
  text-align: center;
  width: 100%;
  @media screen and ${device.LAPTOP} {
    padding: 40px 61px;
    text-align: left;
    max-width: 1440px;
    margin: auto;
  }
`;

export const TopWrapper = styled.section`
  text-align: center;
  width: 100%;
  background-color: ${styles.colors.NUDE};
`;

export const Wrapper = styled.div`
  padding-top: 220px;
  @media screen and ${device.LAPTOP} {
    padding-top: 0;
  }
`;
