import React from 'react';

import { analyticsPageTypes } from 'common/enums/pages';
import imgLogoFooter from 'static/images/icons/logo-footer.svg';
import { Map } from 'components';
import { GetApp, Text } from 'components';
import { contacts, media } from './enums';
import links from 'common/enums/links';

import {
  FContainer,
  Image,
  Link,
  MediaItem,
  MediaImage,
  Media,
  ContainerLeft,
  ContainerRight,
  Title,
  Address,
  MapContainer,
  Copy,
  LinkContainer,
  LinksGrid,
  Buttons,
  Section,
  footerTextStyle,
  TextWrapper
} from './styled';

const Footer = () => (
  <Section id="footer">
    <FContainer>
      <ContainerLeft>
        <Image src={imgLogoFooter} />
        <TextWrapper>
          <Text text2 style={footerTextStyle}>
            America's most loved home services company
          </Text>
        </TextWrapper>
        <LinkContainer>
          <div>
            <LinksGrid>
              {Object.values(links)
                .filter(item => item.IS_FOOTER_NAVIGATION)
                .map(item => (
                  <Link key={item.TYPE} href={item.URL} {...(item.EXTERNAL_LINK && {
                    target: '_blank',
                    rel: 'noopener noreferrer'
                  })}>
                    {item.TITLE}
                  </Link>
                ))}
            </LinksGrid>
          </div>
          <Buttons>
            <GetApp
              hasAllLinks={window.innerWidth >= 1024}
              useDirectLinks={window.innerWidth >= 1024}
              light
              entryPoint={analyticsPageTypes.FOOTER}
            />
          </Buttons>
        </LinkContainer>
        <Media>
          {Object.values(media).map(item => (
            <MediaItem key={item.TYPE} href={item.URL} target="_blank">
              <MediaImage src={item.IMG} alt={item.ALT}></MediaImage>
            </MediaItem>
          ))}
        </Media>
      </ContainerLeft>
      <ContainerRight>
        <Title>Contact Us</Title>
        <Address>
          {Object.values(contacts).map(item => (
            <li key={item.TYPE}>{item.VALUE}</li>
          ))}
        </Address>
        <MapContainer>
          <Map />
        </MapContainer>
        <Copy>
          Copyright © Halos Inc. (dba Dobby) - {new Date().getFullYear()}
        </Copy>
      </ContainerRight>
    </FContainer>
  </Section>
);

export default Footer;
