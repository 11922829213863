import styled from 'styled-components/macro';
import styles from 'common/enums/styles';
import { device } from 'common/enums/devices';

export const Container = styled.div`
  color: ${styles.colors.DARK_BLUE};
  display: grid;
  justify-content: center;
  @media screen and ${device.LAPTOP} {
    grid-auto-flow: column;
    gap: ${({ isTop }) => (isTop ? '4.8%' : '0')};
    justify-content: space-between;
    margin: auto;
    max-width: 1440px;
    width: 100%;
    padding: ${({ isTop }) => (isTop ? '0 9% 0 13%' : '115px 7% 0 9.9%')};
  }
  @media screen and (min-width: 1260px) {
    gap: 0;
  }
  @media screen and ${device.LAPTOP_L} {
    padding: ${({ isTop }) =>
      isTop ? '0 127px 0 190px' : '128px 190px 0 127px'};
  }
`;

export const Wrapper = styled.section`
  padding: ${({ isTop }) => (isTop ? '27px' : '37px')} 31px 0 31px;

  background: ${({ background }) =>
    background ? styles.colors.NUDE : styles.colors.WHITE_NEW};
  @media screen and ${device.LAPTOP} {
    padding: 0;
  }
`;
export const Title = styled.h1`
  margin: 0;
  letter-spacing: 0;
`;
export const Text = styled.p`
  font-weight: 600;
  font-size: 18px;
  line-height: 1.2;
  margin-top: 16px;
  @media screen and ${device.LAPTOP} {
    font-weight: 600;
    font-size: 24px;
    margin-top: 27px;
    max-width: 473px;
  }
`;
export const TextContainer = styled.div`
  @media screen and ${device.LAPTOP} {
    padding: ${({ isTop }) => (isTop ? '108px' : '132px')} 0 163px 5%;
    grid-column: ${({ isTop }) => (isTop ? '1/2' : '2/2')};
    width: 100%;
    max-width: 557px;
  }
`;
export const Form = styled.div`
  margin-top: 48px;
  display: grid;
  justify-items: center;
  gap: 19px;
  @media screen and ${device.LAPTOP} {
    justify-items: baseline;
  }
  @media screen and (min-width: 1260px) {
    grid-auto-flow: column;
    gap: 43px;
  }
`;

export const InputWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

export const Input = styled.input`
  border: none;
  border-radius: 8px;
  color: ${styles.colors.DARK_BLUE};
  text-shadow: ${styles.boxShadows.WHITE};
  font-size: 16px;
  width: 240px;
  height: 48px;
  padding: 0 48px 0 72px;
  font-weight: 400;
  width: 277px;
  background-color: ${({ isTop }) =>
    isTop ? styles.colors.WHITE_NEW : styles.colors.NUDE};
  font-family: 'HKGrotesk', sans-serif !important;
  @media screen and ${device.LAPTOP} {
    width: 293px;
  }
`;

export const InputPrefix = styled.div`
  font-size: 16px;
  font-family: 'HKGrotesk', sans-serif !important;
  font-weight: 400;
  color: ${styles.colors.DARK_BLUE};
  position: absolute;
  left: ${({ left }) => left}px;
`;

export const ButtonWrap = styled.div`
  width: 240px;
`;
export const ImageContainer = styled.div`
  position: relative;
  min-height: 271px;
  margin-top: 49.8px;
  overflow: hidden;
  width: 100%;
  @media screen and ${device.LAPTOP} {
    margin-top: 0;
    width: 600px;
    max-width: 455px;
  }
`;

export const Image = styled.img`
  position: absolute;
  top: 0;
  height: auto;
  width: 100%;
  max-width: 184px;
  left: 50%;
  transform: translateX(-50%);
  @media screen and ${device.LAPTOP} {
    max-width: 455px;
  }
`;
