import styled, { css } from 'styled-components';
import { device } from 'common/enums/devices';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  @media screen and ${device.LAPTOP} {
    justify-content: flex-start;
    flex-direction: row;
    text-align: left;
    padding: 0 104px;
  }
`;

export const ImageContainer = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  min-width: 180px;
  align-items: center;

  @media screen and ${device.LAPTOP} {
    margin: 0 26px 0px 16px;
    min-width: 200px;
  }
`;

export const ProImage = styled.img`
  height: 180px;
  width: 180px;
  border-radius: 50%;
  margin-bottom: 12px;
  @media screen and ${device.LAPTOP} {
    height: 200px;
    width: 200px;
    margin-bottom: 7px;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  align-self: center;
  max-width: 500px;
  padding: 0 32px 0;

  @media screen and ${device.LAPTOP} {
    margin-top: 9px;
    text-align: left;
    padding: 0;
    align-items: flex-start;
    align-self: flex-start;
    max-width: unset;
  }
`;

export const ReviewText = styled.div`
  white-space: pre-wrap;
  text-align: left;
  & p {
    margin: ${({ type }) => (type === 'review_5' ? '0 10px' : '0 13px')};
    line-height: 1.2;
  }

  @media screen and ${device.LAPTOP} {
    text-align: left;
    & p {
      margin: 0 28px;
    }
  }
`;

export const QuoteImage = styled.img`
  height: 26px;
  width: auto;
  align-self: flex-start;
  margin: 0 0 13px;
  transform: translate(-16px, 10px);
  ${({ reverse }) =>
    reverse &&
    `    
    transform: scale(-1, -1) translate(-16px, 5px);
    align-self: flex-end;
    margin: 0;
  `}
  
  @media screen and ${device.LAPTOP} {
    height: 24px;
    margin: 0 0 24px;
    transform: translate(0, 0);
    ${({ reverse }) => reverse && `margin: 0 0 40px; transform: scale(-1, -1);`}
  }
`;

export const SignWrapper = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  align-self: center;
  & p {
    margin-bottom: 4px;
  }
`;

export const ProSignWrapper = styled.div`
  ${SignWrapper}
`;

export const ReviewerSignWrapper = styled.div`
  ${SignWrapper}
  & p {
    margin: 0 24px 4px;
    line-height: 1.2;
  }

  @media screen and ${device.LAPTOP} {
    align-items: flex-end;
    text-align: right;
    align-self: flex-end;
    & p {
      margin: 0 42px 4px 0;
    }
  }
`;
