import styled from 'styled-components/macro';
import { device } from 'common/enums/devices';
import styles from 'common/enums/styles';

export const Section = styled.section`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const Wrapper = styled.div`
  padding: 56px 16px 0;
  margin: 0 auto;
  max-width: 841px;
  @media screen and ${device.LAPTOP} {
    padding: 80px 20px 0;
  }
`;

export const Title = styled.h1`
  text-align: center;
  margin: auto;
  margin-bottom: 48px;
  color: ${styles.components.HEADER_COLOR};
  text-align: left;
  @media screen and ${device.TABLET} {
    margin-bottom: 60px;
    text-align: center;
  }
`;

export const SignPosition = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: ${styles.colors.GRAY_NEW};
`;

export const SignName = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
`;

export const TextWrapper = styled.div`
  margin-bottom: 6px;
  margin-top: 16px;
  a {
    text-decoration: underline;
    color: ${styles.colors.DARK_BLUE};
    &:hover {
      color: ${styles.colors.YELLOW};
    }
  }
  ol {
    margin-left: 25px;
  }
  p {
    margin-bottom: 17px;
    text-align: justify;
  }
  bold {
    font-weight: bold;
  }
  li {
    margin-top: 10px;
  }
  ol {
    margin-top: 12px;
  }
  @media screen and ${device.LAPTOP} {
    margin-top: 24px;
    margin-bottom: 0;
    p {
      margin-bottom: 19px;
      text-align: left;
    }
  }
`;

export const Signs = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 40px;
  row-gap: 22px;
  justify-content: center;
`;

export const Sign = styled.div`
  display: flex;
  gap: 8px;
  justify-content: center;
`;

export const Footer = styled.div`
  margin: 32px 0 0;
  display: grid;
  grid-auto-flow: column;
  justify-content: center;
`;

export const Bold = styled.p`
  font-weight: bold;
  font-size: 20px;
  ${({ black }) => !black && `color: ${styles.colors.BLUE}`};
`;

export const Welcome = styled.div`
  margin-bottom: 12px;
`;

export const Image = styled.img`
  width: 100%;
`;

export const Container = styled.div`
  display: flex;
  border-radius: 4px;
  background: ${({ inverted }) =>
    inverted ? styles.colors.DARK_CHARCOAL : styles.gradients.WHITE};
  box-shadow: ${styles.boxShadows.SHADOW};
  margin: 56px auto 0;
  width: 100%;
  overflow: hidden;
`;
