import styled from 'styled-components/macro';
import styles from 'common/enums/styles';
import { NavLink } from 'react-router-dom';
import { device } from 'common/enums/devices';

export const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  background-color: ${styles.colors.GRAY_TRUE};
  padding: 0 8px 38px;
  align-items: flex-start;
  -ms-overflow-style: none;
  scrollbar-width: none;
  @media screen and (min-width: 550px) {
    padding: 85px 8px 58px;
    align-items: center;
  }
  @media (prefers-color-scheme: dark) {
    background: ${styles.colors.EMAIL_WRAP_DARK};
  }
`;

export const HeadBox = styled.div`
  display: grid;
  justify-items: center;
  background: ${styles.colors.NUDE};
`;
export const HeadImage = styled.img`
  width: 100%;
  height: auto;
`;

export const InnerBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0;
  max-width: 542px;
`;

export const WhiteBox = styled.div`
  margin-top: 0;
  position: relative;
  background: ${styles.colors.WHITE_NEW};
  padding: 42px 28px 39px 32px;
  width: 100%;
  @media screen and ${device.TABLET} {
    padding: 43px 64px 40px 59px;
  }
  @media (prefers-color-scheme: dark) {
    background: ${styles.colors.EMAIL_DARK};
  }
`;

export const FormHeader = styled.h4`
  margin: 0;
  padding: 0;
  font-weight: 500;
  font-size: 18px;
  line-height: 1.2;
  letter-spacing: 0.2px;
  color: ${styles.colors.DARK_BLUE};
  @media (prefers-color-scheme: dark) {
    color: ${styles.colors.WHITE_NEW};
  }
  @media screen and ${device.TABLET} {
    font-size: 24px;
  }
`;

export const FormNotation = styled.div`
  font-family: Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.5;
  color: ${styles.colors.DARK_BLUE};
  width: auto;
  margin-top: 12px;
  @media screen and ${device.TABLET} {
    width: 100%;
    margin-top: 11px;
  }
  @media (prefers-color-scheme: dark) {
    color: ${styles.colors.WHITE_NEW};
  }

  a {
    @media (prefers-color-scheme: dark) {
      color: ${styles.colors.EMAIL_LINK};
    }
  }
`;

export const FormSubheader = styled.h6`
  font-family: Helvetica;
  font-weight: 700;
  font-size: 14px;
  line-height: 1.2;
  color: ${styles.colors.DARK_BLUE};
  max-width: 391px;
  margin-top: 39px;
  @media screen and ${device.TABLET} {
    line-height: 1.5;
  }
  @media (prefers-color-scheme: dark) {
    color: ${styles.colors.WHITE_NEW};
  }
`;

export const InputWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  ${({ marginBottom }) => marginBottom && `margin-bottom: ${marginBottom}px`};
`;

export const OtherReasonTextareaWrapper = styled.div`
  padding-left: 33px;
  width: 100%;
`;

export const OtherReasonTextarea = styled.textarea`
  font-family: Helvetica;
  background: ${styles.colors.GREY_FIELD};
  padding: 9px 10px;
  font-size: 14px;
  line-height: 1.5;
  border-radius: 8px;
  width: 100%;
  max-width: 383px;
  height: 80px;
  border: none;
  resize: none;
  ::placeholder {
    color: ${styles.colors.GREY_FIELD_PLACEHOLDER};
  }
  @media screen and ${device.TABLET} {
    height: 128px;
  }
  @media (prefers-color-scheme: dark) {
    background: ${styles.colors.EMAIL_WRAP_DARK};
    color: ${styles.colors.WHITE_NEW};
  }
`;

export const SubmitButton = styled.button`
  cursor: pointer;
  box-shadow: none;
  border: 0;
  border-radius: 8px;
  width: 200px;
  min-height: 40px;
  padding: 0 45px;
  font-weight: 700;
  line-height: 1.5;
  color: ${styles.colors.WHITE};
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${styles.colors.GREEN};
  font-size: 22px;
  margin: 35px auto 10px;
  font-weight: normal;
  height: 40px;
  ${({ disabled }) =>
    disabled &&
    `
    opacity: 0.5;
    cursor: default;
  `}
  @media screen and ${device.TABLET} {
    margin: 0;
    margin-top: 27px;
    width: 160px;
    padding: 0 49px;
    font-size: 18px;
    height: 48px;
  }
`;

export const ResultBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: baseline;
  flex-direction: column;
`;

export const Link = styled(NavLink)`
  position: absolute;
  bottom: -26px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 12px;
  color: ${styles.colors.DARK_BLUE};
  text-transform: uppercase;
  text-decoration: none;
  @media screen and ${device.TABLET} {
    bottom: -38px;
  }
  @media (prefers-color-scheme: dark) {
    color: ${styles.colors.WHITE_NEW};
  }
`;

export const InputGroup = styled.div`
  margin-top: 17px;
  label > div {
    background-color: ${styles.colors.WHITE_NEW};
    border: 1px solid ${styles.colors.GREY_FIELD_PLACEHOLDER};
  }
  p {
    @media (prefers-color-scheme: dark) {
      color: ${styles.colors.WHITE_NEW};
    }
  }
`;
