import React, { useEffect } from 'react';
import { LoadingSpinner } from '@sourcegraph/react-loading-spinner';
import links from 'common/constants/links';
import {
  getSocialMediaParams,
  trackDownloadAppLinkUse,
  prepareAppLink
} from './helpers/link';

const dobbyAppLink = () => {
  if (navigator.userAgent.includes('Android')) {
    /* if we're on Android */
    return links.MARKET_HO_APP_ANDROID;
  }
  return links.MARKET_HO_APP_IOS;
};

const DownloadAppRedirect = ({ location: { search, pathname } }) => {
  useEffect(() => {
    if (!search && pathname === '/app') {
      const link = dobbyAppLink();
      window.location.assign(link);
      return;
    }

    const socialMediaParams = getSocialMediaParams({ search, pathname });

    trackDownloadAppLinkUse({
      pathname,
      socialMediaParams
    });

    const linkUrl = prepareAppLink({ pathname, search, socialMediaParams });
    const encodedLinkUrl = encodeURIComponent(linkUrl);
    const dynamicLink = `https://hellodobby.page.link?link=${encodedLinkUrl}&${links.DYNAMIC_LINK_IOS_MARKET}&${links.DYNAMIC_LINK_ANDROID_MARKET}&ofl=${links.MARKET_HO_APP_IOS}`;

    window.location.assign(dynamicLink);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <LoadingSpinner />;
};

export default DownloadAppRedirect;
