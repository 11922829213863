import React from 'react';
import PropTypes from 'prop-types';
import StyledInput from './styled';

const Input = ({
  type,
  placeholder,
  backgroundNude,
  value,
  onUpdate,
  errorBorders,
  onBlur,
  smallBottomMargin,
  lineHeight,
  fullWidth,
  ...rest
}) => {
  return (
    <StyledInput
      backgroundNude={backgroundNude}
      errorBorders={errorBorders}
      type={type}
      onBlur={onBlur}
      placeholder={placeholder}
      value={value}
      smallBottomMargin={smallBottomMargin}
      lineHeight={lineHeight}
      fullWidth={fullWidth}
      {...rest}
    />
  );
};

Input.propTypes = {
  type: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onUpdate: PropTypes.func // dirty fix
};

Input.defaultProps = {
  type: 'text',
  placeholder: '',
  value: ''
};

export default Input;
