import React from 'react';
import {
  Quote,
  QuoteSymbol,
  QuotedText,
  QuoteAuthor,
  QuoteAuthorText
} from './styled';

const QuoteText = ({ text, author, position }) => (
  <>
    <Quote>
      <QuoteSymbol>“</QuoteSymbol>
      <QuotedText>{text}</QuotedText>
      <QuoteSymbol>“</QuoteSymbol>
    </Quote>
    <QuoteAuthor>
      <QuoteSymbol></QuoteSymbol>
      <QuoteAuthorText>
        {author}
        <br />
        <span>{position}</span>
      </QuoteAuthorText>
      <QuoteSymbol></QuoteSymbol>
    </QuoteAuthor>
  </>
);

export default QuoteText;
