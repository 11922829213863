import React from 'react';
import { useHistory } from 'react-router-dom';

import {
  Wrapper,
  InnerBox,
  ImageWrapper,
  TopHeader,
  SubHeader
} from './styled';

import emailVerificationIcon from 'static/images/icons/email-verification-icon.svg';

const SuccessEmailVerification = () => {
  const {
    location: { search }
  } = useHistory();
  const params = new URLSearchParams(search);
  return (
    <Wrapper>
      <InnerBox>
        <ImageWrapper>
          <img src={emailVerificationIcon} alt="Email Verification Icon" />
        </ImageWrapper>
        <TopHeader>
          Here we go!{' '}
          <span role="img" aria-label="congratulations">
            🎉
          </span>
        </TopHeader>
        <SubHeader>
          <span>{params.get('email')}</span> was successfully verified!
        </SubHeader>
      </InnerBox>
    </Wrapper>
  );
};

export default SuccessEmailVerification;
