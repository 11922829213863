import React, { useEffect } from 'react';

import { connect } from 'react-redux';

import { NUMBER_OF_JOBS } from 'common/constants/content';
import {
  selectReviews,
  selectReviewsCount,
  selectReviewsPage,
  selectReviewsLoading
} from 'store/reducers/review';
import { getReviews } from 'store/actions/review';
import starImg from 'static/images/icons/star.svg';
import arrowIcon from 'static/images/icons/arrow-down.svg';
import { formatDate } from './helpers';

import {
  Button,
  Icon,
  Title,
  White,
  Table,
  TableContainer,
  Thead,
  HRow,
  Row,
  Cell,
  HCell,
  Star,
  Stars,
  Wrapper
} from './styled';

const ListTimeline = ({ page, reviews, getReviews, count, loading }) => {
  useEffect(() => {
    getReviews({ page: 1 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLoadMore = () => {
    getReviews({ page: page + 1 });
  };

  return (
    <Wrapper>
      <Title>
        98% <White>5-star ratings on {NUMBER_OF_JOBS} jobs</White>
      </Title>
      <TableContainer>
        <Table>
          <Thead>
            <HRow>
              <HCell>Pro Name</HCell>
              <HCell>Category</HCell>
              <HCell>Review</HCell>
              <HCell>Rating</HCell>
              <HCell>Date</HCell>
            </HRow>
          </Thead>
          <tbody>
            {reviews.length
              ? reviews.map(item => (
                  <Row key={item?.id}>
                    <Cell>{item?.provider.full_name}</Cell>
                    <Cell>{item?.service_request?.job_category_name}</Cell>
                    <Cell>{item?.comment}</Cell>
                    <Cell>
                      {item?.rating ? (
                        <Stars>
                          {Array(item?.rating)
                            .fill('0')
                            .map((item, index) => (
                              <Star key={index} src={starImg} />
                            ))}
                        </Stars>
                      ) : null}
                    </Cell>
                    <Cell>{formatDate(item?.date_created)}</Cell>
                  </Row>
                ))
              : null}
          </tbody>
        </Table>
        {reviews.length < count && (
          <Button onClick={handleLoadMore} disabled={loading}>
            Load more <Icon src={arrowIcon} />
          </Button>
        )}
      </TableContainer>
    </Wrapper>
  );
};

const mapDispatchToProps = {
  getReviews
};

const mapStateToProps = state => ({
  page: selectReviewsPage(state),
  count: selectReviewsCount(state),
  reviews: selectReviews(state),
  loading: selectReviewsLoading(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(ListTimeline);
