import { call, takeLatest, put } from 'redux-saga/effects';
import api_constants from 'common/enums/api';
import actionTypes from 'common/enums/actionTypes';
import Api from 'services/ApiService';

function* sendCV(action) {
  const { payload } = action;
  try {
    const response = yield call(Api.apiPost, `${api_constants.CV}`, payload);
    if (response && !response.error) {
      yield put({
        type: actionTypes.SEND_CV_SUCCESS
      });
    } else {
      yield put({
        type: actionTypes.SEND_CV_ERROR,
        payload: response.message
      });
    }
  } catch (err) {
    yield put({
      type: actionTypes.SEND_CV_ERROR
    });
  }
}

export default function* watchSendCV() {
  yield takeLatest(actionTypes.SEND_CV_FETCH, sendCV);
}
