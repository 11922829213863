import React, { useState } from 'react';
import NumberFormat from 'react-number-format';
import { useHistory } from 'react-router-dom';

import { Icon, Button } from 'components';
import {
  PhoneChip,
  PhoneChipsWrapper,
  CloseIconWrapper,
  PhoneChipInput,
  PhoneChipError,
  PhoneChipInputWrapper,
  PhoneChipInputPrefix,
  FlexRow
} from './styled';
import styles from 'common/enums/styles';
import { validators } from 'common/helpers';

const CustomPhoneChipInput = props => {
  return <PhoneChipInput {...props} />;
};

const PhoneNumberChips = ({
  submitInviteForm,
  loading,
  phoneNumberCode,
  phoneNumbers,
  setPhoneNumbers
}) => {
  const [value, setValue] = useState('');
  const [error, setError] = useState(null);

  const handleChange = event => {
    const phoneNumber = event.target.value.trim();
    if (phoneNumber.length === 12) {
      if (phoneNumber && isValid(phoneNumberCode + phoneNumber)) {
        setValue('');
        setPhoneNumbers([...phoneNumbers, phoneNumberCode + phoneNumber]);
      } else {
        setValue(phoneNumber);
      }
    } else {
      setValue(phoneNumber);
      setError(null);
    }
  };
  const handleKeyDown = event => {
    if (['Enter', ',', ' '].includes(event.key)) {
      event.preventDefault();
      const phoneNumber = value.trim();
      if (phoneNumber && isValid(phoneNumberCode + phoneNumber)) {
        setValue('');
        setPhoneNumbers([...phoneNumbers, phoneNumberCode + phoneNumber]);
      }
    } else if (
      ['Backspace'].includes(event.key) &&
      phoneNumbers.length &&
      !value
    ) {
      setPhoneNumbers(phoneNumbers.splice(0, phoneNumbers.length - 1));
    }
  };

  const handleDelete = toBeRemoved => {
    setPhoneNumbers(
      phoneNumbers.filter(phoneNumber => phoneNumber !== toBeRemoved)
    );
  };

  const handleOnBlur = event => {
    const phoneNumber = event.target.value.trim();
    if (phoneNumber && isValid(phoneNumberCode + phoneNumber)) {
      setValue('');
      setPhoneNumbers([...phoneNumbers, phoneNumberCode + phoneNumber]);
    }
  };

  const isPhoneNumber = phoneNumber => {
    return validators.phoneNumber(phoneNumber, phoneNumberCode);
  };

  const isInList = phoneNumber => {
    return phoneNumbers.includes(phoneNumber);
  };

  const isValid = phoneNumber => {
    let error = null;
    if (!isPhoneNumber(phoneNumber)) {
      console.log(phoneNumber);
      error = `Please enter correct phone number.`;
    }
    if (isInList(phoneNumber)) {
      error = `Current phone number has already been added.`;
    }
    if (error) {
      setError(error);
      return false;
    }
    return true;
  };

  const onSubmit = () => {
    submitInviteForm(phoneNumbers);
  };
  const isPhoneNumberInputActive = phoneNumbers.length === 0 || value;
  const history = useHistory();
  const isUA = history.location.hash === '#ua';

  return (
    <>
      <PhoneChipsWrapper isUA={isUA}>
        {phoneNumbers.map(phoneNumber => (
          <PhoneChip key={phoneNumber}>
            <span>{phoneNumber}</span>
            <CloseIconWrapper
              disabled={loading}
              onClick={() => handleDelete(phoneNumber)}>
              <Icon
                name="close2"
                color={styles.colors.GRAY_NEW}
                backgroundColor={styles.colors.NUDE}
                size={10}
                backgroundSize={16}
              />
            </CloseIconWrapper>
          </PhoneChip>
        ))}
        <PhoneChipInputWrapper isHided={phoneNumbers.length >= 5} isUA={isUA}>
          {isPhoneNumberInputActive ? (
            <PhoneChipInputPrefix isValid={!error}>
              {phoneNumberCode}
            </PhoneChipInputPrefix>
          ) : null}
          <NumberFormat
            type="tel"
            format="###.###.####"
            customInput={CustomPhoneChipInput}
            placeholder={
              isPhoneNumberInputActive ? '202.460.7470' : ' Add more'
            }
            padding="20px 38px 18px"
            lineHeight={1}
            errorBorders={Boolean(error)}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            onBlur={handleOnBlur}
            value={value}
            isValid={!error}
            isUA={phoneNumberCode === '+38.'}
            isAddMore={!isPhoneNumberInputActive}
            // TODO: disable when loading
            // disabled={}
          />
        </PhoneChipInputWrapper>
      </PhoneChipsWrapper>
      <FlexRow>
        <Button
          disabled={phoneNumbers.length === 0 || loading}
          loading={loading}
          onClick={onSubmit}
          title="Invite"
        />
        {error && <PhoneChipError>{error}</PhoneChipError>}
      </FlexRow>
    </>
  );
};

export default PhoneNumberChips;
