import styled from 'styled-components';
import styles from 'common/enums/styles';
import { device } from 'common/enums/devices';

export const Container = styled.div`
  display: grid;
  grid-auto-flow: row;
  justify-content: center;
  justify-items: center;
  gap: 20px;
  margin-top: 16px;
  @media screen and ${device.LAPTOP} {
    grid-auto-flow: column;
    margin-top: 71px;
  }
`;
export const InputWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;
export const InputStyles = `
  width: 100%;
  max-width: 300px;
  padding: 0 48px 0 72px;
  @media screen and ${device.LAPTOP} {
    max-width: 240px;
  }
`;
export const Input = styled.input`
  border: none;
  border-radius: 8px;
  background-color: ${styles.colors.NUDE};
  color: ${styles.colors.DARK_BLUE};
  text-shadow: ${styles.boxShadows.WHITE};
  font-size: 16px;
  max-width: 279px;
  height: 48px;
  padding: 0 48px 0 72px;
  font-weight: 400;
  @media screen and ${device.LAPTOP} {
    max-width: 280px;
  }
`;

export const CloseButton = styled.div`
  position: absolute;
  top: 18px;
  right: 26px;
  width: 40px;
  height: 40px;
  background-color: ${styles.colors.NUDE};
  display: grid;
  justify-content: center;
  align-content: center;
  border-radius: 50%;
  z-index: 1;
  cursor: pointer;
`;

export const CloseButtonIcon = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 19px;
  width: auto;
`;

export const InputPrefix = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: ${styles.colors.DARK_BLUE};
  position: absolute;
  left: ${({ left }) => left}px;
`;
