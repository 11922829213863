import routes from 'common/enums/routes';

export const pageTypes = {
  ABOUT_US: 'ABOUT_US',
  DOBBY_CARE: 'DOBBY_CARE',
  DOBBY_HO: 'DOBBY_HO',
  ASK_US_ANYTHING: 'ASK_US_ANYTHING',
  CATEGORIES: 'CATEGORIES',
  JOIN_US_DMV: 'JOIN_US_DMV',
  JOIN_US_CHARLOTTE: 'JOIN_US_CHARLOTTE',
  PROVIDERS: 'PROVIDERS',
  PARTNERS: 'PARTNERS',
  HOME: 'HOME',
  LOVE_OUR_PLANET: 'LOVE_OUR_PLANET',
  MEET_DOBBY: 'MEET_DOBBY',
  PRESS_RELEASE: 'PRESS_RELEASE',
  PRIVACY: 'PRIVACY',
  REVIEWS: 'REVIEWS'
};

export const analyticsPageTypes = {
  ABOUT_US: 'About Us',
  ATTIC: 'Attic',
  CHECK_THE_PRICE: 'Check The Price',
  ASK_US_ANYTHING: 'Home Explained',
  BECOME_PARTNER: 'Become a Partner',
  BUILD_TOGETHER: 'Build Together',
  CATEGORIES: 'Categories',
  DOBBY_HO: 'Are you a Dobby Homeowner?',
  FAQ: 'FAQ',
  FOOTER: 'Footer',
  GET_STARTED: 'Get Started',
  GROW_TOGETHER: 'Grow Together',
  HOME: 'Home',
  JOIN_US_CHARLOTTE: 'Join Us Charlotte',
  JOIN_US_DMV: 'Join Us DMV',
  LOVE_OUR_PLANET: 'Love Our Planet',
  MOBILE_MENU: 'Mobile menu',
  PLANT_ONE_TREE: 'PlantOneTree',
  SERVICE_PROVIDERS: 'Service Providers',
  DESKTOP_HEADER: 'Header button',
  REVIEWS: 'Reviews',
  DOWNLOAD_APP: 'Download Dobby App',
  DOWNLOAD_PRO_APP: 'Download Dobby Pro App',
  IN_APP_REDIRECT: 'Dobby App redirect',
  PLANNING_MODE: 'Planning Mode'
};

export const captchaActions = {
  ASK_US_ANYTHING: {
    SUBMIT: 'home_explained/submit'
  },
  SEND_ME_THE_LINK: {
    SUBMIT: 'send_me_the_link/submit'
  }
};

const jobConfirmation = {
  TYPE: 'SUCCESSFUL_BOOKING',
  PATHNAME: routes.SUCCESSFUL_BOOKING,
  TITLE: `Confirmed — we'll be in touch soon!`,
  DESCRIPTION_GENERATOR: ({ category_name = 'handyman' }) =>
    `Your ${category_name} job has been created in the Dobby app. We’ll be in touch to confirm details and schedule the visit via Dobby app.`
};

export const confirmationScreenTypes = {
  HANDYMAN_CONFIRMED: {
    ...jobConfirmation,
    PATHNAME: routes.HANDYMAN_CONFIRMED
  },
  SUCCESSFUL_BOOKING: jobConfirmation
};
