import { call, takeLatest, put } from 'redux-saga/effects';
import api_constants from 'common/enums/api';
import actionTypes from 'common/enums/actionTypes';
import Api from 'services/ApiService';

function* getReviews({ payload: { page = 1 } = {} }) {
  try {
    const response = yield call(
      Api.apiGet,
      `${api_constants.REVIEW}?page=${page}&limit=20`
    );

    if (!response?.results) {
      throw new Error();
    }

    yield put({
      type: actionTypes.GET_REVIEWS_SUCCESS,
      payload: {
        ...response,
        page
      }
    });
  } catch (err) {
    yield put({
      type: actionTypes.GET_REVIEWS_ERROR,
      payload: 'Something went wrong. Try again later'
    });
  }
}

export default function* watchGetReviews() {
  yield takeLatest(actionTypes.GET_REVIEWS_FETCH, getReviews);
}
