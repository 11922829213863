import styled from 'styled-components/macro';
import styles from 'common/enums/styles';
import { device } from 'common/enums/devices';

export const Section = styled.section`
  @media screen and ${device.LAPTOP} {
    padding: 0 0 20px 0;
    background-color: ${styles.colors.NUDE};
  }
`;
export const HCContainer = styled.section`
  width: auto;
  padding: 55px 0;
  background: linear-gradient(
      0deg,
      rgba(49, 57, 48, 0.7),
      rgba(49, 57, 48, -0.7)
    ),
    url(${({ src }) => src});
  background-size: cover;
  background-position: center;
  @media screen and ${device.LAPTOP} {
    padding: 80px 0;
  }
`;
export const Container = styled.div`
  margin: auto;
  width: 90%;
  display: grid;
  max-width: 1044px;

  @media screen and ${device.LAPTOP} {
    width: 94%;
  }
`;

export const TextContainer = styled.div`
  display: grid;
  gap: 19px;
  margin-top: 32px;
  white-space: pre-wrap;
  text-align: justify;
  @media screen and ${device.LAPTOP} {
    width: 1044px;
    gap: 28px;
    margin-top: 48px;
    text-align: left;
  }
`;

export const Link = styled.a`
  color: ${styles.colors.WHITE_NEW};
  text-decoration: underline;
  &:hover {
    color: ${styles.colors.WHITE_NEW};
  }
`;

export const Image = styled.img`
  height: 36.8px;
  width: auto;
  @media screen and ${device.LAPTOP} {
    height: 38.3px;
  }
`;

export const ImageContainer = styled.div`
  display: grid;
  gap: 13px;
  margin-top: 32px;
  grid-auto-flow: row;
  @media screen and ${device.LAPTOP} {
    grid-auto-flow: column;
    justify-content: flex-start;
    gap: 69px;
    margin-top: 72px;
  }
`;

export const Title = styled.div`
  font-weight: 700;
  font-size: 30px;
  line-height: 1.3;
  letter-spacing: 1px;
  white-space: pre-line;
  text-align: left;
  margin-left: 0;
  width: 100%;
  text-align: center;
  color: ${styles.colors.WHITE_NEW};
  @media screen and ${device.LAPTOP} {
    margin-left: 0;
    font-size: 50px;
    line-height: 1.2;
  }
  br {
    display: none;
    @media screen and ${device.LAPTOP} {
      display: block;
    }
  }
`;

export const TextWithLink = styled.div`
  display: inline-block;
  white-space: pre-wrap;
  text-align: left;
  width: 100%;
  color: ${styles.colors.WHITE};
  font-weight: 400;
  font-size: 18px;

  @media screen and ${device.LAPTOP} {
    font-size: 24px;
  }
`;

export const textStyle = {
  color: styles.colors.WHITE,
  textAlign: 'left'
};
