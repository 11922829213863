import React from 'react';
import { AppStoreSVG } from './styled';
import styles from 'common/enums/styles';

export const AppStoreIcon = ({
  highlightColor = styles.colors.YELLOW,
  backgroundColor,
  color = styles.colors.WHITE,
  modal = false
}) => (
  <AppStoreSVG
    highlightColor={highlightColor}
    backgroundColor={backgroundColor}
    modal={modal}
    viewBox={modal ? '0 0 150 50' : '0 0 170 50'}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M163.704 50H6.2963C2.83333 50 0 47.1875 0 43.75V6.25C0 2.8125 2.83333 0 6.2963 0H163.704C167.167 0 170 2.8125 170 6.25V43.75C170 47.1875 167.167 50 163.704 50Z"
      fill={backgroundColor}
    />
    <path
      d="M30.9195 24.3764C30.9333 23.3331 31.2168 22.3101 31.7436 21.4026C32.2704 20.4952 33.0234 19.7328 33.9326 19.1863C33.355 18.3799 32.593 17.7162 31.7072 17.248C30.8214 16.7798 29.8361 16.52 28.8296 16.4892C26.6825 16.2689 24.6011 17.7453 23.507 17.7453C22.3917 17.7453 20.7072 16.5111 18.8932 16.5476C17.7199 16.5846 16.5764 16.9182 15.5742 17.5158C14.5719 18.1133 13.7451 18.9545 13.1742 19.9574C10.7015 24.1429 12.5459 30.2942 14.9146 33.6775C16.0998 35.3342 17.4848 37.1848 19.2971 37.1191C21.0706 37.0472 21.733 36.0135 23.8737 36.0135C25.9945 36.0135 26.616 37.1191 28.4651 37.0774C30.3681 37.0472 31.5671 35.4134 32.7106 33.741C33.5621 32.5605 34.2173 31.2559 34.652 29.8754C33.5464 29.4182 32.6029 28.653 31.9392 27.6751C31.2755 26.6972 30.9208 25.55 30.9195 24.3764Z"
      fill={color}
    />
    <path
      d="M27.4269 14.2637C28.4645 13.0459 28.9757 11.4807 28.8519 9.90039C27.2667 10.0632 25.8024 10.8039 24.7508 11.9749C24.2366 12.547 23.8428 13.2125 23.5919 13.9335C23.341 14.6545 23.2379 15.4167 23.2885 16.1766C24.0814 16.1846 24.8658 16.0166 25.5826 15.6852C26.2995 15.3539 26.9301 14.8678 27.4269 14.2637Z"
      fill={color}
    />
    <path
      d="M53.3374 32.9246H47.2853L45.832 37.1202H43.2686L49.0009 21.5977H51.6642L57.3966 37.1202H54.7895L53.3374 32.9246ZM47.9121 30.9886H52.7094L50.3445 24.1795H50.2783L47.9121 30.9886Z"
      fill={color}
    />
    <path
      d="M69.7766 31.4623C69.7766 34.9792 67.8512 37.2387 64.9457 37.2387C64.2097 37.2763 63.4777 37.1106 62.8336 36.7604C62.1894 36.4102 61.6592 35.8898 61.3035 35.2587H61.2485V40.8641H58.8724V25.8031H61.1724V27.6854H61.2161C61.5881 27.0574 62.1273 26.5396 62.7764 26.1873C63.4254 25.835 64.16 25.6613 64.902 25.6847C67.84 25.6847 69.7766 27.9552 69.7766 31.4623ZM67.3343 31.4623C67.3343 29.1711 66.1232 27.6647 64.2752 27.6647C62.4597 27.6647 61.2386 29.2028 61.2386 31.4623C61.2386 33.7426 62.4597 35.2697 64.2752 35.2697C66.1232 35.2697 67.3343 33.7743 67.3343 31.4623Z"
      fill={color}
    />
    <path
      d="M82.5174 31.4623C82.5174 34.9791 80.5921 37.2387 77.6866 37.2387C76.9506 37.2763 76.2186 37.1105 75.5744 36.7604C74.9303 36.4102 74.4 35.8898 74.0444 35.2587H73.9894V40.8641H71.6133V25.8031H73.9132V27.6854H73.9569C74.3289 27.0573 74.8681 26.5396 75.5172 26.1873C76.1662 25.835 76.9008 25.6613 77.6428 25.6847C80.5809 25.6847 82.5174 27.9552 82.5174 31.4623ZM80.0752 31.4623C80.0752 29.171 78.864 27.6647 77.0161 27.6647C75.2006 27.6647 73.9794 29.2028 73.9794 31.4623C73.9794 33.7426 75.2006 35.2697 77.0161 35.2697C78.864 35.2697 80.0752 33.7743 80.0752 31.4623H80.0752Z"
      fill={color}
    />
    <path
      d="M90.9381 32.7952C91.1142 34.3345 92.6438 35.3452 94.7339 35.3452C96.7367 35.3452 98.1776 34.3345 98.1776 32.9465C98.1776 31.7417 97.3085 31.0203 95.2508 30.5259L93.1931 30.0413C90.2776 29.3528 88.9241 28.0198 88.9241 25.8567C88.9241 23.1785 91.3115 21.3389 94.7015 21.3389C98.0564 21.3389 100.356 23.1785 100.434 25.8567H98.0352C97.8916 24.3076 96.5818 23.3726 94.6677 23.3726C92.7536 23.3726 91.4438 24.3186 91.4438 25.6956C91.4438 26.793 92.2804 27.4387 94.3268 27.9331L96.0761 28.353C99.3338 29.1062 100.687 30.3855 100.687 32.656C100.687 35.56 98.3211 37.3789 94.5578 37.3789C91.0367 37.3789 88.6594 35.6027 88.5058 32.7951L90.9381 32.7952Z"
      fill={color}
    />
    <path
      d="M105.815 23.125V25.8032H108.017V27.6428H105.815V33.8818C105.815 34.8511 106.256 35.3027 107.224 35.3027C107.485 35.2983 107.746 35.2803 108.005 35.249V37.0776C107.57 37.1571 107.128 37.1931 106.686 37.185C104.342 37.185 103.428 36.3244 103.428 34.1296V27.6428H101.745V25.8032H103.428V23.125H105.815Z"
      fill={color}
    />
    <path
      d="M109.292 31.4624C109.292 27.9016 111.437 25.6641 114.782 25.6641C118.138 25.6641 120.273 27.9016 120.273 31.4624C120.273 35.033 118.149 37.2607 114.782 37.2607C111.415 37.2607 109.292 35.0329 109.292 31.4624ZM117.852 31.4624C117.852 29.0198 116.707 27.5781 114.782 27.5781C112.856 27.5781 111.713 29.0308 111.713 31.4624C111.713 33.9148 112.856 35.3455 114.782 35.3455C116.707 35.3455 117.852 33.9148 117.852 31.4624H117.852Z"
      fill={color}
    />
    <path
      d="M122.232 25.803H124.498V27.7293H124.553C124.707 27.1277 125.067 26.596 125.574 26.2236C126.081 25.8513 126.704 25.6609 127.338 25.6846C127.612 25.6836 127.885 25.7127 128.152 25.7713V27.9441C127.806 27.8408 127.445 27.7934 127.084 27.8037C126.739 27.79 126.395 27.8495 126.075 27.9782C125.756 28.1068 125.469 28.3014 125.234 28.5488C124.998 28.7962 124.821 29.0904 124.713 29.4112C124.605 29.732 124.569 30.0719 124.608 30.4075V37.1201H122.232L122.232 25.803Z"
      fill={color}
    />
    <path
      d="M139.107 33.7964C138.787 35.8508 136.741 37.2607 134.123 37.2607C130.755 37.2607 128.665 35.0549 128.665 31.5161C128.665 27.9663 130.766 25.6641 134.023 25.6641C137.225 25.6641 139.239 27.8149 139.239 31.2463V32.0422H131.064V32.1826C131.026 32.5991 131.079 33.0187 131.22 33.4135C131.361 33.8083 131.586 34.1693 131.881 34.4725C132.175 34.7756 132.532 35.0141 132.928 35.172C133.324 35.3299 133.75 35.4036 134.178 35.3882C134.739 35.4396 135.303 35.3125 135.784 35.0257C136.266 34.7389 136.64 34.3077 136.851 33.7964L139.107 33.7964ZM131.075 30.4187H136.862C136.883 30.0442 136.825 29.6694 136.691 29.3179C136.558 28.9664 136.351 28.6457 136.084 28.376C135.818 28.1063 135.497 27.8934 135.142 27.7506C134.787 27.6079 134.406 27.5383 134.023 27.5464C133.636 27.5441 133.252 27.6168 132.894 27.7602C132.536 27.9037 132.211 28.115 131.937 28.382C131.662 28.6491 131.445 28.9665 131.297 29.3161C131.149 29.6657 131.074 30.0404 131.075 30.4187V30.4187Z"
      fill={color}
    />
    <path
      d="M47.6143 9.91276C48.1125 9.87781 48.6124 9.95139 49.0779 10.1282C49.5434 10.305 49.9631 10.5806 50.3065 10.9351C50.6499 11.2896 50.9085 11.7143 51.0637 12.1783C51.219 12.6424 51.2669 13.1344 51.2041 13.6188C51.2041 16.0016 49.8868 17.3713 47.6143 17.3713H44.8586V9.91276H47.6143ZM46.0436 16.3165H47.482C47.8379 16.3373 48.1942 16.2803 48.5247 16.1495C48.8553 16.0187 49.1519 15.8175 49.3929 15.5606C49.6339 15.3036 49.8132 14.9974 49.9179 14.6641C50.0226 14.3308 50.0499 13.9789 49.9979 13.634C50.0461 13.2905 50.016 12.9408 49.9097 12.6099C49.8035 12.2791 49.6237 11.9753 49.3832 11.7204C49.1428 11.4655 48.8476 11.2658 48.5189 11.1355C48.1902 11.0052 47.8361 10.9476 47.482 10.9668H46.0436V16.3165Z"
      fill={color}
    />
    <path
      d="M52.5426 14.5552C52.5064 14.1853 52.5497 13.8121 52.6697 13.4595C52.7897 13.107 52.9837 12.7828 53.2394 12.5078C53.495 12.2328 53.8066 12.0131 54.1542 11.8628C54.5018 11.7124 54.8777 11.6348 55.2577 11.6348C55.6378 11.6348 56.0137 11.7124 56.3613 11.8628C56.7089 12.0131 57.0205 12.2328 57.2761 12.5078C57.5318 12.7828 57.7258 13.107 57.8458 13.4595C57.9658 13.8121 58.009 14.1853 57.9728 14.5552C58.0097 14.9255 57.967 15.2992 57.8473 15.6524C57.7276 16.0056 57.5337 16.3303 57.278 16.6058C57.0223 16.8814 56.7105 17.1015 56.3625 17.2522C56.0146 17.4029 55.6383 17.4807 55.2577 17.4807C54.8772 17.4807 54.5009 17.4029 54.1529 17.2522C53.805 17.1015 53.4932 16.8814 53.2375 16.6058C52.9818 16.3303 52.7878 16.0056 52.6682 15.6524C52.5485 15.2992 52.5057 14.9255 52.5426 14.5552ZM56.8041 14.5552C56.8041 13.3352 56.2435 12.6217 55.2596 12.6217C54.272 12.6217 53.7164 13.3352 53.7164 14.5553C53.7164 15.7851 54.272 16.4931 55.2596 16.4931C56.2435 16.4931 56.8041 15.7802 56.8041 14.5552H56.8041Z"
      fill={color}
    />
    <path
      d="M65.191 17.3718H64.0124L62.8224 13.2263H62.7325L61.5476 17.3718H60.3801L58.7932 11.7432H59.9456L60.977 16.0382H61.0619L62.2456 11.7432H63.3356L64.5193 16.0382H64.6092L65.6355 11.7432H66.7718L65.191 17.3718Z"
      fill={color}
    />
    <path
      d="M68.1066 11.7431H69.2004V12.6373H69.2853C69.4293 12.3161 69.6722 12.0469 69.9802 11.8671C70.2882 11.6874 70.6458 11.606 71.0034 11.6344C71.2835 11.6139 71.5648 11.6552 71.8264 11.7553C72.088 11.8554 72.3232 12.0118 72.5145 12.2128C72.7059 12.4139 72.8486 12.6545 72.932 12.9167C73.0153 13.179 73.0373 13.4563 72.9961 13.7279V17.3717H71.8599V14.0069C71.8599 13.1024 71.4579 12.6525 70.6175 12.6525C70.4273 12.6439 70.2375 12.6755 70.0609 12.7453C69.8844 12.8151 69.7254 12.9214 69.5949 13.0568C69.4643 13.1923 69.3652 13.3538 69.3045 13.5302C69.2437 13.7066 69.2227 13.8938 69.2428 14.0789V17.3718H68.1066L68.1066 11.7431Z"
      fill={color}
    />
    <path d="M74.8066 9.5459H75.9429V17.3718H74.8066V9.5459Z" fill={color} />
    <path
      d="M77.5223 14.5553C77.4862 14.1854 77.5295 13.8122 77.6495 13.4596C77.7695 13.107 77.9636 12.7828 78.2192 12.5078C78.4749 12.2328 78.7865 12.0131 79.1341 11.8628C79.4817 11.7124 79.8577 11.6348 80.2378 11.6348C80.6179 11.6348 80.9938 11.7124 81.3414 11.8628C81.689 12.0131 82.0006 12.2328 82.2563 12.5078C82.5119 12.7828 82.706 13.107 82.826 13.4596C82.946 13.8122 82.9893 14.1854 82.9532 14.5553C82.99 14.9256 82.9472 15.2994 82.8275 15.6525C82.7078 16.0057 82.5138 16.3304 82.2581 16.606C82.0024 16.8815 81.6905 17.1016 81.3426 17.2523C80.9946 17.4029 80.6183 17.4808 80.2378 17.4808C79.8572 17.4808 79.4809 17.4029 79.1329 17.2523C78.785 17.1016 78.4731 16.8815 78.2174 16.606C77.9617 16.3304 77.7677 16.0057 77.648 15.6525C77.5283 15.2994 77.4855 14.9256 77.5223 14.5553ZM81.7838 14.5553C81.7838 13.3353 81.2232 12.6218 80.2393 12.6218C79.2517 12.6218 78.6961 13.3353 78.6961 14.5554C78.6961 15.7852 79.2517 16.4932 80.2393 16.4932C81.2232 16.4932 81.7838 15.7803 81.7838 14.5553H81.7838Z"
      fill={color}
    />
    <path
      d="M84.1494 15.7803C84.1494 14.7671 84.921 14.183 86.2907 14.1L87.8502 14.0121V13.5262C87.8502 12.9318 87.4482 12.5961 86.6715 12.5961C86.0373 12.5961 85.5978 12.8237 85.4716 13.2217H84.3716C84.4877 12.2549 85.4179 11.6348 86.724 11.6348C88.1674 11.6348 88.9815 12.3373 88.9815 13.5262V17.3721H87.8877V16.5811H87.7978C87.6153 16.8648 87.3591 17.0961 87.0553 17.2514C86.7514 17.4066 86.4109 17.4803 86.0685 17.4648C85.8268 17.4894 85.5825 17.4642 85.3514 17.3908C85.1203 17.3174 84.9074 17.1975 84.7266 17.0388C84.5458 16.8801 84.401 16.6861 84.3016 16.4693C84.2022 16.2526 84.1503 16.0178 84.1494 15.7803ZM87.8502 15.2993V14.8287L86.4443 14.9166C85.6514 14.9685 85.2918 15.2322 85.2918 15.7284C85.2918 16.235 85.7413 16.5298 86.3594 16.5298C86.5405 16.5477 86.7235 16.5298 86.8974 16.4772C87.0713 16.4246 87.2326 16.3383 87.3716 16.2235C87.5107 16.1087 87.6248 15.9677 87.707 15.809C87.7892 15.6502 87.8379 15.4769 87.8502 15.2993Z"
      fill={color}
    />
    <path
      d="M90.4749 14.5551C90.4749 12.7765 91.4101 11.6498 92.8647 11.6498C93.2245 11.6336 93.5816 11.7178 93.8943 11.8927C94.2069 12.0676 94.4622 12.3258 94.6302 12.6373H94.7152V9.5459H95.8514V17.3718H94.7626V16.4825H94.6727C94.4916 16.7919 94.2275 17.0469 93.9088 17.22C93.5901 17.3931 93.229 17.4777 92.8647 17.4646C91.4001 17.4646 90.4749 16.3379 90.4749 14.5551ZM91.6486 14.5551C91.6486 15.7489 92.2242 16.4673 93.1869 16.4673C94.1445 16.4673 94.7364 15.7386 94.7364 14.56C94.7364 13.3869 94.1383 12.6477 93.1869 12.6477C92.2304 12.6477 91.6485 13.371 91.6485 14.5551H91.6486Z"
      fill={color}
    />
    <path
      d="M100.552 14.5552C100.516 14.1853 100.559 13.8121 100.679 13.4595C100.799 13.107 100.993 12.7828 101.249 12.5078C101.505 12.2328 101.816 12.0131 102.164 11.8628C102.512 11.7124 102.887 11.6348 103.268 11.6348C103.648 11.6348 104.023 11.7124 104.371 11.8628C104.719 12.0131 105.03 12.2328 105.286 12.5078C105.542 12.7828 105.736 13.107 105.856 13.4595C105.976 13.8121 106.019 14.1853 105.983 14.5552C106.019 14.9255 105.977 15.2992 105.857 15.6524C105.737 16.0056 105.543 16.3303 105.288 16.6058C105.032 16.8814 104.72 17.1015 104.372 17.2522C104.024 17.4029 103.648 17.4807 103.268 17.4807C102.887 17.4807 102.511 17.4029 102.163 17.2522C101.815 17.1015 101.503 16.8814 101.247 16.6058C100.992 16.3303 100.798 16.0056 100.678 15.6524C100.558 15.2992 100.516 14.9255 100.552 14.5552ZM104.814 14.5552C104.814 13.3352 104.253 12.6217 103.269 12.6217C102.282 12.6217 101.726 13.3352 101.726 14.5553C101.726 15.7851 102.282 16.4931 103.269 16.4931C104.253 16.4931 104.814 15.7802 104.814 14.5552Z"
      fill={color}
    />
    <path
      d="M107.507 11.7431H108.601V12.6373H108.686C108.83 12.3161 109.073 12.0469 109.381 11.8671C109.689 11.6874 110.046 11.606 110.404 11.6344C110.684 11.6139 110.965 11.6552 111.227 11.7553C111.489 11.8554 111.724 12.0118 111.915 12.2128C112.107 12.4139 112.249 12.6545 112.333 12.9167C112.416 13.179 112.438 13.4563 112.397 13.7279V17.3717H111.26V14.0069C111.26 13.1024 110.858 12.6525 110.018 12.6525C109.828 12.6439 109.638 12.6755 109.462 12.7453C109.285 12.8151 109.126 12.9214 108.995 13.0568C108.865 13.1923 108.766 13.3538 108.705 13.5302C108.644 13.7066 108.623 13.8938 108.643 14.0789V17.3718H107.507V11.7431Z"
      fill={color}
    />
    <path
      d="M118.817 10.3418V11.7688H120.064V12.7045H118.817V15.5988C118.817 16.1884 119.066 16.4465 119.631 16.4465C119.776 16.4461 119.921 16.4375 120.064 16.4209V17.3462C119.86 17.3819 119.654 17.4009 119.446 17.4029C118.183 17.4029 117.68 16.9684 117.68 15.8832V12.7044H116.766V11.7687H117.68V10.3418H118.817Z"
      fill={color}
    />
    <path
      d="M121.616 9.5459H122.743V12.6477H122.833C122.984 12.3236 123.233 12.0528 123.547 11.8724C123.862 11.692 124.225 11.6106 124.588 11.6394C124.867 11.6246 125.145 11.6701 125.404 11.7726C125.663 11.8752 125.895 12.0323 126.084 12.2327C126.274 12.4331 126.415 12.6719 126.5 12.9321C126.584 13.1923 126.608 13.4674 126.571 13.7378V17.3718H125.434V14.0118C125.434 13.1128 125.005 12.6575 124.202 12.6575C124.007 12.6418 123.811 12.668 123.627 12.7344C123.443 12.8007 123.276 12.9055 123.138 13.0414C123 13.1773 122.894 13.3411 122.827 13.5213C122.761 13.7014 122.735 13.8936 122.753 14.0845V17.3718H121.617L121.616 9.5459Z"
      fill={color}
    />
    <path
      d="M133.196 15.8524C133.042 16.3668 132.707 16.8118 132.251 17.1095C131.795 17.4072 131.247 17.5386 130.701 17.4808C130.322 17.4906 129.945 17.4196 129.597 17.2727C129.248 17.1257 128.937 16.9065 128.684 16.63C128.431 16.3536 128.242 16.0267 128.131 15.6719C128.02 15.3171 127.99 14.943 128.042 14.5755C127.991 14.2069 128.022 13.832 128.133 13.4762C128.244 13.1203 128.431 12.7919 128.683 12.513C128.934 12.2342 129.245 12.0115 129.592 11.86C129.939 11.7085 130.316 11.6317 130.696 11.6349C132.298 11.6349 133.265 12.7049 133.265 14.4724V14.86H129.199V14.9222C129.181 15.1288 129.208 15.3367 129.277 15.5326C129.346 15.7286 129.457 15.9082 129.601 16.06C129.745 16.2118 129.921 16.3325 130.116 16.4142C130.31 16.496 130.521 16.537 130.733 16.5347C131.004 16.5666 131.28 16.5188 131.524 16.3973C131.768 16.2758 131.969 16.0861 132.102 15.8523L133.196 15.8524ZM129.199 14.0384H132.107C132.122 13.8495 132.095 13.6598 132.03 13.4814C131.965 13.303 131.862 13.1399 131.729 13.0025C131.595 12.8652 131.434 12.7567 131.255 12.684C131.076 12.6113 130.884 12.576 130.69 12.5803C130.494 12.5779 130.299 12.614 130.117 12.6864C129.934 12.7588 129.769 12.8661 129.63 13.002C129.491 13.1379 129.382 13.2996 129.308 13.4776C129.234 13.6556 129.197 13.8463 129.199 14.0384H129.199Z"
      fill={color}
    />
  </AppStoreSVG>
);
