import React, { useState } from 'react';
import { connect } from 'react-redux';

import { sendMeTheLink } from 'store/actions/user';
import { Text, GetApp } from 'components';
import { ModalPlayer, Player } from './components';

import {
  Container,
  Title,
  TextContainer,
  Wrapper,
  TextWrapper,
  VideoHolder,
  GetAppWrapper,
  VideoWrapper,
  VideoPlay,
  MobilePlayerHolder
} from './styled';

const TextVideoForm = ({ entryPoint, eventNameEntryPoint }) => {
  const [isModal, setModal] = useState(false);
  const [isMobilePlayerPlay, setMobilePlayerPlay] = useState(false);

  return (
    <Wrapper>
      <Container>
        <TextContainer>
          <Title>Vetted Pros at the push of a button</Title>
          <TextWrapper>
            <Text description1>
              Dobby is a delightfully better way to repair, maintain, and
              upgrade your home. Top quality pros, upfront pricing, and support
              whenever you need us.
            </Text>
          </TextWrapper>
          <GetAppWrapper>
            <GetApp
              isCentered
              mobileOnly
              entryPoint={entryPoint}
              eventNameEntryPoint={eventNameEntryPoint}
            />
          </GetAppWrapper>
        </TextContainer>
      </Container>
      <VideoWrapper>
        <VideoHolder>
          <VideoPlay
            onClick={() =>
              window.innerWidth >= 1024
                ? setModal(true)
                : setMobilePlayerPlay(true)
            }
          />
          <Player autoPlay={isMobilePlayerPlay} />
          {isMobilePlayerPlay && (
            <MobilePlayerHolder>
              <Player autoPlay />
            </MobilePlayerHolder>
          )}
        </VideoHolder>
      </VideoWrapper>

      {isModal && (
        <ModalPlayer
          isModal={isModal}
          showModal={() => setModal(true)}
          hideModal={() => setModal(false)}
        />
      )}
    </Wrapper>
  );
};

const mapDispatchToProps = {
  sendMeTheLink
};

export default connect(null, mapDispatchToProps)(TextVideoForm);
