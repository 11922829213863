import styled from 'styled-components';
import styles from 'common/enums/styles';
import { device } from 'common/enums/devices';

export const Container = styled.div`
  font-size: 14px;
  line-height: 1.5;
  align-items: center;
  color: ${styles.colors.DARK_BLUE};
`;

export const DesktopView = styled.div`
  display: none;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  max-width: 518px;
  margin-left: auto;
  margin-right: auto;
  column-gap: 24px;
  @media screen and ${device.LAPTOP} {
    display: flex;
  }
`;

export const MobileView = styled.div`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px 10px 0 10px;
  margin-left: auto;
  margin-right: auto;
  column-gap: 24px;
  @media screen and ${device.LAPTOP} {
    display: none;
  }
`;

export const Title = styled.div`
  font-weight: 700;
  line-height: 1.2;
  margin: ${({ noMargin }) => (noMargin ? '0 0 -20px 0' : '30px 0 16px 0')};
  text-align: center;
  font-size: 24px;
  @media screen and ${device.TABLET} {
    font-size: 32px;
    margin-bottom: 30px;
  }
`;

export const Question = styled.div`
  font-size: 16px;
  line-height: 1.5;
  color: ${styles.colors.DARK_BLUE};
  margin-bottom: 10px;
  font-weight: bold;
  margin-top: 2px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
export const BottomSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  column-gap: 36px;
  flex-wrap: wrap;
`;
