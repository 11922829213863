import styled from 'styled-components/macro';
import styles from 'common/enums/styles';
import { device } from 'common/enums/devices';

export const Section = styled.section`
  background: ${styles.components.BACKGROUND};
  overflow-x: hidden;
`;

export const TypeFormTitle = styled.h2`
  text-align: center;
  color: ${styles.components.HEADER_2};
`;

export const TypeFormContainer = styled.div`
  display: grid;
  justify-items: center;
  padding: 56px 0;
  max-width: 400px;
  @media screen and ${device.LAPTOP} {
    padding: 80px 0 50px;
    max-width: unset;
  }
`;
