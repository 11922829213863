import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { LoadingSpinner } from '@sourcegraph/react-loading-spinner';

import { links } from 'common/constants';

const dobbyAppLink = () => {
  if (navigator.userAgent.includes('Android')) {
    /* if we're on Android */
    return links.MARKET_HO_APP_ANDROID;
  }
  return links.MARKET_HO_APP_IOS;
};

const dobbyProAppLink = () => {
  if (navigator.userAgent.includes('Android')) {
    /* if we're on Android */
    return links.MARKET_SP_APP_ANDROID;
  }
  return links.MARKET_SP_APP_IOS;
};

const DownloadRedirect = () => {
  const {
    location: { search }
  } = useHistory();
  const params = new URLSearchParams(search);

  useEffect(() => {
    const app = params.get('app');
    let downloadLink = '';
    if (app === 'DobbyPro') {
      downloadLink = dobbyProAppLink();
    } else {
      downloadLink = dobbyAppLink();
    }

    window.location.assign(downloadLink);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <LoadingSpinner />;
};

export default DownloadRedirect;
