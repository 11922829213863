import styled from 'styled-components/macro';
import styles from 'common/enums/styles';
import { device } from 'common/enums/devices';

export const HeaderContainer = styled.div`
  position: absolute;
  top: ${styles.layout.MOBILE_MENU_HEIGHT}px;
  left: 0;
  z-index: 4;
  width: 100%;
  padding: 7px 16px;
  text-align: center;
  text-decoration: none;
  background: ${styles.colors.BLUE};

  & p {
    max-width: 350px;
    margin: 0 auto;
  }

  @media screen and (min-width: 732px) {
    & p {
      max-width: unset;
    }
  }

  @media screen and ${device.LAPTOP} {
    display: flex;
    position: relative;
    left: unset;
    top: unset;
    margin-top: ${styles.layout.MENU_HEIGHT}px;
    padding: 10px 3px;
  }
`;
