import styled from 'styled-components/macro';
import styles from 'common/enums/styles';
import { device } from 'common/enums/devices';

export const Container = styled.section`
  position: relative;
  background-color: ${styles.components.BACKGROUND};
  padding: 63px 5px 40px;
  overflow: hidden;
  @media screen and ${device.LAPTOP_L} {
    padding: 59px 0 117px;
    display: grid;
    align-content: baseline;
    justify-items: center;
  }
`;

export const Title = styled.h1`
  letter-spacing: 0;
  margin: auto;
  margin-bottom: 0;
  width: 221px;
  color: ${styles.components.HEADER_INVERTED};
  text-align: center;
  padding-horizontal: 3%;
  @media screen and ${device.TABLET} {
    text-align: center;
    width: auto;
  }
  @media screen and ${device.LAPTOP_L} {
    font-size: 50px;
    line-height: 1.3;
  }
`;

export const TextWrapper = styled.div`
  margin: auto;
  max-width: 859px;
  width: 94%;
  margin-top: 24px;
  margin-bottom: 63px;
  text-align: center;
  @media screen and ${device.TABLET} {
    text-align: center;
  }
  @media screen and ${device.LAPTOP_L} {
    margin-top: 20px;
    margin-bottom: 57px;
  }
  p {
    @media screen and ${device.LAPTOP} {
      letter-spacing: -0.2px;
    }
  }
`;

export const GetAppWrapTop = styled.div`
  margin-top: 24px;
  display: grid;
  justify-content: center;
  @media screen and ${device.LAPTOP_L} {
    display: none;
  }
`;
