import { SquareButton } from 'components';
import {
  Wrapper,
  Title,
  SubTitle,
  InfoSection,
  ButtonsSection,
  InfoTitle,
  InfoText
} from './styled';
import React from 'react';
import {
  setSubscription,
  setSubscriptionStep
} from 'store/actions/subscription';
import { connect } from 'react-redux';

import {
  selectAuthUser,
  selectLoadingSubscription
} from 'store/reducers/subscription';
import { prettifyPhone } from 'common/helpers';
import { useHistory } from 'react-router-dom';

const Confirm = ({ user, setSubscription, setSubscriptionStep, isLoading }) => {
  const history = useHistory();
  const isUA = history.location.hash === '#ua';

  return (
    <Wrapper>
      <Title>Confirm your account details</Title>
      <SubTitle>Verify or update the information below</SubTitle>

      <InfoSection>
        <div>
          <InfoTitle>Name</InfoTitle>
          <InfoText>
            {user.given_name} {user.family_name}
          </InfoText>
        </div>
        <div>
          <InfoTitle>Phone Number</InfoTitle>
          <InfoText>{prettifyPhone(user.phone_number, isUA)}</InfoText>
        </div>
        <div>
          <InfoTitle>Email </InfoTitle>
          <InfoText>{user.email}</InfoText>
        </div>
        <div>
          <InfoTitle>Home Address</InfoTitle>
          <InfoText>{user.address}</InfoText>
        </div>
      </InfoSection>

      <ButtonsSection>
        <SquareButton
          fontSize={16}
          loading={isLoading}
          onClick={() => setSubscription({ id: user?.id })}>
          Looks good!
        </SquareButton>
        <a href="#phone">
          <SquareButton
            white
            fontSize={16}
            onClick={() => setSubscriptionStep('')}>
            Edit phone number
          </SquareButton>
        </a>
      </ButtonsSection>
    </Wrapper>
  );
};
const mapStateToProps = state => ({
  user: selectAuthUser(state),
  isLoading: selectLoadingSubscription(state)
});
const mapDispatchToProps = {
  setSubscriptionStep,
  setSubscription
};

export default connect(mapStateToProps, mapDispatchToProps)(Confirm);
