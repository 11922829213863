import styled from 'styled-components';

export const GradientBackground = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 80px;
  height: 80px;
  border-radius: 50%;
  background: linear-gradient(222.26deg, #a1ee93 1.15%, #2cb5c7 96.8%);

  margin: 10px;
`;
