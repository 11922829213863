import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import mixpanel from 'mixpanel-browser';

import { useScrollPosition, useTrackPageViews } from 'hooks';
import { analyticsPageTypes, captchaActions } from 'common/enums/pages';
import { CAPTCHA_SITE_KEY } from 'common/constants/html';
import { TextArea, Input, Button, DownloadAppImageText } from 'components';
import { useHistory } from 'react-router-dom';
import { askUsAnything } from 'store/actions/user';
import { askUsAnythingLoading } from 'store/reducers/question';
import { getPublications } from 'store/actions/attic';
import checkThePriceBackground from 'static/images/backgrounds/checkThePriceRight.png';
import {
  Wrapper,
  HeaderWrapper,
  ColoredHeader,
  FormWrapper,
  InputGroup,
  InputWrapper,
  SendButtonWrapper,
  HeaderText,
  HeaderTitle,
  Image,
  InputPhoneWrapper,
  ScreenContentWrapper,
  WrapperSection,
  MainContent,
  LoaderWrapper,
  StorySection,
  StoryInfo,
  StoryTitle,
  StoryDesc,
  ReadStory,
  Story,
  ImageBackground,
  Separator
} from './styled';
import { openModal } from 'store/actions/modal';
import {
  selectLoadingPublications,
  selectPublications
} from 'store/reducers/attic';

import { InputPrefix } from '../Home/components/CommunityForm/styled';
import NumberFormat from 'react-number-format';
import { ReactComponent as Arrow } from 'static/images/icons/arrow-right-dark.svg';
import { Element } from 'react-scroll';
import { LoadingSpinner } from '@sourcegraph/react-loading-spinner';
import routes from 'common/enums/routes';

const Attic = ({
  errors,
  fields,
  values,
  isLoading,
  getPublications,
  publications,
  askUsAnything,
  isLoadingQuestion
}) => {
  useTrackPageViews({ page: analyticsPageTypes.ASK_US_ANYTHING });
  useScrollPosition();
  const [showValidation, setShowValidation] = useState({});
  const history = useHistory();
  const locationUA = history.location.hash === '#ua';
  const phoneNumberCode = locationUA ? '+38.' : '+1.';

  const isFormFilled = Boolean(values.question && values.phone_number);
  const isFieldError = field => errors[field] && showValidation[field];

  const activateValidation = field => () => {
    if (!showValidation[field]) {
      setShowValidation(showValidation => ({
        ...showValidation,
        [field]: true
      }));
    }
  };

  const submitAskUsForm = event => {
    event.preventDefault();
    mixpanel.track('Ask Us Anything Form Submit', {
      'Entry Point': analyticsPageTypes.ASK_US_ANYTHING,
      $phone_number: `${phoneNumberCode}${values.phone_number}`
    });

    window.grecaptcha
      .execute(CAPTCHA_SITE_KEY, {
        action: captchaActions.ASK_US_ANYTHING.SUBMIT
      })
      .then(token => {
        const data = {
          phone_number:
            `${phoneNumberCode}${values.phone_number}`?.replace(/\./g, '') ||
            '',
          question: values.question
        };
        askUsAnything({
          data: { entryPoint: analyticsPageTypes.ASK_US_ANYTHING, ...data },
          security_token: token,
          isAtticPage: true,
          ...data
        });
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getPublications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <ScreenContentWrapper>
        <Wrapper id="formSection" withHeader>
          <HeaderWrapper>
            <HeaderTitle>
              Introducing <ColoredHeader>Check The Price&#8482;</ColoredHeader>
            </HeaderTitle>
            <HeaderText>
              Ask our AI to give you the price range for any project, big or
              small.
              <Separator /> No more unknowns, smart and informed purchase
              decisions for you!
            </HeaderText>
          </HeaderWrapper>
          <FormWrapper>
            <InputWrapper>
              <TextArea
                fullWidth
                errorBorders={isFieldError('question')}
                type="text"
                placeholder="How much does it cost to upgrade my kitchen in Alexandria, VA?"
                {...fields.question}
                onBlur={activateValidation('question')}
              />
            </InputWrapper>
            <InputGroup>
              <InputPhoneWrapper leftMargin={locationUA ? 46 : 38}>
                <InputPrefix color={'#FFFFFF'} left={12}>
                  {phoneNumberCode}
                </InputPrefix>
                <NumberFormat
                  type="tel"
                  format="###.###.####"
                  id="phoneNumber"
                  customInput={Input}
                  placeholder="202.460.7470"
                  padding="0 48px 0 72px"
                  smallBottomMargin
                  lineHeight={1}
                  errorBorders={isFieldError('phone_number')}
                  {...fields.phone_number}
                  onBlur={activateValidation('phone_number')}
                />
              </InputPhoneWrapper>
              <SendButtonWrapper>
                <Button
                  title={'Ask'}
                  loading={isLoadingQuestion}
                  disabled={
                    isFieldError('question') ||
                    isFieldError('phone_number') ||
                    !isFormFilled ||
                    isLoading
                  }
                  onClick={submitAskUsForm}
                />
              </SendButtonWrapper>
            </InputGroup>
          </FormWrapper>
        </Wrapper>
        <ImageBackground
          src={checkThePriceBackground}
          alt="backgroundCheckThePrice"
        />
      </ScreenContentWrapper>
      <WrapperSection>
        <Element name="scrollBelowSecondSection" />
        <MainContent>
          {isLoading ? (
            <LoaderWrapper>
              <LoadingSpinner />
            </LoaderWrapper>
          ) : (
            <StorySection id="mainSection">
              {publications.map(({ id, content }, i) => (
                <Story
                  key={id}
                  onClick={() => {
                    history.push(`${routes.ATTIC}/${content?.publication_pk}`);
                  }}>
                  <Image>
                    <img src={content?.preview_image_url} alt="content" />
                  </Image>
                  <StoryInfo reverse={i % 2 !== 0}>
                    <div>
                      <StoryTitle>{content?.title}</StoryTitle>
                      <StoryDesc>{content?.subTitle}</StoryDesc>
                    </div>
                    <ReadStory>
                      Read Story
                      <Arrow />
                    </ReadStory>
                  </StoryInfo>
                </Story>
              ))}
            </StorySection>
          )}
        </MainContent>
        <DownloadAppImageText entryPoint={analyticsPageTypes.CHECK_THE_PRICE} />
      </WrapperSection>
    </div>
  );
};

const validate = values => {
  const errors = {};

  if (!values.question || values?.question.trim() === '') {
    errors.question = 'required';
  }

  if (!values.phone_number) {
    errors.phone_number = 'required';
  } else if (values.phone_number && values.phone_number.indexOf(' ') >= 0) {
    errors.phone_number = 'wrongFormat';
  }

  return errors;
};

const mapStateToProps = state => ({
  isLoading: selectLoadingPublications(state),
  isLoadingQuestion: askUsAnythingLoading(state),
  publications: selectPublications(state)
});

const mapDispatchToProps = {
  askUsAnything,
  openModal,
  getPublications
};

export default reduxForm({
  form: 'askUsAnything',
  fields: ['question', 'phone_number'],
  validate
})(connect(mapStateToProps, mapDispatchToProps)(Attic));
