import { useState, useEffect, useRef } from 'react';

export function useScrollPosition() {
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    let activeElementBeforeBlur = null;

    const handleFocusIn = event => {
      if (event.target.id === 'phoneNumber') {
        setScrollPosition(window.scrollY || document.documentElement.scrollTop);
      }
    };

    const handleFocusOut = event => {
      activeElementBeforeBlur = event.target;

      if (activeElementBeforeBlur.id === 'phoneNumber') {
        setTimeout(() => {
          const activeElementAfterBlur = document.activeElement;
          const isSwitchingInputs =
            (activeElementAfterBlur.tagName === 'INPUT' ||
              activeElementAfterBlur.tagName === 'TEXTAREA') &&
            activeElementBeforeBlur !== activeElementAfterBlur;

          if (!isSwitchingInputs) {
            window.requestAnimationFrame(() => {
              window.scrollTo({
                top: 0,
                behavior: 'smooth'
              });
            });
          }
        }, 100);
      }
    };

    document.addEventListener('focusin', handleFocusIn);
    document.addEventListener('focusout', handleFocusOut);

    return () => {
      document.removeEventListener('focusin', handleFocusIn);
      document.removeEventListener('focusout', handleFocusOut);
    };
  }, [scrollPosition]);

  return scrollPosition;
}
