import React from 'react';

import { Text } from 'components';
import starImg from 'static/images/icons/star.svg';
import {
  Wrapper,
  Stars,
  Star,
  NameBox,
  ImageWrapper,
  Image,
  grayTextStyle,
  ProName,
  ProPosition
} from './styled';

const Rate = ({ item, isLighter }) => {
  return (
    <Wrapper isLighter={isLighter}>
      <ImageWrapper>
        <Image src={item.PRO_IMAGE} />
        <ProName>{item.PRO_NAME}</ProName>
        <ProPosition styles={grayTextStyle}>{item.PRO_OCUPATION}</ProPosition>
      </ImageWrapper>
      <div>
        <Stars>
          {Array(5)
            .fill('0')
            .map((item, index) => (
              <Star key={index} src={starImg} />
            ))}
        </Stars>
        <Text text2>“{item.TEXT}“</Text>
        <NameBox>
          <Text text1>
            <strong>{item.HO_NAME}</strong>
          </Text>
          <Text text2 style={grayTextStyle}>
            ({item.HO_LOCATION})
          </Text>
        </NameBox>
      </div>
    </Wrapper>
  );
};

export default Rate;
