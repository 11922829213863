import React, { Component } from 'react';
import { connect } from 'react-redux';
import { MainContent, OutdatedButton } from 'components';
import { scroller } from 'react-scroll';
import { Redirect } from 'react-router-dom';
import Sticky from 'react-sticky-el';

import styles from 'common/enums/styles';
import { roles } from './roles.js';
import { sendCV } from 'store/actions/user';
import { selectCVError, selectCVLoading } from 'store/reducers/user';

import clockIcon from 'static/images/icons/clock-icon.svg';
import locationIcon from 'static/images/icons/location-icon.svg';
import {
  ApplyNowImage,
  ApplyNowStickyContainer,
  DescriptionSection,
  DescriptionText,
  DesktopApplyNowSection,
  Icon,
  IconWrapper,
  ImageWrapper,
  LightHeading,
  ListItem,
  MainSection,
  MobileSection,
  MobileSectionFixed,
  PageFlexContainer,
  RoleContainer,
  RowContainer,
  RowItem,
  SecondaryDescriptionText,
  SubHeading,
  TopHeading
} from './styled';

import ApplyNowForm from './ApplyNowForm';

const ApplyButton = ({ onClick }) => (
  <OutdatedButton
    title="Apply now"
    onClick={onClick}
    disabled={false}
    mobileWithoutMargins={true}
    mobileSquareBorders={true}
  />
);

const OtherRolesButton = ({ onClick }) => (
  <OutdatedButton
    title="Other roles"
    onClick={onClick}
    type="white"
    disabled={false}
    mobileWithoutMargins={true}
    mobileSquareBorders={true}
  />
);

const RoleImage = ({ src, alt }) => (
  <ImageWrapper>
    <ApplyNowImage src={src} alt={alt} />
  </ImageWrapper>
);

class RoleDescription extends Component {
  state = {
    modalVisible: false
  };

  componentDidMount() {
    document.title =
      'Dobby | The most future-forward home services and renovation company';
    window.scrollTo(0, 0);
    const { history } = this.props;
    if (history.location.state && history.location.state.section) {
      this.scrollTo(history.location.state.section);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.role !== prevProps.match.params.role) {
      window.scrollTo(0, 0);
    }
  }

  scrollTo = section => {
    scroller.scrollTo(section, {
      duration: 1000,
      delay: 100,
      smooth: true,
      offset: -150
    });
  };

  openUploadModal = () => {
    this.setState({
      modalVisible: true
    });
  };

  hideUploadModal = () => {
    this.setState({
      modalVisible: false
    });
  };

  handleFileReportDialog = event => {
    event.stopPropagation();
    event.preventDefault();
    const files = event.target.files;

    if (files && files.length > 0) {
      const uploadedFile = files[0];
      this.setState({ reportFile: uploadedFile });
    }
  };

  render() {
    const role = this.props.match.params.role || '';
    const { modalVisible } = this.state;
    const { CVLoading, CVError, sendCV } = this.props;
    if (!role || !roles[role]) {
      return <Redirect to={'/not-found'} />;
    }
    return (
      <MainContent>
        <PageFlexContainer>
          <MainSection>
            <RoleContainer>
              <DesktopApplyNowSection>
                <Sticky
                  topOffset={-styles.layout.MENU_HEIGHT - 50}
                  bottomOffset={-350}
                  stickyStyle={{
                    top: styles.layout.MENU_HEIGHT + 50
                  }}
                  boundaryElement={RoleContainer}
                  hideOnBoundaryHit={false}>
                  <ApplyNowStickyContainer>
                    <RoleImage src={roles[role].photo} alt={roles[role].alt} />
                    <ApplyButton onClick={() => this.openUploadModal()} />
                    <OtherRolesButton
                      onClick={() => this.scrollTo('openRoles')}
                    />
                  </ApplyNowStickyContainer>
                </Sticky>
              </DesktopApplyNowSection>
              <DescriptionSection>
                <MobileSection>
                  <RoleImage src={roles[role].photo} alt={roles[role].alt} />
                </MobileSection>
                <TopHeading>{roles[role].name}</TopHeading>
                <RowContainer>
                  <RowItem>
                    <IconWrapper>
                      <Icon src={locationIcon} alt="LOCATION" />
                    </IconWrapper>
                    <LightHeading>{roles[role].location}</LightHeading>
                  </RowItem>
                  <RowItem>
                    <IconWrapper>
                      <Icon src={clockIcon} alt="TIME" />
                    </IconWrapper>
                    <LightHeading>{roles[role].timeType}</LightHeading>
                  </RowItem>
                </RowContainer>
                <DescriptionText>{roles[role].description}</DescriptionText>
                {roles[role].paragraphsStart
                  ? roles[role].paragraphsStart.map((paragraph, index) => (
                      <div key={`div-start-paragraph-${index}`}>
                        <SubHeading>{paragraph.name}:</SubHeading>
                        {paragraph.content.map((item, indexItem) => (
                          <SecondaryDescriptionText
                            key={`start-p-${index}-${indexItem}`}>
                            {item}
                          </SecondaryDescriptionText>
                        ))}
                      </div>
                    ))
                  : null}
                {roles[role].lists.map(
                  list =>
                    Boolean(list.listData.length) && (
                      <div key={`div-${list.listName}`}>
                        <SubHeading>{list.listName}:</SubHeading>
                        <ul>
                          {list.listData.map((item, index) => (
                            <ListItem key={`${list.listName}-${index}`}>
                              <span>{item}</span>
                            </ListItem>
                          ))}
                        </ul>
                      </div>
                    )
                )}
                {roles[role].type === 'engineer' ? (
                  <>
                    <SubHeading>About Dobby:</SubHeading>
                    <SecondaryDescriptionText>
                      At Dobby, we are building a leapfrog proptech platform
                      driven by artificial intelligence (AI) - that is
                      integrating homeowners, service providers, realtors, and
                      property inspectors to fundamentally change how we manage,
                      maintain, and upgrade our home. Starting with a
                      best-in-class home maintenance product that won TechCrunch
                      Top Pick in AI/ ML 2019 - Dobby is pioneering digitization
                      & AI in the $16T residential property market, the largest
                      asset class in the US. Our vision is "
                      <strong>
                        to become the operating system for American homes
                      </strong>
                      " by adding adjacent products, and services over time -
                      insurance to home automation.
                    </SecondaryDescriptionText>
                    <SubHeading>Why you should join us at Dobby:</SubHeading>
                    <SecondaryDescriptionText>
                      At Dobby, you will work with a full-stack team of
                      world-class experts and a stellar group of early
                      investors. Dobby was originally founded with Techstars.
                      Our founding team members previously worked at Fortune 100
                      companies: AIG, Liberty Mutual, Deloitte, at two unicorn
                      startups: Uber and DataRobot, and at Clinton Foundation
                      and Twitter. Our engineers are graduates of top CS
                      programs in Ukraine and got us TechCrunch Top Pick in AI/
                      ML.
                      <br />
                      <br />
                      Our lead investor is a prominent fund supporting some top
                      companies in the midwest and mid-atlantic states. Our
                      early investors include: Jeremy Achin (CEO/ founder @
                      DataRobot), Mark Bailey (founder/ partner @ DFJ Growth,
                      invested in companies like Tesla and SpaceX), Maury Devine
                      (board @ ConocoPhillips, early investor Sweetgreen), Paul
                      Mang (ex-AON, ex-McKinsey) and Andrew Macdonald (head of
                      Uber Mobility).
                      <br />
                      <br />
                      We value experts and deep, high-quality work. You will get
                      all our support - as you aim to become one of the best
                      frontend developers in the world.
                    </SecondaryDescriptionText>
                  </>
                ) : null}
                {roles[role].paragraphsEnd
                  ? roles[role].paragraphsEnd.map((paragraph, index) => (
                      <div key={`div-end-paragraph-${index}`}>
                        <SubHeading>{paragraph.name}:</SubHeading>
                        {paragraph.content.map((item, indexItem) => (
                          <SecondaryDescriptionText
                            key={`end-p-${index}-${indexItem}`}>
                            {item}
                          </SecondaryDescriptionText>
                        ))}
                      </div>
                    ))
                  : null}
                <MobileSectionFixed>
                  <ApplyButton onClick={() => this.openUploadModal()} />
                  <OtherRolesButton
                    onClick={() => this.scrollTo('openRoles')}
                  />
                </MobileSectionFixed>
              </DescriptionSection>
            </RoleContainer>
          </MainSection>
        </PageFlexContainer>
        <ApplyNowForm
          role={role}
          sendCV={sendCV}
          CVLoading={CVLoading}
          CVError={CVError}
          modalVisible={modalVisible}
          hideUploadModal={this.hideUploadModal}
        />
      </MainContent>
    );
  }
}

const mapStateToProps = state => ({
  CVLoading: selectCVLoading(state),
  CVError: selectCVError(state)
});

const mapDispatchToProps = {
  sendCV
};

export default connect(mapStateToProps, mapDispatchToProps)(RoleDescription);
