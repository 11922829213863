import React from 'react';

import categoriesArrow from 'static/images/icons/categiry-arrow-down.svg';

import {
  Card,
  CardImage,
  CardImageWrap,
  CardTitle,
  CardContent,
  List,
  ListItem,
  ListWrapper,
  More,
  MoreIcon
} from './styled';

const CategoryItem = ({
  item,
  activeListName,
  setActiveListName,
  order,
  itemHeight
}) => {
  const onClickHandler = () => {
    setActiveListName(activeListName !== item.TYPE ? item.TYPE : null);
  };

  return (
    <Card order={order} id={`card-${item.TYPE}`}>
      <CardContent>
        <CardImageWrap>
          <CardImage src={item.IMAGE} />
        </CardImageWrap>
        <CardTitle>{item.TITLE}</CardTitle>
        <ListWrapper
          isActive={activeListName === item.TYPE}
          height={itemHeight}>
          <List id={`card-${item.TYPE}-list`}>
            {item.LIST.map(item => (
              <ListItem key={item}>{item}</ListItem>
            ))}
          </List>
        </ListWrapper>
        <More onClick={onClickHandler}>
          {activeListName === item.TYPE ? 'See less' : 'See more'}{' '}
          <MoreIcon
            src={categoriesArrow}
            isActive={activeListName === item.TYPE}
          />
        </More>
      </CardContent>
    </Card>
  );
};

export default CategoryItem;
