import React from 'react';

import homecareBackground from 'static/images/backgrounds/homecare-background.png';
import strongerCommunity from 'static/images/brands/stronger-community.svg';
import cleanerAir from 'static/images/brands/cleaner-air.svg';
import { Text } from 'components';
import {
  Title,
  TextWithLink,
  TextContainer,
  Link,
  Image,
  HCContainer,
  ImageContainer,
  textStyle,
  Container,
  Section
} from './styled';

const HomeCare = () => (
  <Section>
    <HCContainer src={homecareBackground}>
      <Container>
        <Title>{'Good for people, good for the planet.'}</Title>
        <TextContainer>
          <Text description2 style={textStyle}>
            Matchmaking websites have been exploiting independent service
            providers for decades with their predatory business model.
          </Text>
          <TextWithLink>
            Read FTC memo on Angi:{' '}
            <Link href="https://www.ftc.gov/news-events/news/press-releases/2022/03/ftc-charges-homeadvisor-inc-cheating-businesses-including-small-businesses-seeking-leads-home">
              https://www.ftc.gov/news-events/news/press-releases/2022/03/ftc-charges-homeadvisor-inc-cheating-businesses-including-small-businesses-seeking-leads-home
            </Link>
          </TextWithLink>

          <Text description2 style={textStyle}>
            We founded Dobby to stop that predatory practice by pioneering the
            "one-job, one-pro" property management business model (you still get
            multiple quotations for your large jobs, but pros don't pay us for
            leads that they never make money from).
          </Text>
          <Text description2 style={textStyle}>
            We're also very proud to be the first home services platform to
            contribute a portion of the revenue to remove CO<sub>2</sub> from
            the atmosphere. And every time you complete a job, we plant a tree!
          </Text>
          <TextWithLink>
            Read our climate memo:{' '}
            <Link href="/love-our-planet">
              https://hellodobby.com/love-our-planet
            </Link>
          </TextWithLink>
          <Text description2 style={textStyle}>
            From newly born startups to Fortune 50 companies - we've got to
            think holistically about our business, and that should include
            climate change and building a future-forward community. That's how
            we are leading the way in a half-a-trillion dollar legacy industry!
            Join us on this journey.
          </Text>
        </TextContainer>
        <ImageContainer>
          <Image src={cleanerAir}></Image>
          <Image src={strongerCommunity}></Image>
        </ImageContainer>
      </Container>
    </HCContainer>
  </Section>
);

export default HomeCare;
