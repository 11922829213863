import styled from 'styled-components/macro';
import styles from 'common/enums/styles';
import { device } from 'common/enums/devices';

export const ModalVideoWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: grid;
  justify-content: center;
  align-content: center;
  width: 100vw;
  height: 100vh;
  z-index: 100;
`;

export const ModalVideoBackground = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  background: ${styles.colors.BLACK_TRUE}80;
  top: 0;
  left: 0;
  display: grid;
  justify-items: center;
  align-items: center;
  z-index: -1;
`;

export const CloseButton = styled.button`
  position: absolute;
  top: -50px;
  right: 13px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: none;
  outline: 0;
  background: ${styles.colors.BLACK_TRUE};
  transition: background 0.3s;
  padding: 0;
  display: grid;
  justify-content: center;
  align-content: center;
  &:focus {
    outline: 0;
  }
  &:hover {
    background: ${styles.colors.BLACK_TRUE};
    cursor: pointer;
  }
  @media screen and ${device.LAPTOP} {
    top: 15px;
    right: -50px;
  }
`;

export const CloseButtonIcon = styled.img`
  height: 20px;
  width: auto;
  @media screen and ${device.LAPTOP} {
    height: 20px;
  }
`;

export const Holder = styled.div`
  position: relative;
  width: 85vw;
`;
