import { useEffect } from 'react';

const Redirect = ({ location: { search } }) => {
  useEffect(() => {
    const params = new URLSearchParams(search);
    const link = params.get('link');
    if (!link) {
      return;
    }

    window.location.href = link;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default Redirect;
