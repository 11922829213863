import { call, takeLatest, put } from 'redux-saga/effects';

import actionTypes from 'common/enums/actionTypes';
import { API } from 'aws-amplify';

import { subscriptionSteps } from 'common/enums/subscription';
import api_constants from 'common/enums/api';

const amplifyPost = (path, body) => {
  return API.post('mainApi', path, { body })
    .then(data => {
      return data;
    })
    .catch(err => {
      throw new Error(err);
    });
};

function* setSubscription(action) {
  const { payload } = action;
  try {
    const { id } = payload;

    yield call(amplifyPost, api_constants.SUBSCRIPTION, {
      user_id: id
    });

    yield put({
      type: actionTypes.SET_SUBSCRIPTION_STEP,
      payload: subscriptionSteps.FINAL
    });

    yield put({
      type: actionTypes.SET_SUBSCRIPTION_SUCCESS
    });
  } catch (err) {
    yield put({
      type: actionTypes.SET_SUBSCRIPTION_ERROR
    });
  }
}

export default function* watchSetSubscription() {
  yield takeLatest(actionTypes.SET_SUBSCRIPTION_FETCH, setSubscription);
}
