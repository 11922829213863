function createActionTypes(full, single) {
  let types = {};
  full.forEach(type => {
    types[`${type}_FETCH`] = `${type}_FETCH`;
    types[`${type}_SUCCESS`] = `${type}_SUCCESS`;
    types[`${type}_ERROR`] = `${type}_ERROR`;
  });
  single.forEach(type => {
    types[type] = type;
  });
  return types;
}

export { createActionTypes };
