import styled from 'styled-components/macro';
import { device } from 'common/enums/devices';

export const GridContainer = styled.div`
  display: ${({ isHome }) => (isHome ? 'none' : 'flex')};
  flex-direction: column;
  align-items: center;
  margin-top: 55px;
  justify-content: center;
  gap: 16px;
  // TODO: remove this after fixing the alignment issue
  width: 100%;
  min-height: 1600px;

  @media screen and ${device.TABLET} {
    margin-top: 57px;
    gap: 20px;
    grid-template-columns: unset;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: start;
    justify-items: center;
    align-content: center;
    max-height: 2000px;
  }
  @media screen and (min-width: 1160px) {
    margin-top: 40px;
  }
`;
