import styled from 'styled-components';
import styles from 'common/enums/styles';

export const StyledButton = styled.button`
  background: ${({ disabled, disabledColor, buttonStyle, transparent }) => {
    if (disabled && disabledColor) return disabledColor;
    if (transparent) return 'transparent';
    if (buttonStyle === 'secondary' || buttonStyle === 'outline')
      return 'transparent';
    if (buttonStyle === 'brand') return styles.colors.GREEN;
    if (buttonStyle === 'white') return styles.colors.WHITE;
    if (buttonStyle === 'big-reversed') return styles.colors.BLACK_TRUE;
    return styles.colors.YELLOW;
  }};
  color: ${({
    disabled,
    disabledColor,
    disabledFontColor,
    buttonStyle,
    transparent
  }) => {
    if (disabled && disabledColor)
      return disabledFontColor ? disabledFontColor : styles.colors.GRAY;
    if (buttonStyle === 'secondary') return styles.colors.RED;
    if (transparent) return styles.colors.BLACK_TRUE;
    if (buttonStyle === 'big-reversed') return styles.colors.YELLOW;
    if (buttonStyle === 'outline') return styles.colors.BLACK_TRUE;
    return styles.colors.WHITE;
  }};
  border: ${({ transparent, buttonStyle }) =>
    transparent
      ? `1px solid ${styles.colors.BLACK_TRUE}`
      : buttonStyle === 'outline'
      ? `1px solid ${styles.colors.YELLOW}`
      : '1px solid transparent'};
  border-radius: ${({ buttonStyle }) =>
    buttonStyle === 'brand' ? `8px` : `2px`};

  outline: 0;
  text-transform: ${({ buttonStyle }) =>
    buttonStyle === 'brand' ? 'none' : 'uppercase'};
  box-shadow: ${({ transparent, buttonStyle, shadow, disabled }) => {
    if (shadow) {
      return shadow;
    } else if (disabled) {
      return 'none;';
    } else if (buttonStyle === 'white') {
      return '0px 0px 25px rgba(0, 0, 0, 0.2);';
    } else if (buttonStyle === 'big-reversed') {
      return '0px 0px 30px rgba(64, 66, 66, 0.2);';
    } else if (buttonStyle === 'brand') {
      return styles.boxShadows.GREEN;
    } else if (!transparent) {
      return 'rgb(255 171 64) 0px 0px 10px;';
    }
    return 'none;';
  }};
  font-size: 16px;
  font-weight: 700;
  line-height: 1.5;
  ${({ buttonStyle }) => buttonStyle !== 'brand' && 'letter-spacing: 0;'}
  min-width: ${({ buttonStyle, width }) => {
    if (
      buttonStyle === 'big' ||
      buttonStyle === 'big-reversed' ||
      buttonStyle === 'outline'
    )
      return '280px';
    return `${width || 160}px`;
  }};
  padding: ${({ buttonStyle, isLoading }) => {
    if (
      buttonStyle === 'big' ||
      buttonStyle === 'outline' ||
      buttonStyle === 'brand'
    )
      return '16px 25px 16px';
    if (buttonStyle === 'big-reversed') return '9px 17.5px';
    if (isLoading) return '8px 17.5px 8px 41px';
    return '8px 17.5px';
  }};
  ${({ padding }) => padding && `padding: ${padding}`};
  margin-bottom: 13px;
  opacity: ${({ disabled, notShowDisabled }) =>
    disabled && !notShowDisabled ? 0.3 : 1};
  transition: all 0.3s ease;
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
  &:focus {
    outline: 0;
  }
  &:active {
    box-shadow: none;
    color: ${styles.colors.WHITE};
  }
  &:hover {
    ${({ disabled, buttonStyle }) =>
      !disabled &&
      `
            box-shadow: ${
              buttonStyle === 'white'
                ? '0px 0px 25px rgba(0, 0, 0, 0.2)'
                : 'none'
            };
            color: ${
              buttonStyle === 'white'
                ? styles.colors.YELLOW
                : buttonStyle === 'outline'
                ? styles.colors.BLACK_TRUE
                : styles.colors.WHITE
            };
        `}
  }
  display: flex;
  flex-direction: row;
  justify-content: center;
  & div {
    ${({ disabled }) =>
      disabled &&
      `border: 2px solid ${styles.colors.GRAY}70;
    border-top: 2px solid ${styles.colors.GRAY}`}
  }

  max-height: ${({ buttonStyle }) =>
    buttonStyle === 'big' ||
    buttonStyle === 'outline' ||
    buttonStyle === 'brand'
      ? '56px'
      : buttonStyle === 'big-reversed'
      ? '44px'
      : '40px'};

    @media screen and (min-width: 813px) {
      width: auto;
      min-width: ${({ width }) => (width ? `${width}px` : '160px')};
    }

  @media screen and (max-width: 1023px) {
    ${({ flexMobile }) =>
      flexMobile &&
      `
      width: 100%;
      min-width: 0px;
    `};
    ${({ widthMobPercentage }) =>
      widthMobPercentage && `width: ${widthMobPercentage}%`};
    ${({ mobileSquareBorders }) =>
      mobileSquareBorders &&
      `
            border-radius: 0px;
        `}
    ${({ mobileWithoutMargins }) =>
      mobileWithoutMargins &&
      `
            margin-bottom: 0px;
            max-height: 44px;
            padding: 10px 17.5px;
        `}
  }
  ${({ margin }) => margin && `margin: ${margin}`};

  ${({ fullWidth }) => fullWidth && 'flex: 1'};

  .loading-spinner {
    border: 2px solid ${styles.colors.WHITE};
    border-top: 2px solid ${styles.colors.GRAY};
  }
`;

export const StyledLink = styled.a`
  background: ${styles.colors.YELLOW};
  color: ${styles.colors.BLACK_TRUE};
  text-decoration: none;
  border: none;
  outline: 0;
  text-transform: capitalize;
  box-shadow: 0 0 20px rgba(195, 54, 90, 0.43);
  font-size: 13px;
  line-height: 1.5;
  letter-spacing: 1px;
  min-width: 160px;
  display: inline-block;
  text-align: center;
  padding: 10px;
  font-family: NunitoSansSemiBold, serif;
  transition: all 0.3s;
  &:focus {
    outline: 0;
    border: none;
  }
  &:active {
    background: ${styles.colors.YELLOW};
    color: ${styles.colors.BLACK_TRUE};
  }
  &:hover {
    box-shadow: none;
    color: ${styles.colors.BLACK_TRUE};
    text-decoration: none;
    opacity: 0.75;
  }
`;
