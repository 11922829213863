import React from 'react';

import { confirmationScreenTypes } from 'common/enums/pages';
import { Logo } from 'components';

import {
  Wrapper,
  InnerBox,
  WhiteBox,
  FormHeader,
  FormNotation,
  HeadBox
} from './styled';

const Confirmation = ({ location: { pathname, search } }) => {
  const content = Object.values(confirmationScreenTypes).find(
    ({ PATHNAME }) => PATHNAME === pathname
  );

  if (!content) {
    return null;
  }

  const urlParams = new URLSearchParams(search);

  const params = {
    category_name: urlParams.get('category_name') || 'handyman'
  };

  return (
    <Wrapper>
      <InnerBox>
        <HeadBox>
          <Logo black unsubscribe />
        </HeadBox>
        <WhiteBox>
          <FormHeader>{content?.TITLE}</FormHeader>
          {content?.DESCRIPTION_GENERATOR && (
            <FormNotation>
              {content?.DESCRIPTION_GENERATOR(params)}
            </FormNotation>
          )}
        </WhiteBox>
      </InnerBox>
    </Wrapper>
  );
};

export default Confirmation;
