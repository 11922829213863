import styled from 'styled-components';
import styles from 'common/enums/styles';
import { device } from 'common/enums/devices';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 1.2;
  color: #2e3045;
  text-align: center;

  margin-bottom: 16px;
  @media screen and ${device.TABLET} {
    font-size: 32px;
    margin-bottom: 16px;
  }
`;
export const Text = styled.div`
  font-size: 18px;
  line-height: 120%;
  text-align: center;
  color: #2e3045;
  margin-bottom: 14px;
  max-width: 315px;

  @media screen and ${device.TABLET} {
    font-size: 24px;
    margin-bottom: 28px;
    max-width: 490px;
  }
  span {
    font-weight: 700;
  }
`;

export const Input = styled.div`
  div {
    width: auto !important;
    display: flex;
    gap: 11px;
  }
  input {
    font-weight: 400;
    font-size: 30px;
    line-height: 0.5;
    color: #000000 !important;

    background: #ffffff;
    border: 1px solid #e6e6e6 !important;
    caret-color: #000000 !important;
    border-radius: 8px !important;

    height: 64px !important;
    width: 40px !important;

    font-family: 'HKGrotesk', sans-serif !important;
  }
`;

export const Resend = styled.div`
  font-weight: 300;
  font-size: 24px;
  line-height: 1.2;
  text-align: center;
  color: #2e3045;
  margin-top: 40px;
  ${({ underline }) =>
    underline && 'text-decoration: underline; cursor: pointer'};
`;

export const WrongCode = styled.div`
  position: relative;
  width: 100%;
  max-width: 560px;
  div {
    font-weight: 400;
    font-size: 14px;
    line-height: 15px;
    color: #e12525;
    position: absolute;
    top: 4px;
    padding: 0 25px;
  }
`;

export const LoadingWrapper = styled.div`
  margin-top: 4px;
`;
