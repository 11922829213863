import styled from 'styled-components/macro';
import styles from 'common/enums/styles';
import { device } from 'common/enums/devices';

export const QuestionList = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 20px 0 0;
  @media screen and ${device.LAPTOP} {
    margin: 1px 0 0;
  }
`;

export const QuestionBlock = styled.div`
  ${({ dobbyCare }) =>
    `border-bottom: 1px solid ${
      dobbyCare ? styles.colors.GRAY_NEW : styles.colors.BLACK_TRUE
    }`};
`;
export const QuestionHeader = styled.div`
  display: flex;
  justify-content: center;
  cursor: pointer;
  &:hover {
    color: ${styles.colors.BLUE};
    div svg path {
      fill: ${styles.colors.BLUE};
    }
  }
`;

export const QuestionHeaderIcon = styled.div`
  display: flex;
  align-items: center;
  margin-left: 16px;
  svg {
    color: ${styles.colors.GRAY_NEW};
  }
  @media screen and ${device.LAPTOP} {
    margin-left: 18px;
    margin-right: 18px;
  }
`;

export const AnswerLink = styled.a`
  color: ${styles.components.TEXT};
  margin-left: 4px;
  text-decoration: underline;

  &:hover {
    color: ${styles.colors.BLUE};
  }
`;

export const Question = styled.span`
  display: flex;
  flex: 1;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.5;
  padding: 13px 0 14px;

  @media screen and ${device.LAPTOP} {
    padding: 24px 0 23px;
    font-size: 16px;
  }
  ${({ center }) => center && 'justify-content: center;'}
`;

export const Answer = styled.div`
  white-space: pre-wrap;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  ${({ height }) =>
    height
      ? `
    max-height: ${height + 18}px;
    padding: 0px 35px 18px 0;
  `
      : `
    padding: 0px 35px 0 0;
    max-height: 0;
  `};
  @media screen and ${device.LAPTOP} {
    ${({ height }) =>
      height
        ? `
      padding: 0px 55px 24px 0;
      max-height: ${height + 24}px;
  `
        : `padding: 0px 55px 0 0;`};
  }
`;

export const AnswerDescription = styled.span`
  color: ${styles.colors.BLACK_TRUE};
  font-size: 14px;
  font-weight: normal;
  line-height: 1.5;
  margin: 0;
  sub {
    font-size: 10px;
  }

  @media screen and ${device.LAPTOP} {
    font-size: 16px;
    font-weight: normal;
    sub {
      font-size: 12px;
    }
  }
`;

export const Wrapper = styled.section`
  background-color: ${styles.colors.WHITE_NEW};
  margin-top: 32px;
  padding: ${({ withSpace }) => (withSpace ? '50px 16px 80px' : '0')};
  display: grid;
  justify-items: center;
  @media screen and ${device.LAPTOP} {
    padding: ${({ withSpace }) => (withSpace ? '127px 16px 117px' : '0')};
  }
`;

export const Container = styled.div`
  width: 100%;
  padding: 16px 32px 32px;
  max-width: 1200px;
`;

export const TitleWrapper = styled.div`
  background-color: ${styles.colors.CHARCOAL};
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 15px 40px;
`;

export const Title = styled.h3`
  color: ${({ titleColor = styles.components.TEXT_INVERTED }) => titleColor};
  font-size: 24px;
  line-height: 1.5;
  max-width: 700px;
  text-align: left;
  white-space: break-spaces;
`;
