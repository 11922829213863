import styled from 'styled-components';
import styles from 'common/enums/styles';
import { device } from 'common/enums/devices';

export const StyledButton = styled.div`
  background: ${({ white }) =>
    white ? styles.colors.WHITE_NEW : styles.colors.GREEN};
  box-shadow: ${styles.boxShadows.BUTTON};
  border-radius: 10px;
  font-weight: 600;
  font-size: 16px;
  color: ${({ white }) =>
    white ? styles.colors.DARK_BLUE : styles.colors.WHITE_NEW};
  width: 160px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    color: ${({ white, disabled }) =>
      white && !disabled ? '#63657a' : styles.colors.WHITE_NEW}B3;
  }
  @media screen and ${device.TABLET} {
    font-weight: 700;
    font-size: ${({ fontSize = 20 }) => fontSize}px;
    width: 200px;
  }
  :disabled {
    opacity: 0.5;
    cursor: default;
  }
  .loading-spinner {
    border: 2px solid ${styles.colors.WHITE};
    border-top: 2px solid ${styles.colors.GRAY};
  }

  ${({ disabled }) =>
    disabled &&
    `
    background: #c5c5c5;
    cursor: default;
    `}
`;
