import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { setActiveJob } from 'store/actions/user';

import { GridContainer } from './styled';
import { CategoryItem, CategoryItemLast } from './components';
import { selectActiveJob } from 'store/reducers/user';
import { items } from 'common/enums/categories';

const CategoryGrid = ({
  activeJob,
  lastBoxConent,
  disableDownloadButton,
  setActiveJob,
  isHome,
  entryPoint
}) => {
  const [activeListName, setActiveListName] = useState(null);
  const [itemHeight, setItemHeight] = useState(null);

  useEffect(() => {
    setActiveListName(null);
    return () => {
      setActiveJob(null);
      setActiveListName(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setElementHeight = activeListName => {
    const element = document.getElementById(`card-${activeListName}-list`);

    if (element) {
      const elementHeight = element.clientHeight;
      setItemHeight(elementHeight);
    }
  };

  useEffect(() => {
    if (activeJob) {
      setTimeout(() => {
        const additionalTop = window.innerWidth > 786 ? 100 : 85;
        const element = document.getElementById(`card-${activeJob}`);
        const y = element.offsetTop - additionalTop;
        window.scroll({
          top: y,
          behavior: 'smooth'
        });
        setActiveJob(null);
        setActiveListName(activeJob);
        setElementHeight(activeJob);
      }, 150);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeJob]);

  useEffect(() => {
    if (activeListName) {
      setElementHeight(activeListName);
    }
  }, [activeListName]);

  return (
    <GridContainer isHome={isHome}>
      {Object.values(items).map(item => (
        <CategoryItem
          key={item.TYPE}
          item={item}
          activeListName={activeListName}
          itemHeight={activeListName === item.TYPE ? itemHeight : null}
          setActiveListName={setActiveListName}
          order={item.ORDER}
        />
      ))}
      <CategoryItemLast
        lastBoxConent={lastBoxConent}
        disableDownloadButton={disableDownloadButton}
        order={Object.values(items).length}
        entryPoint={entryPoint}
      />
    </GridContainer>
  );
};

const mapStateToProps = state => ({
  activeJob: selectActiveJob(state)
});

const mapDispatchToProps = {
  setActiveJob
};

export default connect(mapStateToProps, mapDispatchToProps)(CategoryGrid);
