import React from 'react';

const IconChain = ({ size = 18, color = '#404242' }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.3633 2.63513C11.848 -0.87912 6.14938 -0.878277 2.63513 2.63706C-0.87912 6.1524 -0.878277 11.851 2.63706 15.3652C6.1524 18.8795 11.851 18.8786 15.3652 15.3633C17.053 13.675 18.0009 11.3854 18.0004 8.99825C17.9999 6.61145 17.0513 4.32259 15.3633 2.63513Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.1323 10.661C6.78257 9.76272 6.52544 7.73488 7.70375 6.51295L7.71021 6.50601L9.36471 4.81349C10.4174 3.7366 12.149 3.72773 13.2126 4.79381C14.2548 5.83844 14.2636 7.55652 13.232 8.61176L11.6173 10.2636C11.6065 10.2747 11.5878 10.2648 11.5911 10.2497C11.7082 9.70876 11.6652 8.99283 11.4905 8.48509C11.4741 8.43736 11.4855 8.38456 11.5206 8.34836L12.2793 7.56637C12.7784 7.05575 12.77 6.22863 12.2606 5.72833C11.7562 5.22282 10.9351 5.22703 10.436 5.73767L9.1834 7.01904L9.18549 7.01977C9.13045 7.07765 9.0726 7.13631 9.01399 7.19575C8.71663 7.49729 8.3996 7.81877 8.3293 8.16015L8.32907 8.16011C8.25593 8.47931 8.30296 8.78838 8.44111 9.0641L8.44125 9.06402C8.55657 9.28545 8.76143 9.49507 8.9673 9.60686C9.02133 9.6362 9.04136 9.70426 9.00916 9.75671C8.79867 10.0995 8.55829 10.3691 8.27414 10.6474C8.23594 10.6848 8.17677 10.6906 8.1323 10.661ZM10.7224 10.8939L10.7255 10.8926C11.055 10.2886 11.1199 9.59594 10.9654 8.92067L10.9647 8.92139L10.964 8.92106C10.8173 8.31962 10.4149 7.72239 9.86844 7.35468C9.82143 7.32305 9.74633 7.32671 9.70231 7.3624C9.42549 7.58674 9.15453 7.87443 8.97573 8.24155C8.94765 8.29919 8.96868 8.36843 9.02402 8.40062C9.23151 8.52136 9.4189 8.69814 9.54437 8.93283L9.54456 8.93269C9.64234 9.09848 9.73869 9.41305 9.67628 9.75105H9.67615C9.62426 10.1504 9.26015 10.5202 8.92751 10.8581C8.88683 10.8994 8.84662 10.9403 8.80751 10.9806L8.80769 10.9808C8.65453 11.1378 8.36896 11.4298 8.09859 11.7063C7.88666 11.923 7.68407 12.1301 7.56187 12.2552C7.06272 12.7659 6.23751 12.7743 5.72806 12.274C5.21861 11.7737 5.21019 10.9466 5.70934 10.436L6.47029 9.65162C6.50477 9.61607 6.5165 9.56441 6.50116 9.51727C6.3322 8.99765 6.28591 8.29786 6.39256 7.75757C6.39553 7.7425 6.37704 7.73289 6.36631 7.74386L4.77585 9.37089C3.73374 10.437 3.74257 12.1727 4.79551 13.228C5.85907 14.2725 7.58186 14.2548 8.62393 13.1888C8.72928 13.0707 8.93518 12.8652 9.1788 12.6221C9.77232 12.0297 10.5897 11.2139 10.7224 10.8939Z"
      fill="white"
    />
  </svg>
);

export default IconChain;
