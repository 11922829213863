import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { GradientBackground } from './styled';

class IconDone extends Component {
  render() {
    return (
      <GradientBackground>
        <svg width="29" height="21" viewBox="0 0 29 21" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M2.27246 9.6378L10.8031 18.3217L26.3364 2.23096" stroke="white" strokeWidth="4"
          strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
      </GradientBackground>
    );
  }
}

IconDone.propTypes = {
  visible: PropTypes.bool,
  onModalClose: PropTypes.func.isRequired
};

export default IconDone;
