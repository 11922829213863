import styled from 'styled-components/macro';
import styles from 'common/enums/styles';
import { NavLink } from 'react-router-dom';
import { device } from 'common/enums/devices';
import bgImage from 'static/images/backgrounds/our-planet-bg.png';

export const HeaderContainer = styled.section`
  display: grid;
  width: 100%;
  padding: 40px 16px;
  text-align: center;
  justify-items: center;
  text-decoration: none;
  color: ${styles.colors.WHITE_NEW};
  background-color: ${styles.colors.PURE_BLACK};
  background-image: url(${bgImage});
  background-size: cover;
  background-position: 70.5% center;

  & h2 {
    font-size: 50px;
    margin-bottom: 8px;
    color: ${styles.colors.WHITE_NEW};
  }

  & p {
    max-width: 350px;
    margin-bottom: 16px;
  }
  @media screen and ${device.LAPTOP} {
    background-position: center;
    & h2 {
      margin-bottom: 4px;
    }
    & p {
      max-width: unset;
      margin-bottom: 24px;
    }
  }
`;

export const Button = styled(NavLink)`
  display: grid;
  justify-content: center;
  align-content: center;
  width: 200px;
  padding: 12px 24px;
  border: none;
  border-radius: 8px;
  background-color: ${styles.colors.BLUE};
  color: ${styles.colors.WHITE_NEW};
  text-decoration: none;
  cursor: pointer;
  font-weight: 700;
  font-size: 20px;
  @media screen and (hover: hover) and (pointer: fine) {
    &:hover {
      transition: all 0.2s ease-in-out;
      transform: scale(1.1);
    }
  }
  @media screen and ${device.LAPTOP} {
    width: 240px;
  }
  :disabled {
    opacity: 0.5;
    cursor: default;
  }
`;
