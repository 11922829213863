import { useEffect } from 'react';

const GetStartedRedirect = () => {
  useEffect(() => {
    window.location =
      'https://www.hellodobby.com/get-started/?utm_source=sms&utm_medium=sms&utm_campaign=download_to_new_ho';
  }, []);

  return null;
};

export default GetStartedRedirect;
