import styled from 'styled-components/macro';
import styles from 'common/enums/styles';
import { pageTypes } from 'common/enums/pages';
import { device } from 'common/enums/devices';

const heroTitleStyles = {
  [pageTypes.ABOUT_US]: `
    padding-top: 42px;
    @media screen and ${device.LAPTOP} {
      margin-bottom: 31px;
      padding-top: 0;
    }
  `,
  [pageTypes.HOME]: `
    object-position: 34.5% 50%;
  `
};

export const ColorWrapper = styled.header`
  background-color: ${styles.colors.NUDE};
  overflow: hidden;
  ${({ type }) => {
    if ([pageTypes.PARTNERS].includes(type)) {
      return `
        background-color: ${styles.colors.WHITE_NEW};
      `;
    }
  }}
  padding: ${styles.layout.MOBILE_MENU_HEIGHT}px 0 0;
  @media screen and ${device.LAPTOP} {
    padding: 0;
  }
`;

export const Section = styled.div`
  height: calc(100vh - ${styles.layout.MOBILE_MENU_HEIGHT}px);
  position: relative;

  @media screen and ${device.LAPTOP} {
    min-height: 100vh;
    height: 100vh;
    overflow: hidden;
  }
`;

export const Container = styled.div`
  position: absolute;
  z-index: 3;
  display: grid;
  justify-items: center;
  max-width: 600px;
  text-align: ${({ type }) =>
    type === pageTypes.PRESS_RELEASE ? 'left' : 'center'};
  top: 50%;
  left: ${({ type }) => (type === pageTypes.PRESS_RELEASE ? '16px' : '50%')};
  transform: translate(
    ${({ type }) => (type === pageTypes.PRESS_RELEASE ? '0' : '-50%')},
    -50%
  );
  width: 97%;
  white-space: pre-line;
  padding-top: ${({ type }) => type === pageTypes.HOME && '14%'};
  padding-bottom: ${({ type }) =>
    type === pageTypes.LOVE_OUR_PLANET && '225px'};
  p {
    ${({ type }) => type === pageTypes.HOME && 'width: 300px'};
    text-align: ${({ type }) =>
      type === pageTypes.PRESS_RELEASE ? 'left' : 'center'};
  }
  @media screen and ${device.MOBILE} {
    p {
      ${({ type }) => type === pageTypes.HOME && 'width: 328px'};
    }
  }
  @media screen and ${device.MOBILE_L} {
    p {
      ${({ type }) => type === pageTypes.HOME && 'width: 328px'};
    }
  }
  @media screen and ${device.TABLET} {
    padding-top: 0;
    p {
      width: auto;
    }
  }
  @media screen and ${device.LAPTOP} {
    max-width: 900px;
    padding-bottom: 5vh;
    left: ${({ type }) => (type === pageTypes.PRESS_RELEASE ? '103px' : '50%')};
  }

  @media screen and ${device.LAPTOP_L} {
    max-width: 64%;
    p {
      font-size: 1.66vw;
    }

    h1 {
      font-size: 4vw;
    }
  }
`;

export const Title = styled.h1`
  color: ${styles.colors.WHITE_NEW};
  ${({ type }) => Boolean(heroTitleStyles[type]) && heroTitleStyles[type]}

  margin-bottom: 20px;
  @media screen and ${device.LAPTOP} {
    margin-bottom: 24px;
    white-space: pre-line;
  }
`;
