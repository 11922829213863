import routes from 'common/enums/routes';

export const checkRoute = location => {
  const pathname = `/${location.pathname.split('/')[1]}`;
  const isGetStartedRoute = pathname === routes.GET_STARTED;

  const isNavBarShown = ![routes.GET_STARTED, routes.MEET_DOBBY].includes(
    pathname
  );

  const isCircleButton = ![
    routes.CATEGORIES,
    routes.GET_STARTED,
    routes.JOIN_US_DMV,
    routes.JOIN_US_CHARLOTTE,
    routes.LOVE_OUR_PLANET,
    routes.MEET_DOBBY,
    routes.PRIVACY_POLICY,
    routes.DOBBY_CARE
  ].includes(pathname);

  return {
    isGetStartedRoute,
    isNavBarShown,
    isCircleButton
  };
};
