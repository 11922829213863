import React, { useEffect, useCallback, useState } from 'react';
import { useSpring, interpolate } from 'react-spring';
import { PhoneWrapper, ImageContainer } from './styled';

function debounce(fn, ms) {
  let timer;
  return _ => {
    clearTimeout(timer);
    timer = setTimeout(_ => {
      timer = null;
      fn.apply(this, arguments);
    }, ms);
  };
}

const getMaxAnimatedValues = windowWidth => ({
  scale: windowWidth >= 1024 ? 2 : 1.5,
  translateY: windowWidth >= 1024 ? 80 : 60
});

export const AnimatedPhone = ({ children, sectionRef }) => {
  const [height, setHeight] = useState(sectionRef.current.scrollHeight);
  const [maxAnimatedValues, setMaxAnimatedValues] = useState(
    getMaxAnimatedValues(window.innerWidth)
  );

  useEffect(() => {
    const newHeight = sectionRef.current.offsetTop;
    if (newHeight !== height) {
      setHeight(newHeight);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setHeight, sectionRef.current]);

  const [{ scrollY }, setSpring] = useSpring(() => ({
    scrollY: 0,
    immediate: true
  }));

  React.useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      const animatedValues = getMaxAnimatedValues(window.innerWidth);
      if (maxAnimatedValues.scale !== animatedValues.scale) {
        setMaxAnimatedValues(animatedValues);
      }
    }, 1000);
    window.addEventListener('resize', debouncedHandleResize);
    return _ => {
      window.removeEventListener('resize', debouncedHandleResize);
    };
  });

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onScroll = useCallback(e => {
    setSpring({
      scrollY: window.scrollY
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const translateStyle = interpolate(
    [
      scrollY.interpolate({
        range: [0, 1, height * 0.3, height * 0.7, height * 0.85, height * 0.9],
        output: [
          0,
          maxAnimatedValues.translateY,
          maxAnimatedValues.translateY * 0.95,
          maxAnimatedValues.translateY * 0.5,
          maxAnimatedValues.translateY * 0.1,
          0
        ],
        extrapolate: 'clamp'
      }),
      scrollY.interpolate({
        range: [0, 1, height * 0.3, height * 0.7, height * 0.885],
        output: [
          1,
          maxAnimatedValues.scale,
          maxAnimatedValues.scale * 0.9 + 1,
          maxAnimatedValues.scale * 0.15 + 1,
          1
        ],
        extrapolate: 'clamp'
      })
    ],
    (translateY, scale) => `translateY(${translateY}%) scale(${scale})`
  );

  return (
    <PhoneWrapper>
      <ImageContainer style={{ transform: translateStyle }}>
        {children}
      </ImageContainer>
    </PhoneWrapper>
  );
};

export default AnimatedPhone;
