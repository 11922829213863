import React from 'react';
import { Text } from 'components';
import styles from 'common/enums/styles';
import routes from 'common/enums/routes';
import { HeaderContainer, Button } from './styled';

const ButtonSection = () => (
  <HeaderContainer>
    <h2>Our Planet, Our Home.</h2>
    <Text text1 color={styles.colors.WHITE_NEW}>
      Why we're leading the way for climate change in the home maintenance
      industry.
    </Text>
    <Button to={routes.LOVE_OUR_PLANET}>Learn more</Button>
  </HeaderContainer>
);
export default ButtonSection;
