import styled, { css } from 'styled-components';

import styles from 'common/enums/styles';
import { device } from 'common/enums/devices';

export const Container = styled.div`
  position: fixed;
  right: 0;
  bottom: ${({ isLovePlannetScreen }) => (isLovePlannetScreen ? 120 : 20)}px;
  height: fit-content;
  width: fit-content;
  z-index: 4;
  border-radius: 8px;
  opacity: ${({ scrollPosition }) => (scrollPosition > 10 ? 1 : 0)};

  @media screen and ${device.LAPTOP} {
    background-color: #010111;
    border-radius: 1vw;
    // animate on desktop
    right: 0;
    bottom: 0;
    opacity: 1;
    border: 1px solid #fff;
    z-index: ${({ isAnimate }) => (isAnimate ? 4 : -1)};
    transform: ${({ isAnimate }) =>
      isAnimate ? 'translate(-32px, -40px)' : 'translate(-45vw, -30vh)'};
    transition: ${({ isHomeScreen }) =>
      isHomeScreen ? 'transform 0.4s linear' : 'none'};
  }
`;

export const QRWrapper = styled.div`
  position: relative;
  width: 11vw;
  height: 16.4vw;
  display: none;

  @media screen and ${device.LAPTOP} {
    display: block;
  }
`;

const titleStyle = css`
  line-height: 1.2;
  font-weight: 700;
  text-align: left;
  color: ${styles.colors.WHITE};
`;

export const QRCodeTitle = styled.div`
  ${titleStyle}
  font-size: 0.8vw;
  margin: 0.3vw 0 0 1vw;
  color: #b3b3b8;
`;

export const QRCodeAppTitle = styled.div`
  ${titleStyle}
  font-size: 1.2vw;
  margin: 1vw 0 0 1vw;
`;

export const QRCode = styled.img`
  border-radius: 1vw;
  position: absolute;
  width: 10vw;
  height: 10vw;
  margin: 0.5vw;
`;

export const DownloadWrapper = styled.div`
  justify-content: center;
  display: block;

  @media screen and ${device.LAPTOP} {
    display: none;
  }
`;
