import { call, takeLatest, put } from 'redux-saga/effects';

import api_constants from 'common/enums/api';
import actionTypes from 'common/enums/actionTypes';
import modalTypes from 'common/enums/modalTypes';
import Api from 'services/ApiService';
import { openModal } from 'store/actions/modal';
import mixpanel from 'mixpanel-browser';

function* sendMeTheLink({ payload: { data, ...body } }) {
  try {
    const response = yield call(
      Api.apiPost,
      api_constants.SEND_ME_THE_LINK,
      body
    );

    if (!response) {
      throw new Error('Something went wrong. Try again later');
    }

    mixpanel.track(
      `${data?.eventNameEntryPoint || data?.entryPoint} HO download link sent`,
      {
        'Entry Point': data?.eventNameEntryPoint || data?.entryPoint,
        $user_id: data?.userId,
        $phone_number: body.phone_number
      }
    );

    yield put({
      type: actionTypes.SEND_ME_THE_LINK_SUCCESS
    });
    yield put(
      openModal(modalTypes.RESULT, {
        title: "Let's do this!",
        text:
          "We're excited to work with you.\nCheck your phone for your link to download Dobby."
      })
    );
  } catch (err) {
    yield put({
      type: actionTypes.SEND_ME_THE_LINK_ERROR,
      payload: 'Something went wrong. Try again later'
    });
    yield put(
      openModal(modalTypes.ERROR, {
        title: 'Something went wrong. Try again later'
      })
    );
  }
}

export default function* watchSendMeTheLink() {
  yield takeLatest(actionTypes.SEND_ME_THE_LINK_FETCH, sendMeTheLink);
}
