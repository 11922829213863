import styled from 'styled-components/macro';
import styles from 'common/enums/styles';
import { device } from 'common/enums/devices';

export const Wrapper = styled.section`
  display: none;
  position: relative;
  overflow: hidden;
  padding: 184px 0 204px;
  @media screen and (min-width: 1200px) {
    display: grid;
    padding: 99px 0 117px;
    align-content: baseline;
    justify-items: center;
  }
`;
export const Container = styled.section`
  display: grid;
  gap: 30px;
  @media screen and ${device.TABLET} {
    grid-template-columns: repeat(2, auto);
    margin-top: 66px;
  }
  @media screen and ${device.LAPTOP} {
    grid-template-columns: repeat(2, 564px);
  }
`;

export const Title = styled.h1`
  letter-spacing: 0;
  margin: auto;
  margin-bottom: 0;
  width: 221px;
  color: ${styles.components.HEADER};
  text-align: center;
  padding-horizontal: 3%;
  @media screen and ${device.TABLET} {
    text-align: center;
    width: auto;
  }
  @media screen and ${device.LAPTOP_L} {
    font-size: 50px;
    line-height: 1.3;
  }
`;

export const Rates = styled.div`
  display: grid;
  gap: 27px;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
`;
