import { call, takeLatest, put } from 'redux-saga/effects';
import api_constants from 'common/enums/api';
import actionTypes from 'common/enums/actionTypes';
import Api from 'services/ApiService';

function* inviteUserByReferralLink(action) {
  const { payload } = action;
  let response = null;
  try {
    response = yield call(
      Api.apiPost,
      api_constants.INVITE_USER_BY_REFERRAL_LINK,
      payload
    );
    if (response.message === 'OK') {
      yield put({
        type: actionTypes.INVITE_USER_BY_REFERRAL_LINK_SUCCESS
      });
    } else {
      yield put({
        type: actionTypes.INVITE_USER_BY_REFERRAL_LINK_ERROR
      });
    }
  } catch (err) {
    yield put({
      type: actionTypes.INVITE_USER_BY_REFERRAL_LINK_ERROR
    });
  }
}

export default function* watchInviteUserByReferralLink() {
  yield takeLatest(
    actionTypes.INVITE_USER_BY_REFERRAL_LINK_FETCH,
    inviteUserByReferralLink
  );
}
