import React from 'react';
import { Element } from 'react-scroll';
import stepOne from 'static/images/icons/love-our-planet-step-1.svg';
import stepTwo from 'static/images/icons/love-our-planet-step-2.svg';
import stepThree from 'static/images/icons/love-our-planet-step-3.svg';
import { IconList } from 'components';

const steps = {
  JOB: {
    TYPE: 0,
    IMAGE: stepOne,
    TITLE: 'Use the Dobby app',
    TEXT: 'Complete a new job via Dobby app'
  },
  DONATE: {
    TYPE: 1,
    IMAGE: stepTwo,
    TITLE: 'We donate',
    TEXT: 'Dobby makes a donation to our partner OneTreePlanted'
  },
  TREE: {
    TYPE: 2,
    IMAGE: stepThree,
    TITLE: 'A tree is planted',
    TEXT: 'Together, we make a difference in our planet'
  }
};

const HowItWorks = () => (
  <Element name="plant-a-tree">
    <IconList items={steps} title={'How it works'} hasNudeBackground noWaves />
  </Element>
);

export default HowItWorks;
