import styles from 'common/enums/styles';

export const compareColumns = {
  VALUE: {
    NAME: '',
    TYPE: 'TITLE',
    FONT_SIZE: 32,
    COLOR: styles.colors.BLUE,
    BACKGROUND: styles.components.BACKGROUND_LIGHT_EXTRA,
    ORDER: 1
  },
  DOBBY: {
    NAME: 'Dobby',
    TYPE: 'DOBBY',
    IS_LOGO: true,
    COLOR: styles.components.TEXT_INVERTED,
    BACKGROUND: styles.components.HIGHLIGHT,
    IS_HIGHLIGHTED: true,
    ORDER: 3
  },
  MATCHMAKING: {
    NAME: 'Online Matchmaking Platforms',
    SUBNAME:
      'where you get connected to multiple pros and then you select the pro and manage the work',
    TYPE: 'MATCHMAKING',
    COLOR: styles.components.TEXT,
    BACKGROUND: styles.components.BACKGROUND_LIGHT_EXTRA,
    ORDER: 2
  }
};

export const compareTableData = [
  {
    id: 1,
    TITLE: 'Commitment to People',
    DOBBY:
      'Dobby pioneered the "one-job, one-pro" property management model for home services industry to support small, local providers. You still get multiple quotes for large jobs on Dobby, but pros don\'t pay us for leads.',
    MATCHMAKING:
      'They make money by selling your personal data ("leads") to multiple pros, don\'t solve your problem, and exploit service providers - by charging them upfront and forcing them to pay for leads that never realize to jobs. Read the ',
    LINKS: {
      MATCHMAKING: {
        TEXT: 'Federal Trade Commission Memo',
        REF:
          'https://www.ftc.gov/news-events/news/press-releases/2022/03/ftc-charges-homeadvisor-inc-cheating-businesses-including-small-businesses-seeking-leads-home'
      }
    }
  },
  {
    id: 2,
    TITLE: 'Commitment to the Planet',
    DOBBY:
      'Introduced climate program on the 2021 Earth Day. We plant a tree for every job you complete. And we contribute 0.5% of our revenue to carbon offset programs.',
    MATCHMAKING:
      'Not a single company in the carbon-heavy home services industry participates in any form of climate program.'
  },
  {
    id: 3,
    TITLE: 'Superior Quality',
    DOBBY:
      'Dobby is an invite-only community of top service pros. Through a strict vetting process, we ensure only the best join us. Our pros enjoy full back office support for marketing, tech, and payment - but to remain on Dobby, they must maintain a minimum 98% 5-star rating.',
    MATCHMAKING:
      'You can join any of these matchmaking platforms as a handyman faster than you finish reading this. They make money by selling leads to multiple pros and are incentivized to have low-quality pros on the platform so they can sell more!'
  },
  {
    id: 4,
    TITLE: 'Fair Pricing',
    DOBBY:
      'We run our business with automated operations and referral-powered marketing. Our independent pros run their businesses at minimal cost thanks to our comprehensive support. Both benefit you as a homeowner and you pay a fair price for jobs. Plus, we use AI to verify prices from thousands of data points for your peace of mind.',
    MATCHMAKING:
      "Service pros spend marketing money upfront on these platforms and pass that cost to you. With no dedicated customer success, service pros spend even more money on running their business which you pay for as well! Additionally, prices vary widely and can't be verified."
  },
  {
    id: 5,
    TITLE: 'The Magic',
    DOBBY:
      'Dobby is built on an AI-first infrastructure to do things magically better, faster, and smarter. We love good design, playful micro-copies, and the sheer goodness of a passionate user community. Our homeowners find Dobby experience a story worth telling! Our founders are pioneers of AI-first marketplace and hospitality.',
    MATCHMAKING:
      'These platforms make money and leave the playground as soon as pros are connected to the homeowner. With a lead generation business model, they have no incentives to build magical products. Founded in pre-AI and pre-Airbnb era.'
  }
];
