import styled from 'styled-components/macro';
import styles from 'common/enums/styles';
import { device } from 'common/enums/devices';

export const WrapperSection = styled.div`
  width: 100%;
  background-color: ${styles.colors.NUDE};
`;

export const Wrapper = styled.div`
  max-width: 950px;
  position: relative;
  padding: 0 20px 20px 20px;

  @media screen and ${device.LAPTOP} {
    padding-top: ${({ withHeader }) =>
      withHeader ? `${styles.layout.MENU_HEIGHT}px` : 0};
    margin: auto;
  }
  @media screen and ${device.LAPTOP_L} {
    max-width: 100%;
    width: 90%;
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0;
  margin-top: 80px;
  @media screen and ${device.LAPTOP} {
    margin-top: 40px;
    justify-content: center;
    padding: 0 20%;
  }
`;

export const TopHeader = styled.h1`
  margin: 0;
  padding: 0;
  font-weight: 700;
  letter-spacing: 0;
  font-size: 48px;
  color: ${styles.colors.DARK_BLUE};
  line-height: ${({ lineHeight }) => lineHeight || 1.3};
  text-align: left;
  @media screen and ${device.MOBILE} {
    width: 335px;
  }
  @media screen and ${device.TABLET} {
    width: auto;
    margin-bottom: 0;
  }
`;

export const ColoredHeader = styled.span`
  color: ${styles.colors.GREEN};
`;

export const ResponseWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0;
  margin-top: 20px;
}
`;

export const ResponseBlock = styled.text`
  width: 100%;
  padding: 25px;
  padding-left: 48px;
  background-color: ${styles.colors.WHITE_NEW};
  color: ${styles.colors.DARK_BLUE};
  border-radius: 8px;
  box-shadow: 0px 0px 0px;
  white-space: pre-line;
`;

export const FormWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0;
  margin-bottom: 20px;
  @media screen and ${device.LAPTOP} {
    justify-content: center;
    padding: 0 20%;
    margin-bottom: 40px;
  }
`;

export const Form = styled.form`
  max-width: 400px;
  min-width: 360px;
  margin: 0 auto;

  @media screen and ${device.LAPTOP} {
    margin: 0;
    max-width: 100%;
    width: 100%;
  }
`;

export const InputGroup = styled.div`
  display: grid;
  margin-top: 20px;
  width: 100%;
  gap: 10px;
`;

export const InputWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  @media screen and ${device.LAPTOP} {
    flex-direction: row;
  }

  input {
    background-color: ${styles.colors.WHITE_NEW};
    margin: 0;
  }

  textarea {
    transition: all 0.2s ease-out;
    font-family: 'HKGrotesk', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: 1px;
    padding: 12px 0 12px 48px;
    background-color: ${styles.colors.WHITE_NEW};
    color: ${styles.colors.DARK_BLUE};
    border-radius: 8px;
    box-shadow: 0px 0px 0px;
    border: 0;
  }
`;

export const SendButtonWrapper = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
`;

export const GetAppWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 80px 0 60px;
`;
