import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import NumberFormat from 'react-number-format';
import { Element } from 'react-scroll';
import { reduxForm } from 'redux-form';
import mixpanel from 'mixpanel-browser';

import { useTrackPageViews } from 'hooks';
import { analyticsPageTypes } from 'common/enums/pages';
import { GetApp, IconList } from 'components';
import {
  selectUserId,
  selectGetUserReferralLinkLoading
} from 'store/reducers/user';
import { getReferralLink } from 'store/actions/user';

import {
  Wrapper,
  BuildCommunitySection,
  BuildCommunitySubHeader,
  ContactsSection,
  BuildCommunityContent,
  BuildCommunityPhoto,
  TopHeader,
  ColoredHeader,
  SubHeader,
  Contacts,
  ContactItem,
  ContactPhoto,
  ContactFullName,
  ContactPosition,
  ContactEmail,
  Form,
  Image,
  InputPrefix,
  Link,
  SendButtonWrapper,
  InputWrapper,
  Footnote,
  GetAppWrapper,
  WrapperSection,
  InputGroup
} from './styled';

import InviteUserModal from './InviteUserModal';

import { Input, Button } from 'components';
import growTogetherHouses from 'static/images/icons/grow-together-houses.svg';

import { useHistory } from 'react-router-dom';
import { contacts, providerSteps, homeOwnersteps } from './enums';

const GrowTogetherPage = ({
  type = 'homeowner',
  errors,
  fields,
  values,
  getReferralLink,
  getUserReferralLinkLoading,
  resetForm
}) => {
  const isGrowTogether = type === 'homeowner';
  const page = isGrowTogether
    ? analyticsPageTypes.GROW_TOGETHER
    : analyticsPageTypes.BUILD_TOGETHER;
  useTrackPageViews({ page });
  const [isInviteFormShown, setIsInviteFormShown] = useState(false);
  const [showValidation, setShowValidation] = useState({});

  const history = useHistory();
  const isUA = history.location.hash === '#ua';
  const phoneNumberCode = isUA ? '+38.' : '+1.';

  const isFormFilled = Boolean(values.name && values.phone_number);

  useEffect(() => {
    if (isFormFilled && !getUserReferralLinkLoading) {
      toggleInviteForm();
      setShowValidation({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getUserReferralLinkLoading]);

  const toggleInviteForm = () =>
    setIsInviteFormShown(isFormShown => !isFormShown);

  const isFieldError = field => errors[field] && showValidation[field];

  const activateValidation = field => () => {
    if (!showValidation[field]) {
      setShowValidation(showValidation => ({
        ...showValidation,
        [field]: true
      }));
    }
  };

  const submitGetReferralLinkForm = event => {
    event.preventDefault();
    getReferralLink({
      phone_number: `${phoneNumberCode}${values.phone_number}`,
      group_id: isGrowTogether ? 1 : 2,
      given_name: values.name
    });

    mixpanel.track('"Give $50" Sign Ups', {
      $name: values.name,
      'Entry Point': page,
      $phone_number: `+1${values.phone_number}`
    });
  };

  return (
    <>
      <WrapperSection>
        <Wrapper id="mainSection" withHeader>
          <BuildCommunitySection>
            <BuildCommunityContent>
              <TopHeader>
                {isGrowTogether ? (
                  <>
                    <ColoredHeader>Get rewarded</ColoredHeader> to build
                    <br />
                    our community
                  </>
                ) : (
                  <>
                    It <ColoredHeader>pays</ColoredHeader> to build our Service
                    Pro community.
                  </>
                )}
              </TopHeader>
              <BuildCommunitySubHeader>
                {isGrowTogether ? (
                  <>
                    Love Dobby? Tell your friends & neighbors and you 
                    <span>both get $50</span> when they complete
                    their first maintenance job.
                  </>
                ) : (
                  <>
                    Refer quality service providers to join Dobby and you{' '}
                    <span>will get $50</span>. Enter your name and phone number
                    to start.
                  </>
                )}
              </BuildCommunitySubHeader>
              <Form>
                <InputGroup>
                  <InputWrapper>
                    <Input
                      fullWidth
                      errorBorders={isFieldError('name')}
                      type="text"
                      placeholder="Your Name"
                      disabled={getUserReferralLinkLoading}
                      {...fields.name}
                      onBlur={activateValidation('name')}
                    />
                  </InputWrapper>
                  <InputWrapper>
                    <InputPrefix left={isUA ? 39 : 48}>
                      {phoneNumberCode}
                    </InputPrefix>
                    <NumberFormat
                      fullWidth
                      type="tel"
                      format={'###.###.####'}
                      isUA={phoneNumberCode === '+38'}
                      customInput={Input}
                      placeholder="202.460.7470"
                      padding="12px 91px 12px 73px"
                      smallBottomMargin
                      lineHeight={1}
                      errorBorders={isFieldError('phone_number')}
                      {...fields.phone_number}
                      onBlur={activateValidation('phone_number')}
                      disabled={getUserReferralLinkLoading}
                    />
                  </InputWrapper>
                </InputGroup>
                <SendButtonWrapper>
                  <Button
                    title="GET $50"
                    disabled={
                      isFieldError('name') ||
                      isFieldError('phone_number') ||
                      !values.name ||
                      !values.phone_number ||
                      getUserReferralLinkLoading
                    }
                    loading={getUserReferralLinkLoading}
                    onClick={submitGetReferralLinkForm}
                  />
                  {type === 'provider' && (
                    <Footnote>* after their first completed job</Footnote>
                  )}
                </SendButtonWrapper>
              </Form>
            </BuildCommunityContent>
            <BuildCommunityPhoto>
              <Image src={growTogetherHouses} alt="Grow Together Houses" />
            </BuildCommunityPhoto>
          </BuildCommunitySection>
        </Wrapper>
        <Element name="scrollBelowSecondSection" />
        <IconList
          items={isGrowTogether ? homeOwnersteps : providerSteps}
          title={'How it works'}
          noWaves
        />
        <Wrapper>
          <ContactsSection>
            <SubHeader centered>
              {isGrowTogether
                ? 'Our Home team is here to help!'
                : 'Our team is here to help'}
            </SubHeader>
            <Contacts>
              {Object.values(contacts).map((contact, idx) => (
                <ContactItem key={idx}>
                  <ContactPhoto>
                    <Image src={contact.PHOTO} bordered alt="Contact Photo" />
                  </ContactPhoto>
                  <ContactFullName>{contact.FULLNAME}</ContactFullName>
                  <ContactPosition marginBottom={4}>
                    {contact.POSITION}
                  </ContactPosition>
                  <ContactEmail>
                    <Link
                      href={`mailto:${contact.EMAIL}`}
                      target="_blank"
                      rel="noopener noreferrer">
                      {contact.EMAIL}
                    </Link>
                  </ContactEmail>
                </ContactItem>
              ))}
            </Contacts>
          </ContactsSection>
        </Wrapper>
      </WrapperSection>
      <GetAppWrapper>
        <GetApp
          hasTitle
          hasArrows
          isCentered
          entryPoint={page}
          appName={isGrowTogether ? 'HO' : 'SP'}
        />
      </GetAppWrapper>
      <InviteUserModal
        isInviteFormShown={isInviteFormShown}
        toggleInviteForm={toggleInviteForm}
        resetGetReferralLink={resetForm}
        type={type}
      />
    </>
  );
};

const validate = values => {
  const errors = {};

  if (!values.name || values?.name.trim() === '') {
    errors.name = 'required';
  }

  if (!values.phone_number) {
    errors.phone_number = 'required';
  } else if (values.phone_number && values.phone_number.indexOf(' ') >= 0) {
    errors.phone_number = 'wrongFormat';
  }

  return errors;
};

const mapStateToProps = state => ({
  userId: selectUserId(state),
  getUserReferralLinkLoading: selectGetUserReferralLinkLoading(state)
});

const mapDispatchToProps = {
  getReferralLink
};

export default reduxForm({
  form: 'getReferralLink',
  fields: ['name', 'phone_number'],
  validate
})(connect(mapStateToProps, mapDispatchToProps)(GrowTogetherPage));
