import styled from 'styled-components';
import { device } from 'common/enums/devices';
import styles from 'common/enums/styles';

export const Section = styled.section`
  background: ${styles.components.BACKGROUND};
  overflow-x: hidden;
`;

export const DownloadImageWrapper = styled.div`
  margin: 56px 0;

  @media screen and ${device.LAPTOP} {
    margin-bottom: 100px;
  }
`;
