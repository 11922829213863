import styled from 'styled-components/macro';
import styles from 'common/enums/styles';
import { device } from 'common/enums/devices';
import ReactMarkdown from 'react-markdown';

export const Title = styled.div`
  font-weight: 700;
  font-size: 24px;
  @media screen and ${device.TABLET} {
    font-size: 50px;
  }
  line-height: 1.2;
`;

export const SubTitle = styled.div`
  font-size: 18px;
  line-height: 1.2;
  letter-spacing: 1px;
  margin: 16px 0 32px 0;
  @media screen and ${device.TABLET} {
    font-size: 24px;
    margin: 16px 0 57px 0;
  }
`;

export const Wrapper = styled.div`
  max-width: 876px;
  padding: ${styles.layout.MOBILE_MENU_HEIGHT + 40}px 16px 0 16px;
  @media screen and ${device.TABLET} {
    padding: ${styles.layout.MENU_HEIGHT + 100}px 16px 0 16px;
  }
  display: grid;
  margin: 0 auto;
`;

export const SectionTitle = styled.div`
  font-weight: 700;
  font-size: 18px;
  @media screen and ${device.TABLET} {
    font-size: 24px;
  }
  margin-top: 56px;
  line-height: 1.2;
  letter-spacing: 1px;
`;
export const SectionSubTitle = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 1.2;
  letter-spacing: 1px;
  @media screen and ${device.TABLET} {
    font-size: 18px;
  }
`;

export const SectionDesc = styled(ReactMarkdown)`
  * {
    font-size: 16px;
    @media screen and ${device.TABLET} {
      font-size: 18px;
    }
    line-height: 1.2;
    letter-spacing: 1px;
    font-weight: 400;
  }
  bold {
    font-weight: 600;
  }
  margin-bottom: 10px;
  a {
    text-decoration: underline;
    color: ${styles.colors.DARK_BLUE};
  }
  li {
    list-style-type: disc;
    margin: 10px 0 0 30px;
  }
`;

export const BTNTitle = styled.div`
  font-weight: 700;
  font-size: 18px;

  @media screen and ${device.TABLET} {
    font-size: 24px;
  }
  line-height: 1.2;
  letter-spacing: 1px;
`;
export const BTNSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 34px;
  margin: ${({ isLast }) => (isLast ? '46px 0 0 0' : '46px 0 56px 0')};

  @media screen and ${device.TABLET} {
    ${({ mobileOnly }) => mobileOnly && 'display: none;'}
  }
`;

export const AverageSection = styled.div`
  margin: 18px 0;
  @media screen and ${device.TABLET} {
    margin: 24px 0 24px 28px;
  }
`;
export const Download = styled.div`
  font-weight: 700;
  font-size: 20px;
  display: none;
  align-items: center;
  justify-content: center;
  color: ${styles.colors.WHITE_NEW};
  background: ${styles.colors.GREEN};
  border-radius: 8px;
  width: 200px;
  height: 48px;
  cursor: pointer;
  font-family: 'HKGrotesk', sans-serif;
  @media screen and ${device.LAPTOP} {
    display: flex;
  }
  &:hover {
    color: ${styles.colors.WHITE_NEW + 'B3'};
    box-shadow: none;
  }
`;

export const MobileDownload = styled.div`
  @media screen and ${device.LAPTOP} {
    display: none;
  }
`;

export const Image = styled.div`
  @media screen and ${device.TABLET} {
    margin-bottom: 50px;
  }
  margin-bottom: 28px;
  display: flex;
  justify-content: center;
`;
export const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: ${styles.layout.MOBILE_MENU_HEIGHT + 40}px 16px 100px 16px;
  @media screen and ${device.TABLET} {
    padding: ${styles.layout.MENU_HEIGHT + 100}px 16px 100px 16px;
  }
`;

export const Summary = styled.div`
  margin: 24px 0 70px 0;
`;

export const MainDesc = styled.div`
  margin-bottom: 50px;
`;

export const DownloadImageWrapper = styled.div`
  margin-bottom: 100px;
  screen and ${device.TABLET} {
    margin-bottom: 80px;
  }
`;
