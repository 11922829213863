import styled from 'styled-components/macro';
import styles from 'common/enums/styles';
import { deviceSizes, device } from 'common/enums/devices';

export const BonusFormHeader = styled.h2`
  margin: 0;
  padding: 0;
  color: ${styles.colors.BLACK_TRUE};
  margin-bottom: 24px;
  @media screen and ${device.TABLET} {
    margin-bottom: 40px;
    text-align: center;
  }
`;

export const BonusForm = styled.form``;

export const Container = styled.div`
  width: 100%;
  max-width: 320px;
  margin: auto;
`;

export const InputWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  ${({ marginBottom }) => marginBottom && `margin-bottom: ${marginBottom}px`};
`;

export const InputPrefix = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: ${({ color }) => color || styles.colors.DARK_TRUE};
  position: absolute;
  left: ${({ left }) => left}px;
  @media screen and (max-width: ${deviceSizes.MOBILE}) {
    ${({ leftMob }) => leftMob && `left: ${leftMob}px`};
  }
`;

export const InputGroup = styled.div`
  display: grid;
  gap: 16px;
`;

export const RadioGroup = styled.div`
  margin-top: 20px;
  @media screen and ${device.LAPTOP} {
    margin-top: 29px;
  }
`;
export const ButtonGroup = styled.div`
  display: grid;
  gap: 16px;
  margin-top: 33px;
  @media screen and ${device.LAPTOP} {
    margin-top: 40px;
  }
`;
