import React from 'react';
import { connect } from 'react-redux';
import { selectModalData } from 'store/reducers/modal';
import { Modal } from 'modals/components';

const ErrorModal = ({ modalData: { title } }) => <Modal visible text={title} />;

const mapStateToProps = state => ({
  modalData: selectModalData(state)
});

export default connect(mapStateToProps, null)(ErrorModal);
