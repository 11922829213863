import { createActionTypes } from 'common/helpers';

const fullActions = [
  // customer auth:
  'SIGN_IN',
  'CONFIRM_SIGN_IN',
  // other:
  'SEND_CV',
  'GET_REFERRAL_LINK',
  'GET_REVIEWS',
  'INVITE_USER_BY_REFERRAL_LINK',
  'PLANT_TREE',
  'UNSUBSCRIBE_FROM_EMAIL',
  'ASK_US_ANYTHING',
  'SEND_ME_THE_LINK',
  'GET_PUBLICATIONS',
  'GET_PUBLICATION',
  'SET_SUBSCRIPTION',
  'ADD_GLOW_MAGIC_LINK',
  'USER_UTM_TRACK',
  'ASK_PLANNING_MODE',
  'GET_ARTICLES',
  'GET_ARTICLE'
];

const singleActions = [
  'SET_SUBSCRIPTION_STEP',
  'SET_ACTIVE_JOB',
  'OPEN_MODAL',
  'CLOSE_MODAL',
  'OPEN_NAVIGATION',
  'CLOSE_NAVIGATION',
  'SET_LOCATION_BY_IP',
  'DISMISS_INVITE_USER_BY_REFERRAL_LINK_RESULT'
];

export default createActionTypes(fullActions, singleActions);
