import React from 'react';
import { connect } from 'react-redux';
import { selectModalData } from 'store/reducers/modal';
import { Modal } from 'modals/components';
import {
  Container,
  Title,
  BoldText,
  Question,
  Download,
  DownloadContainer,
  MobileDownload
} from './styled';
import { reset } from 'redux-form';
import { analyticsPageTypes } from 'common/enums/pages';
import { GetApp } from 'components';
import { getFirebaseDobbyDownloadLink } from 'common/helpers';
import { useLocation } from 'react-router-dom';
import { useUtmParams } from 'hooks';

const AINoResult = ({ reset, modalData: { email, question } }) => {
  const location = useLocation();

  const { utmParamsString } = useUtmParams({
    page: analyticsPageTypes.ATTIC
  });
  const dobbyAppDownloadLink = getFirebaseDobbyDownloadLink({
    utmParamsString,
    pathname: location.pathname
  });
  return (
    <Modal
      visible
      centeredContent
      scrollable
      onClose={() => reset('askUsAnything')}>
      <Question>{question}</Question>
      <Title>
        Oops! I’m not ready to answer right away. Please try again later.
      </Title>

      <DownloadContainer>
        <Download
          onClick={() => {
            window.open(dobbyAppDownloadLink, '_blank');
          }}>
          Download Dobby
        </Download>
        <MobileDownload>
          <GetApp entryPoint={analyticsPageTypes.CHECK_THE_PRICE} />
        </MobileDownload>
      </DownloadContainer>
    </Modal>
  );
};

const mapStateToProps = state => ({
  modalData: selectModalData(state)
});

const mapDispatchToProps = {
  reset
};

export default connect(mapStateToProps, mapDispatchToProps)(AINoResult);
