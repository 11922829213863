import styled from 'styled-components';

export const TypeFormWrapper = styled.div`
  position: relative;
  max-width: 100vw;
  margin-top: -30px;
  overflow: scroll;
  scroll-y: visible;
  margin: 0;
`;
