import styled from 'styled-components/macro';
import styles from 'common/enums/styles';
import { device } from 'common/enums/devices';

export const Container = styled.section`
  position: relative;
  color: ${styles.colors.PURE_BLACK};
  background-color: ${styles.components.BACKGROUND};
  padding: 129px 13px 58px;
  overflow: hidden;
  @media screen and ${device.LAPTOP} {
    padding: 154px 0 82px;
    display: grid;
    align-content: baseline;
    justify-items: center;
  }
`;

export const Title = styled.h1`
  color: ${styles.components.HEADER_INVERTED};
  margin-bottom: 0;
  margin: auto;
  padding-left: 10px;
  text-align: left;
  letter-spacing: 0;
  @media screen and ${device.TABLET} {
    padding-left: 0;
    text-align: center;
    max-width: 75%;
  }
  @media screen and ${device.LAPTOP_L} {
    font-size: 50px;
    line-height: 1.3;
    width: 730px;
  }
`;

export const TextWrapper = styled.div`
  margin: auto;
  text-align: left;
  max-width: 859px;
  margin-top: 14px;
  padding-left: 10px;
  @media screen and ${device.TABLET} {
    text-align: center;
    margin-top: 26px;
    width: 96%;
    padding-left: 0;
  }

  p {
    @media screen and ${device.LAPTOP} {
      letter-spacing: -0.2px;
    }
  }
`;

export const GetAppWrapTop = styled.div`
  margin-top: 40px;
  display: grid;
  justify-content: flex-start;
  @media screen and ${device.TABLET} {
    justify-content: center;
  }
  @media screen and ${device.LAPTOP_L} {
    margin-top: 24px;
  }
`;
