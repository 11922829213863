import styled from 'styled-components';
import styles from 'common/enums/styles';
import { MemberContainer } from '../Member/styled';
import { TEAM_DESKTOP_WIDTH } from '../../constants';

export const ProfileLink = styled.svg`
  height: ${({ size }) => size || 60}px;
  width: ${({ size }) => size || 60}px;
  fill: ${styles.colors.WHITE_NEW};

  ${({ relative }) =>
    !relative &&
    `
    position: absolute;
    bottom: -1px;
    right: -1px;
  `}

  ${MemberContainer} a & {
    display: ${({ desktopOnly }) => (desktopOnly ? 'none' : 'block')};
  }

  @media screen and (min-width: ${TEAM_DESKTOP_WIDTH}px) {
    height: ${({ size }) => size || 36}px;
    width: ${({ size }) => size || 36}px;
    ${MemberContainer} a & {
      display: ${({ mobileOnly }) => (mobileOnly ? 'none' : 'block')};
    }
  }

  &:hover {
    cursor: pointer;
    ${({ hasReverseHover }) => {
      return hasReverseHover
        ? `
      rect {
        fill: ${styles.colors.BLUE};
      }`
        : `
      path {
        fill: ${styles.colors.BLUE};
      }`;
    }}
  }
`;
