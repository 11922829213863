import React from 'react';
import { Paragraph } from './styled';
import { DEFAULT_TEXT_TYPE } from './constants';

const Text = ({
  description1,
  description2,
  text1,
  text2,
  children,
  ...params
}) => {
  const types = {
    description1,
    description2,
    text1,
    text2
  };

  const type = Object.keys(types).find(key => types[key]) || DEFAULT_TEXT_TYPE;

  return (
    <Paragraph type={type} {...params}>
      {children}
    </Paragraph>
  );
};

export default Text;
