import actionTypes from 'common/enums/actionTypes';

const defaultState = {
  modalType: '',
  confirmationData: {}
};

function reducer(state = defaultState, action) {
  switch (action.type) {
    case actionTypes.OPEN_MODAL: {
      return {
        ...state,
        modalType: action.payload.modalType,
        confirmationData: action.payload.data || state.confirmationData
      };
    }

    case actionTypes.CLOSE_MODAL: {
      return {
        ...state,
        modalType: '',
        confirmationData: {}
      };
    }

    default:
      return state;
  }
}

// selectors
export const selectModalType = state => state.modal.modalType;
export const selectModalData = state => state.modal.confirmationData;

export default reducer;
