import styled, { keyframes } from 'styled-components/macro';
import styles from 'common/enums/styles';
import { device } from 'common/enums/devices';

const fadeInFromNone = keyframes`
  0% {
      display: none;
      opacity: 0;
  }

  1% {
      display: block;
      opacity: 0;
  }

  100% {
      display: block;
      opacity: 0.56;
  }
`;

export const DarkerBackground = styled.div`
  display: none;
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  animation: ${fadeInFromNone} 0.2s ease-out;
  background-color: ${styles.components.BACKGROUND};
  ${({ isMobileNavBar }) =>
    isMobileNavBar &&
    `
      display: block; 
      opacity: 0.56;
    `}

  @media screen and ${device.LAPTOP} {
    display: none;
  }
`;

export const Navigation = styled.div`
  position: fixed;
  z-index: 7;
  top: 0;
  left: 0;
  right: 0;
  height: ${styles.layout.MOBILE_MENU_HEIGHT}px;
  @media screen and ${device.LAPTOP} {
    height: ${styles.layout.MENU_HEIGHT}px;
  }
`;

export const TopNavBar = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: ${styles.components.BACKGROUND};
  height: ${styles.layout.MOBILE_MENU_HEIGHT}px;
  padding: 0 20px 0 13px;
  position: relative;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.15);

  @media screen and ${device.LAPTOP} {
    height: ${styles.layout.MENU_HEIGHT}px;
    flex: 1;
    padding: 0 25px;
  }
`;

export const DesktopNavigation = styled.div`
  display: none;
  @media screen and ${device.LAPTOP} {
    display: block;
  }
`;

export const LogoWrapper = styled.div`
  margin: 0 auto;
  @media screen and ${device.LAPTOP} {
    margin: 0;
  }
`;

export const MobileNavigation = styled.div`
  transition: all 0.2s ease;
  border-bottom: none;
  transform: translateY(-100%);
  opacity: 0;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${styles.components.BACKGROUND};
  overflow: auto;
  
  ${({ isNavigationOpened }) =>
    isNavigationOpened &&
    `
        transform: translateY(0);
        opacity: 1;
    `}
    @media screen and ${device.LAPTOP} {
      display: none;
    }
`;
