import styled from 'styled-components/macro';
import styles from 'common/enums/styles';
import { device } from 'common/enums/devices';

export const StyledRadio = styled.div`
  border: 1px solid ${({ color }) => (color ? color : styles.colors.GREEN)};
  border-radius: 50%;
  box-sizing: border-box;
  display: inline-box;
  padding: 7px;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  vertical-align: middle;
  position: absolute;
  &:before {
    content: '';
    position: absolute;
    display: none;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: ${({ color }) => (color ? color : styles.colors.GREEN)};
  }
`;

export const RadioInput = styled.input`
  width: 1px;
  clip: rect(0 0 0 0);
  height: 1px;
  overflow: hidden;
  left: 0;
  top: 0;
  position: absolute;
  color: ${({ color }) => color};
  &:focus:not([disabled]) + ${StyledRadio} {
    box-shadow: 0 0 0 0.1em ${styles.colors.GRAY};
  }
  &:checked + ${StyledRadio} {
    &:before {
      display: block;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  &:hover:not([disabled]) + ${StyledRadio} {
    cursor: pointer;
  }
`;

export const RadioLabel = styled.label`
  font-family: Helvetica, Arial, sans-serif;
  margin: 6px 0 6px 35px;
  vertical-align: middle;
  display: inline-block;
  color: ${styles.colors.DARK_BLUE};
  font-size: 16px;
  line-height: 1;
  letter-spacing: 1px;
  font-weight: 400;
  &:hover:not([disabled]) {
    cursor: pointer;
  }
  ${({ labelColor }) => labelColor && `color: ${labelColor}`};
  ${({ disabled }) => disabled && `color: ${styles.colors.GRAY}`};
  ${RadioInput}:checked ~ p {
    color: red;
    ${({ selectedLabelColor }) =>
      selectedLabelColor && `color: ${selectedLabelColor}`};
    ${({ selectedWeight }) =>
      selectedWeight && `font-weight: ${selectedWeight}`};
  }
  @media screen and (max-width: 420px) {
    font-size: 14px;
    letter-spacing: initial;
    margin: 6px 0 6px 28px;
  }
`;

export const RadioWrapper = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  margin-bottom: 0;
  @media screen and ${device.LAPTOP} {
    margin-bottom: 2px;
  }
`;
