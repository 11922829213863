import React, { useState, useEffect } from 'react';

import homeQR from 'static/images/qr/home.png';

import { QRCode, QRWrapper } from './styled';

const DownloadButton = ({ isHero, desktopOnly }) => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [animate, setAnimate] = useState(false);

  const handleScroll = () => {
    setScrollPosition(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (scrollPosition > 120) {
      setAnimate(true);
    } else {
      setAnimate(false);
    }
  }, [scrollPosition]);

  const isAnimate = isHero && animate;

  return (
    <QRWrapper isAnimate={isAnimate} desktopOnly={desktopOnly}>
      <QRCode isHero={isHero} src={homeQR} alt="Download Dobby" />
    </QRWrapper>
  );
};

export default DownloadButton;
