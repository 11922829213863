import styled from 'styled-components/macro';
import styles from 'common/enums/styles';
import {
  TEAM_DESKTOP_WIDTH,
  PORTRAIT_DESKTOP_SIZE,
  PORTRAIT_MOBILE_SIZE
} from '../../constants';

export const MemberContainer = styled.div`
  background-color: ${styles.colors.NUDE};
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 12px 12px;
  position: relative;
  overflow: hidden;
  align-items: center;

  @media screen and (min-width: ${TEAM_DESKTOP_WIDTH}px) {
    width: unset;
    flex-wrap: wrap;
    &:hover {
      box-shadow: ${styles.boxShadows.DARK};
    }
  }
`;

export const MemberStory = styled.p`
  color: ${styles.colors.DARK_BLUE};
  font-size: 14px;
  line-height: 1.5;
  margin-bottom: 10px;

  @media screen and (min-width: ${TEAM_DESKTOP_WIDTH}px) {
    padding: 0;
    font-size: 11px;
    margin-top: 3px;
    margin: 3px 0 0 0;
  }
`;

export const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  white-space: pre-wrap;
  width: ${PORTRAIT_MOBILE_SIZE}px;

  @media screen and (min-width: ${TEAM_DESKTOP_WIDTH}px) {
    background-image: ${styles.gradients.LIGHT},
      url(${({ imageSrc }) => imageSrc});
    background-position: center;
    background-size: cover;
    left: 0;
    right: 0;
    top: 0;
    height: ${PORTRAIT_DESKTOP_SIZE}px;
    position: absolute;
    visibility: hidden;
    justify-content: flex-end;
    padding: 16px 14px 6px;
    opacity: 0;
    width: ${PORTRAIT_DESKTOP_SIZE}px;
    transition: transform 350ms ease-in-out, opacity 350ms;
    ${MemberContainer}:hover & {
      visibility: visible;
      opacity: 1;
    }
  }
`;

export const MemberInfo = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 6px;
  margin: 0 0 2px 16px;

  @media screen and (min-width: ${TEAM_DESKTOP_WIDTH}px) {
    margin: 0;
  }

  p {
    line-height: 1.1;
  }
`;

export const Name = styled.h2`
  margin-bottom: 1px;
  @media screen and (min-width: ${TEAM_DESKTOP_WIDTH}px) {
    font-size: 27px;
  }
`;
export const DescriptionRow = styled.div`
  display: flex;
  // To prevent 0.5 cut of LI icon
  margin: -1px;

  @media screen and (min-width: ${TEAM_DESKTOP_WIDTH}px) {
    margin-bottom: 7px;
  }
`;
