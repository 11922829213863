import React from 'react';
import { BackgroundVideo, Text } from 'components';
import styles from 'common/enums/styles';
import { pageTypes } from 'common/enums/pages';

import { ColorWrapper, Section, Container, Title } from './styled';

const HeroVideo = ({
  light,
  backgroundType,
  children,
  srcFull,
  srcMobile,
  srcPlaceholder,
  subTitle,
  title
}) => {
  const hasTopBar = pageTypes.HOME === backgroundType;
  return (
    <ColorWrapper type={backgroundType} hasTopBar={hasTopBar} id="mainSection">
      <Section>
        <BackgroundVideo
          light={light}
          srcFull={srcFull}
          srcMobile={srcMobile}
          srcPlaceholder={srcPlaceholder}
          backgroundType={backgroundType}
        />
        <Container type={backgroundType}>
          {Boolean(title) && <Title type={backgroundType}>{title}</Title>}
          {Boolean(subTitle) && (
            <Text text1 color={styles.colors.WHITE_NEW} textAlign="center">
              {subTitle}
            </Text>
          )}
          {children}
        </Container>
      </Section>
    </ColorWrapper>
  );
};

export default HeroVideo;
