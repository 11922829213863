import React from 'react';
import { LoadingSpinner } from '@sourcegraph/react-loading-spinner';
import '@sourcegraph/react-loading-spinner/lib/LoadingSpinner.css';
import { StyledButton } from './styled';

const Button = ({
  onClick,
  disabled,
  title,
  loading,
  fullWidth,
  type,
  isMobileActive,
  disabledHover,
  flat,
  otp
}) => (
  <StyledButton
    type={type}
    onClick={!disabled ? onClick : () => {}}
    disabled={disabled || loading}
    fullWidth={fullWidth}
    disabledHover={disabledHover}
    isMobileActive={isMobileActive}
    flat={flat}
    otp={otp}>
    {loading ? <LoadingSpinner /> : title}
  </StyledButton>
);

export default Button;
