import React, { useEffect } from 'react';
import { scroller, Element } from 'react-scroll';

import { useTrackPageViews } from 'hooks';
import { analyticsPageTypes, pageTypes } from 'common/enums/pages';
import styles from 'common/enums/styles';
import { DOCUMENT_TITLE } from 'common/constants/html';
import { Hero, MainContent } from 'components';

import hero from 'static/images/hero/hero-home@2x.png';
import heroPlaceholder from 'static/images/hero/hero-home@0.5x.png';

import {
  Companies,
  CoreValues,
  Investors,
  JoinUs,
  Letter,
  // Team,
  ButtonSection
} from './components';

const AboutUs = ({ location }) => {
  useTrackPageViews({ page: analyticsPageTypes.ABOUT_US });
  const scrollTo = section => {
    scroller.scrollTo(section, {
      duration: 1000,
      delay: 100,
      smooth: true,
      offset:
        document.body.offsetWidth < 1024
          ? -styles.layout.MOBILE_MENU_HEIGHT
          : -styles.layout.MENU_HEIGHT
    });
  };

  useEffect(() => {
    document.title = DOCUMENT_TITLE;
    window.scrollTo(0, 0);
    const hash = location.hash.slice(1);
    if (hash) {
      setTimeout(() => scrollTo(hash), 100);
    } else {
      window.scrollTo(0, 0);
    }

    if (location.state?.section) {
      setTimeout(() => scrollTo(location.state?.section), 100);
    }
  }, [location.hash, location.state]);

  return (
    <MainContent>
      <Hero
        title="Reimagining our homes with AI & Community"
        imageSrc={hero}
        placeholderSrc={heroPlaceholder}
        backgroundType={pageTypes.ABOUT_US}
      />
      <Element name="scrollBelowSecondSection" />
      <Letter />
      {/* <Team /> */}
      <Companies />
      <CoreValues />
      <Investors />
      <ButtonSection />
      <JoinUs />
    </MainContent>
  );
};

export default AboutUs;
