import styled from 'styled-components';
import { textTypes } from './enums';
import { device } from 'common/enums/devices';

export const Paragraph = styled.p`
  ${({ type }) => {
    switch (type) {
      case textTypes.DESCRIPTION_1:
        return `
                font-weight: 600;
                font-size: 18px;
                @media screen and ${device.LAPTOP} {
                    font-size: 24px;
                }
            `;
      case textTypes.DESCRIPTION_2:
        return `
                font-weight: 400;
                font-size: 18px;
                @media screen and ${device.LAPTOP} {
                    font-size: 24px;
                }
            `;
      case textTypes.HEADING_1:
        return `
                font-weight: 600;
                font-size: 36px;
                @media screen and ${device.LAPTOP} {
                    font-size: 48px;
                }
            `;
      case textTypes.TEXT_1:
        return `
                font-weight: 600;
                font-size: 16px;
                line-height: 1.5;
            `;
      case textTypes.TEXT_2:
      default:
        return `
                font-weight: 400;
                font-size: 16px;
                line-height: 1.5;
            `;
    }
  }}
  ${({ color }) => Boolean(color) && `color: ${color};`}
  ${({ textAlign }) => Boolean(textAlign) && `text-align: ${textAlign};`}
  ${({ textShadow }) => Boolean(textShadow) && `text-shadow: ${textShadow};`}
`;
