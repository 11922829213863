import styles from 'common/enums/styles';
import styled from 'styled-components';
import { device } from 'common/enums/devices';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 0 100px 0;
  background-color: ${styles.colors.WHITE_NEW};
`;

export const Title = styled.h1`
  color: ${styles.components.HEADER};

  line-height: 1.2;
  font-weight: 700;
  text-align: center;
  white-space: pre-wrap;
  margin: 24px 20px;

  @media screen and ${device.TABLET} {
    font-size: 50px;
  }
`;
