import actionTypes from 'common/enums/actionTypes';

export const openModal = (modalType, data) => ({
  type: actionTypes.OPEN_MODAL,
  payload: {
    modalType,
    data
  }
});

export const closeModal = (modalType, data) => ({
  type: actionTypes.CLOSE_MODAL,
  payload: {
    modalType,
    data
  }
});
