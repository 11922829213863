import { call, takeLatest, put, all } from 'redux-saga/effects';
import Auth from '@aws-amplify/auth';
import actionTypes from 'common/enums/actionTypes';
import { API } from 'aws-amplify';
import { closeModal } from 'store/actions/modal';
import modalTypes from 'common/enums/modalTypes';
import api_constants from 'common/enums/api';

import { subscriptionSteps } from 'common/enums/subscription';

const amplifyGet = path => {
  return API.get('mainApi', path)
    .then(data => {
      return data;
    })
    .catch(err => {
      throw new Error(err);
    });
};

function* confirmSignIn(action) {
  const { payload } = action;
  try {
    const { user, code, onSuccess } = payload;

    yield call([Auth, Auth.sendCustomChallengeAnswer], user, code);

    const userInfo = yield call(amplifyGet, api_constants.ME);

    yield put({
      type: actionTypes.CONFIRM_SIGN_IN_SUCCESS,
      payload: userInfo
    });

    if (onSuccess) {
      onSuccess(userInfo);
    } else {
      yield put({
        type: actionTypes.SET_SUBSCRIPTION_STEP,
        payload: userInfo.address
          ? subscriptionSteps.CONFIRM
          : subscriptionSteps.NO_USER
      });
    }
    yield put(closeModal(modalTypes.CONFIRM_CODE));
  } catch (err) {
    yield put({
      type: actionTypes.CONFIRM_SIGN_IN_ERROR
    });
  }
}

export default function* watchConfirmSignIn() {
  yield takeLatest(actionTypes.CONFIRM_SIGN_IN_FETCH, confirmSignIn);
}
