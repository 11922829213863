import styled, { css } from 'styled-components/macro';
import styles from 'common/enums/styles';
import { NavLink } from 'react-router-dom';
import { device, deviceSizes } from 'common/enums/devices';

export const Line = styled.div`
  border-top: 1px solid ${styles.colors.GRAY_NEW};
  margin: 0 21px 0;
  display: flex;
  flex: 1;

  @media screen and ${device.LAPTOP} {
    display: none;
  }
`;

export const NavigationHolder = styled.ul`
  display: block;
  padding: ${styles.layout.MOBILE_MENU_HEIGHT}px 0 0 0;
  list-style: none;
  width: 100%;

  @media screen and (min-height: 500px) {
    padding: ${styles.layout.MOBILE_MENU_HEIGHT + 36}px 0 0 0;
  }

  @media screen and ${device.LAPTOP} {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0;
    padding: 0;
    min-height: 0;
  }
`;

export const NavigationLinkHolder = styled.li`
  padding: 2.9vh 24px;

  @media screen and (min-height: 500px) {
    padding: 20px 24px;
  }

  @media screen and ${device.LAPTOP} {
    padding: 0 20px;
    display: ${({ mobileOnly }) => (mobileOnly ? 'none' : 'block')};
  }
`;

const LinkStyles = css`
  font-size: 16px;
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: 1px;
  text-decoration: none;
  cursor: pointer;

  transition: box-shadow 0.2s ease;
  color: ${styles.colors.WHITE_NEW};

  @media screen and ${device.LAPTOP} {
    font-size: 20px;
    letter-spacing: unset;
  }
`;

export const NavigationLink = styled(NavLink)`
  ${LinkStyles}
  padding-bottom: 5px;

  &:hover,
  &.active {
    color: ${styles.components.LINK};
  }

  @media screen and ${device.LAPTOP} {
    font-size: 18px;
    letter-spacing: unset;
  }
`;

export const ExternalLink = styled.a`
  ${LinkStyles}
  padding-bottom: 5px;

  &:hover,
  &.active {
    color: ${styles.components.LINK};
  }

  @media screen and ${device.LAPTOP} {
    font-size: 18px;
    letter-spacing: unset;
  }
`;

export const StyledButtonLink = styled.a`
  ${LinkStyles}
  display: none;
  background: ${styles.colors.WHITE_NEW};
  border-radius: 53px;
  color: ${styles.colors.PURE_BLACK};
  padding: 12px 47px;
  text-align: center;
  margin-top: 8px;

  &:hover {
    color: ${styles.colors.BLUE};
    box-shadow: none;
  }

  @media screen and (max-height: ${deviceSizes.MOBILE}) {
    margin-top: 0px;
  }

  @media screen and ${device.LAPTOP} {
    margin-top: 0px;
    margin-left: 30px;
    width: 200px;
    display: inline-block;
  }
`;

export const MenuItemsWrapper = styled.div`
  display: flex;
  flex: 1;

  @media screen and ${device.LAPTOP} {
    display: block;
  }
`;

export const GetAppWrapper = styled.div`
  margin: 16px 24px 4vh;
`;
