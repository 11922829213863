export const amplifyConfigCustomer = {
  product: {
    Auth: {
      userPoolId: 'us-east-1_B90WOQIaY', //  Amazon Cognito User Pool ID
      userPoolWebClientId: '2o915b62cr69o9ob9s6tfkfq69', // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      identityPoolId: 'us-east-1:5ac8884b-43dc-4aea-ae32-101114401af9', // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
      region: 'us-east-1'
    },
    API: {
      endpoints: [
        {
          name: 'mainApi',
          endpoint: 'https://product.api.hellodobby.com/v0'
        }
      ]
    }
  },
  staging: {
    Auth: {
      userPoolId: 'us-east-1_cM1tYpDa3',
      userPoolWebClientId: '6h648147vhf6rn0p0d2cam8su1',
      identityPoolId: 'us-east-1:84bffec4-17ae-4eeb-a33e-6d6e7789e9f3',
      region: 'us-east-1'
    },
    API: {
      endpoints: [
        {
          name: 'mainApi',
          endpoint: 'https://staging.api.hellodobby.com/v0'
        }
      ]
    }
  }
};
