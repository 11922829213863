import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { sendMeTheLink } from 'store/actions/user';
import { selectSendMeTheLinkLoading } from 'store/reducers/loading';
import { useLocation } from 'react-router-dom';
import NumberFormat from 'react-number-format';

import { captchaActions } from 'common/enums/pages';
import { CAPTCHA_SITE_KEY } from 'common/constants/html';
import { Button } from 'components';
import imgPhone from 'static/images/marketing/meet-dobby-phone.png';

import {
  Container,
  Title,
  Text,
  TextContainer,
  Form,
  Input,
  InputWrapper,
  InputPrefix,
  ButtonWrap,
  ImageContainer,
  Wrapper,
  Image
} from './styled';

const TextForm = ({ loading, sendMeTheLink, isTop, hasNudeBackground }) => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const location = useLocation();
  const phoneNumberCode = location.hash === '#ua' ? '+38.' : '+1.';

  useEffect(() => {
    if (!loading) {
      setPhoneNumber('');
    }
  }, [loading]);

  const handleClick = () => {
    const number = (phoneNumberCode + phoneNumber).replaceAll('.', '');
    window.grecaptcha
      .execute(CAPTCHA_SITE_KEY, {
        action: captchaActions.SEND_ME_THE_LINK.SUBMIT
      })
      .then(token => {
        sendMeTheLink({
          security_token: token,
          phone_number: number
        });
      });
  };

  const isDisabled = Boolean(
    !phoneNumber || (phoneNumber && phoneNumber.indexOf(' ') >= 0)
  );

  return (
    <Wrapper isTop={isTop} background={hasNudeBackground}>
      <Container isTop={isTop}>
        <TextContainer isTop={isTop}>
          <Title>Your Best Financial Friend</Title>
          <Text>
            Meet your new BFF that's helped our members save over $100M in
            overdraft fees with Floats up to $50 between paydays. No credit
            checks. No interest.
          </Text>
          <Form>
            <InputWrapper>
              <InputPrefix left={location.hash === '#ua' ? 39 : 48}>
                {phoneNumberCode}
              </InputPrefix>
              <NumberFormat
                type="tel"
                format={`###.###.####`}
                padding="3px 91px 2px 72px"
                customInput={Input}
                backgroundNude
                isPhone
                placeholder={`202.460.7470`}
                smallBottomMargin={true}
                isTop={isTop}
                value={phoneNumber}
                onValueChange={values => {
                  const { formattedValue } = values;
                  setPhoneNumber(formattedValue);
                }}
              />
            </InputWrapper>
            <ButtonWrap>
              <Button
                title="Sent me the link"
                onClick={handleClick}
                disabled={isDisabled}
                loading={loading}
              />
            </ButtonWrap>
          </Form>
        </TextContainer>
        <ImageContainer>
          <Image src={imgPhone} />
        </ImageContainer>
      </Container>
    </Wrapper>
  );
};

const mapStateToProps = state => ({
  loading: selectSendMeTheLinkLoading(state)
});

const mapDispatchToProps = {
  sendMeTheLink
};

export default connect(mapStateToProps, mapDispatchToProps)(TextForm);
