import React from 'react';

import { Wrapper, Container, Title, Rates } from './styled';
import { Rate } from './components';

const Rating = ({ reviews }) => {
  return (
    <Wrapper>
      <Title>An experience like nothing else</Title>
      <Container>
        <Rates>
          {Object.values(reviews)
            .filter((item, index) => index % 2 === 0)
            .map((item, index) => (
              <Rate key={item.TEXT} item={item} isLighter={index % 2 === 0} />
            ))}
        </Rates>
        <Rates>
          {Object.values(reviews)
            .filter((item, index) => index % 2 !== 0)
            .map((item, index) => (
              <Rate key={item.TEXT} item={item} isLighter={index % 2 !== 0} />
            ))}
        </Rates>
      </Container>
    </Wrapper>
  );
};

export default Rating;
