import actionTypes from 'common/enums/actionTypes';

export const setSubscriptionStep = payload => ({
  type: actionTypes.SET_SUBSCRIPTION_STEP,
  payload
});
export const setSubscription = payload => ({
  type: actionTypes.SET_SUBSCRIPTION_FETCH,
  payload
});
