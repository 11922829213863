import React from 'react';
import { Svg } from './styled';
import styles from 'common/enums/styles';

const IconLocationPin = ({
  color = styles.colors.BLACK_TRUE,
  background = styles.colors.WHITE,
  size = 38,
  sizeMobile = 24
}) => (
  <Svg
    fill="none"
    height={size}
    heightMobile={sizeMobile}
    viewBox="0 0 38 38"
    width={size}
    widthMobile={sizeMobile}
    xmlns="http://www.w3.org/2000/svg">
    <circle cx={size / 2} cy={size / 2} r={size / 2} fill={background} />
    <path
      clipRule="evenodd"
      d="M26.7867 17.2014C26.7867 22.9637 18.9998 31.209 18.9998 31.209C18.9998 31.209 11.2129 22.9637 11.2129 17.2014C11.2129 12.5597 14.6992 8.79688 18.9998 8.79688C23.3004 8.79688 26.7867 12.5597 26.7867 17.2014ZM19.0004 19.5262C20.5264 19.5262 21.7635 18.2891 21.7635 16.7631C21.7635 15.2371 20.5264 14 19.0004 14C17.4744 14 16.2373 15.2371 16.2373 16.7631C16.2373 18.2891 17.4744 19.5262 19.0004 19.5262Z"
      fill={color}
      fillRule="evenodd"
    />
  </Svg>
);

export default IconLocationPin;
