import styled from 'styled-components/macro';
import styles from 'common/enums/styles';
import { device } from 'common/enums/devices';

export const Top = styled.div`
  padding-top: 26px;
  padding-bottom: 19px;
  text-align: center;
  width: 100%;
  @media screen and ${device.LAPTOP} {
    padding: 40px 61px;
    text-align: left;
    max-width: 1440px;
    margin: auto;
  }
`;

export const TopWrapper = styled.section`
  text-align: center;
  width: 100%;
  background-color: ${styles.colors.NUDE};
`;
