import catColor from 'static/images/roles/cat-color.svg';
// import dogColor from 'static/images/roles/dog-color.svg';
import octopusColor from 'static/images/roles/octopus-color.svg';
import bearColor from 'static/images/roles/bear-color.svg';
import dolphinColor from 'static/images/roles/dolphin-color.svg';
import owlColor from 'static/images/roles/owl-color.svg';

export const roles = {
  'frontend-engineer': {
    alt: 'CAT',
    name: 'Front-End Engineer (React Native)',
    location: 'Kyiv,\u00a0Ukraine',
    timeType: 'Full‑time',
    description: `We are looking for a front-end developer responsible for the development and maintenance of cross-platform mobile applications and web services. Your primary focus will be the development of iOS and Android applications and their integration with a back-end.\n\nYou will be working alongside other engineers and developers on different layers of the infrastructure. Therefore, a commitment to collaborative problem solving, sophisticated design, and the creation of quality products are essential.`,
    type: 'engineer',
    lists: [
      {
        listName: 'Core Requirements',
        listData: [
          '2+ years of experience developing consumer-facing applications.',
          'Ability to brainstorm and design the overall architecture of iOS and Android applications (preferably cross-platformed apps).',
          'Good knowledge and experience with React Native.',
          'Familiarity with ReactJS (or any other front-end framework), HTML and CSS.',
          'Understanding of cloud services (GCP & AWS) useful in frontend development.',
          'Familiarity with RESTful APIs to connect applications to back-end services.',
          'Collaboratively working with graphic designers and converting designs to visual elements (the user-centric design is a big part of what we do).',
          'Understanding of security and data protection (we work with highly sensitive customer activity data).',
          'Ability to work in a small development and design team.',
          'Build reusable front-end interfaces with various levels of abstraction.',
          'Help architect a modular and easily maintainable application infrastructure using sensible and appropriate technologies.'
        ]
      },
      {
        listName: 'Will be a huge plus',
        listData: [
          'The religious practice of using code versioning tools (Git).',
          'Maintain code integrity and organization with a long-term vision in mind.',
          'Maintain quality and ensure the responsiveness of applications.',
          'Ability to write unit tests and participate in code reviews.'
        ]
      },
      {
        listName:
          'As a founding team, we look for these three qualities in each other',
        listData: [
          'A learning mindset and willingness to build deep expertise at a particular discipline.',
          'A relentless desire to make a difference, a genuine love for community and people.',
          'A true willingness to work on a vision that is bigger than ourselves.'
        ]
      },
      {
        listName: 'What we offer',
        listData: [
          'Competitive salaries.',
          'Unlimited paid vacations and sick-leaves.',
          'Flexible schedule.',
          'Ground-floor impact and working with zero technical debt.',
          'One of those rare opportunities where you can truly “leave a dent in the universe”.',
          'This is a contract-to-hire position with full potential to become a critical senior hire of the engineering team.',
          'Free English classes.',
          'Modern and comfortable office very close to the Lybidska subway with perks such as free snacks and drinks.'
        ]
      }
    ],
    photo: catColor
  },
  'backend-engineer': {
    alt: 'BEAR',
    name: 'Back-end Engineer',
    location: 'Kyiv,\u00a0Ukraine',
    timeType: 'Full‑time',
    description: `We are looking for a back-end developer responsible for the development and maintenance of services for mobile apps, web apps and inner tools. Your primary responsibility will be the development of new and upgrading existing services for our mobile apps.\n\nYou will be working alongside other engineers and developers on different layers of the infrastructure. Therefore, a commitment to collaborative problem solving, sophisticated design, and the creation of quality products are essential.`,
    type: 'engineer',
    lists: [
      {
        listName: 'Core Requirements',
        listData: [
          '2+ years of experience developing backend applications.',
          'Good knowledge of Python 3.',
          'Ability to design serverless-based services.',
          'Understanding of cloud services (AWS).',
          'Familiarity with RESTful APIs concepts.',
          'Understanding of security and data protection (we work with highly sensitive customer activity data).',
          'Ability to work in a small development and design team.',
          'Build reusable components with various levels of abstraction.',
          'Help architect a modular and easily maintainable application infrastructure using sensible and appropriate technologies.'
        ]
      },
      {
        listName: 'Will be a huge plus',
        listData: [
          'The religious practice of using code versioning tools (Git).',
          'Maintain code integrity and organization with a long-term vision in mind.',
          'Maintain quality and ensure the responsiveness of applications.',
          'Ability to write unit tests and participate in code reviews.'
        ]
      },
      {
        listName:
          'As a founding team, we look for these three qualities in each other',
        listData: [
          'A learning mindset and willingness to build deep expertise at a particular discipline.',
          'A relentless desire to make a difference, a genuine love for community and people.',
          'A true willingness to work on a vision that is bigger than ourselves'
        ]
      },
      {
        listName: 'What we offer',
        listData: [
          'Competitive salaries.',
          'Unlimited paid vacations and sick-leaves.',
          'Flexible schedule.',
          'Ground-floor impact and working with zero technical debt.',
          'One of those rare opportunities where you can truly “leave a dent in the universe”.',
          'This is a contract-to-hire position with full potential to become a critical senior hire of the engineering team.',
          'Free English classes.',
          'Modern and comfortable office very close to the Lybidska subway with perks such as free snacks and drinks.'
        ]
      }
    ],
    photo: bearColor
  },
  'qa-engineer': {
    alt: 'OCTOPUS',
    name: 'QA Engineer',
    location: 'Kyiv,\u00a0Ukraine',
    timeType: 'Full‑time',
    description: `We are looking for a QA Engineer responsible for the testing and maintenance of services for mobile apps, web apps and inner tools. Your primary responsibility will be the testing of new and updates of existing services for our mobile apps and web resources.\n\nYou will be working alongside other engineers and developers on different layers of the infrastructure. Therefore, a commitment to collaborative problem solving, sophisticated design, and the creation of quality products are essential.
      `,
    type: 'engineer',
    lists: [
      {
        listName: 'Core Requirements',
        listData: [
          '2+ years of experience of testing products(web or mobile apps).',
          'Strong knowledge of testing types and methodologies.',
          'Ability to analyze user flows and build self-composed test plans based on them.',
          'Using automation tools to automate test cycles and assure high test coverage.',
          'Build reusable components with various levels of abstraction.',
          'Be proactive and resourceful by not only finding bugs but preventing them.',
          'Ability to work in a small development and design team.',
          'Regularly communicate QA status reports to the team.',
          'Ensuring each release meets well-defined quality standards on estimates, statuses, and risk.'
        ]
      },
      {
        listName: 'Will be a huge plus',
        listData: [
          'Experience in testing REST API.',
          'Knowledge of Python.',
          'Knowledge of JS.',
          'Knowledge of React Native.'
        ]
      },
      {
        listName:
          'As a founding team, we look for these three qualities in each other',
        listData: [
          'A learning mindset and willingness to build deep expertise at a particular discipline.',
          'A relentless desire to make a difference, a genuine love for community and people.',
          'A true willingness to work on a vision that is bigger than ourselves.'
        ]
      },
      {
        listName: 'What we offer',
        listData: [
          'Competitive salaries.',
          'Unlimited paid vacations and sick-leaves.',
          'Flexible schedule.',
          'Ground-floor impact and working with zero technical debt.',
          'One of those rare opportunities where you can truly “leave a dent in the universe”.',
          'This is a contract-to-hire position with full potential to become a critical senior hire of the engineering team.',
          'Free English classes.',
          'Modern and comfortable office very close to the Lybidska subway with perks such as free snacks and drinks.'
        ]
      }
    ],
    photo: octopusColor
  },
  'operations-manager': {
    alt: 'DOLPHIN',
    name: 'Operations Manager\nGrowth, Service Pro Community',
    location: 'Washington,\u00a0DC,\u00a0USA',
    timeType: 'Full‑time',
    description: `Service providers are the life-blood of Dobby’s home maintenance platform. We are looking to onboard an operations manager with a strong growth mindset to lead the expansion of our service providers community. If you have a genuine interest in creating opportunities for others then this role is for you. Join us to build Dobby - a technology company powered by humanity!`,
    type: 'operations',
    paragraphsStart: [
      {
        name: 'The OS for modern American homes',
        content: [
          'Dobby is fundamentally changing how we manage, maintain, and upgrade our homes. With an AI-first, fully digital productivity platform for homeowners and high-quality service providers, we are unlocking a severely antiquated $300B home maintenance marketplace. Dobby creates a luxury experience for homeowners, increases the property value, and builds a socially responsible community by supporting local small businesses and independent operators.',
          'Starting with a leapfrog home maintenance product, we are pioneering digitization & AI in the $16T residential property market. Our vision is "to become the operating system for American homes" by adding adjacent products and services over time.'
        ]
      },
      {
        name:
          'An incredible founding team backed by some world-class investors',
        content: [
          'We previously worked at AIG, Liberty Mutual, Deloitte, and Twitter. We built a multi-million dollar global business at AI unicorn DataRobot, witnessed torrid growth at Uber, and ran an anti-malaria program for The Clinton Foundation. Our engineering colleagues won TechCrunch Top Pick in AI/ ML in 2019.',
          'Dundee VC, our lead investor, is a prominent fund supporting midwest and mid-Atlantic companies. Our early investors include: Techstars, Mark Bailey (founder/ partner @ DFJ Growth, invested in companies like Tesla and SpaceX), Maury Devine (board @ ConocoPhillips, early investor Sweetgreen), and Andrew Macdonald (head of Uber Mobility).'
        ]
      }
    ],
    lists: [
      {
        listName:
          'In your day-to-day, you will be accountable for the expansion of our service provider community and make them successful working with Dobby',
        listData: [
          'You will source high-quality service providers from local communities (e.g., handymen, electricians, plumbers, etc - independent operators or small businesses).',
          'You will educate them about the Dobby PRO app - a productivity platform that helps service pros make more money in less time - with automatic deal-flow, efficient communication, digital payment, and customer success.',
          'You will run the vetting process, recruitment, and onboarding (product training, payment setup, pro tips)You will ensure service pro happiness and retention on the Dobby platform.',
          'You will eventually create a repeatable playbook for geo rollouts You will learn about “a day in the life of a service pro” and help Dobby build better product for the service pro community.'
        ]
      },
      {
        listName: 'Your career and life at Dobby',
        listData: [
          'Competitive salary + stock options + healthcare benefits, including reimbursement for physical and mental wellbeing subscriptions.',
          'Ground-level role with tremendous leverage plus unlimited learning and growth opportunity.',
          'With clarity, focus, autonomy, and fun - we get a lot of high-quality work done.',
          'At Dobby, everyone has a voice. We listen to what you are saying, not who you are.',
          'We’ve successfully built a diverse, high integrity, happy team by deliberately implementing a trust-by-default culture. This would be our continued focus as we grow.'
        ]
      }
    ],
    paragraphsEnd: [
      {
        name: 'Join us to build a tech company powered by humanity',
        content: [
          "We don't care where you worked before or where you went to school. However, you will need to show that you are a learning machine who has taken progressively responsible roles in the last several years and has the drive to be a key team member of an early-stage company. A huge plus, if you’ve worked in customer success before. We value expertise and deep work. You will get all our support - as you aim to become the best customer success expert in the B2C space.",
          '\nDobby is headquartered in Washington, DC, with a distributed workforce.',
          '\nWe look forward to hearing from you.'
        ]
      }
    ],
    photo: dolphinColor
  },
  /* 'director-customer-success': {
      alt: 'DOG',
      name: 'Director\nCustomer Success & Experience',
      location: 'Remote',
      timeType: 'Full‑time',
      type: 'operations',
      description: `We are looking to onboard an ops manager to lead customer success & experience at Dobby and build the foundation of our CSM organization. If you have a keen attention to details, love for problem-solving, and a big heart - then this role is for you.`,
      paragraphsStart: [
        {
          name: 'Join us to build the Operating System for modern American homes',
          content: [
            'Dobby is fundamentally changing how we manage, maintain, and upgrade our homes. Starting with a leapfrog home maintenance product that won TechCrunch Top Pick in AI/ ML 2019 - we are pioneering digitization & AI in the $16T residential property market, the largest asset class in the US.',
            'Dobby creates a luxury experience for homeowners and increases their property value with an AI-first, fully digital, “get-stuff-done” app backed by our invite-only, high-quality service pro community. Together, we are building a socially responsible community by supporting local small businesses and independent operators.',
            'Our vision is "to become the operating system for American homes" by adding adjacent products and services over time.'
          ]
        }
      ],
      lists: [
        {
          listName: 'In your day-to-day, you will ensure a delightful experience for our homeowners while making our service pros community wildly successful',
          listData: [
            'You will work on Gryffindor, our near-automatic, GUI-based customer success platform, and manage our maintenance jobs - from inception to payment.',
            'You will define service delivery standards and maintain the quality through timely interventions and periodic pro-tips to service providers.',
            'As the custodian of the platform happiness, you will ensure that our users, both homeowners and service providers, have delightful experiences on the Dobby platform.',
            'You will listen to our homeowners and service providers, learn from the field - and then create a repeatable playbook that we can use to scale our operations as we grow via geo-rollouts.',
            'You will share your insights with the product team, recommend new product features - as we continuously enhance our products to bring 100% automation.'
          ],
        },
        {
          listName: 'Why you should join us',
          listData: [
            'Ground-level role with tremendous leverage and growth opportunities.',
            'Early stage equity from a VC-backed, high-growth, product-led startup.',
            'Competitive salary + healthcare benefits, including reimbursement for physical and mental wellbeing subscriptions',
            'We promote clarity, focus, autonomy, and fun - so that you can get a lot of high-quality stuff done.',
            'At Dobby, everyone has a voice and we optimize for learning. We listen to what you are saying, not who you are',
            'We’ve successfully built a diverse, high integrity, and happy team by deliberately implementing a highly transparent, trust-by-default culture. You’ll love it!'
          ]
        },
        {
          listName: 'What we think you need to succeed in this role',
          listData: [
            '5+ years of progressively responsible experience in customer success management (CSM) - ideally in a high-growth startup environment.',
            'Genuine love for people.',
            'Clear thinking, clean writing, precise articulation, and a playful & fun-loving style.',
            'Analytical mind, and a bias towards measurable outcome.',
            'Resourcefulness, problem-solving skills, comfort with ambiguity, and the drive to be a key team member of an early-stage startup.',
            'Project and time management skills, effective communication with terrific interpersonal skill.',
            'Deep care, attention to detail, and superb organizational skills.',
            'Ability to attract talented people and build the CSM organization over time.'
          ]
        }
      ],
      paragraphsEnd: [
        {
          name: 'An incredible founding team backed by some world-class investors',
          content: [
            'Before starting Dobby, we built a multi-million dollar global business from pre-revenue at AI unicorn DataRobot, witnessed torrid growth at Uber, and ran an anti-malaria program for The Clinton Foundation. Our engineering colleagues won TechCrunch Top Pick in AI/ ML in 2019, and we previously worked at AIG, Liberty Mutual, Deloitte, and Twitter.',
            '\nDundee VC, our lead investor, is a prominent fund supporting midwest and mid-Atlantic companies. Our early investors include Techstars, Mark Bailey (founder/ partner @ DFJ Growth, invested in companies like Tesla and SpaceX), Jeremy Achin (co-founder/ CEO @ DataRobot), Maury Devine (board @ ConocoPhillips, early investor Sweetgreen), and Andrew Macdonald (head of Uber Mobility).',
            '\nWe look forward to hearing from you.'
          ]
        }
      ],
      photo: dogColor
    },*/
  'director-marketing': {
    alt: 'OWL',
    name: 'Director\nMarketing & Acquisition',
    location: 'Remote',
    timeType: 'Full‑time',
    description: `At Dobby, we’re hiring an experienced marketing professional to join us on the ground floor and lead our marketing efforts. Reporting directly to the CEO - you will be a member of our Growth Team, own the vision and execution of Dobby’s marketing and acquisition strategy, and help us drive revenue via new user acquisition and repeat usage.\n\n** In this player-coach role, you will be hands-on doer for the acquisition side while managing the content & creative side with internal and external resources.**`,
    type: 'marketing',
    paragraphsStart: [
      {
        name: 'Join us to build the Operating System for modern American homes',
        content: [
          'Dobby is fundamentally changing how we manage, maintain, and upgrade our homes. Starting with a leapfrog home maintenance product that won TechCrunch Top Pick in AI/ ML 2019 - we are pioneering digitization & AI in the $16T residential property market, the largest asset class in the US.',
          'Dobby creates a luxury experience for homeowners and increases their property value with an AI-first, fully digital, “get-stuff-done” app backed by our invite-only, high-quality service pro community. Together, we are building a socially responsible community by supporting local small businesses and independent operators.',
          'Our vision is "to become the operating system for American homes" by adding adjacent products and services over time.'
        ]
      }
    ],
    lists: [
      {
        listName: 'How you will make an impact at Dobby',
        listData: [
          'Customer life-cycle & GTM strategy: you will lead a thorough customer journey mapping initiative and work cross-functionally to develop a robust go-to-market strategy as we aim to increase LTV and drive aggregated acquisitions via channel partners.',
          'Marketing campaigns - digital & offline: you will build a rapid-iteration framework for marketing experimentations, run A/B testing, learn from key insights, build a scalable playbook for direct acquisition, channel partners, and loyalty programs.',
          'A catalyst to Dobby’s aggressive growth: As a member of the Growth Team, your work will drive new users and repeat usage. In your day-to-day, you will consult with the CEO and other growth operators in the company to identify their marketing needs and help them execute tactics to drive revenue growth.',
          'Managing the content engine: you will internalize Dobby’s vision, listen to our users and channel partners, distill product proof points - manage internal and external content marketing resources and help us build a rich, multi-purpose content engine for messaging, positioning, email, SMS, and digital/offline copywriting and marketing assets.'
        ]
      },
      {
        listName: 'What we think you need to succeed in this role',
        listData: [
          '5+ years of progressively responsible experience in marketing, distribution, and acquisition - ideally in a high-growth startup environment.',
          'Deep understanding of marketing strategy and planning with the ability to identify the right tactics across a multitude of marketing channels (offline, digital, social, content, video).',
          'Clear thinking, clean writing, and a playful & fun-loving style .',
          'Well-documented track record of producing revenue-generating marketing campaigns within a capital-efficient environment.',
          'Analytical mind, and a bias towards measurable outcome.',
          'Resourcefulness, problem-solving skills, comfort with ambiguity, and the drive to be a key team member of an early-stage startup.',
          'Project and time management skills, effective communication with terrific interpersonal skill.',
          'Deep care, attention to detail, and superb organizational skills.',
          'Ability to attract talented people and build a marketing organization over time.'
        ]
      },
      {
        listName: 'Why you should join us',
        listData: [
          'Ground-level role with tremendous leverage and growth opportunities.',
          'Early stage equity from a VC-backed, high-growth, product-led startup.',
          'Competitive salary + healthcare benefits, including reimbursement for physical and mental wellbeing subscriptions.',
          'We promote clarity, focus, autonomy, and fun - so that you can get a lot of high-quality stuff done.',
          'At Dobby, everyone has a voice and we optimize for learning. We listen to what you are saying, not who you are.',
          'We’ve successfully built a diverse, high integrity, and happy team by deliberately implementing a highly transparent, trust-by-default culture. You’ll love it!'
        ]
      }
    ],
    paragraphsEnd: [
      {
        name:
          'An incredible founding team backed by some world-class investors',
        content: [
          'Before starting Dobby, we built a multi-million dollar global business from pre-revenue at AI unicorn DataRobot, witnessed torrid growth at Uber, and ran an anti-malaria program for The Clinton Foundation. Our engineering colleagues won TechCrunch Top Pick in AI/ ML in 2019, and we previously worked at AIG, Liberty Mutual, Deloitte, and Twitter.',
          '\nDundee VC, our lead investor, is a prominent fund supporting midwest and mid-Atlantic companies. Our early investors include Techstars, Mark Bailey (founder/ partner @ DFJ Growth, invested in companies like Tesla and SpaceX), Jeremy Achin (co-founder/ CEO @ DataRobot), Maury Devine (board @ ConocoPhillips, early investor Sweetgreen), and Andrew Macdonald (head of Uber Mobility).',
          '\nWe look forward to hearing from you.'
        ]
      }
    ],
    photo: owlColor
  }
  /* devops: {
      alt: 'DOG',
      name: 'DevOps Engineer',
      location: 'Kyiv,\u00a0Ukraine',
      timeType: 'Full‑time',
      description: ``,
      type: 'engineer',
      lists: [
        {
          listName: 'Requirements',
          listData: [],
        },
        {
          listName: 'Will be a plus',
          listData: [],
        },
        {
          listName: 'Our values',
          listData: [],
        },
        {
          listName: 'What we offer',
          listData: [],
        }
      ],
      photo: dogColor
    }, */
};
