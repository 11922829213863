import styled, { keyframes, css } from 'styled-components';
import styles from 'common/enums/styles';
import 'animate.css/animate.compat.css';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { device } from 'common/enums/devices';

const bounce = keyframes`
  0% {
    bottom: 0;
  }
  50% {
    bottom: 20px;
  }
  100% {
    bottom: 0;
  }
`;

export const Wrapper = styled.div`
  background-color: ${styles.colors.WHITE};
  transition: background-color 600ms ease-in-out;
`;

export const ContentWrapper = styled.div`
  max-width: 1100px;
  @media screen and ${device.LAPTOP} {
    margin: 0 auto;
  }
  @media screen and ${device.LAPTOP_L} {
    max-width: 1100px;
  }
  @media screen and (min-width: 786px) {
    padding-top: 0;
  }
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const Section = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  ${'' /* margin-bottom: 90px; */}
  padding-left: 10px;
  padding-right: 10px;
  height: 100vh;
  width: 100vw;
  scroll-snap-align: start;

  @media screen and (min-width: 786px) {
    ${'' /* margin-bottom: 160px; */}
    padding-left: 0;
    padding-right: 0;
  }

  ${({ firstSection }) =>
    firstSection &&
    `
    ${'' /* margin-bottom: 190px; */}
    height: 100vh;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 6%;
    @media screen and ${device.LAPTOP} {
      ${'' /* margin-bottom: 210px; */}
      justify-content: center;
      align-items: center;
      padding-top: 5.5%;
      padding-left: 30px;
      padding-right: 30px;
    }
  `};
`;

export const BackgroundImageWrapper = styled.div`
  z-index: -1;
  img {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    height: -webkit-fill-available;

    /* Preserve aspet ratio */
    min-width: 100%;
    min-height: 100%;

    &.desktop {
      display: none;
    }
    &.mob {
      display: block;
    }

    @media screen and ${device.LAPTOP} {
      &.desktop {
        display: block;
      }
      &.mob {
        display: none;
      }
    }
  }
`;

export const ImageWrapper = styled.div`
  width: auto;
  ${({ marginBottomMob }) =>
    marginBottomMob && `margin-bottom: ${marginBottomMob}px`};
  img {
    max-width: 100%;
    height: auto;
  }
  @media screen and (min-width: 786px) {
    ${({ marginBottom }) => marginBottom && `margin-bottom: ${marginBottom}px`};
    ${({ widthDesktop }) => widthDesktop && `width: ${widthDesktop}px`};
  }
`;

export const StyledAnchorLink = styled(AnchorLink)`
  ${css`
    animation: ${bounce} 2s ease infinite;
    animation-delay: 2s;
    position: relative;
  `}
`;

export const CircleSectionNumber = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 49px;
  height: 49px;
  background-color: ${styles.colors.GRAY_TRUE};
  color: ${styles.colors.YELLOW};
  font-weight: bold;
  font-size: 28px;
  line-height: 1;
  margin-bottom: 4px;
  padding: 3px 0 0 1px;
`;

export const TopHeader = styled.h3`
  margin: 0;
  padding: 0;
  font-weight: bold;
  font-size: 32px;
  line-height: 1.2;
  text-align: center;
  letter-spacing: 1px;
  ${({ marginBottomMob }) =>
    marginBottomMob >= 0 && `margin-bottom: ${marginBottomMob}px`};
  color: ${styles.colors.GREEN};
  @media screen and (min-width: 786px) {
    ${({ marginBottom }) =>
      marginBottom >= 0 && `margin-bottom: ${marginBottom}px`};
    ${({ widthDesktop }) => widthDesktop && `width: ${widthDesktop}%`};
    font-size: 50px;
  }
`;

export const GradientHeaderText = styled.span`
  color: ${styles.colors.BLACK_TRUE};
  background: linear-gradient(137.24deg, #1a42d7 15.7%, #ec0352 84.71%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export const MainSubHeader = styled.h5`
  margin: 0;
  padding: 0;
  font-weight: 700;
  font-size: 24px;
  line-height: 1.2;
  text-align: center;
  color: ${styles.colors.YELLOW};
  margin-bottom: 36px;
  letter-spacing: 1px;

  @media screen and (min-width: 786px) {
    margin-bottom: 40px;
    font-size: 32px;
  }
`;

export const SubHeader = styled.h5`
  margin: 0 20px;
  padding: 0;
  font-weight: 700;
  font-size: 18px;
  line-height: 1.2;
  text-align: center;
  color: ${styles.colors.BLACK_TRUE};
  ${({ orange }) => orange && `color: ${styles.colors.YELLOW}`}
  ${({ marginBottomMob }) =>
    marginBottomMob >= 0 && `margin-bottom: ${marginBottomMob}px`};
  ${({ letterSpacing }) =>
    letterSpacing && `letter-spacing: ${letterSpacing}px`};

  @media screen and (min-width: 786px) {
    ${({ leftTextAlign }) => leftTextAlign && 'text-align: left'};
    ${({ marginBottom }) =>
      marginBottom >= 0 && `margin-bottom: ${marginBottom}px`};
    ${({ widthDesktop }) => widthDesktop && `width: ${widthDesktop}%`};
    font-size: 32px;
  }
`;

export const SubTitle = styled.p`
  margin: 0 20px;
  padding: 0;
  font-size: 14px;
  line-height: 1.5;
  text-align: center;
  color: ${styles.colors.BLACK_TRUE};
  ${({ marginBottomMob }) =>
    marginBottomMob >= 0 && `margin-bottom: ${marginBottomMob}px`};

  @media screen and (min-width: 786px) {
    ${({ marginBottom }) =>
      marginBottom >= 0 && `margin-bottom: ${marginBottom}px`};
    max-width: ${({ maxWidth }) => maxWidth}px;
    font-size: 14px;
  }
`;
