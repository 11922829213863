const IconBack = () => {
  return (
    <svg
      width="26"
      height="24"
      viewBox="0 0 26 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.931621 13.1677C0.663951 12.8672 0.5 12.4524 0.5 11.9999C0.5 11.5409 0.668608 11.1209 0.94297 10.8194L6.07949 4.99988C6.66777 4.33337 7.64195 4.33337 8.23023 4.99988C8.79937 5.6447 8.79937 6.68005 8.23023 7.32487L5.57118 10.3375L23.9978 10.3375C24.8594 10.3375 25.5 11.1129 25.5 11.9999C25.5 12.8868 24.8594 13.6622 23.9978 13.6622L5.57095 13.6622L8.23023 16.6751C8.79937 17.3199 8.79937 18.3553 8.23023 19.0001C7.64195 19.6666 6.66777 19.6666 6.07949 19.0001L0.932603 13.1688L0.931621 13.1677Z"
        fill="white"
      />
    </svg>
  );
};

export default IconBack;
