import styled from 'styled-components';
import styles from 'common/enums/styles';
import { device } from 'common/enums/devices';

export const Button = styled.div`
  @media screen and ${device.LAPTOP} {
    display: none;
  }
  position: fixed;
  right: 16px;
  bottom: ${({ bigOffset }) => (bigOffset ? 120 : 25)}px;
  display: block;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 4;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background: ${styles.components.HIGHLIGHT};
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
`;

export const ArrowIcon = styled.img`
  transform: ${({ rotated }) => (rotated ? 'rotate(180deg)' : 'rotate(0deg)')};
`;
