import styled from 'styled-components/macro';
import styles from 'common/enums/styles';
import { device } from 'common/enums/devices';

export const Card = styled.article`
  padding: 24px 20px;
  background-color: ${styles.colors.WHITE_NEW};
  max-width: 343px;
  width: 100%;
  height: auto;

  h2 {
    width: 200px;
  }
  @media screen and ${device.TABLET} {
    width: 343px;
    align-items: baseline;
    height: auto;
    padding: 40px 37px 253.5px 42px;
    max-width: none;
  }
  @media screen and (min-width: 1160px) {
    width: 360px;
    ${({ order }) => order && `order: ${order}`};
  }
`;

export const Box = styled.div`
  display: grid;
  justify-items: center;
  @media screen and ${device.TABLET} {
    justify-items: baseline;
  }
`;
export const BoxTitle = styled.p`
  font-weight: 700;
  font-size: 24px;
  line-height: 1.2;
  letter-spacing: 0;
  margin-top: 24px;
  @media screen and ${device.TABLET} {
    font-weight: 700;
    font-size: 32px;
    padding: 0;
    margin-top: 13px;
  }
`;

export const BoxText = styled.p`
  font-size: 18px;
  line-height: 1.2;
  margin-top: 6px;
  margin-bottom: 18px;
  @media screen and ${device.TABLET} {
    font-size: 16px;
    line-height: 1.5;
    margin-top: 17px;
  }
`;
export const BoxContent = styled.div`
  display: grid;
  text-align: center;
  @media screen and ${device.TABLET} {
    justify-items: baseline;
    text-align: left;
  }
`;
export const BoxImage = styled.img`
  width: 100px;
  height: 100px;
  @media screen and ${device.TABLET} {
    margin-top: 0;
    width: 80px;
    height: 80px;
  }
`;
