import styled from 'styled-components/macro';
import styles from 'common/enums/styles';
import { device } from 'common/enums/devices';

export const Item = styled.article`
  display: grid;
  position: relative;
  margin-top: 80px;
  &:first-of-type {
    margin-top: 0;
  }
  @media screen and ${device.LAPTOP} {
    grid-auto-flow: column;
    grid-template-columns: 1fr min-content 1fr;
    gap: 32px;
    margin-top: 0;
  }
`;

export const Title = styled.h2`
  color: ${styles.components.HEADER_INVERTED};
  text-align: left;
  @media screen and ${device.TABLET} {
    margin-top: 8px;
    width: 100%;
    white-space: normal;
  }
`;

export const TextContainer = styled.div`
  margin-top: 24px;
  text-align: justify;
  @media screen and ${device.LAPTOP} {
    width: 100%;
    margin-top: 22px;
    max-width: 619px;
    display: grid;
    align-content: start;
    text-align: right;
  }

  ${`${Item}:nth-of-type(odd) & {
    @media screen and ${device.LAPTOP} {
      text-align: left;
      grid-column: 3;
    }
  }`}
  ${`${Item}:nth-of-type(even) & {
      ${Title} {
      @media screen and ${device.LAPTOP} {
        text-align: right;
        white-space: normal;
      }
    }
  `}
`;

export const TextWrapper = styled.div`
  margin: 16px 0 0;
  display: grid;
  gap: 16px;
  @media screen and ${device.LAPTOP} {
    margin: 24px 0 0;
  }
`;

export const ImageContainer = styled.div`
  text-align: center;
  grid-row: 1;

  @media screen and ${device.LAPTOP} {
    align-content: baseline;
    display: grid;
    grid-row: unset;
    width: 200px;
  }

  ${`${Item}:nth-of-type(odd) & {
    justify-self: center;
    @media screen and ${device.LAPTOP} {
      grid-column: 1;
      justify-self: flex-end;
    }
  }`}
`;

export const Image = styled.img`
  height: 180px;
  min-height: 180px;
  width: 180px;
  min-width: 180px;
  overflow: hidden;
  background: url(${({ src }) => src});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: ${styles.components.BACKGROUND};
  border-radius: 50%;
  @media screen and ${device.LAPTOP} {
    height: 200px;
    width: 200px;
    margin-top: 40px;
  }
`;

export const ProName = styled.h3`
  color: ${styles.components.HEADER_INVERTED};
  margin-top: 24px;
  margin-bottom: 1px;
`;

export const Relative = styled.div`
  position: relative;
  width: 16px;
`;
