import styled from 'styled-components';
import styles from 'common/enums/styles';
import { device } from 'common/enums/devices';

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
`;
export const Container = styled.div`
  display: grid;
  gap: 40px;
  margin-top: 56px;
  border-radius: 4px;
  background: ${({ inverted }) =>
    inverted ? styles.colors.DARK_CHARCOAL : styles.gradients.WHITE};
  box-shadow: ${styles.boxShadows.SHADOW};
  padding: 16px 16px 0;
  margin: auto;
  justify-content: center;
  max-width: 347px;

  @media screen and ${device.TABLET} {
    grid-auto-flow: column;
    justify-content: space-between;
    align-items: flex-end;
    gap: 11px;
    max-width: 784px;
    width: 100%;
    padding: 0 56px 0 3px;
  }
`;
export const TextWrapper = styled.div`
  padding: 0 16px 40px;
  @media screen and ${device.TABLET} {
    padding: 38px 0 22px;
    max-width: 380px;
  }
`;

export const Image = styled.img`
  width: 100%;
  @media screen and ${device.TABLET} {
    max-width: 310px;
  }
`;

export const Title = styled.h2`
  letter-spacing: 0;
  margin: auto;
  margin-bottom: 16px;
  max-width: 954px;
  color: ${styles.components.HEADER_2};
  font-weight: 600;
`;

export const GetAppWrapper = styled.h2`
  margin-top: 32px;
`;

export const invertedTextStyle = {
  color: styles.components.TEXT_INVERTED
};
