import styled from 'styled-components/macro';
import styles from 'common/enums/styles';
import { device } from 'common/enums/devices';

export const Section = styled.section`
  display: flex;
  justify-content: center;
  padding: 56px 0 0;
  background-color: ${({ inverted }) =>
    inverted ? styles.components.BACKGROUND : styles.colors.WHITE_NEW};
  @media screen and ${device.LAPTOP} {
    padding: 72px 0 0;
  }

  &:first-child {
    padding-top: 40px 0 0;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  width: auto;

  @media screen and ${device.LAPTOP} {
    width: 100%;
    max-width: 1167px;
    justify-content: center;
    justify-items: baseline;
  }
`;

export const TextContainer = styled.div`
  padding: 10px 16px 0;
  width: 100%;
  white-space: break-spaces;

  @media screen and ${device.LAPTOP} {
    margin: 0;
    width: 100%;
    max-width: 550px;
  }
  @media screen and (min-width: 1200px) {
    max-width: 670px;
  }
`;

export const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 180px;
  height: 180px;
  border-radius: 10px;
  overflow: hidden;
  margin-top: 40px;

  &:first-child {
    margin: 0;
  }

  a {
    align-self: flex-end;
    position: relative;
    bottom: 36px;

    svg {
      height: 36px;
      width: 36px;
    }
  }

  @media screen and ${device.LAPTOP} {
    a {
      position: relative;
      bottom: 36px;
    }
`;

export const Image = styled.img`
  border-radius: 10px;

  width: 180px;
  height: 180px;
`;

export const Title = styled.h1`
  text-align: center;
  margin: 0;
  white-space: normal;
  color: ${({ inverted }) =>
    inverted
      ? styles.components.HEADER_INVERTED
      : styles.components.HEADER_COLOR};
  @media screen and ${device.LAPTOP} {
    text-align: left;
    white-space: pre-wrap;
  }
`;

export const TextWrapper = styled.div`
  position: relative;
  margin-top: 54px;
  padding: 0;
  white-space: pre-wrap;
  @media screen and ${device.LAPTOP} {
    margin-top: 8px;
    padding: 0;
  }
  p {
    text-align: justify;
    @media screen and ${device.LAPTOP} {
      text-align: center;
      padding: 4px;
    }
  }
`;
export const Name = styled.p`
  font-weight: 700;
  font-size: 32px;
  line-height: 1.2;
  color: ${({ inverted }) =>
    inverted ? styles.components.TEXT_INVERTED : styles.components.TEXT};
  margin: 0;
`;

export const Position = styled.p`
  font-weight: 400;
  font-size: 20px;
  line-height: 1.5;
  color: ${({ inverted }) =>
    inverted ? styles.components.TEXT_INVERTED : styles.colors.GRAY_NEW};

  @media screen and ${device.LAPTOP} {
    margin-top: 4px;
  }
`;

export const NameBox = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;

  @media screen and ${device.LAPTOP} {
    text-align: left;
  }
`;

const Icon = styled.img`
  position: absolute;
  height: 24px;
  width: auto;
`;

export const IconUp = styled(Icon)`
  right: calc(100% - 24px);
  bottom: calc(100% + 20px);
  transform: scale(1, 1);
  @media screen and ${device.LAPTOP} {
    right: calc(100% + 1px);
    bottom: calc(100% + 1px);
  }
`;

export const IconDown = styled(Icon)`
  right: -0px;
  bottom: -45px;
  transform: scale(-1, -1);
  @media screen and ${device.LAPTOP} {
    right: -25px;
    bottom: -25px;
  }
`;

export const Delimeter = styled.div`
  height: 10px;
  width: 60%;
  margin: 70px 20% 0;
  border: 1px solid ${styles.colors.GRAY};
  border-left: none;
  border-right: none;
`;
