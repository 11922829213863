import React, { Component } from 'react';

import { reduxForm } from 'redux-form';
import { OutdatedButton, Input, TextArea } from 'components';
import { Modal } from 'modals/components';
import { setBodyOverflowHidden } from 'common/helpers';
import {
  FormHolder,
  InputWrapper,
  ButtonWrapper,
  ValidationBlock,
  UploadLabel,
  UploadButton,
  UploadFileBlock,
  FileName,
  FormCenterHolder,
  DeleteButton,
  FileNameWraper
} from './styled';

class ApplyNowForm extends Component {
  defaultState = {
    cv_base64: '',
    CVFile: undefined,
    CVFileError: undefined,
    showValidationCVFile: false,
    showValidation: {},
    showRequiredValidation: false,
    successModalVisible: false
  };

  state = this.defaultState;

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.CVLoading && !this.props.CVLoading && !this.props.CVError) {
      this.openSuccessModal();
    }
    setBodyOverflowHidden(
      prevProps.modalVisible || prevState.successModalVisible,
      this.props.modalVisible || this.state.successModalVisible
    );
  }

  activateValidation = field => {
    if (field === 'cv' && !this.state.showValidationCVFile) {
      this.setState({ showValidationCVFile: true });
    } else if (!this.state.showValidation[field]) {
      this.setState(state => ({
        showValidation: { ...state.showValidation, [field]: true }
      }));
    }
  };

  resetState = () => {
    this.setState({ ...this.defaultState });
  };

  openSuccessModal = () => {
    this.props.hideUploadModal();
    this.setState({ successModalVisible: true });
  };

  closeSuccessModal = () => {
    this.props.resetForm();
    this.resetState();
  };

  handleFileReportDialog = event => {
    event.stopPropagation();
    event.preventDefault();
    const files = event.target.files;

    if (files && files.length > 0) {
      const uploadedFile = files[0];
      if (uploadedFile.size >= 5000000) {
        this.setState({
          showValidationCVFile: true,
          CVFileError:
            '* the file is too large, please select a file less than 4.5 MB'
        });
      } else {
        this.getBase64(uploadedFile, result => {
          this.setState({
            cv_base64: result,
            CVFile: uploadedFile,
            CVFileError: undefined
          });
        });
      }
    }
  };

  getBase64(file, cb) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function() {
      cb(reader.result);
    };
    reader.onerror = function(error) {
      console.log('Error: ', error);
      this.setState({
        showValidationCVFile: true,
        CVFileError: '* something went wrong, please try again'
      });
    };
  }

  submitForm = () => {
    const { sendCV, role, values } = this.props;
    const { CVFile, cv_base64 } = this.state;
    sendCV({ ...values, type: role, cv_type: CVFile.type, cv_base64 });
  };

  isRequiredError = () => {
    const { errors } = this.props;
    const { showValidation, showValidationCVFile, cv_base64 } = this.state;
    return (
      (errors.given_name === 'required' && showValidation.given_name) ||
      (errors.email === 'required' && showValidation.email) ||
      (errors.description === 'required' && showValidation.description) ||
      (errors.portfolio === 'required' && showValidation.portfolio) ||
      (!cv_base64 && showValidationCVFile)
    );
  };

  isFieldError = field => {
    const { errors } = this.props;
    const { showValidation } = this.state;
    return errors[field] && showValidation[field];
  };

  render() {
    const {
      modalVisible,
      hideUploadModal,
      fields,
      errors,
      CVLoading
    } = this.props;
    const {
      CVFile,
      successModalVisible,
      cv_base64,
      CVFileError,
      showValidationCVFile
    } = this.state;
    const disabled = !(
      errors.given_name === undefined &&
      errors.email === undefined &&
      errors.description === undefined &&
      errors.portfolio === undefined &&
      CVFileError === undefined &&
      cv_base64
    );
    const requiredError = this.isRequiredError();
    return (
      <>
        <Modal
          visible={modalVisible}
          onModalClose={hideUploadModal}
          heading="Submit Your Application">
          <FormHolder>
            <InputWrapper>
              <Input
                errorBorders={this.isFieldError('given_name')}
                type="text"
                placeholder="Your Name"
                {...fields.given_name}
                onBlur={() => this.activateValidation('given_name')}
              />
              <ValidationBlock />
            </InputWrapper>
            <InputWrapper>
              <Input
                errorBorders={this.isFieldError('email')}
                type="email"
                placeholder="Email"
                {...fields.email}
                onBlur={() => this.activateValidation('email')}
              />
              <ValidationBlock>
                {errors.email === 'wrongFormat' ? (
                  <span> * enter email in the correct format</span>
                ) : (
                  ''
                )}
              </ValidationBlock>
            </InputWrapper>
            <InputWrapper>
              <Input
                errorBorders={this.isFieldError('portfolio')}
                type="link"
                placeholder="LinkedIn"
                {...fields.portfolio}
                onBlur={() => this.activateValidation('portfolio')}
              />
              <ValidationBlock>
                {errors.portfolio === 'wrongFormat' ? (
                  <span> * enter link in the correct format</span>
                ) : (
                  ''
                )}
              </ValidationBlock>
            </InputWrapper>
            <InputWrapper>
              <UploadFileBlock>
                <UploadLabel htmlFor="upload-cv-file">
                  <UploadButton
                    errorBorders={
                      showValidationCVFile && (CVFileError || !cv_base64)
                    }>
                    Upload your resume
                  </UploadButton>
                </UploadLabel>
                <input
                  type="file"
                  id="upload-cv-file"
                  onChange={event => this.handleFileReportDialog(event)}
                  lable="Upload CV"
                  hidden
                  accept="application/pdf,image/jpeg,image/png"
                />
                {Boolean(cv_base64 && CVFile) && (
                  <FileNameWraper>
                    <FileName>
                      <p>{CVFile.name}</p>
                    </FileName>
                    <DeleteButton
                      onClick={() =>
                        this.setState({
                          cv_base64: '',
                          CVFile: undefined,
                          CVFileError: undefined,
                          showValidationCVFile: true
                        })
                      }
                    />
                  </FileNameWraper>
                )}
              </UploadFileBlock>
              <ValidationBlock>
                {showValidationCVFile && CVFileError ? (
                  <span>{CVFileError}</span>
                ) : (
                  ''
                )}
              </ValidationBlock>
            </InputWrapper>
            <TextArea
              errorBorders={this.isFieldError('description')}
              type="text"
              rows={9}
              placeholder="Tell us about yourself and why you want to join Dobby"
              {...fields.description}
              onBlur={() => this.activateValidation('description')}
            />
            <ValidationBlock wide>
              {Boolean(requiredError)
                ? '* please fill all the required fields'
                : Boolean(this.props.CVError)
                ? '* something went wrong, please try again'
                : ''}
            </ValidationBlock>
            <ButtonWrapper>
              <OutdatedButton
                disabled={disabled}
                title="APPLY"
                loading={CVLoading}
                onClick={this.submitForm}
                width={240}
                type="big"
              />
            </ButtonWrapper>
          </FormHolder>
        </Modal>
        <Modal visible={successModalVisible} closeButton={false}>
          <FormCenterHolder>
            <span>Thank You!</span>
            <span>We will review your CV and reach you out soon</span>
            <OutdatedButton
              title="GOT IT"
              width={240}
              type="big"
              onClick={this.closeSuccessModal}
            />
          </FormCenterHolder>
        </Modal>
      </>
    );
  }
}

const validate = values => {
  const errors = {};
  if (!values.given_name || values?.given_name.trim() === '') {
    errors.given_name = 'required';
  }

  if (!values.email || values?.email.trim() === '') {
    errors.email = 'required';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'wrongFormat';
  }

  if (!values.portfolio || values?.portfolio.trim() === '') {
    errors.portfolio = 'required';
  } else if (
    !/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/i.test(
      values.portfolio
    )
  ) {
    errors.portfolio = 'wrongFormat';
  }

  if (!values.description || values?.description.trim() === '') {
    errors.description = 'required';
  }

  return errors;
};

export default reduxForm({
  form: 'applyNowForm',
  fields: ['given_name', 'email', 'portfolio', 'description'],
  validate
})(ApplyNowForm);
