import actionTypes from 'common/enums/actionTypes';

export const addGlowMagicLink = payload => ({
  type: actionTypes.ADD_GLOW_MAGIC_LINK_FETCH,
  payload
});

export const signIn = payload => ({
  type: actionTypes.SIGN_IN_FETCH,
  payload
});

export const confirmSignIn = payload => ({
  type: actionTypes.CONFIRM_SIGN_IN_FETCH,
  payload
});

export const userUtmTrack = payload => ({
  type: actionTypes.USER_UTM_TRACK_FETCH,
  payload
});
