import styled from 'styled-components/macro';
import styles from 'common/enums/styles';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { animated } from 'react-spring';
import { device } from 'common/enums/devices';

export const AnimatedDiv = styled(animated.div)`
  display: flex;
  flex: 1;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
`;

export const Arrow = styled.img`
  transform: rotate(${({ isLeft }) => (isLeft ? 180 : 0)}deg);
  height: 33px;
  width: auto;

  &:first-child {
    margin-right: 18px;
  }
`;

export const Arrows = styled.div`
  display: flex;
  margin-top: 32px;
  height: 33px;

  @media screen and ${device.LAPTOP} {
    display: none;
  }
`;

export const FeatureImage = styled(LazyLoadImage)`
  width: 100%;
  height: 100%;
`;
export const ForegroundImage = styled(LazyLoadImage)`
  width: 100%;
  height: 99%;
  position: absolute;
  top: 0;
`;

export const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 86.8%;
  margin-top: 0;
  @media screen and ${device.LAPTOP} {
    margin-top: 0;
    flex-direction: row;
  }
`;

export const FlexOne = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 23px;
  order: 1;
  @media screen and ${device.LAPTOP} {
    margin-top: 0;
    transform: translate(0px, -9px);
    order: 0;
  }
`;

export const FlexOneDescription = styled(FlexOne)`
  margin-top: 24px;
  position: relative;
  width: 100%;
  min-height: 90px;
  @media screen and ${device.LAPTOP} {
    display: flex;
    justify-content: center;
    min-height: 117px;
    order: 2;
  }
`;

export const PhoneWrapperDiv = styled.div`
  overflow: hidden;
  display: flex;
  position: absolute;
  border-radius: 8px;
  flex: 1;
  top: 6px;
  right: 7px;
  left: 7px;
  bottom: 10px;
  background: ${styles.colors.WHITE_NEW};
  @media screen and ${device.LAPTOP} {
    top: 11px;
    right: 11px;
    left: 11px;
    bottom: 17px;
  }
`;

export const Title = styled.h1`
  text-align: center;
  margin: 0;
  max-width: min-content;
  color: ${styles.components.HEADER_INVERTED};
  @media screen and ${device.LAPTOP} {
    width: auto;
    margin: 14.2px 0 74px;
  }
`;

export const Section = styled.section`
  background: ${styles.components.BACKGROUND};
`;

export const Wrapper = styled.div`
  margin: 0 auto;
  padding: 88px 20px 56px;
  max-width: 1312px;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  @media screen and ${device.LAPTOP} {
    padding: 80px 20px;
  }
`;
