import styled from 'styled-components';
import styles from 'common/enums/styles';
import { device, deviceSizes } from 'common/enums/devices';

export const Author = styled.p`
  color: ${styles.colors.DARK_BLUE};
  margin: 0 auto;
  font-weight: bold;
  font-size: 18px;
  line-height: 1.2;
  text-align: center;
  @media screen and ${device.LAPTOP} {
    font-size: 22px;
  }
`;

export const CommonSection = styled.div`
  max-width: ${deviceSizes.LAPTOP_L};
  margin: 0 auto;
`;

export const Content = styled.div`
  margin: 6px -31px;
  padding: 20px 0 29px;
  @media screen and ${device.LAPTOP} {
    padding: 43px 20px 46px;
    margin: 0 0;
  }
`;

export const ContentWrapper = styled.div`
  @media screen and ${device.LAPTOP} {
    display: flex;
    align-items: baseline;
    max-width: 768px;
  }
`;

export const Corner = styled.img`
  &:last-child {
    transform: rotate(-180deg);
    align-self: flex-end;
  }
`;

export const ModalResponse = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  p {
    font-weight: 600;
    font-size: 18px;
    line-height: 1.5;
    text-align: center;
    letter-spacing: 1px;
    margin-bottom: 34px;
    margin-top: 23px;
  }
`;

export const Position = styled.p`
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.2;
  color: ${styles.colors.DARK_BLUE};
  margin: 0;
  opacity: 0.4;
  @media screen and ${device.LAPTOP} {
    font-size: 18px;
  }
`;

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  @media screen and ${device.LAPTOP} {
    max-width: 829px;
  }
  margin: 0 auto;
`;

export const Text = styled.p`
  color: ${styles.colors.DARK_BLUE};
  margin: 0;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.5;
  @media screen and ${device.LAPTOP} {
    font-size: 16px;
  }
`;

export const QuoteSection = styled(CommonSection)`
  padding: 52px 16px 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and ${device.LAPTOP} {
    padding: 210px 16px 84px;
  }
`;

export const Quote = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  margin: 28px auto 0;
  @media screen and ${device.LAPTOP} {
    margin: 48px auto 0;
  }
`;

export const QuoteAuthor = styled(Quote)`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  margin: 0 auto 28px;
  @media screen and ${device.LAPTOP} {
    margin: 14px auto 48px;
    max-width: 470px;
  }
`;

export const QuoteSymbol = styled.p`
  color: ${styles.colors.YELLOW};
  font-size: 50px;
  font-weight: bold;
  line-height: 1.5;
  margin: 0;
  @media screen and ${device.LAPTOP} {
    transform: translateX(-10px);
  }
  &:last-child {
    align-self: flex-end;
    line-height: 1;
    @media screen and ${device.LAPTOP} {
      transform: translateY(15px);
    }
  }
`;

export const QuoteText = styled.p`
  color: ${styles.colors.DARK_BLUE};
  font-style: italic;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.5;
  text-align: left;
  margin: 31px 11px;
  max-width: 400px;

  @media screen and ${device.LAPTOP} {
    font-size: 16px;
  }
`;

export const QuoteAuthorText = styled(QuoteText)`
  font-weight: 400;
  margin: 0 34px;
  width: 100%;
  font-style: normal;
  span {
    opacity: 0.5;
  }
`;

export const AboutDobbyContainer = styled.div`
  width: 100%
  max-width: 650px;
  margin-top: 50px;
  @media screen and ${device.LAPTOP} {
    margin-top: 98px;
  }
`;

export const AboutDobbyTitle = styled.p`
  font-size: 14px;
  font-weight: bold;
  line-height: 1.5;
  margin: 0;
  width: 100%;
  @media screen and ${device.LAPTOP} {
    font-size: 16px;
  }
`;

export const AboutDobbyText = styled(AboutDobbyTitle)`
  font-weight: normal;
  margin: 8px 0 0;
  @media screen and ${device.LAPTOP} {
    margin: 8px 0 0;
  }
`;

export const MediaContact = styled(AboutDobbyTitle)`
  margin: 30px 0 0;
  @media screen and ${device.LAPTOP} {
    margin-top: 57px;
  }
`;

export const Email = styled.a`
  color: ${styles.colors.DARK_BLUE};
  font-size: 14px;
  font-weight: normal;
  line-height: 1.5;
  margin: 8px 0 0;
  text-decoration: none;
  width: 100%;
  &:hover {
    color: ${styles.colors.YELLOW};
  }
  @media screen and ${device.LAPTOP} {
    font-size: 16px;
  }
`;

export const BoldLink = styled(Email)`
  font-weight: bold;
`;
export const LinkedInSvg = styled.svg`
  height: 16px;
  margin-bottom: -2px;
  width: 16px;
  ${BoldLink}:hover & {
    rect {
      fill: ${styles.colors.YELLOW};
    }
  }
`;

export const OriginalSourceLink = styled(Email)`
  color: ${styles.colors.YELLOW};
  font-weight: bold;
  &:hover {
    color: ${styles.colors.YELLOW_DARKER};
    text-decoration: underline;
  }
`;

export const FormHolder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  @media screen and ${device.LAPTOP} {
    flex-direction: row;
    justify-content: center;
  }
`;

export const ButtonWrapper = styled.div`
  margin: 23px auto 0;
`;

export const ValidationBlock = styled.div`
  flex: 1;
  max-width: 320px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  color: ${styles.colors.RED_ERROR};
  font-weight: bold;
  font-size: 12px;
  line-height: 1.5;
  letter-spacing: 1px;
  word-wrap: break-word;
  padding-bottom: 16px;
  @media screen and ${device.LAPTOP} {
    font-size: 16px;
    padding-left: 8px;
    max-width: 320px;
  }
`;
