import React from 'react';
import { connect } from 'react-redux';
import { selectUserId } from 'store/reducers/user';

import styles from 'common/enums/styles';

import { analyticsPageTypes } from 'common/enums/pages';
import { Text, CategoryGrid, GetApp } from 'components';
import { Container, Title, TextWrapper, GetAppWrapTop } from './styled';
import { lastBoxConent } from './enum';
import { openModal } from 'store/actions/modal';

const Categories = ({ openModal, userId }) => {
  return (
    <Container>
      <Title>Have a job? We do it all!</Title>
      <TextWrapper>
        <Text description2 color={styles.colors.WHITE_NEW}>
          If you have a job in mind but aren't sure where to start, you've come
          to the right place! These are a few of the most popular jobs our
          homeowners are doing.
        </Text>
        <GetAppWrapTop>
          <GetApp mobileOnly entryPoint={analyticsPageTypes.CATEGORIES} />
        </GetAppWrapTop>
      </TextWrapper>
      <CategoryGrid
        lastBoxConent={lastBoxConent}
        entryPoint={analyticsPageTypes.CATEGORIES}
        disableDownloadButton
      />
    </Container>
  );
};

const mapDispatchToProps = {
  openModal
};

const mapStateToProps = state => ({
  userId: selectUserId(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(Categories);
