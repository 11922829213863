import React, { Component } from 'react';
import {
    MainContent,
    Wrapper,
    Container,
    LinkWithArrow
} from 'components';
import { ContentHolder, InnerPageHolder, InnerPageTitle, InnerPageSubtitle } from './styled';

class NotFound extends Component {
    componentDidMount() {
        document.title = 'Page not found';
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <MainContent>
                <Wrapper>
                    <Container>
                        <InnerPageHolder>
                            <InnerPageTitle>404</InnerPageTitle>
                            <InnerPageSubtitle>We can't find this page.</InnerPageSubtitle>
                            <ContentHolder>
                                <LinkWithArrow linkText="try to start from the homepage" linkUrl='/'/>
                            </ContentHolder>
                        </InnerPageHolder>
                    </Container>
                </Wrapper>
            </MainContent>
        );
    }
}

export default NotFound;
