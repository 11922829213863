import { call, takeLatest, put } from 'redux-saga/effects';
import Auth from '@aws-amplify/auth';

import actionTypes from 'common/enums/actionTypes';
import { openModal } from 'store/actions/modal';
import modalTypes from 'common/enums/modalTypes';

function* signIn(action) {
  const { onSuccess, ...payload } = action.payload;
  const formattedPhoneNumber = `${payload.phoneNumberCode}${payload.phone}`;
  const phone = formattedPhoneNumber.split('.').join('');

  try {
    yield call([Auth, Auth.signOut], phone);
    // SIGN IN
    const response = yield call([Auth, Auth.signIn], phone);
    yield put({
      type: actionTypes.SIGN_IN_SUCCESS,
      payload: response
    });
    yield put(
      openModal(modalTypes.CONFIRM_CODE, {
        formattedPhoneNumber,
        phoneNumberCode: payload.phoneNumberCode,
        phone: payload.phone,
        onSuccess
      })
    );
  } catch (err) {
    try {
      // SIGN UP
      yield call([Auth, Auth.signUp], {
        username: phone,
        password: Math.random().toString(36)
      });
    } finally {
      try {
        // SIGN IN
        const response = yield call([Auth, Auth.signIn], phone);
        yield put({
          type: actionTypes.SIGN_IN_SUCCESS,
          payload: response
        });
        yield put(
          openModal(modalTypes.CONFIRM_CODE, {
            formattedPhoneNumber,
            phoneNumberCode: payload.phoneNumberCode,
            phone: payload.phone,
            onSuccess
          })
        );
      } catch {
        yield put({
          type: actionTypes.SIGN_IN_ERROR
        });
      }
    }
  }
}

export default function* watchSignIn() {
  yield takeLatest(actionTypes.SIGN_IN_FETCH, signIn);
}
