import styled from 'styled-components';
import { Link } from 'react-router-dom';
import styles from 'common/enums/styles';
import { device } from 'common/enums/devices';

export const LogoLink = styled(Link)`
  display: block;
  padding-left: 3px;
`;

export const LogoImage = styled.img`
  display: block;
  width: 106px;
  height: 20px;
  @media screen and ${device.LAPTOP} {
    width: 137px;
    height: 26px;
  }
`;

export const StyledSVG = styled.svg`
  display: block;
  width: ${({ footer }) => (footer ? '128px' : '106px')};
  height: 40px;
  @media screen and ${device.LAPTOP} {
    width: ${({ footer }) => (footer ? '128px' : '106px')};
    height: ${({ footer }) => (footer ? '40px' : '21px')};
  }

  ${({ footer }) => footer && 'margin-left: -10px'};

  &:hover {
    path {
      fill: ${styles.colors.YELLOW};
    }
  }
`;

export const SVGIcon = styled.svg`
  height: ${({ unsubscribe }) => (unsubscribe ? 28 : 33)}px;
  width: auto;
  @media screen and ${device.LAPTOP} {
    height: ${({ unsubscribe }) => (unsubscribe ? 52.24 : 33)}px;
  }
`;
