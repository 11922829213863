import { call, takeLatest, put } from 'redux-saga/effects';

import api_constants from 'common/enums/api';
import actionTypes from 'common/enums/actionTypes';
import Api from 'services/ApiService';

function* unsubscribeFromEmail({ payload: { onSuccess, ...payload } }) {
  try {
    const response = yield call(
      Api.apiPost,
      api_constants.UNSUBSCRIBE,
      payload
    );

    if (response) {
      yield put({
        type: actionTypes.UNSUBSCRIBE_FROM_EMAIL_SUCCESS,
        payload
      });
      onSuccess();
    } else {
      yield put({
        type: actionTypes.UNSUBSCRIBE_FROM_EMAIL_ERROR,
        payload: 'Something went wrong. Try again later'
      });
    }
  } catch (err) {
    yield put({
      type: actionTypes.UNSUBSCRIBE_FROM_EMAIL_ERROR,
      payload: 'Something went wrong. Try again later'
    });
  }
}

export default function* watchUnsubscribeFromEmail() {
  yield takeLatest(
    actionTypes.UNSUBSCRIBE_FROM_EMAIL_FETCH,
    unsubscribeFromEmail
  );
}
