import React from 'react';
import { Text, QuoteText } from 'components';
import { DescriptionSection, TextPadding, Link, Heading } from './styled';

const TextContent = () => (
  <DescriptionSection>
    <QuoteText
      text="You wouldn't think that a three year old startup would lead the way in a half-a-trillion dollar legacy industry when it comes to a significant cause such as climate change. But we did!"
      author="Satadru Sengupta"
      position="co-founder & CEO"
    />
    <br />
    <Heading>
      Dobby is the first and the only company in the home services and property
      management space to contribute a portion of the revenue to remove CO
      <sub>2</sub> from the atmosphere.
    </Heading>
    <Text text2>
      When we founded Dobby, we decided to take care of our planet early on.
      Starting Fall 2020, we have been making systematic contributions to four
      companies working on carbon reduction technologies: Project Vesta, Charm
      Industrial, Carbon Cure, Climeworks. We give a portion to these companies
      every dollar we earn as revenue. Our goal is to stay net-zero in carbon
      emissions from our operations.
    </Text>
    <QuoteText
      text=" Last year on Earth Day, we started something fun and meaningful with a partnership with One Tree Planted. We committed to plant a tree for every job you complete as a homeowner on the Dobby platform. We're so delighted to report that we planted more than 1200 trees in the Appalachian region to celebrate all the home maintenance and improvement jobs our homeowners did with us."
      author="Alex Fontova"
      position="co-founder & COO"
    />
    <br />
    <Heading>Why does it matter?</Heading>
    <Text>
      <strong>
        At Dobby, our mission is to help every homeowner fall in love with their
        home and grow a sense of belonging to their community.
      </strong>{' '}
      And we believe the best way to accomplish our mission is to build a
      tech-enabled property management platform that creates a delightful
      experience for homeowners and service providers. Together with our
      best-in-class service providers community, we handle all the heavy lifting
      and unknowns of homeownership: from pre-sale inspection to day-to-day home
      maintenance and occasional home improvements to timely upkeep.
    </Text>
    <Heading>
      But we can't <i>just</i> stop there!
    </Heading>
    <Text>
      <strong>
        When we build a new company in a legacy industry - we make an audacious
        promise to ourselves: we won't build it - if it is not 10x better than
        what already exists in the market.
      </strong>
    </Text>
    <TextPadding>
      <strong>
        And with a promise of building 10x better than the market - we can't
        ignore carbon emissions and climate change.
      </strong>{' '}
      From newly born startups to Fortune 50 companies - we've got to think
      holistically about our business, and that should include climate change.
      Simply put, we need to build a 10x better product, create a 10x better
      experience, and adopt a future-forward operating model that is better for
      our community and planet. We've got to meet three goals: build better for
      the customers, better for the bottom-line, and better for the planet.
      These goals drive sustainable benefits for everyone, and that's why
      climate is not an afterthought for us at Dobby.
    </TextPadding>
    <TextPadding>
      <strong>Pushing the industry in the greener direction:</strong>
      <br />
      The $500B home maintenance industry is responsible for a significant
      amount of vehicle emissions. We're inviting all the incumbents in the home
      maintenance industry - Porch, Thumbtack, Angi, TaskRabbit, and others - to
      take Dobby's lead and participate in climate change initiatives. Start
      small - and map out a net-zero future that matches carbon emissions from
      company operations. Here is the{' '}
      <strong>
        <Link
          href="https://exponentialroadmap.org/wp-content/uploads/2020/01/1.5C-business-playbook-1.0_digital-spread.pdf"
          target="_blank"
          rel="noopener noreferrer">
          playbook
        </Link>
      </strong>
      .
    </TextPadding>
  </DescriptionSection>
);

export default TextContent;
