import styled from 'styled-components';

const Wrapper = styled.div`
  max-width: 1680px;
  position: relative;
  margin: 0 auto;
  height: auto;
`;

export default Wrapper;
