const CloseIconFilled = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM8.06724 8.11464C8.30773 7.87415 8.68055 7.85707 8.89996 8.07648L11.9638 11.1404L14.7674 8.05657C14.9827 7.81976 15.3612 7.81324 15.6129 8.04203C15.8645 8.27081 15.894 8.64826 15.6787 8.88508L12.8357 12.0122L15.9711 15.1476C16.1905 15.367 16.1734 15.7398 15.9329 15.9803C15.6924 16.2208 15.3196 16.2379 15.1002 16.0185L12.0063 12.9246L9.08239 16.1407C8.86709 16.3775 8.48855 16.384 8.2369 16.1552C7.98525 15.9264 7.95578 15.549 8.17108 15.3122L11.1344 12.0527L8.02907 8.94737C7.80966 8.72796 7.82675 8.35513 8.06724 8.11464Z"
        fill="#9A9AAA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.06717 8.11464C8.30766 7.87415 8.68048 7.85706 8.89989 8.07647L11.9638 11.1403L14.7673 8.05656C14.9826 7.81975 15.3612 7.81324 15.6128 8.04202C15.8645 8.27081 15.8939 8.64825 15.6786 8.88507L12.8356 12.0122L15.971 15.1476C16.1904 15.367 16.1733 15.7398 15.9328 15.9803C15.6923 16.2208 15.3195 16.2379 15.1001 16.0185L12.0062 12.9246L9.08232 16.1407C8.86702 16.3775 8.48848 16.384 8.23683 16.1552C7.98518 15.9264 7.95571 15.549 8.17101 15.3122L11.1343 12.0527L8.029 8.94736C7.80959 8.72795 7.82668 8.35513 8.06717 8.11464Z"
        fill="white"
      />
    </svg>
  );
};

export default CloseIconFilled;
