import React from 'react';

import {
  DotsNavigation,
  SelectOptionWrapper,
  SelectOption,
  Circle,
  SelectSpan,
  BlackLine
} from './styled';

const Dots = ({ activeSlide, onSlideChange, slides }) => {
  return (
    <DotsNavigation>
      {slides.map(({ TITLE, TYPE }, index) => (
        <SelectOptionWrapper key={`dot-${TYPE}`}>
          <SelectOption key={`navigation-${TYPE}}`}>
            <Circle
              filled={TYPE === activeSlide}
              onClick={() => onSlideChange(TYPE)}
            />
            <SelectSpan onClick={() => onSlideChange(TYPE)}>{TITLE}</SelectSpan>
          </SelectOption>
          {index !== slides.length - 1 && <BlackLine />}
        </SelectOptionWrapper>
      ))}
    </DotsNavigation>
  );
};

export default Dots;
