import styled, { css } from 'styled-components';
import styles from 'common/enums/styles';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 54px 24px 57px;
  background: ${styles.colors.DARK_BEIGE};
  margin: 0 16px;
  border-radius: 8px;
`;

export const ImageContainer = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  min-width: 180px;
  align-items: center;
`;

export const ProImage = styled.img`
  height: 180px;
  width: 180px;
  border-radius: 50%;
  margin-bottom: 12px;
  box-shadow: ${styles.textShadows.GREY};
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  align-self: center;
  max-width: 500px;
  padding: 0 16px 0;
`;

export const SignWrapper = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  align-self: center;
  & p {
    margin-bottom: 4px;
  }
`;

export const ProSignWrapper = styled.div`
  ${SignWrapper}
  margin-top: 6px;
`;

export const ReviewerSignWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-top: 8px;
  & p {
    line-height: 1.2;
  }
`;

export const Star = styled.img`
  width: 32px;
  height: 32px;
`;

export const Stars = styled.div`
  display: grid;
  gap: 5px;
  justify-content: flex-start;
  grid-auto-flow: column;
  margin-top: 20px;
`;

export const textStyle = {
  marginTop: 18,
  textAlign: 'justify'
};
