import React from 'react';
import { Logo } from 'components';
import { Top, TopWrapper } from './styled';

const TopLogo = () => (
  <TopWrapper>
    <Top>
      <Logo black />
    </Top>
  </TopWrapper>
);

export default TopLogo;
