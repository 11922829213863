import styled from 'styled-components/macro';
import styles from 'common/enums/styles';
import { device } from 'common/enums/devices';

import { TIMELINE_ITEM_GAP } from '../../constants';

export const Section = styled.section`
  background: ${styles.components.BACKGROUND};
`;

export const Wrapper = styled.div`
  display: grid;
  padding: 56px 16px;
  white-space: pre-line;
  margin: 0 auto;

  @media screen and ${device.TABLET} {
    width: 100%;
    max-width: 1240px;
    padding: 56px 10%;
  }
  @media screen and ${device.LAPTOP} {
    padding: 80px 11px;
  }
`;

export const Title = styled.h1`
  text-align: center;
  margin-bottom: 36px;
  color: ${styles.components.HEADER_INVERTED};
  @media screen and ${device.LAPTOP} {
    margin-bottom: 22px;
  }
`;

export const SubTitle = styled.div`
  margin: auto;
  white-space: pre-line;
  text-align: justify;
  @media screen and ${device.LAPTOP} {
    max-width: 1041px;
    text-align: left;
  }
`;

export const Container = styled.div`
  display: grid;
  justify-items: center;
  margin-top: 96px;
  @media screen and ${device.LAPTOP} {
    position: relative;
    gap: ${TIMELINE_ITEM_GAP}px;
    margin-top: 80px;
  }
`;
