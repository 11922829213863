import React from 'react';
import { LoadingSpinner } from '@sourcegraph/react-loading-spinner';
import '@sourcegraph/react-loading-spinner/lib/LoadingSpinner.css';
import { StyledButton } from './styled';

const SquareButton = ({ loading, children, disabled, onClick, ...props }) => (
  <StyledButton
    {...props}
    disabled={disabled || loading}
    onClick={disabled || loading ? () => ({}) : onClick}>
    {loading ? <LoadingSpinner /> : children}
  </StyledButton>
);

export default SquareButton;
