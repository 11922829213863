import React from 'react';
import { isMobile } from 'react-device-detect';

import { Logo } from 'components';

import { compareColumns, compareTableData } from './compareTableData';
import {
  Section,
  Wrapper,
  Title,
  TableWrapper,
  Table,
  Column,
  HeaderCell,
  Cell,
  CategoryWrapper,
  Category,
  SubCategory,
  CellText,
  Link,
  LogoWrapper
} from './styled';

const CompareTable = () => {
  // Order compareColumns by ORDER
  const compareColumnsOrdered = Object.values(compareColumns).sort(
    (a, b) => a.ORDER - b.ORDER
  );

  return (
    <Section id="us-vs-them">
      <Wrapper>
        <Title>{'What matters to you.\nWhat matters to us.'}</Title>
        <TableWrapper>
          <Table isMobile={isMobile}>
            {Object.values(compareColumnsOrdered).map((item, index) => (
              <Column
                key={index}
                isHighligted={item.IS_HIGHLIGHTED}
                color={item.COLOR}
                background={item.BACKGROUND}>
                <HeaderCell
                  color
                  fontSize={item.FONT_SIZE}
                  background={item.BACKGROUND}>
                  {item.IS_LOGO ? (
                    <LogoWrapper>
                      <Logo white disabled />
                    </LogoWrapper>
                  ) : (
                    <CategoryWrapper>
                      <Category>{item.NAME}</Category>
                      {item.SUBNAME && (
                        <SubCategory>
                          {'\n'}
                          {''}
                          {item.SUBNAME}
                        </SubCategory>
                      )}
                    </CategoryWrapper>
                  )}
                </HeaderCell>
                {compareTableData.map(cellData => (
                  <Cell key={cellData.id} center={cellData[item.TYPE] === '-'}>
                    <CellText>
                      {cellData[item.TYPE]}
                      {cellData.LINKS && cellData.LINKS[item.TYPE] && (
                        <Link href={cellData.LINKS[item.TYPE].REF}>
                          {cellData.LINKS[item.TYPE].TEXT}
                        </Link>
                      )}
                    </CellText>
                  </Cell>
                ))}
              </Column>
            ))}
          </Table>
        </TableWrapper>
      </Wrapper>
    </Section>
  );
};

export default CompareTable;
