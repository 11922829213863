import React, { useState } from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { Radio } from 'components';
import { useHistory } from 'react-router-dom';
import { LoadingSpinner } from '@sourcegraph/react-loading-spinner';
import routes from 'common/enums/routes';

import { unsubscribeFromEmail } from 'store/actions/user';
import { selectUnsubscribeLoading } from 'store/reducers/user';
import { reasonTypes, unsubscribeImage } from './enums';
import styles from 'common/enums/styles';

import {
  Wrapper,
  InnerBox,
  WhiteBox,
  FormHeader,
  FormNotation,
  FormSubheader,
  HeadBox,
  InputGroup,
  HeadImage,
  InputWrapper,
  OtherReasonTextarea,
  OtherReasonTextareaWrapper,
  SubmitButton,
  ResultBox,
  Link
} from './styled';

const Unsubscribe = ({
  fields,
  values,
  unsubscribeLoading,
  unsubscribeFromEmail,
  invalid
}) => {
  const [isUnsubscribeSuccess, setIsUnsubscribeSuccess] = useState(false);
  const {
    location: { search }
  } = useHistory();
  const params = new URLSearchParams(search);
  const email = params.get('email');
  const code = params.get('code');
  const { reasonType, description } = values;

  const onFormSubmit = () => {
    unsubscribeFromEmail({
      code,
      reason_id: Number(reasonType),
      ...(Number(reasonType) === reasonTypes.OTHER.TYPE && { description }),
      onSuccess: () => setIsUnsubscribeSuccess(true)
    });
  };

  return (
    <Wrapper>
      <InnerBox>
        <HeadBox>
          <HeadImage src={unsubscribeImage} />
        </HeadBox>
        <WhiteBox>
          {!isUnsubscribeSuccess ? (
            <>
              <FormHeader>Unsubscribe from email newsletters</FormHeader>
              <FormNotation>
                You will no longer receive emails to <strong>{email}</strong>.
              </FormNotation>
              <FormSubheader>
                If you have a moment, please let us know why you want to
                unsubscribe:
              </FormSubheader>
              <InputGroup>
                <InputWrapper>
                  <Radio
                    type="unsubscribe"
                    {...fields.reasonType}
                    selectedLabelColor={styles.colors.GREEN}
                    selectedWeight={700}
                    disabled={unsubscribeLoading}
                    value={reasonTypes.DONT_WANT_TO_RECIEVE.TYPE}
                    label={reasonTypes.DONT_WANT_TO_RECIEVE.DESCRIPTION}
                  />
                </InputWrapper>
                <InputWrapper>
                  <Radio
                    {...fields.reasonType}
                    selectedLabelColor={styles.colors.GREEN}
                    disabled={unsubscribeLoading}
                    selectedWeight={700}
                    value={String(reasonTypes.NEVER_SIGN_UP_TO_LIST.TYPE)}
                    label={reasonTypes.NEVER_SIGN_UP_TO_LIST.DESCRIPTION}
                  />
                </InputWrapper>
                <InputWrapper>
                  <Radio
                    {...fields.reasonType}
                    selectedLabelColor={styles.colors.GREEN}
                    disabled={unsubscribeLoading}
                    selectedWeight={700}
                    value={String(reasonTypes.INAPPROPRIATE.TYPE)}
                    label={reasonTypes.INAPPROPRIATE.DESCRIPTION}
                  />
                </InputWrapper>
                <InputWrapper>
                  <Radio
                    {...fields.reasonType}
                    selectedLabelColor={styles.colors.GREEN}
                    disabled={unsubscribeLoading}
                    selectedWeight={700}
                    value={String(reasonTypes.OTHER.TYPE)}
                    label={reasonTypes.OTHER.DESCRIPTION}
                  />
                  {Number(reasonType) === reasonTypes.OTHER.TYPE && (
                    <OtherReasonTextareaWrapper>
                      <OtherReasonTextarea
                        {...fields.description}
                        placeholder="Please enter the reason to unsubscribe from emails"
                      />
                    </OtherReasonTextareaWrapper>
                  )}
                </InputWrapper>
              </InputGroup>

              <SubmitButton
                disabled={invalid || unsubscribeLoading}
                onClick={onFormSubmit}>
                {unsubscribeLoading ? <LoadingSpinner /> : 'Submit'}
              </SubmitButton>
            </>
          ) : (
            <ResultBox>
              <FormHeader>
                Your email has been successfully unsubscribed!
              </FormHeader>
              <FormNotation>
                You will no longer receive emails to <strong>{email}</strong>.
              </FormNotation>

              <FormNotation>
                If you have some questions please contact{' '}
                <a
                  href="mailto:support@hellodobby.com"
                  rel="noopener noreferrer"
                  target="_blank">
                  support@hellodobby.com
                </a>
              </FormNotation>
            </ResultBox>
          )}
          <Link to={routes.HOME}>hellodobby</Link>
        </WhiteBox>
      </InnerBox>
    </Wrapper>
  );
};

const validate = values => {
  const errors = {};

  if (
    Number(values.reasonType) === reasonTypes.OTHER.TYPE &&
    (!values.description || values?.description.trim() === '')
  ) {
    errors.description = 'required';
  }

  if (!values.reasonType) {
    errors.reasonType = 'required';
  }

  return errors;
};

const mapStateToProps = state => ({
  unsubscribeLoading: selectUnsubscribeLoading(state)
});

const mapDispatchToProps = {
  unsubscribeFromEmail
};

export default reduxForm({
  form: 'unsubscribe',
  fields: ['reasonType', 'description'],
  validate
})(connect(mapStateToProps, mapDispatchToProps)(Unsubscribe));
