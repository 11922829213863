import React from 'react';
import { connect } from 'react-redux';

import { selectModalType } from 'store/reducers/modal';
import modalTypes from 'common/enums/modalTypes';

import {
  SendMeTheLink,
  Result,
  Error,
  AIResult,
  AINoResult,
  ConfirmCode
} from './components';

const modals = {
  [modalTypes.SEND_ME_THE_LINK]: <SendMeTheLink />,
  [modalTypes.ERROR]: <Error />,
  [modalTypes.RESULT]: <Result />,
  [modalTypes.AI_RESULT]: <AIResult />,
  [modalTypes.AI_NO_RESULT]: <AINoResult />,
  [modalTypes.CONFIRM_CODE]: <ConfirmCode />
};

const Modals = ({ modalType }) => {
  if (!modals[modalType]) {
    return null;
  }

  return modals[modalType];
};

const mapStateToProps = state => ({
  modalType: selectModalType(state)
});

export default connect(mapStateToProps)(Modals);
