import styled from 'styled-components';
import styles from 'common/enums/styles';
import { device } from 'common/enums/devices';

const StyledInput = styled.input`
  display: inline-block;
  margin-right: ${({ isPhone }) => (isPhone ? '0' : '10px')};
  background: ${({ disabled, backgroundNude }) =>
    disabled
      ? styles.colors.GRAY + '35'
      : backgroundNude
      ? styles.colors.NUDE
      : styles.colors.WHITE_NEW};
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 1px;
  height: auto;
  box-shadow: none;
  height: 48px;
  padding: ${({ padding }) => (padding ? padding : '12px 91px 12px 48px')};
  width: 100%;
  max-width: ${({ fullWidth }) => (fullWidth ? '100%' : '300px')};
  box-sizing: border-box;
  color: ${styles.colors.DARK_BLUE};
  border-radius: 8px;
  border: 1px solid
    ${({ disabled, backgroundNude }) =>
      disabled
        ? styles.colors.GRAY + '35'
        : backgroundNude
        ? styles.colors.NUDE
        : styles.colors.WHITE_NEW};
  outline: none;
  transition: all 0.2s ease-out;
  font-family: 'HKGrotesk', sans-serif;
  font-weight: 400;
  ${({ errorBorders }) =>
    errorBorders &&
    `
        border: 1px solid ${styles.colors.RED_ERROR} !important;
    `}

  &::placeholder {
    color: ${styles.colors.GRAY_NEW};
    line-height: 1.5;
  }
  &:hover,
  &:focus {
    outline: none;
  }
  @media screen and ${device.LAPTOP} {
    border: 1px solid transparent;
    max-width: 100%;
    ${({ errorBorders }) =>
      errorBorders &&
      `
        border: 1px solid ${styles.colors.RED_ERROR};
        `}
  }
  ${({ InputStyles }) => InputStyles && InputStyles}
`;

export default StyledInput;
