import styled from 'styled-components/macro';
import styles from 'common/enums/styles';
import { device } from 'common/enums/devices';

export const DotsNavigation = styled.div`
  display: flex;
  flex-direction: row;
  @media screen and ${device.LAPTOP} {
    flex-direction: column;
    align-self: flex-end;
  }
`;

export const SelectOptionWrapper = styled.div`
  display: flex;
  align-items: center;
  @media screen and ${device.LAPTOP} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const SelectOption = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and ${device.LAPTOP} {
    flex-direction: row;
  }
`;

export const Circle = styled.div`
  width: 18px;
  height: 18px;
  background-color: ${({ filled }) =>
    filled ? styles.components.DOT : 'transparent'};
  border: 2px solid
    ${({ filled }) =>
      filled ? styles.components.DOT : styles.colors.WHITE_NEW};
  display: flex;
  border-radius: 12px;
  margin: 0 16px;
  cursor: pointer;
  @media screen and ${device.LAPTOP} {
    width: 24px;
    height: 24px;
    margin: 18px 16px;
  }
`;

export const SelectSpan = styled.span`
  font-weight: 600;
  font-size: 16px;
  line-height: 1.2;
  cursor: pointer;
  display: none;
  color: ${styles.colors.WHITE_NEW};

  @media screen and ${device.LAPTOP} {
    font-size: 24px;
    display: inline-block;
  }
`;

export const BlackLine = styled.div`
  border-top: 2px solid ${styles.colors.BLACK_TRUE};
  height: 0px;
  width: 44px;
  align-self: flex-start;
  width: 29px;

  ${`${SelectOptionWrapper}:nth-child(1) & {
    margin: 8px 0 0px -1px;
  }`}

  ${`${SelectOptionWrapper}:nth-child(2) & {
    margin: 8px 0 0px -6px;
  }`}

  @media screen and ${device.LAPTOP} {
    border-top-width: 0;
    border-left: 2px solid ${styles.colors.WHITE_NEW};
    height: 44px;
    width: 0px;
    ${`${SelectOptionWrapper}:nth-child(1) &, 
    ${SelectOptionWrapper}:nth-child(2) & {
      margin: 0 0 0 27px;
    }`}
  }
`;
