import React from 'react';
import { Text, GetApp } from 'components';
import { pageTypes } from 'common/enums/pages';
import {
  Wrapper,
  TextWrapper,
  Image,
  Container,
  Title,
  invertedTextStyle,
  GetAppWrapper
} from './styled';
import reviewsDownload from 'static/images/rating/reviews-download.png';

const DownloadAppImageText = ({ entryPoint, inverted }) => {
  return (
    <Wrapper>
      <Container inverted={inverted}>
        <Image src={reviewsDownload} />
        <TextWrapper>
          <Title text2 textAlign="justify">
            The best service pros.
            <br />
            All on Dobby.
          </Title>
          <Text
            text2
            textAlign="justify"
            style={inverted ? invertedTextStyle : {}}>
            Our Pros are incredibly talented, download the Dobby app, and we
            will connect you with a 5-star Pro!
          </Text>
          <GetAppWrapper>
            <GetApp
              light
              type={pageTypes.REVIEWS}
              mobileOnly
              entryPoint={entryPoint}
            />
          </GetAppWrapper>
        </TextWrapper>
      </Container>
    </Wrapper>
  );
};

export default DownloadAppImageText;
