import styled from 'styled-components/macro';
import styles from 'common/enums/styles';
import { device } from 'common/enums/devices';
import { pageTypes } from 'common/enums/pages';

export const Section = styled.section`
  display: grid;
  justify-content: center;
  justify-items: center;
  padding: 56px 16px 16px;
  background-size: cover;
  background-position: 35% center;
  background-color: ${styles.colors.WHITE_NEW};
  @media screen and ${device.LAPTOP} {
    width: 100%;
    padding: 144px 16px;
    background-position: center;
  }
`;

export const Title = styled.h1`
  color: ${styles.components.HEADER};
  max-width: 800px;
  text-align: center;
  white-space: break-spaces;
`;

export const GridContainer = styled.div`
  display: grid;
  grid-auto-flow: row;
  margin: ${({ type }) => (type === pageTypes.MEET_DOBBY ? 47 : 32)}px auto 0;
  width: 100%;
  max-width: 1356px;
  gap: ${({ type }) => (type === pageTypes.MEET_DOBBY ? 3 : 8)}px;
  justify-items: center;
  p {
    margin-bottom: 40px;
    max-width: 420px;
  }

  @media screen and ${device.LAPTOP} {
    grid-auto-flow: column;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, min-content);
    column-gap: 14px;
    row-gap: 16px;
    margin-top: ${({ type }) => (type === pageTypes.MEET_DOBBY ? 69 : 46)}px;
    p {
      margin: 0 3px;
    }
  }
`;

export const Image = styled.img`
  width: 88px;
  height: 88px;
  justify-self: center;
`;

export const ItemTitle = styled.h2`
  text-align: center;
  margin-top: 16px;
  white-space: break-spaces;
  color: ${styles.colors.DARK_BLUE};
  @media screen and ${device.LAPTOP} {
    margin: ${({ type }) => (type === pageTypes.MEET_DOBBY ? 24 : 14)}px 8px 0;
  }
`;

export const textStyles = {
  whiteSpace: 'pre-wrap'
};
