import styled from 'styled-components/macro';
import styles from 'common/enums/styles';

export const Section = styled.section`
  background: ${styles.components.BACKGROUND};
  overflow-x: hidden;
`;

export const HeroButtons = styled.div`
  display: grid;
  grid-auto-flow: column;
  gap: 16px;
  justify-content: center;
  margin-top: 28px;

  @media screen and (min-width: 768px) {
    margin-top: 56px;
  }
`;
