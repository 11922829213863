import React from 'react';
import 'react-alice-carousel/lib/alice-carousel.css';

import AliceCarousel from 'react-alice-carousel';

import { pageTypes } from 'common/enums/pages';
import { Text, ImageQuote } from 'components';
import { ArrowButton } from './components';
import { directions } from './enums';
import {
  Section,
  Container,
  Title,
  SubTitle,
  CarouselContainer,
  Row,
  Absolute,
  ArrowButtons,
  Gradient
} from './styled';

const Reviews = ({
  reviews,
  reviewsSection,
  darkBackground,
  type,
  isMobile
}) => {
  let carouselRef = null;
  const items = Object.values(reviews).map(review => (
    <ImageQuote
      key={review.TYPE}
      type={review.TYPE}
      image={review.PRO_IMAGE}
      name={review.PRO_NAME}
      position={review.PRO_OCUPATION}
      text={review.TEXT}
      quoteName={review.HO_NAME}
      quoteSub={review.HO_LOCATION}
      inverted={darkBackground}
    />
  ));

  const handleButtonClick = item => () => {
    if (!carouselRef) {
      return;
    }

    if (item === directions.NEXT) {
      carouselRef.slideNext();
      return;
    }
    carouselRef.slidePrev();
  };

  return (
    <Section isMobile={isMobile} background={darkBackground} type={type}>
      <Container type={type}>
        {type !== pageTypes.DOBBY_CARE && (
          <>
            <Title type={type}>{reviewsSection.TITLE}</Title>
            <SubTitle>
              <Text description2>
                <span
                  dangerouslySetInnerHTML={{
                    __html: reviewsSection.SUBTITLE
                  }}></span>
              </Text>
            </SubTitle>
          </>
        )}

        <Row>
          <CarouselContainer type={type}>
            <AliceCarousel
              ref={el => (carouselRef = el)}
              disableDotsControls
              disableButtonsControls
              mouseTracking
              infinite
              // autoPlay
              // autoPlayInterval={5000}
              items={items}
            />
          </CarouselContainer>
          <Gradient left background={darkBackground} />
          <Gradient right background={darkBackground} />
          <Absolute left type={type}>
            <ArrowButton
              direction={directions.PREV}
              onClick={handleButtonClick(directions.PREV)}
              desktopOnly
            />
          </Absolute>
          <Absolute right type={type}>
            <ArrowButton
              direction={directions.NEXT}
              onClick={handleButtonClick(directions.NEXT)}
              desktopOnly
            />
          </Absolute>
        </Row>
        <ArrowButtons type={type}>
          {Object.values(directions).map(direction => (
            <ArrowButton
              key={direction}
              direction={direction}
              onClick={handleButtonClick(direction)}
              mobileOnly
            />
          ))}
        </ArrowButtons>
      </Container>
    </Section>
  );
};

export default Reviews;
