import React from 'react';
import { BackgroundImage } from 'components';
import { pageTypes } from 'common/enums/pages';
import heroBg from 'static/images/hero/hero-reviews-bg.png';

import {
  ColorWrapper,
  Section,
  Container,
  HeroBackground,
  Signature,
  SubTitle,
  Title
} from './styled';

const HeroReview = ({
  alt = 'background',
  backgroundType,
  imageSrc,
  placeholderSrc,
  signature,
  subTitle,
  title
}) => {
  const hasTopBar = pageTypes.HOME === backgroundType;
  return (
    <ColorWrapper type={backgroundType} hasTopBar={hasTopBar} id="mainSection">
      <Section>
        <BackgroundImage
          alt={alt}
          src1x={imageSrc}
          placeholderSrc={placeholderSrc}
        />
        <HeroBackground src={heroBg} />
        <Container type={backgroundType}>
          <Title type={backgroundType}>{title}</Title>
          <SubTitle>{subTitle}</SubTitle>
        </Container>
        <Signature>{signature}</Signature>
      </Section>
    </ColorWrapper>
  );
};

export default HeroReview;
