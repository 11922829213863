import React from 'react';

const IconClose = ({ size = 20, color = '#253757' }) => <svg
      height={size} 
      fill="none" 
      viewBox="0 0 14 14"
      width={size} 
      xmlns="http://www.w3.org/2000/svg">
        <path 
          clipRule="evenodd" 
          d="M0.699534 11.9301C0.317047 12.3285 0.329947 12.9616 0.728347 13.344C1.12675 13.7265 1.75978 13.7136 2.14227 13.3152L6.99964 8.25577L11.858 13.3162C12.2405 13.7146 12.8735 13.7275 13.2719 13.345C13.6703 12.9625 13.6832 12.3295 13.3007 11.9311L8.3859 6.81184L13.3007 1.69256C13.6832 1.29416 13.6703 0.661122 13.2719 0.278635C12.8735 -0.103852 12.2405 -0.0909518 11.858 0.307448L6.99964 5.36792L2.14226 0.308483C1.75978 -0.0899151 1.12674 -0.102814 0.728344 0.279674C0.329946 0.662162 0.317047 1.2952 0.699535 1.69359L5.61338 6.81185L0.699534 11.9301Z"
          fill={color}
          fillRule="evenodd"  />
    </svg>

export default IconClose;
