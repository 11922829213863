import styled from 'styled-components';
import styles from 'common/enums/styles';
import { device } from 'common/enums/devices';

export const ContactsContainer = styled.div`
  display: grid;
  grid-auto-flow: row;
  justify-items: center;
  @media screen and ${device.LAPTOP} {
    grid-auto-flow: column;
    grid-template-columns: repeat(2, auto);
    justify-content: center;
    gap: 71px;
  }
`;

export const ContactItem = styled.div`
  display: grid;
  justify-items: center;
  text-align: center;
  width: 170px;
  margin-bottom: ${({ page }) => (page === 'our-planet' ? 48 : 45)}px;

  @media screen and ${device.LAPTOP} {
    margin-bottom: 0;
    width: 15vw;
  }
`;

export const ContactPhoto = styled.div`
  width: 80px;
  height: 80px;
  margin-bottom: ${({ marginBottom, page }) =>
    page === 'our-planet' ? 15 : marginBottom || 11}px;

  @media screen and ${device.LAPTOP} {
    width: 7vw;
    height: 7vw;
  }
`;

export const ContactFullName = styled.div`
  font-weight: bold;
  font-size: 14px;
  line-height: 1.2;
  color: ${styles.components.TEXT_INVERTED};
  margin-bottom: 2px;
  @media screen and (max-width: 786px) {
    font-size: ${({ page }) => (page === 'our-planet' ? 14 : 18)}px;
  }

  @media screen and ${device.LAPTOP} {
    font-size: 1vw;
  }
`;

export const ContactPosition = styled.div`
  font-size: 14px;
  line-height: 1.2;
  color: ${styles.components.TEXT_INVERTED};
  margin-bottom: ${({ page, marginBottom }) =>
    page === 'our-planet' ? 0 : marginBottom || 10}px;
  @media screen and (max-width: 786px) {
    font-size: 14px;
  }

  @media screen and ${device.LAPTOP} {
    font-size: 1vw;
  }
`;

export const ContactEmail = styled.div`
  font-size: 14px;
  line-height: 1.5;
  color: ${styles.colors.HEADER_INVERTED};

  @media screen and ${device.LAPTOP} {
    font-size: 1vw;
  }
`;

export const Image = styled.img`
  max-width: 100%;
  height: auto;
  ${({ bordered }) => bordered && 'border-radius: 50%'};
`;

export const Link = styled.a`
  text-decoration: none;
  ${({ page }) =>
    page === 'our-planet' && `color: ${styles.components.TEXT_INVERTED};`};
  ${({ defaultColor }) => defaultColor && `color: ${styles.colors.BLACK_TRUE}`}
  &:hover {
    text-decoration: underline;
  }
`;
