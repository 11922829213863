import styled from 'styled-components/macro';
import styles from 'common/enums/styles';
import { device } from 'common/enums/devices';

export const ModalShadow = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 99;
  overflow: hidden;
  display: ${({ visible, topPosition }) => {
    if (visible && !topPosition) return 'flex';
    else if (visible && topPosition) return 'block';
    return 'none';
  }};
  flex-direction: column;
  align-items: center;
  justify-content: center;
  white-space: pre-line;

  @media screen and ${device.MOBILE} {
    ${({ lightBackground, aiResult }) =>
      aiResult
        ? 'background-color: transparent'
        : lightBackground
        ? `
      background-color: rgba(255,255,255,0.6);
      `
        : `
      background-color: #363C4B80;
    `}
  }

  @media screen and ${device.LAPTOP} {
    top: 0;
    bottom: 0;
    ${({ lightBackground }) =>
      lightBackground
        ? `
      background-color: rgba(255,255,255,0.6);
      `
        : `
      background-color: #363C4B80;
    `}
  }
`;

export const ModalContent = styled.div`
  position: relative;
  align-content: center;
  max-width: ${({ aiResult }) => (aiResult ? `calc(100vw - 20px)` : '343px')};
  background-color: ${styles.colors.WHITE_NEW};
  border-radius: ${({ borderRadius }) => borderRadius}px;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  @media screen and ${device.LAPTOP} {
    max-width: 343px;
  }

  ${({ topPosition, margin }) =>
    topPosition &&
    `
    margin: 80px auto 0 auto;
    @media screen and ${device.TABLET} {
      margin: 198px auto 0 auto;
      ${margin && `margin: ${margin}`};
    }
  `}
  max-height: calc(100vh - 50px);
  @media screen and ${device.TABLET} {
    min-height: 328px;
    max-width: calc(100vw - 10px);
    max-width: 800px;
    width: 90%;
    ${({ scrollable }) => !scrollable && `padding: 72px 104px 74px`}; 
    ${({ padding }) => padding && `padding: ${padding}`};
  }
  ${({ contentDisplay = 'grid' }) => `display: ${contentDisplay};`}
  ${({ scrollable, mobilePadding = '60px 32px 59px' }) =>
    scrollable
      ? `overflow-y: hidden;`
      : `overflow-y: auto;
 padding: ${mobilePadding};`}
 ${({ aiResult }) => aiResult && `margin: 72px 0 16px 0;`}
`;

export const MainContent = styled.div`
  @media screen and ${device.LAPTOP} {
    padding: ${({ centeredContent }) =>
      centeredContent ? '0' : '0 33px 0 33px'};
  }
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  align-self: ${({ centeredContent }) =>
    centeredContent ? 'flex-end' : 'flex-start'};
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: none;
  outline: 0;
  transition: background 0.3s;
  padding: 0;

  &:focus {
    outline: 0;
  }

  &:hover {
    background: ${styles.colors.ASPHALT};
    cursor: pointer;
  }

  @media screen and ${device.LAPTOP} {
    top: 18px;
    right: 26px;
    ${({ centeredContent }) =>
      centeredContent &&
      `
        align-self: center;
      `}
  }
`;

export const CloseButtonIcon = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 19px;
  width: auto;
  @media screen and ${device.LAPTOP} {
    height: 15px;
  }
`;
export const Heading = styled.h2`
  margin: 0;
  width: 173px;
  @media screen and ${device.TABLET} {
    padding-left: ${({ centeredContent }) => (centeredContent ? '0' : '33px')};
    width: 100%;
  }
`;

export const TopHeader = styled.div`
  display: grid;
  gap: 16px;
  justify-items: center;
  ${({ centeredContent }) =>
    centeredContent &&
    `
    text-align: center;
    `}
  ${({ headingPaddingBottom }) =>
    headingPaddingBottom && `padding-bottom: ${headingPaddingBottom}px`};
  @media screen and ${device.LAPTOP} {
    ${({ centeredContent }) =>
      centeredContent &&
      `
        align-items: center;
      `}
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const ScrollableHeader = styled.div`
  position: relative;
  height: 40px;

  div {
    background: linear-gradient(
      180deg,
      #ffffff 73.33%,
      rgba(255, 255, 255, 0) 100%
    );
    width: 100%;
    height: 80px;
    position: absolute;
    border-radius: 30px 30px 0px 0px;
  }
`;

export const ScrollableContent = styled.div`
  ${({ scrollable, aiResult }) =>
    scrollable &&
    `
    @media screen and ${device.TABLET} {
      padding: 52px 35px 52px 35px
    }
    overflow-y: auto;
  ${
    aiResult
      ? 'height: calc(100vh - 216px)'
      : 'max-height: calc(100vh - 50px - 80px)'
  };
  padding: 28px 20px 40px 20px
  `};
`;
export const Download = styled.div`
    font-weight: 700;
    font-size: 20px;
    display: none;
    align-items: center;
    justify-content: center;
    color: ${styles.colors.BLUE};
    background: ${styles.colors.WHITE_NEW};
    box-shadow: 0px 0px 20px rgba(115, 113, 252, 0.50);
    border-radius: 8px;
    width: 200px;
    height: 48px;
    cursor: pointer;
    //font-family: 'HKGrotesk', sans-serif;
    @media screen and ${device.LAPTOP} {
        display: flex;
    }

    // &:hover {
        //   color: ${styles.colors.WHITE_NEW + 'B3'};
    //   box-shadow: none;
    // }
`;

export const DownloadContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  ${({ aiResult }) => aiResult && `padding-bottom: 20px;`}
`;

export const MobileDownload = styled.div`
  @media screen and ${device.LAPTOP} {
    display: none;
  }
`;

export const DesktopView = styled.div`
  display: none;
  flex-direction: row;
  background-color: #f9f8f1;
  border-radius: 10px;
  padding: 16px 16px 16px 26px;
  align-items: center;
  max-width: 518px;
  margin-left: auto;
  margin-right: auto;
  column-gap: 24px;
  @media screen and ${device.LAPTOP} {
    display: flex;
  }
`;
export const TextSection = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 5px;
`;

export const QRcode = styled.img`
  width: 138px;
  height: 138px;
  outline: solid 2px #7371f4;
  border-radius: 10px;
`;
export const Title = styled.p`
  color: #2e3045;
  font-size: 24px;
  font-weight: 700;
  line-height: 120%;
`;
export const Description = styled.p`
  color: #6b6c79;
  font-size: 18px;
  font-weight: 700;
  line-height: 120%;
`;
