import styled from 'styled-components/macro';
import { device } from 'common/enums/devices';
import { TIMELINE_ITEM_GAP } from '../../constants';

export const Section = styled.div`
  display: none;

  position: absolute;
  top: 0;
  bottom: ${({ isLast }) => (isLast ? 0 : `-${TIMELINE_ITEM_GAP}px`)};

  @media screen and ${device.LAPTOP} {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const Line = styled.span`
  width: 6px;
  display: flex;
  margin: 0 auto;
  ${({ before }) => (before ? `height: 60px;` : `flex: 1;`)}
`;

export const Point = styled.div`
  width: 16px;
  height: 16px;
`;
