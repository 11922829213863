import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import mixpanel from 'mixpanel-browser';
import { isMobile } from 'react-device-detect';
import { useLocation } from 'react-router-dom';

import { useUtmParams } from 'hooks';
import { analyticsPageTypes, pageTypes } from 'common/enums/pages';
import { GetApp } from 'components';
import { closeNavigation } from 'store/actions/navigation';
import links from 'common/enums/links';

import {
  ExternalLink,
  GetAppWrapper,
  Line,
  MenuItemsWrapper,
  NavigationHolder,
  NavigationLink,
  NavigationLinkHolder
} from './styled';

import {
  getDownloadLink,
  getFirebaseDobbyDownloadLink,
  setBodyOverflowHiddenFunctional
} from 'common/helpers';

const TopNavigation = ({ closeNavigation, isNavigationOpened, mobileMenu }) => {
  useEffect(() => {
    setBodyOverflowHiddenFunctional(isNavigationOpened);
  }, [isNavigationOpened]);

  const closeMobileNavigation = () => {
    closeNavigation();
  };

  const location = useLocation();
  const { utmParamsString } = useUtmParams({
    page: analyticsPageTypes.DESKTOP_HEADER
  });
  const dobbyAppDownloadLink = getFirebaseDobbyDownloadLink({
    utmParamsString,
    pathname: location.pathname
  });
  const { downloadLinkPlatform } = getDownloadLink();

  const handleTrackDownloadApp = () => {
    mixpanel.track('Navigation HO download ' + downloadLinkPlatform, {
      'Entry Point': analyticsPageTypes.DESKTOP_HEADER
    });
  };

  const desktopLink = isMobile ? {} : { target: '_blank' };

  const handleIsActiveCheck = aliasPaths => (match, location) =>
    Boolean(match) || aliasPaths?.includes(location.pathname);

  return (
    <>
      <MenuItemsWrapper>
        <NavigationHolder>
          {Object.values(links)
            .filter(item => item.IS_TOP_NAVIGATION)
            .map(item => {
              const isHome = item.TYPE === pageTypes.HOME;
              return (
                <React.Fragment key={item.TYPE}>
                  <NavigationLinkHolder mobileOnly={isHome} key={item.TYPE}>
                    {item.EXTERNAL_LINK ? (
                      <ExternalLink
                        href={item.URL}
                        target="_blank"
                        rel="noopener noreferrer">
                        {item.TITLE}
                      </ExternalLink>
                    ) : (
                      <NavigationLink
                        exact={isHome}
                        to={item.URL}
                        isActive={handleIsActiveCheck(item.ALIAS_PATHS)}
                        onClick={closeMobileNavigation}>
                        {item.TITLE}
                      </NavigationLink>
                    )}
                  </NavigationLinkHolder>
                  <Line />
                </React.Fragment>
              );
            })}
        </NavigationHolder>
      </MenuItemsWrapper>
      {mobileMenu && (
        <GetAppWrapper>
          <GetApp entryPoint={analyticsPageTypes.MOBILE_MENU} />
        </GetAppWrapper>
      )}
    </>
  );
};

TopNavigation.propTypes = {
  closeNavigation: PropTypes.func.isRequired
};

const mapDispatchToProps = {
  closeNavigation
};

export default connect(null, mapDispatchToProps)(TopNavigation);
