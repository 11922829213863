import React from 'react';
import closeImage from 'static/images/icons/close-white.svg';
import Player from '../Player';

import {
  ModalVideoWrapper,
  ModalVideoBackground,
  CloseButton,
  CloseButtonIcon,
  Holder
} from './styled';

const ModalPlayer = ({ hideModal }) => (
  <ModalVideoWrapper>
    <ModalVideoBackground onClick={hideModal} />
    <Holder>
      <CloseButton onClick={hideModal}>
        <CloseButtonIcon src={closeImage} />
      </CloseButton>
      <Player autoPlay />
    </Holder>
  </ModalVideoWrapper>
);

export default ModalPlayer;
