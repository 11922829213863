import { useEffect } from 'react';

import links from 'common/constants/links';

const RedirectToArticle = ({ match: { params } }) => {
  useEffect(() => {
    const articleID = params['articleID'];
    const checklistID = params['checklistID'];
    let link = links.WEBSITE_LINK;
    if (checklistID) {
      link = encodeURIComponent(
        `${links.WEBSITE_LINK}/checklist?article_id=${checklistID}`
      );
    } else if (articleID) {
      link = encodeURIComponent(
        `${links.WEBSITE_LINK}/article?article_id=${articleID}`
      );
    }
    const fullLink = `${links.DYNAMIC_LINK_BASE}?link=${link}&${links.DYNAMIC_LINK_IOS_MARKET}&${links.DYNAMIC_LINK_ANDROID_MARKET}&${links.DYNAMIC_OTHER_MARKET}`;

    window.location.href = fullLink;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default RedirectToArticle;
