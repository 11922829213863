import { call, takeLatest, put } from 'redux-saga/effects';
import actionTypes from 'common/enums/actionTypes';
import api_constants from 'common/enums/api';
import Api from 'services/ApiService';

function* userUtmTrack({ payload }) {
  try {
    yield call(Api.amplifyPost, api_constants.USER_UTM_TRACK, payload);
  } catch (err) {
    yield put({
      type: actionTypes.USER_UTM_TRACK_ERROR,
      payload: err?.message || 'Something went wrong, please try again later'
    });
  }
}

export default function* watchUserUtmTrack() {
  yield takeLatest(actionTypes.USER_UTM_TRACK_FETCH, userUtmTrack);
}
