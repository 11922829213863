const DYNAMIC_LINK_BASE = 'https://hellodobby.page.link/';
const DYNAMIC_LINK_IOS_MARKET =
  'isi=1445563710&ibi=com.halos.insurance.HalosAssistant';
const DYNAMIC_LINK_ANDROID_MARKET = 'apn=com.halosassistant';
const DYNAMIC_OTHER_MARKET = 'ofl=https://hellodobby.com/%23how-it-works-3';
const WEBSITE_LINK = 'https://hellodobby.com';
const HO_DEEP_LINK_BASE = 'com.halosinsurance://';
const PRO_DEEP_LINK_BASE = 'com.hellodobby.service://';
const MARKET_HO_APP_IOS = 'https://apps.apple.com/app/id1445563710';
const MARKET_HO_APP_ANDROID =
  'https://play.google.com/store/apps/details?id=com.halosassistant';
const MARKET_SP_APP_IOS = 'https://apps.apple.com/app/id1449612135';
const MARKET_SP_APP_ANDROID =
  'https://play.google.com/store/apps/details?id=com.halosservices';
const CREATE_JOB_UNIVERSAL_LINK = 'https://hellodobby.com/create_task';
const SUPPORT_UNIVERSAL_LINK = 'https://hellodobby.com/support_chat';
const MAIL_SUPPORT_REDIRECT =
  'https://hellodobby.com/redirect?link%3Dmailto%253Asupport%2540hellodobby.com';
const SUPPORT_CHAT_DYNAMIC = `${DYNAMIC_LINK_BASE}?link=${SUPPORT_UNIVERSAL_LINK}&apn=com.halosassistant&afl=${MAIL_SUPPORT_REDIRECT}&ibi=com.halos.insurance.HalosAssistant&ifl=${MAIL_SUPPORT_REDIRECT}&ofl=${MAIL_SUPPORT_REDIRECT}`;
const SUPPORT_CHAT_DEEP = `${HO_DEEP_LINK_BASE}support_chat`;
const CREATE_JOB_DYNAMIC = `${DYNAMIC_LINK_BASE}?link=${CREATE_JOB_UNIVERSAL_LINK}&apn=com.halosassistant&ibi=com.halos.insurance.HalosAssistant&isi=1445563710&ofl=${MARKET_HO_APP_IOS}`;
const CREATE_JOB_DEEP = `${HO_DEEP_LINK_BASE}create_task`;

export default {
  DYNAMIC_LINK_BASE,
  DYNAMIC_LINK_IOS_MARKET,
  DYNAMIC_LINK_ANDROID_MARKET,
  DYNAMIC_OTHER_MARKET,
  MARKET_HO_APP_IOS,
  MARKET_HO_APP_ANDROID,
  MARKET_SP_APP_IOS,
  MARKET_SP_APP_ANDROID,
  SUPPORT_CHAT_DYNAMIC,
  SUPPORT_CHAT_DEEP,
  CREATE_JOB_DYNAMIC,
  CREATE_JOB_DEEP,
  HO_DEEP_LINK_BASE,
  PRO_DEEP_LINK_BASE,
  WEBSITE_LINK
};
