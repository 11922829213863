import styled from 'styled-components/macro';
import styles from 'common/enums/styles';
import { device } from 'common/enums/devices';

export const CommonSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0;
`;

export const HeaderPadding = styled.div`
  height: ${styles.layout.MOBILE_MENU_HEIGHT}px;
  @media screen and ${device.LAPTOP} {
    height: ${styles.layout.MENU_HEIGHT}px;
  }
`;

export const WellContainer = styled.div`
  padding: 0 20px;
  margin: 20px auto 0;
  max-width: 100%;
  p {
    word-wrap: break-word;
  }

  @media screen and ${device.LAPTOP} {
    margin-top: 50px;
    max-width: 720px;
  }
`;

export const Heading = styled.h2`
  font-weight: bold;
  font-size: 30px;
  line-height: 1.2;
  text-align: center;
  margin: 5px 0 10px;
`;

export const SubHeading = styled.h3`
  font-weight: 600;
  font-size: 24px;
  line-height: 1.5;
  text-align: center;
  margin: 3px 0;
`;

export const Category = styled.h4`
  font-weight: 600;
  font-size: 18px;
  line-height: 1.66;
  text-align: center;
  margin: 17px 0 2px;
`;

export const Strong = styled.span`
  font-weight: 700;
  color: ${styles.colors.DARK_BLUE};
`;

export const MainContentstyles = `
  padding-bottom: 80px;
`;
