import styled from 'styled-components/macro';
import styles from 'common/enums/styles';
import { device } from 'common/enums/devices';

export const AbsoluteWrapper = styled.div`
  cursor: pointer;
  position: absolute;
  top: 16px;
  left: 16px;
  bottom: 16px;

  @media screen and ${device.LAPTOP} {
    display: none;
  }
`;

export const Hamburger = styled.div`
  width: 29px;
  height: 40px;
  position: relative;
  margin: 0 auto;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
`;

export const HamburgerLine = styled.span`
  display: block;
  position: absolute;
  height: 3.8px;
  width: 100%;
  background: ${styles.components.TEXT_INVERTED};
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
`;

export const TopLine = styled(HamburgerLine)`
  top: 8px;
  ${({ open }) =>
    open &&
    `
    top: 18px;
    height: 3px;
    width: 0%;
    left: 50%;
  `}
`;

export const MiddleLine = styled(HamburgerLine)`
  top: 18px;
  width: 82%;
  ${({ open }) =>
    open &&
    `
    width: 100%;
    height: 3px;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
`}
`;
export const SecondMiddleLine = styled(HamburgerLine)`
  top: 18px;
  width: 82%;
  ${({ open }) =>
    open &&
    `
    width: 100%;
    height: 3px;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  `}
`;

export const BottomLine = styled(HamburgerLine)`
  top: 28.2px;
  ${({ open }) =>
    open &&
    `
    top: 18px;
    height: 3px;
    width: 0%;
    left: 50%;
  `}
`;
