import styled from 'styled-components';
import { device } from 'common/enums/devices';

export const StyledImage = styled.img`
  height: 42px;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
`;

export const StyledMarker = styled.div`
  width: 1px;
  height: 1px;
  overflow: visible;
  position: relative;
  left: -15px;
`;

export const MapHolder = styled.div`
  height: 481px;
  margin-top: 48px;
  margin-bottom: 24px;
  border-radius: 6px;
  overflow: hidden;
  @media screen and ${device.LAPTOP} {
    height: 318px;
    margin-top: 16px;
    margin-bottom: 24px;
    overflow: visible;
  }
`;
