import React, { Component } from 'react';
import { animateScroll } from 'react-scroll';

import styles from 'common/enums/styles';
import { LogoLink, SVGIcon } from './styled';

class Logo extends Component {
  scrollToTop = e => {
    e && e.stopPropagation && e.stopPropagation();
    const { handleClick, disabled } = this.props;

    if (disabled) {
      return;
    }

    animateScroll.scrollTo(0, {
      duration: 1500,
      delay: 100,
      smooth: true
    });

    if (handleClick) {
      handleClick();
    }
  };

  render() {
    const { white, dark, black, footer, unsubscribe, navy } = this.props;
    if (black) {
      return (
        <LogoLink to="/" onClick={this.scrollToTop}>
          <SVGIcon
            unsubscribe={unsubscribe}
            viewBox="0 0 123 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M13.7243 3.80035C14.9673 2.80819 16.7276 2.80819 17.9706 3.80035L27.3629 11.2974C28.1773 11.9474 28.6518 12.935 28.6518 13.9796V25.4493H31.6953V13.9796C31.6953 12.0032 30.7974 10.1347 29.2567 8.90487L19.8644 1.40787C17.5127 -0.469289 14.1823 -0.469289 11.8306 1.40787L2.43823 8.90487C0.897501 10.1347 -0.000375748 12.0032 -0.000375748 13.9796V25.4493H3.04309V13.9796C3.04309 12.935 3.51766 11.9474 4.33201 11.2974L13.7243 3.80035Z"
              fill="#2E3045"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M15.847 8.90047L8.2544 14.9218L8.2544 25.4487H5.21094L5.21094 14.3344C5.21094 13.7702 5.46815 13.2371 5.90903 12.8875L14.7091 5.90853C15.3762 5.37945 16.3178 5.37945 16.9849 5.90853L25.7849 12.8875C26.2258 13.2371 26.483 13.7702 26.483 14.3344L26.483 25.4487H23.4396L23.4396 14.9219L15.847 8.90047Z"
              fill="#2E3045"
            />
            <path
              d="M18.2773 21.5002C18.2773 20.9446 18.7259 20.4941 19.2792 20.4941H20.3189C20.8723 20.4941 21.3208 20.9446 21.3208 21.5002V25.4486H18.2773V21.5002Z"
              fill="#2E3045"
            />
            <path
              d="M56.0517 23.4938V4.42578H50.622C50.5289 4.51925 50.3117 4.79966 50.3117 5.29817C50.3117 5.98363 50.684 6.38866 51.3666 6.38866H52.5767V12.4643H52.5456C51.9872 11.9034 50.8081 10.9999 48.6983 10.9999C45.0372 10.9999 42.0586 13.8975 42.0586 18.571C42.0586 22.4968 44.2615 25.7994 48.2329 25.7994C50.622 25.7994 52.0182 24.335 52.5767 23.3692H52.6077V25.4567H57.8202C57.9133 25.3632 58.1304 25.0828 58.1304 24.6154C58.1304 23.8988 57.7581 23.4938 57.0755 23.4938H56.0517ZM52.5767 20.16C52.5767 22.4968 51.2115 23.7119 49.3809 23.7119C47.209 23.7119 45.8128 21.5932 45.8128 18.2906C45.8128 14.8945 47.5193 12.9939 49.6601 12.9939C51.0874 12.9939 52.1113 13.6171 52.5767 14.3337V20.16Z"
              fill="#2E3045"
            />
            <path
              d="M66.0297 25.8617C70.8078 25.8617 73.6002 22.4968 73.6002 18.1348C73.6002 13.5859 70.6837 10.9064 66.371 10.9064C61.5618 10.9064 58.7694 14.1467 58.7694 18.5398C58.7694 23.0887 61.6549 25.8617 66.0297 25.8617ZM66.2468 23.9611C63.7957 23.9611 62.5547 21.4997 62.5547 18.4775C62.5547 15.6423 63.5785 12.7447 66.1227 12.7447C68.5739 12.7447 69.8149 15.2684 69.8149 18.1971C69.8149 21.0947 68.791 23.9611 66.2468 23.9611Z"
              fill="#2E3045"
            />
            <path
              d="M83.354 10.9687C80.9649 10.9687 79.5377 12.4331 79.0102 13.399H78.9792V4.42578H73.7357C73.6426 4.51925 73.4254 4.79966 73.4254 5.29817C73.4254 5.98363 73.7977 6.38866 74.4493 6.38866H75.4732V23.5561C76.1868 24.4596 77.9863 25.7682 81.8337 25.7682C86.5497 25.7682 89.4973 22.8395 89.4973 18.166C89.4973 14.1779 87.2013 10.9687 83.354 10.9687ZM81.9267 23.93C80.0341 23.93 79.1964 22.933 78.9792 22.4344V16.4835C78.9792 14.5206 80.1892 13.0562 82.175 13.0562C84.4399 13.0562 85.743 15.3307 85.743 18.3529C85.743 21.749 84.2227 23.93 81.9267 23.93Z"
              fill="#2E3045"
            />
            <path
              d="M99.277 10.9687C96.8879 10.9687 95.4607 12.4331 94.9333 13.399H94.9022V4.42578H89.6587C89.5656 4.51925 89.3484 4.79966 89.3484 5.29817C89.3484 5.98363 89.7208 6.38866 90.3723 6.38866H91.3962V23.5561C92.1098 24.4596 93.9094 25.7682 97.7567 25.7682C102.473 25.7682 105.42 22.8395 105.42 18.166C105.42 14.1779 103.124 10.9687 99.277 10.9687ZM97.8498 23.93C95.9571 23.93 95.1194 22.933 94.9022 22.4344V16.4835C94.9022 14.5206 96.1123 13.0562 98.098 13.0562C100.363 13.0562 101.666 15.3307 101.666 18.3529C101.666 21.749 100.146 23.93 97.8498 23.93Z"
              fill="#2E3045"
            />
            <path
              d="M115.888 11.2803C115.795 11.4049 115.578 11.6542 115.578 12.1527C115.578 12.8693 115.95 13.2432 116.664 13.2432H117.75C117.595 13.6794 117.223 14.6452 116.881 15.4865L114.43 21.4997H114.399L110.49 13.2432H111.421C112.103 13.2432 112.444 12.8381 112.444 12.1527C112.444 11.6542 112.258 11.4049 112.165 11.2803H105.029C104.936 11.4049 104.75 11.6542 104.75 12.1527C104.75 12.8381 105.122 13.2432 105.805 13.2432H106.611L111.234 22.8395C111.824 24.1169 112.041 24.6466 112.041 25.7682C112.041 27.0457 111.39 29.6005 109.59 29.6005C107.915 29.6005 107.604 28.4477 106.549 28.4477C105.991 28.4477 105.681 28.8528 105.681 29.4136C105.681 30.6599 106.984 31.9996 109.187 31.9996C113.096 31.9996 113.53 28.0738 115.95 22.341L118.184 17.1378C119.208 14.7387 119.767 13.6171 119.922 13.2432H120.853C121.628 13.2432 122.001 12.8693 122.001 12.1527C122.001 11.6542 121.815 11.4049 121.721 11.2803H115.888Z"
              fill="#2E3045"
            />
          </SVGIcon>
        </LogoLink>
      );
    }
    if (navy) {
      return (
        <LogoLink to="/" onClick={this.scrollToTop}>
          <SVGIcon
            unsubscribe={unsubscribe}
            viewBox="0 0 123 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M13.7243 3.80035C14.9673 2.80819 16.7276 2.80819 17.9706 3.80035L27.3629 11.2974C28.1773 11.9474 28.6518 12.935 28.6518 13.9796V25.4493H31.6953V13.9796C31.6953 12.0032 30.7974 10.1347 29.2567 8.90487L19.8644 1.40787C17.5127 -0.469289 14.1823 -0.469289 11.8306 1.40787L2.43823 8.90487C0.897501 10.1347 -0.000375748 12.0032 -0.000375748 13.9796V25.4493H3.04309V13.9796C3.04309 12.935 3.51766 11.9474 4.33201 11.2974L13.7243 3.80035Z"
              fill="#7371FC"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M15.847 8.90047L8.2544 14.9218L8.2544 25.4487H5.21094L5.21094 14.3344C5.21094 13.7702 5.46815 13.2371 5.90903 12.8875L14.7091 5.90853C15.3762 5.37945 16.3178 5.37945 16.9849 5.90853L25.7849 12.8875C26.2258 13.2371 26.483 13.7702 26.483 14.3344L26.483 25.4487H23.4396L23.4396 14.9219L15.847 8.90047Z"
              fill="#7371FC"
            />
            <path
              d="M18.2773 21.5002C18.2773 20.9446 18.7259 20.4941 19.2792 20.4941H20.3189C20.8723 20.4941 21.3208 20.9446 21.3208 21.5002V25.4486H18.2773V21.5002Z"
              fill="#7371FC"
            />
            <path
              d="M56.0517 23.4938V4.42578H50.622C50.5289 4.51925 50.3117 4.79966 50.3117 5.29817C50.3117 5.98363 50.684 6.38866 51.3666 6.38866H52.5767V12.4643H52.5456C51.9872 11.9034 50.8081 10.9999 48.6983 10.9999C45.0372 10.9999 42.0586 13.8975 42.0586 18.571C42.0586 22.4968 44.2615 25.7994 48.2329 25.7994C50.622 25.7994 52.0182 24.335 52.5767 23.3692H52.6077V25.4567H57.8202C57.9133 25.3632 58.1304 25.0828 58.1304 24.6154C58.1304 23.8988 57.7581 23.4938 57.0755 23.4938H56.0517ZM52.5767 20.16C52.5767 22.4968 51.2115 23.7119 49.3809 23.7119C47.209 23.7119 45.8128 21.5932 45.8128 18.2906C45.8128 14.8945 47.5193 12.9939 49.6601 12.9939C51.0874 12.9939 52.1113 13.6171 52.5767 14.3337V20.16Z"
              fill="#7371FC"
            />
            <path
              d="M66.0297 25.8617C70.8078 25.8617 73.6002 22.4968 73.6002 18.1348C73.6002 13.5859 70.6837 10.9064 66.371 10.9064C61.5618 10.9064 58.7694 14.1467 58.7694 18.5398C58.7694 23.0887 61.6549 25.8617 66.0297 25.8617ZM66.2468 23.9611C63.7957 23.9611 62.5547 21.4997 62.5547 18.4775C62.5547 15.6423 63.5785 12.7447 66.1227 12.7447C68.5739 12.7447 69.8149 15.2684 69.8149 18.1971C69.8149 21.0947 68.791 23.9611 66.2468 23.9611Z"
              fill="#7371FC"
            />
            <path
              d="M83.354 10.9687C80.9649 10.9687 79.5377 12.4331 79.0102 13.399H78.9792V4.42578H73.7357C73.6426 4.51925 73.4254 4.79966 73.4254 5.29817C73.4254 5.98363 73.7977 6.38866 74.4493 6.38866H75.4732V23.5561C76.1868 24.4596 77.9863 25.7682 81.8337 25.7682C86.5497 25.7682 89.4973 22.8395 89.4973 18.166C89.4973 14.1779 87.2013 10.9687 83.354 10.9687ZM81.9267 23.93C80.0341 23.93 79.1964 22.933 78.9792 22.4344V16.4835C78.9792 14.5206 80.1892 13.0562 82.175 13.0562C84.4399 13.0562 85.743 15.3307 85.743 18.3529C85.743 21.749 84.2227 23.93 81.9267 23.93Z"
              fill="#7371FC"
            />
            <path
              d="M99.277 10.9687C96.8879 10.9687 95.4607 12.4331 94.9333 13.399H94.9022V4.42578H89.6587C89.5656 4.51925 89.3484 4.79966 89.3484 5.29817C89.3484 5.98363 89.7208 6.38866 90.3723 6.38866H91.3962V23.5561C92.1098 24.4596 93.9094 25.7682 97.7567 25.7682C102.473 25.7682 105.42 22.8395 105.42 18.166C105.42 14.1779 103.124 10.9687 99.277 10.9687ZM97.8498 23.93C95.9571 23.93 95.1194 22.933 94.9022 22.4344V16.4835C94.9022 14.5206 96.1123 13.0562 98.098 13.0562C100.363 13.0562 101.666 15.3307 101.666 18.3529C101.666 21.749 100.146 23.93 97.8498 23.93Z"
              fill="#7371FC"
            />
            <path
              d="M115.888 11.2803C115.795 11.4049 115.578 11.6542 115.578 12.1527C115.578 12.8693 115.95 13.2432 116.664 13.2432H117.75C117.595 13.6794 117.223 14.6452 116.881 15.4865L114.43 21.4997H114.399L110.49 13.2432H111.421C112.103 13.2432 112.444 12.8381 112.444 12.1527C112.444 11.6542 112.258 11.4049 112.165 11.2803H105.029C104.936 11.4049 104.75 11.6542 104.75 12.1527C104.75 12.8381 105.122 13.2432 105.805 13.2432H106.611L111.234 22.8395C111.824 24.1169 112.041 24.6466 112.041 25.7682C112.041 27.0457 111.39 29.6005 109.59 29.6005C107.915 29.6005 107.604 28.4477 106.549 28.4477C105.991 28.4477 105.681 28.8528 105.681 29.4136C105.681 30.6599 106.984 31.9996 109.187 31.9996C113.096 31.9996 113.53 28.0738 115.95 22.341L118.184 17.1378C119.208 14.7387 119.767 13.6171 119.922 13.2432H120.853C121.628 13.2432 122.001 12.8693 122.001 12.1527C122.001 11.6542 121.815 11.4049 121.721 11.2803H115.888Z"
              fill="#7371FC"
            />
          </SVGIcon>
        </LogoLink>
      );
    }
    return (
      <LogoLink to="/" onClick={this.scrollToTop}>
        {white || footer ? (
          <SVGIcon
            unsubscribe={unsubscribe}
            viewBox="0 0 123 33"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <g>
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.891 4.33164C15.1392 3.33946 16.9069 3.33946 18.1551 4.33164L27.5869 11.8287C28.4047 12.4787 28.8812 13.4663 28.8812 14.511V25.9808H31.9375V14.511C31.9375 12.5345 31.0359 10.666 29.4886 9.43621L20.0568 1.93913C17.6952 0.0619562 14.3508 0.0619567 11.9892 1.93913L2.55742 9.43621C1.01021 10.666 0.108562 12.5345 0.108562 14.511V25.9808H3.16482V14.511C3.16482 13.4663 3.64139 12.4787 4.45916 11.8287L13.891 4.33164Z"
                fill="url(#paint0_linear)"
              />
            </g>
            <g>
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.0245 9.43176L8.40001 15.4532L8.40001 25.9801H5.34375L5.34375 14.8657C5.34375 14.3016 5.60204 13.7685 6.04478 13.4188L14.8818 6.43978C15.5517 5.9107 16.4973 5.9107 17.1672 6.43978L26.0042 13.4188C26.447 13.7685 26.7053 14.3016 26.7053 14.8657L26.7053 25.9801H23.649L23.649 15.4532L16.0245 9.43176Z"
                fill="#FFFFFF"
              />
            </g>
            <path
              d="M18.4648 22.0315C18.4648 21.4758 18.9153 21.0254 19.4709 21.0254H20.515C21.0707 21.0254 21.5211 21.4758 21.5211 22.0315V25.9799H18.4648V22.0315Z"
              fill="#FFFFFF"
            />
            <path
              d="M56.3956 24.0252V4.95703H50.9431C50.8497 5.0505 50.6316 5.33092 50.6316 5.82943C50.6316 6.51489 51.0054 6.91993 51.6909 6.91993H52.906V12.9956H52.8749C52.314 12.4348 51.1301 11.5312 49.0114 11.5312C45.3348 11.5312 42.3438 14.4288 42.3438 19.1024C42.3438 23.0282 44.5559 26.3309 48.544 26.3309C50.9431 26.3309 52.3452 24.8665 52.906 23.9006H52.9372V25.9881H58.1716C58.2651 25.8947 58.4832 25.6142 58.4832 25.1469C58.4832 24.4303 58.1093 24.0252 57.4238 24.0252H56.3956ZM52.906 20.6914C52.906 23.0282 51.5351 24.2433 49.6968 24.2433C47.5158 24.2433 46.1138 22.1246 46.1138 18.822C46.1138 15.4258 47.8274 13.5253 49.9773 13.5253C51.4105 13.5253 52.4387 14.1484 52.906 14.865V20.6914Z"
              fill="#FFFFFF"
            />
            <path
              d="M66.4156 26.3932C71.2138 26.3932 74.0179 23.0282 74.0179 18.6662C74.0179 14.1172 71.0892 11.4377 66.7583 11.4377C61.929 11.4377 59.1248 14.6781 59.1248 19.0712C59.1248 23.6202 62.0224 26.3932 66.4156 26.3932ZM66.6337 24.4926C64.1723 24.4926 62.926 22.0312 62.926 19.0089C62.926 16.1736 63.9542 13.276 66.5091 13.276C68.9705 13.276 70.2168 15.7997 70.2168 18.7285C70.2168 21.6261 69.1886 24.4926 66.6337 24.4926Z"
              fill="#FFFFFF"
            />
            <path
              d="M83.8127 11.5C81.4136 11.5 79.9804 12.9644 79.4507 13.9303H79.4196V4.95703H74.154C74.0605 5.0505 73.8424 5.33092 73.8424 5.82943C73.8424 6.51489 74.2163 6.91993 74.8706 6.91993H75.8988V24.0875C76.6154 24.9911 78.4225 26.2997 82.286 26.2997C87.0219 26.2997 89.9819 23.3709 89.9819 18.6973C89.9819 14.7092 87.6762 11.5 83.8127 11.5ZM82.3795 24.4614C80.4789 24.4614 79.6377 23.4644 79.4196 22.9659V17.0149C79.4196 15.052 80.6347 13.5876 82.6288 13.5876C84.9032 13.5876 86.2118 15.862 86.2118 18.8843C86.2118 22.2804 84.6851 24.4614 82.3795 24.4614Z"
              fill="#FFFFFF"
            />
            <path
              d="M99.8027 11.5C97.4036 11.5 95.9704 12.9644 95.4407 13.9303H95.4095V4.95703H90.144C90.0505 5.0505 89.8324 5.33092 89.8324 5.82943C89.8324 6.51489 90.2063 6.91993 90.8606 6.91993H91.8888V24.0875C92.6054 24.9911 94.4125 26.2997 98.276 26.2997C103.012 26.2997 105.972 23.3709 105.972 18.6973C105.972 14.7092 103.666 11.5 99.8027 11.5ZM98.3695 24.4614C96.4689 24.4614 95.6276 23.4644 95.4095 22.9659V17.0149C95.4095 15.052 96.6247 13.5876 98.6187 13.5876C100.893 13.5876 102.202 15.862 102.202 18.8843C102.202 22.2804 100.675 24.4614 98.3695 24.4614Z"
              fill="#FFFFFF"
            />
            <path
              d="M116.484 11.8116C116.39 11.9362 116.172 12.1855 116.172 12.684C116.172 13.4006 116.546 13.7745 117.263 13.7745H118.353C118.198 14.2107 117.824 15.1766 117.481 16.0178L115.02 22.0312H114.988L111.063 13.7745H111.997C112.683 13.7745 113.026 13.3695 113.026 12.684C113.026 12.1855 112.839 11.9362 112.745 11.8116H105.579C105.485 11.9362 105.299 12.1855 105.299 12.684C105.299 13.3695 105.672 13.7745 106.358 13.7745H107.168L111.81 23.3709C112.402 24.6484 112.62 25.178 112.62 26.2997C112.62 27.5771 111.966 30.132 110.159 30.132C108.477 30.132 108.165 28.9792 107.106 28.9792C106.545 28.9792 106.233 29.3843 106.233 29.9451C106.233 31.1914 107.542 32.5311 109.754 32.5311C113.68 32.5311 114.116 28.6053 116.546 22.8724L118.79 17.6692C119.818 15.2701 120.379 14.1484 120.534 13.7745H121.469C122.248 13.7745 122.622 13.4006 122.622 12.684C122.622 12.1855 122.435 11.9362 122.342 11.8116H116.484Z"
              fill="#FFFFFF"
            />
            <defs>
              <linearGradient
                id="paint0_linear"
                x1="-0.393517"
                y1="20.1194"
                x2="32.1031"
                y2="20.8581"
                gradientUnits="userSpaceOnUse">
                <stop stopColor="#FFFFFF" />
                <stop offset="1" stopColor="#FFFFFF" />
              </linearGradient>
            </defs>
          </SVGIcon>
        ) : dark ? (
          <svg
            width="106"
            height="21"
            viewBox="0 0 106 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M17.4608 10.5809C17.4608 13.8644 16.4481 16.3406 14.4228 18.0095C12.4172 19.6603 9.59557 20.4857 5.9579 20.4857H0V1.05713H6.60678C8.80904 1.05713 10.7164 1.41994 12.3287 2.14557C13.9608 2.87119 15.2192 3.94149 16.104 5.35645C17.0085 6.75328 17.4608 8.49477 17.4608 10.5809ZM12.8301 10.6898C12.8301 8.53106 12.3189 6.95282 11.2964 5.95509C10.2739 4.93922 8.78938 4.43128 6.84274 4.43128H4.45368V17.0843H6.37083C10.677 17.0843 12.8301 14.9528 12.8301 10.6898Z"
              fill={styles.colors.BLACK_TRUE}
            />
            <path
              d="M42.2726 10.7442C42.2726 12.7578 41.9089 14.5175 41.1813 16.0231C40.4735 17.5106 39.3822 18.6716 37.9074 19.5061C36.4327 20.3406 34.545 20.7578 32.2445 20.7578C29.9636 20.7578 28.0759 20.3406 26.5815 19.5061C25.1068 18.6716 24.0057 17.5016 23.2781 15.9959C22.5703 14.4902 22.2163 12.7306 22.2163 10.717C22.2163 8.70339 22.5703 6.95282 23.2781 5.4653C24.0057 3.97777 25.1068 2.82584 26.5815 2.00951C28.0759 1.17505 29.9734 0.757812 32.274 0.757812C34.5549 0.757812 36.4327 1.17505 37.9074 2.00951C39.3822 2.82584 40.4735 3.98684 41.1813 5.49251C41.9089 6.98004 42.2726 8.7306 42.2726 10.7442ZM26.906 10.7442C26.906 12.776 27.3287 14.3814 28.1742 15.5605C29.0197 16.7215 30.3765 17.302 32.2445 17.302C34.1518 17.302 35.5184 16.7215 36.3442 15.5605C37.1701 14.3814 37.583 12.776 37.583 10.7442C37.583 8.69432 37.1701 7.08888 36.3442 5.92788C35.5184 4.76688 34.1616 4.18638 32.274 4.18638C30.3863 4.18638 29.0197 4.76688 28.1742 5.92788C27.3287 7.08888 26.906 8.69432 26.906 10.7442Z"
              fill={styles.colors.BLACK_TRUE}
            />
            <path
              d="M47.9621 1.05713H54.5099C57.3217 1.05713 59.4453 1.41994 60.8807 2.14557C62.3161 2.87119 63.0338 4.1501 63.0338 5.9823C63.0338 7.08888 62.7487 8.02312 62.1785 8.78502C61.6279 9.54693 60.8315 10.0095 59.7894 10.1728V10.3088C60.4973 10.4358 61.1363 10.6626 61.7066 10.9891C62.2768 11.3156 62.729 11.7873 63.0633 12.4041C63.3976 13.0209 63.5647 13.8372 63.5647 14.8531C63.5647 16.6127 62.8765 17.9914 61.5001 18.9891C60.1237 19.9868 58.236 20.4857 55.8371 20.4857H47.9621V1.05713ZM52.4158 8.75781H55.0113C56.309 8.75781 57.2037 8.56734 57.6953 8.18638C58.2065 7.80543 58.4621 7.25214 58.4621 6.52652C58.4621 5.78276 58.1672 5.24761 57.5773 4.92108C56.9874 4.59455 56.0534 4.43128 54.7753 4.43128H52.4158V8.75781ZM52.4158 12.0231V17.0843H55.3357C56.6728 17.0843 57.6068 16.8485 58.1377 16.3769C58.6686 15.8871 58.9341 15.2431 58.9341 14.4449C58.9341 13.7193 58.6588 13.1388 58.1082 12.7034C57.5773 12.2499 56.604 12.0231 55.1883 12.0231H52.4158Z"
              fill={styles.colors.BLACK_TRUE}
            />
            <path
              d="M69.106 1.05713H75.6538C78.4656 1.05713 80.5892 1.41994 82.0246 2.14557C83.46 2.87119 84.1777 4.1501 84.1777 5.9823C84.1777 7.08888 83.8926 8.02312 83.3224 8.78502C82.7718 9.54693 81.9755 10.0095 80.9333 10.1728V10.3088C81.6412 10.4358 82.2802 10.6626 82.8505 10.9891C83.4207 11.3156 83.8729 11.7873 84.2072 12.4041C84.5415 13.0209 84.7086 13.8372 84.7086 14.8531C84.7086 16.6127 84.0204 17.9914 82.644 18.9891C81.2676 19.9868 79.3799 20.4857 76.981 20.4857H69.106V1.05713ZM73.5597 8.75781H76.1552C77.453 8.75781 78.3476 8.56734 78.8392 8.18638C79.3504 7.80543 79.6061 7.25214 79.6061 6.52652C79.6061 5.78276 79.3111 5.24761 78.7212 4.92108C78.1313 4.59455 77.1973 4.43128 75.9192 4.43128H73.5597V8.75781ZM73.5597 12.0231V17.0843H76.4796C77.8167 17.0843 78.7507 16.8485 79.2816 16.3769C79.8125 15.8871 80.078 15.2431 80.078 14.4449C80.078 13.7193 79.8027 13.1388 79.2521 12.7034C78.7212 12.2499 77.7479 12.0231 76.3322 12.0231H73.5597Z"
              fill={styles.colors.BLACK_TRUE}
            />
            <path
              d="M96.7977 9.05713L101.192 1.05713H106L99.0098 12.9211V20.4857H94.5856V13.0571L87.5954 1.05713H92.4325L96.7977 9.05713Z"
              fill={styles.colors.BLACK_TRUE}
            />
          </svg>
        ) : (
          <svg
            width="150"
            height="50"
            viewBox="0 0 189 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <g>
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M21.1509 5.8318C23.0663 4.30928 25.7788 4.30928 27.6943 5.8318L42.1676 17.3363C43.4225 18.3338 44.1538 19.8492 44.1538 21.4523L44.1538 39.0531H48.8437L48.8438 21.4523C48.8438 18.4194 47.4601 15.5521 45.0859 13.6649L30.6125 2.16043C26.9886 -0.720144 21.8566 -0.720143 18.2326 2.16043L3.75924 13.6649C1.38501 15.5521 0.00139999 18.4194 0.00139999 21.4523L0.00139999 39.0531H4.69131L4.69131 21.4523C4.69131 19.8492 5.42261 18.3338 6.67751 17.3363L21.1509 5.8318Z"
                fill="url(#paint0_linear)"
              />
            </g>
            <g>
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M24.4212 13.6592L12.7212 22.8992L12.7212 39.0531H8.03125L8.03125 21.9977C8.03125 21.132 8.42761 20.314 9.107 19.7774L22.6676 9.0679C23.6957 8.25601 25.1466 8.25601 26.1747 9.0679L39.7353 19.7774C40.4147 20.314 40.8111 21.132 40.8111 21.9977L40.8111 39.0531H36.1212V22.8992L24.4212 13.6592Z"
                fill="#191468"
              />
            </g>
            <path
              d="M28.168 32.9931C28.168 32.1404 28.8592 31.4492 29.7118 31.4492H31.314C32.1667 31.4492 32.8579 32.1404 32.8579 32.9931V39.0521H28.168V32.9931Z"
              fill="#191468"
            />
            <path
              d="M86.3755 36.0536V6.79297H78.0085C77.865 6.9364 77.5304 7.36671 77.5304 8.13169C77.5304 9.18355 78.1041 9.80509 79.156 9.80509H81.0206V19.1283H80.9728C80.1122 18.2677 78.2953 16.8812 75.0442 16.8812C69.4024 16.8812 64.8125 21.3277 64.8125 28.4994C64.8125 34.5236 68.2071 39.5917 74.327 39.5917C78.0085 39.5917 80.16 37.3445 81.0206 35.8624H81.0684V39.0657H89.1007C89.2442 38.9223 89.5789 38.492 89.5789 37.7748C89.5789 36.6752 89.0051 36.0536 87.9533 36.0536H86.3755ZM81.0206 30.9378C81.0206 34.5236 78.9169 36.3883 76.096 36.3883C72.7492 36.3883 70.5977 33.1371 70.5977 28.0691C70.5977 22.8576 73.2273 19.9411 76.5263 19.9411C78.7256 19.9411 80.3034 20.8974 81.0206 21.997V30.9378Z"
              fill="#191468"
            />
            <path
              d="M101.751 39.6873C109.114 39.6873 113.417 34.5236 113.417 27.83C113.417 20.8495 108.923 16.7378 102.277 16.7378C94.8665 16.7378 90.5635 21.7102 90.5635 28.4516C90.5635 35.4321 95.01 39.6873 101.751 39.6873ZM102.086 36.7708C98.3089 36.7708 96.3965 32.9937 96.3965 28.356C96.3965 24.0051 97.9743 19.5586 101.895 19.5586C105.672 19.5586 107.584 23.4314 107.584 27.9257C107.584 32.3721 106.007 36.7708 102.086 36.7708Z"
              fill="#191468"
            />
            <path
              d="M128.448 16.8334C124.766 16.8334 122.567 19.0805 121.754 20.5627H121.706V6.79297H113.626C113.483 6.9364 113.148 7.36671 113.148 8.13169C113.148 9.18355 113.722 9.80509 114.726 9.80509H116.304V36.1492C117.403 37.5358 120.176 39.5438 126.105 39.5438C133.372 39.5438 137.914 35.0496 137.914 27.8778C137.914 21.758 134.376 16.8334 128.448 16.8334ZM126.248 36.723C123.332 36.723 122.041 35.193 121.706 34.428V25.296C121.706 22.2839 123.571 20.0368 126.631 20.0368C130.121 20.0368 132.129 23.527 132.129 28.1647C132.129 33.3762 129.786 36.723 126.248 36.723Z"
              fill="#191468"
            />
            <path
              d="M152.985 16.8334C149.303 16.8334 147.104 19.0805 146.291 20.5627H146.243V6.79297H138.163C138.02 6.9364 137.685 7.36671 137.685 8.13169C137.685 9.18355 138.259 9.80509 139.263 9.80509H140.841V36.1492C141.94 37.5358 144.713 39.5438 150.642 39.5438C157.909 39.5438 162.451 35.0496 162.451 27.8778C162.451 21.758 158.913 16.8334 152.985 16.8334ZM150.785 36.723C147.869 36.723 146.578 35.193 146.243 34.428V25.296C146.243 22.2839 148.108 20.0368 151.168 20.0368C154.658 20.0368 156.666 23.527 156.666 28.1647C156.666 33.3762 154.323 36.723 150.785 36.723Z"
              fill="#191468"
            />
            <path
              d="M178.583 17.3115C178.439 17.5027 178.104 17.8852 178.104 18.6502C178.104 19.7499 178.678 20.3236 179.778 20.3236H181.451C181.212 20.993 180.638 22.4751 180.113 23.7661L176.335 32.9937H176.288L170.263 20.3236H171.698C172.75 20.3236 173.276 19.7021 173.276 18.6502C173.276 17.8852 172.989 17.5027 172.845 17.3115H161.849C161.705 17.5027 161.418 17.8852 161.418 18.6502C161.418 19.7021 161.992 20.3236 163.044 20.3236H164.287L171.411 35.0496C172.319 37.0098 172.654 37.8226 172.654 39.5438C172.654 41.5041 171.65 45.4247 168.877 45.4247C166.295 45.4247 165.817 43.6556 164.191 43.6556C163.331 43.6556 162.853 44.2772 162.853 45.1378C162.853 47.0503 164.861 49.1062 168.255 49.1062C174.28 49.1062 174.949 43.0819 178.678 34.2846L182.121 26.3001C183.698 22.6186 184.559 20.8974 184.798 20.3236H186.232C187.428 20.3236 188.001 19.7499 188.001 18.6502C188.001 17.8852 187.715 17.5027 187.571 17.3115H178.583Z"
              fill="#191468"
            />
            <defs>
              <linearGradient
                id="paint0_linear"
                x1="-0.769054"
                y1="30.0586"
                x2="49.0979"
                y2="31.192"
                gradientUnits="userSpaceOnUse">
                <stop stopColor="#FF7E35" />
                <stop offset="1" stopColor="#DD34B8" />
              </linearGradient>
            </defs>
          </svg>
        )}
      </LogoLink>
    );
  }
}

export default Logo;
