import React from 'react';

import styles from 'common/enums/styles';
import { Text } from 'components';
import {
  Section,
  Container,
  ValuesContainer,
  ValueCard,
  TitleRow,
  Number
} from './styled';

import { values } from './enums';

const CoreValues = () => {
  return (
    <Section id="our-values">
      <Container>
        <h1>Our Core Values</h1>
        <Text description2>
          It is super difficult to build something you love. Here is how we do
          it.
        </Text>
        <ValuesContainer>
          {Object.values(values).map((value, index) => (
            <ValueCard key={`value-${index}`}>
              <TitleRow>
                <Number>{value.TYPE}</Number>
                <h2>{value.TITLE}</h2>
              </TitleRow>
              <Text text2 color={styles.colors.WHITE_NEW}>
                {value.TEXT}
              </Text>
            </ValueCard>
          ))}
        </ValuesContainer>
      </Container>
    </Section>
  );
};

export default CoreValues;
