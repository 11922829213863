import routes from './routes';

const links = {
  HOME: {
    TYPE: 'HOME',
    TITLE: 'Home',
    URL: routes.HOME,
    IS_TOP_NAVIGATION: true,
    IS_FOOTER_NAVIGATION: false
  },
  DOBBY_HO: {
    TYPE: 'DOBBY_HO',
    TITLE: 'Our Homeowners',
    URL: routes.DOBBY_HO,
    IS_TOP_NAVIGATION: true,
    IS_FOOTER_NAVIGATION: true
  },
  PROVIDERS: {
    TYPE: 'PROVIDERS',
    TITLE: 'Our Service Pros',
    URL: routes.PROVIDER,
    IS_TOP_NAVIGATION: true,
    IS_FOOTER_NAVIGATION: true
  },
  ABOUT_US: {
    TYPE: 'ABOUT_US',
    TITLE: 'Our Stories',
    URL: routes.ABOUT_US,
    IS_TOP_NAVIGATION: true,
    IS_FOOTER_NAVIGATION: true
  },
  REVIEWS: {
    TYPE: 'REVIEWS',
    TITLE: 'Reviews',
    URL: routes.REVIEWS,
    ALIAS_PATHS: [routes.REVIEWS_5_STAR],
    IS_TOP_NAVIGATION: true,
    IS_FOOTER_NAVIGATION: true
  },
  COMMUNITY: {
    TYPE: 'COMMUNITY',
    TITLE: 'Community',
    URL: 'https://community.dobbyhome.com/',
    IS_TOP_NAVIGATION: true,
    IS_FOOTER_NAVIGATION: true,
    EXTERNAL_LINK: true
  },
  ATTIC: {
    TYPE: 'ATTIC',
    TITLE: 'Check The Price™',
    URL: routes.CHECK_THE_PRICE,
    IS_TOP_NAVIGATION: true,
    IS_FOOTER_NAVIGATION: true
  },
  // PARTNERS: {
  //   TYPE: 'PARTNERS',
  //   TITLE: 'Our Partners',
  //   URL: routes.PARTNERS,
  //   IS_TOP_NAVIGATION: true,
  //   IS_FOOTER_NAVIGATION: true
  // },
  PLANNING_MODE: {
    TYPE: 'PLANNING_MODE',
    TITLE: 'Planning Mode',
    URL: routes.PLANNING_MODE,
    IS_TOP_NAVIGATION: true,
    IS_FOOTER_NAVIGATION: true
  },
  FAQ: {
    TYPE: 'FAQ',
    TITLE: 'FAQ',
    URL: routes.FAQ,
    IS_TOP_NAVIGATION: false,
    IS_FOOTER_NAVIGATION: true
  },
  PRIVACY: {
    TYPE: 'PRIVACY',
    TITLE: 'Privacy Policy',
    URL: routes.PRIVACY_POLICY,
    IS_FOOTER_NAVIGATION: true
  },
  JOIN_US_DMV: {
    TYPE: 'JOIN_US_DMV',
    TITLE: 'Join Us DMV',
    URL: routes.JOIN_US_DMV,
    IS_FOOTER_NAVIGATION: false
  },
  JOIN_US_CHARLOTTE: {
    TYPE: 'JOIN_US_CHARLOTTE',
    TITLE: 'Join Us Charlotte',
    URL: routes.JOIN_US_CHARLOTTE,
    IS_FOOTER_NAVIGATION: false
  }
};

export default links;

export const defaultSocialParams = {
  UTM_SOURCE: 'empty_social',
  UTM_MEDIUM: 'organic_social',
  UTM_CAMPAIGN: 'profile'
};

export const solialMedia = {
  NEXTDOOR: {
    ...defaultSocialParams,
    TYPE: 'n',
    UTM_SOURCE: 'nextdoor'
  },
  FACEBOOK: {
    ...defaultSocialParams,
    TYPE: 'f',
    UTM_SOURCE: 'facebook'
  },
  TWITTER: {
    ...defaultSocialParams,
    TYPE: 't',
    UTM_SOURCE: 'twitter'
  },
  LINKEDIN: {
    ...defaultSocialParams,
    TYPE: 'l',
    UTM_SOURCE: 'linkedin'
  },
  MOMGROUPS: {
    ...defaultSocialParams,
    TYPE: 'm',
    UTM_SOURCE: 'momgroups'
  },
  INSTAGRAM: {
    ...defaultSocialParams,
    TYPE: 'i',
    UTM_SOURCE: 'instagram'
  },
  REDDIT: {
    ...defaultSocialParams,
    TYPE: 'r',
    UTM_SOURCE: 'reddit'
  },
  PINTEREST: {
    ...defaultSocialParams,
    TYPE: 'p',
    UTM_SOURCE: 'pinterest'
  },
  CHECK_THE_PRICE: {
    ...defaultSocialParams,
    TYPE: 'chk_prc',
    UTM_SOURCE: 'web_check_the_price'
  },
  DEFAULT: defaultSocialParams
};

export const customRedirectUTMParams = {
  '/app/provider/11593': {
    UTM_SOURCE: 'email',
    UTM_MEDIUM: 'referral',
    UTM_CAMPAIGN: 'aarons_plumbing'
  },
  '/app/provider/11536': {
    UTM_SOURCE: 'email',
    UTM_MEDIUM: 'referral',
    UTM_CAMPAIGN: 'kleenet'
  }
};
