import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import rehypeRaw from 'rehype-raw';

import {
  Wrapper,
  Title,
  SectionDesc,
  Image,
  LoaderWrapper,
  MainDesc,
  DownloadImageWrapper
} from './styled';

import { analyticsPageTypes } from 'common/enums/pages';
import { DownloadAppImageText } from 'components';
import { getArticle } from 'store/actions/article';
import { selectArticle, selectLoadingArticle } from 'store/reducers/article';
import { connect } from 'react-redux';
import { LoadingSpinner } from '@sourcegraph/react-loading-spinner';
import NotFound from 'pages/NotFound';

const Article = ({ article, getArticle, isLoading }) => {
  const { slug = '' } = useParams();
  const title = article?.Title;
  const content = article?.Content;
  const banner = article?.Banner?.data?.attributes?.url;
  const entryPoint = `${analyticsPageTypes.ATTIC} ${slug}`;

  useEffect(() => {
    getArticle({ slug });
    window.scrollTo({ top: 0 });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return (
      <LoaderWrapper>
        <LoadingSpinner />
      </LoaderWrapper>
    );
  }

  if (!isLoading && !article) {
    return <NotFound />;
  }

  return (
    <Wrapper>
      {banner && (
        <Image>
          <img src={banner} alt="Content" />
        </Image>
      )}
      <Title>{title}</Title>
      <MainDesc id="mainSection">
        <SectionDesc
          children={content}
          rehypePlugins={[rehypeRaw]}
          components={{
            p: ({ node, ...props }) => {
              if (!node.children.length) {
                return <p {...props}>&nbsp;</p>;
              }
              return <p {...props} />;
            }
          }}
        />
      </MainDesc>

      <DownloadImageWrapper>
        <DownloadAppImageText entryPoint={entryPoint} />
      </DownloadImageWrapper>
    </Wrapper>
  );
};

const mapStateToProps = state => ({
  isLoading: selectLoadingArticle(state),
  article: selectArticle(state)
});

const mapDispatchToProps = {
  getArticle
};

export default connect(mapStateToProps, mapDispatchToProps)(Article);
