import React from 'react';
import { Text } from 'components';
import ReasonsItem from '../ReasonsItem/ReasonsItem';
import { Container, Wrapper, Section, Title, SubTitle } from './styled';
import { reasons } from './enums';
import styles from 'common/enums/styles';

const Reasons = () => {
  const subTitle =
    'From highest quality craftsmanship to prompt responses to fair pricing - our service providers are known for providing an exceptional customer experience. No wonder, they are maintaining 98% positive reviews from our homeowners!';

  const reasonsList = Object.values(reasons);
  return (
    <Section>
      <Wrapper>
        <Title>{`The stars of the show`}</Title>
        <SubTitle>
          <Text description2 color={styles.components.TEXT_INVERTED}>
            {subTitle}
          </Text>
        </SubTitle>
        <Container>
          {reasonsList.map((item, index) => (
            <ReasonsItem
              key={item.TYPE}
              reasonType={item.TYPE}
              title={item.TITLE}
              name={item.NAME}
              position={item.POSITION}
              image={item.IMAGE}
              text={item.TEXT}
              isLast={reasonsList.length - 1 === index}
            />
          ))}
        </Container>
      </Wrapper>
    </Section>
  );
};

export default Reasons;
