import React, { useEffect } from 'react';
import { scroller, Element } from 'react-scroll';
import { isMobile } from 'react-device-detect';

import { useTrackPageViews } from 'hooks';
import { Hero } from 'components';
import { DOCUMENT_TITLE } from 'common/constants/html';
import { analyticsPageTypes, pageTypes } from 'common/enums/pages';
import {
  HeroVideo,
  IconList,
  ImageQuote,
  TypeForm,
  WrapperSection
} from 'components';

import heroPlaceholder from 'static/images/backgrounds/provider.jpg';
import hero from 'static/images/hero/hero-providers-m.png';

import { Reasons } from './components';
import { items, imageQuoteContent } from './enums';

import { Section, TypeFormTitle, TypeFormContainer } from './styled';

const Provider = ({ history }) => {
  useTrackPageViews({ page: analyticsPageTypes.SERVICE_PROVIDERS });

  const scrollTo = section => {
    scroller.scrollTo(section, {
      duration: 1000,
      delay: 100,
      smooth: true,
      offset: -150
    });
  };

  useEffect(() => {
    document.title = DOCUMENT_TITLE;
    window.scrollTo(0, 0);
    if (history.location.state?.section) {
      scrollTo(history.location.state.section);
    }
  }, [history.location.state]);

  const heroTitle = "Empowering America's independent service providers";
  return (
    <Section>
      {window.innerWidth < 1024 ? (
        <Hero
          title={heroTitle}
          imageSrc={hero}
          placeholderSrc={heroPlaceholder}
          backgroundType={pageTypes.PROVIDERS}
        />
      ) : (
        <HeroVideo
          title={heroTitle}
          srcFull={
            'https://dobby-product-static.s3.amazonaws.com/website/output2.webm'
          }
          srcPlaceholder={heroPlaceholder}
          backgroundType={pageTypes.PROVIDERS}
        />
      )}
      <Element name="scrollBelowSecondSection" />
      <Reasons />
      <IconList
        items={items}
        title={'Our service providers are small biz owners from your community'}
        isWhite
        isProvider
      />
      <ImageQuote
        inverted
        title={
          isMobile
            ? imageQuoteContent.TITLE.MOBILE
            : imageQuoteContent.TITLE.DESKTOP
        }
        image={imageQuoteContent.IMAGE}
        text={imageQuoteContent.TEXT}
        name={imageQuoteContent.NAME}
        position={imageQuoteContent.POSITION}
        longTitle
      />
      <WrapperSection hasWhiteBackground>
        <TypeFormContainer>
          <TypeFormTitle>
            {'Reach out to learn more about becoming a DobbyPro'}
          </TypeFormTitle>
          <TypeForm pageType={pageTypes.PROVIDERS} />
        </TypeFormContainer>
      </WrapperSection>
    </Section>
  );
};

export default Provider;
