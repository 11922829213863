import styled from 'styled-components/macro';
import styles from 'common/enums/styles';
import { device } from 'common/enums/devices';
import { pageTypes } from 'common/enums/pages';

export const Section = styled.section`
  width: 100vw;
  overflow: hidden;
  background: ${({ background }) =>
    background
      ? styles.components.BACKGROUND
      : styles.components.BACKGROUND_LIGHT};
  @media screen and ${device.LAPTOP} {
    display: ${({ isMobile }) => (isMobile ? 'none' : 'flex')};
    flex-direction: column;
    width: 100%;
  }
  @media screen and (min-width: 1200px) {
    display: ${({ type }) =>
      [pageTypes.REVIEWS, pageTypes.DOBBY_CARE].includes(type)
        ? 'none'
        : 'flex'};
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  background: ${({ type, background }) => {
    if (type === pageTypes.REVIEWS) {
      return 'transparent';
    }
    return background
      ? styles.colors.BACKGROUND
      : styles.colors.BACKGROUND_LIGHT;
  }};
  padding: ${({ type }) =>
    type === pageTypes.MEET_DOBBY ? '52px 0 85px' : '58px 0 38px'};
  align-items: center;
  text-align: center;
  @media screen and ${device.LAPTOP} {
    padding: ${({ type }) =>
      type === pageTypes.MEET_DOBBY ? '62px 0 103px' : '80px 0 50px'};
    text-align: center;
  }
`;

export const Title = styled.h1`
  padding: ${({ type }) => (type === pageTypes.REVIEWS ? 0 : '0 16px')};
  width: 281px;
  color: ${styles.components.HEADER_COLOR};
  font-size: ${({ type }) => (type === pageTypes.MEET_DOBBY ? '30px' : '36px')};
  margin-bottom: ${({ type }) => (type === pageTypes.REVIEWS ? 0 : '18px')};
  @media screen and ${device.MOBILE} {
    width: 320px;
  }
  @media screen and ${device.TABLET} {
    padding: 0 16px;
    width: auto;
  }
  @media screen and ${device.LAPTOP} {
    margin-bottom: 24px;
    font-size: 50px;
  }
`;

export const SubTitle = styled.h2`
  text-align: center;
  margin-bottom: 40px;
  max-width: 1083px;
  padding: 0 16px;
  text-align: center;
  @media screen and ${device.LAPTOP} {
    margin-bottom: 102px;
    text-align: center;
  }
`;

export const CarouselContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
  .alice-carousel__stage-item * {
    line-height: 1.2;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 1256px;
  justify-content: space-between;
  position: relative;
  @media screen and ${device.LAPTOP} {
    padding: 0;
  }
`;

export const ArrowButtons = styled.div`
  display: flex;
  justify-content: center;
  ${({ type }) =>
    type !== pageTypes.REVIEWS &&
    `
  @media screen and ${device.LAPTOP} {
    display: none;
  }
  `}
`;

export const Absolute = styled.div`
  position: absolute;
  ${({ type }) =>
    type ? 'top: 50%; transform: translateY(-50%);' : `top: 96px;`}
  ${({ left }) => left && `left: 0;`}
  ${({ right }) => right && `right: 0;`}
  @media screen and ${device.LAPTOP} {
    ${({ type }) => type === pageTypes.REVIEWS && 'display: none'};
    ${({ type }) => type === pageTypes.HOME && 'top: 216px;'};
    ${({ type, left }) => type === pageTypes.HOME && left && 'left: -20px;'};
    ${({ type, right }) => type === pageTypes.HOME && right && 'right: -20px;'};
  }
`;

export const Gradient = styled.div`
  width: 100px;
  height: 100%;
  position: absolute;
  display: none;
  ${({ left, background }) =>
    left &&
    `
    left: -3px;
    background: linear-gradient(
      90deg,
      ${
        background
          ? styles.components.BACKGROUND
          : styles.components.BACKGROUND_LIGHT
      } 63.32%,
      ${
        background
          ? styles.components.BACKGROUND
          : styles.components.BACKGROUND_LIGHT
      }00 100%
    );`}

  ${({ right, background }) =>
    right &&
    `
    right: -3px;
    background: linear-gradient(
      90deg,
      ${
        background
          ? styles.components.BACKGROUND
          : styles.components.BACKGROUND_LIGHT
      }00 0%,
      ${
        background
          ? styles.components.BACKGROUND
          : styles.components.BACKGROUND_LIGHT
      } 36.78%
    );
  `}

  @media screen and ${device.LAPTOP} {
    ${({ type }) => type === pageTypes.REVIEWS && 'display: block'};
  }
`;
