import React, { useEffect, useRef } from 'react';
import { Text } from 'components';

import {
  Section,
  Wrapper,
  TextWrapper,
  Title,
  Container,
  Image,
  Signs,
  Sign,
  Footer,
  Bold,
  Welcome,
  SignName,
  SignPosition
} from './styled';
import { LinkedInIcon } from '../Team/components';
import { useLocation } from 'react-router-dom';
import satadruAlex from 'static/images/marketing/satadruAlex.jpg';

const Letter = () => {
  const location = useLocation();

  const ref = useRef(null);

  useEffect(() => {
    if (location?.state?.isRedirected) {
      setTimeout(() => {
        window.scrollTo({
          top: ref.current?.offsetTop - 30
        });
      }, 0);
    }
  }, []);
  return (
    <Section ref={ref}>
      <Wrapper>
        <Title>
          Building the most future-forward home services company in America
        </Title>
        <TextWrapper>
          <Text text2>
            <Welcome>
              <Bold black>Dear Homeowner,</Bold>
            </Welcome>
            <Welcome>Welcome to Dobby!</Welcome>
            When we buy a cup of coffee from Starbucks, almost nothing can go
            wrong. Well, our names sometimes! But we can't say the same thing
            about home services. Many things can go wrong when we try to get
            plumbing work done at our home or upgrade our kitchen! Getting
            things done at home is messy, time-consuming, and full of unknowns.
          </Text>
          <Text text2>
            <Bold>We founded Dobby to change that.</Bold>
            <bold>
              We do three things differently than every other company in this
              $500B industry.
            </bold>
            <ol type="1">
              <li>
                <bold>
                  We streamline and own the entire home services workflow
                </bold>
                , from service recommendations to budgeting, pro booking, job
                completion, payment, and review. So, you get guaranteed quality,
                simple & delightful UX, and insane time savings across 30+
                service categories from handyman to renovation.
              </li>
              <li>
                <bold>
                  We only work with invite-only, pre-vetted, 5-star quality
                  service pros.
                </bold>{' '}
                We help them grow their business by supporting them with
                marketing, technology, and a customer success team. Local,
                independent service pros are Dobby's lifeblood.
              </li>
              <li>
                <bold>
                  We have built the first-of-its-kind vertical AI software,
                  Gryffindor, designed specifically for the home services
                  industry.
                </bold>{' '}
                We use it to automate the home services workflow while keeping
                our customer success team members in the loop. AI and automation
                help us lower operational costs and create a super personalized
                user experience for you! With AI-powered features like{' '}
                <a
                  href="https://hellodobby.com/check-the-price"
                  target="_blank"
                  rel="noreferrer">
                  Check The Price
                </a>
                ™, you can make informed purchase decisions.
              </li>
            </ol>
          </Text>
          <Text text2>
            <bold>
              Dobby is the highest-rated home services app in the App Store and
              has the most loved user reviews.
            </bold>{' '}
            <br />
            Our business model is designed to help grow local small business
            community. And we are the only home services company with a climate
            program. Together, Dobby is the most future-forward home service
            company out there — built for busy homeowners who love quality
            products, value their time, and care about people and the planet!
          </Text>
          <Text>
            <bold>Thank you for being a part of the Dobby community.</bold>
            <br />
            We look forward to building Dobby with you.
            <br />
            <bold>Satadru & Alex</bold>
          </Text>
          <Text text2>
            PS: Home services industry has a long history of exploiting service
            providers and mis-using your data by selling your job leads to
            multiple service pros.{' '}
            <a
              href="https://www.ftc.gov/news-events/news/press-releases/2023/01/ftc-order-requires-homeadvisor-pay-72-million-stop-deceptively-marketing-its-leads-home-improvement"
              target="_blank"
              rel="noreferrer">
              Read the memo
            </a>{' '}
            from the Federal Trade Commission (FTC).
          </Text>
          <Text text2>
            At Dobby, we’re pioneering a new business model that is aligned with
            everyone’s incentives and protects your data. Dobby makes money only
            when our service pros make money. And our service pros make money
            only when the job is done for you with 5-star quality. So when we
            use Dobby, we all win!
          </Text>
        </TextWrapper>
        <Container>
          <Image src={satadruAlex} />
        </Container>
        <Footer>
          <Signs>
            <Sign>
              <LinkedInIcon
                relative
                hasReverseHover
                size={36}
                link="https://www.linkedin.com/in/satadrusg/"
              />
              <div>
                <SignName>Satadru Sengupta</SignName>
                <SignPosition>Co-founder & CEO</SignPosition>
              </div>
            </Sign>
            <Sign>
              <LinkedInIcon
                relative
                hasReverseHover
                size={36}
                link="https://www.linkedin.com/in/alexandra-fontova/"
              />
              <div>
                <SignName>Alexandra Fontova</SignName>
                <SignPosition>Co-founder & COO</SignPosition>
              </div>
            </Sign>
          </Signs>
        </Footer>
      </Wrapper>
    </Section>
  );
};

export default Letter;
