import styled from 'styled-components/macro';
import styles from 'common/enums/styles';
import { device, deviceSizes } from 'common/enums/devices';

export const TopButtonWrapper = styled.div`
  margin: auto;
  margin-top: 32px;
  width: 200px;
`;
export const TreeButton = styled.div`
  width: 200px;
`;

export const CommonSection = styled.div`
  max-width: ${deviceSizes.LAPTOP_L};
  margin: 0 auto;
`;

export const DescriptionSection = styled(CommonSection)`
  padding: 50px 16px 80px;
  display: flex;
  flex-direction: column;
  max-width: 691px;
`;

export const JustStartingSection = styled(CommonSection)`
  align-items: center;
  display: flex;
  flex-direction: column;
  background-color: ${styles.components.BACKGROUND};
  padding: 144px 0 88px;
  @media screen and ${device.LAPTOP} {
    padding: 88px 0;
    max-width: 100%;
  }
`;

export const Text = styled.p`
  color: ${styles.colors.DARK_BLUE};
  margin: 0;
  font-size: 14px;
  line-height: 1.5;
  width: 100%;
  @media screen and ${device.LAPTOP} {
    font-size: 16px;
  }
`;

export const TextPadding = styled(Text)`
  margin: 16px 0 0;
`;

export const Heading = styled.h2`
  color: ${styles.components.HEADER_COLOR};
  font-weight: bold;
  font-size: 18px;
  line-height: 1.5;
  margin: 22px 0 12px;
  @media screen and ${device.LAPTOP} {
    font-size: 24px;
    margin: 50px 0 16px;
  }
`;

export const Link = styled.a`
  color: ${styles.colors.BLUE};
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

export const Author = styled.p`
  color: ${styles.colors.DARK_BLUE};
  margin: 0 auto;
  font-weight: bold;
  font-size: 18px;
  line-height: 1.2;
  text-align: center;
  @media screen and ${device.LAPTOP} {
    font-size: 22px;
  }
`;

export const Content = styled.div`
  margin: 6px -31px;
  padding: 20px 0 29px;
  @media screen and ${device.LAPTOP} {
    padding: 43px 20px 46px;
    margin: 0 0;
  }
`;

export const Corner = styled.img`
  &:last-child {
    transform: rotate(-180deg);
    align-self: flex-end;
  }
`;

export const MainSectionContainer = styled.section`
  display: block;
  position: relative;
  height: auto;
  background: ${styles.colors.WHITE_NEW};
`;

export const ContactsSection = styled.section`
  background-color: ${styles.components.BACKGROUND};
  padding: 40px 0 80px;
`;

export const TreeHeading = styled.h2`
  color: ${styles.components.HEADER_INVERTED};
  font-weight: bold;
  font-size: 30px;
  line-height: 1.2;
  letter-spacing: 1px;
  text-align: center;
  margin-bottom: 11px;
  @media screen and ${device.LAPTOP} {
    font-size: 50px;
  }
`;

export const DownloadAppHeading = styled(TreeHeading)`
  color: ${styles.components.HEADER};
  margin-bottom: 40px;
`;

export const TreeSubheading = styled.h3`
  color: ${styles.components.HEADER_INVERTED};
  font-weight: bold;
  font-size: 18px;
  line-height: 1.2;
  letter-spacing: 1px;
  text-align: center;
  margin: 0 0 60px;
  max-width: 490px;
  @media screen and ${device.LAPTOP} {
    font-size: 24px;
  }
`;

// Form styles
export const FormHolder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 20px;
  margin: 0 16px 90px;
  padding: 18px 12px 15px 11px;
  background: url(${({ srcMobile }) => srcMobile});
  background-size: cover;
  max-width: 323px;
  @media screen and ${device.LAPTOP} {
    border-radius: 8px;
    margin: 0 16px 50px;
    background: url(${({ src }) => src});
    background-size: cover;
    max-width: 1020px;
    padding: 48px 32px 13px;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 11px;

  @media screen and ${device.LAPTOP} {
    flex-direction: row;
    margin-bottom: 8px;
    justify-content: flex-end;
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  margin-bottom: 16px;
  width: 100%;

  &:nth-child(2) {
    margin-bottom: 3px;
  }

  @media screen and ${device.LAPTOP} {
    margin-bottom: 0;
    width: unset;
    justify-content: flex-end;
    &:nth-child(2) {
      margin-bottom: 0;
    }
  }
`;

export const InputPrefix = styled.div`
  font-size: 16px;
  line-height: 1.38;
  font-weight: 400;
  color: ${styles.colors.DARK_BLUE};
  position: absolute;
  left: 25px;
  @media screen and ${device.LAPTOP} {
    bottom: ${({ top }) => top}px;
  }
`;

export const ButtonWrapper = styled.div``;

export const PlantTreeHeading = styled.h2`
  color: ${styles.colors.WHITE};
  font-weight: bold;
  font-size: 30px;
  line-height: 1.2;
  margin: 0 0 4px;
  @media screen and ${device.LAPTOP} {
    font-size: 32px;
    margin: 0 0 5px;
  }
`;

export const PlantTreeText = styled.p`
  color: ${styles.colors.WHITE};
  font-size: 14px;
  line-height: 1.5;
  margin: 0 0 19px;
  @media screen and ${device.LAPTOP} {
    margin: 0 0 7px;
  }
`;

export const PlantTreeNote = styled(PlantTreeText)`
  font-size: 12px;
  max-width: 262px;
  margin: 0;
  @media screen and ${device.LAPTOP} {
    margin: 0;
    max-width: unset;
  }
`;

// Plant a Tree Result
export const ModalResponse = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background: url(${({ src }) => src});
  background-size: cover;
  background-position: center;
  padding: 183px 12px 98px;
  @media screen and ${device.LAPTOP} {
    padding: 204px 118px 57px;
  }
`;

export const PlantTreeResultHeading = styled.h2`
  margin: 0 0 15px;
  color: ${styles.colors.WHITE};
  font-weight: bold;
  text-align: center;
  font-size: 24px;
  line-height: 1.2;
  max-width: 341px;
  @media screen and ${device.LAPTOP} {
    font-size: 32px;
    max-width: 740px;
    margin: 0 0 18px;
  }
`;

export const PlantTreeResultNote = styled.span`
  display: flex;
  color: ${styles.colors.WHITE};
  font-size: 14px;
  line-height: 1.5;
  margin: 0 0 23px;
  max-width: 285px;
  @media screen and ${device.LAPTOP} {
    margin: 0 0 32px;
    font-size: 24px;
    max-width: 692px;
  }
`;

// Mobile bottom section
export const MobileBottomContainer = styled.div`
  @media screen and ${device.LAPTOP} {
    display: none;
  }
  position: fixed;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 4;
  border-radius: 0px 40px 0px 0px;
  background-color: ${styles.colors.GREEN_PLANET};
  background-image: url(${({ src }) => src});
  background-size: 379%;
  background-position: 94.9% 96%;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  padding: 21px 24px;
  cursor: pointer;
`;

export const ArrowImageWrapper = styled.div`
  width: 25px;
  display: flex;
`;

export const MobileBottomText = styled.h2`
  color: ${styles.colors.WHITE};
  margin: 0 14px 0 0;
  letter-spacing: 0;
`;

export const ErrorModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 40px;
  @media screen and ${device.LAPTOP} {
    padding: 20px 10px;
  }
`;

export const ErrorModalText = styled.div`
  font-size: 18px;
  line-height: 1.5;
  color: ${styles.colors.DARK_BLUE};
  margin: 0 0 27px;
  text-align: center;
  @media screen and ${device.LAPTOP} {
    margin: 0 0 30px;
    font-size: 24px;
  }
`;

export const BeckendError = styled.p`
  color: ${styles.colors.YELLOW};
  font-size: 12px;
  line-height: 1.5;
  min-height: 18px;
  margin-bottom: 3px;
  @media screen and ${device.LAPTOP} {
    margin-bottom: 6px;
  }
`;

export const DownloadWrapper = styled.div`
  display: grid;
  justify-items: center;
  padding: 80px 0;

  @media screen and ${device.LAPTOP} {
    display: none;
  }
`;
