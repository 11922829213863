import React, { useState, useRef } from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import mixpanel from 'mixpanel-browser';
import { LoadingSpinner } from '@sourcegraph/react-loading-spinner';

import { useTrackPageViews } from 'hooks';
import { analyticsPageTypes, captchaActions } from 'common/enums/pages';
import { CAPTCHA_SITE_KEY } from 'common/constants/html';
import { GetApp, TextArea, Input, Button } from 'components';

import { askUsAnything } from 'store/actions/user';
import {
  askUsAnythingLoading,
  askUsAnythingResponse
} from 'store/reducers/question';

import {
  WrapperSection,
  Wrapper,
  HeaderWrapper,
  TopHeader,
  ColoredHeader,
  ResponseWrapper,
  ResponseBlock,
  FormWrapper,
  Form,
  InputGroup,
  InputWrapper,
  SendButtonWrapper,
  GetAppWrapper
} from './styled';

const AskUsAnything = ({
  errors,
  fields,
  values,
  resetForm,
  isLoading,
  askResponse,
  askUsAnything
}) => {
  useTrackPageViews({ page: analyticsPageTypes.ASK_US_ANYTHING });
  const [showValidation, setShowValidation] = useState({});
  // const captchaRef = useRef(null);

  const isFormFilled = Boolean(values.question && values.email);
  const isFieldError = field => errors[field] && showValidation[field];

  const activateValidation = field => () => {
    if (!showValidation[field]) {
      setShowValidation(showValidation => ({
        ...showValidation,
        [field]: true
      }));
    }
  };

  const submitAskUsForm = event => {
    event.preventDefault();
    mixpanel.track('Ask Us Anything Form Submit', {
      'Entry Point': analyticsPageTypes.ASK_US_ANYTHING,
      $email: values.email
    });

    // captchaRef.execute();
    window.grecaptcha
      .execute(CAPTCHA_SITE_KEY, {
        action: captchaActions.ASK_US_ANYTHING.SUBMIT
      })
      .then(token => {
        const data = {
          email: values.email,
          question: values.question
        };
        askUsAnything({
          data: { entryPoint: analyticsPageTypes.ASK_US_ANYTHING, ...data },
          security_token: token,
          ...data
        });
      });
  };

  return (
    <>
      <WrapperSection>
        <Wrapper id="formSection" withHeader>
          <HeaderWrapper>
            <TopHeader>
              Introducing <ColoredHeader>Home Explained</ColoredHeader>!<br />
              Ask any questions about your home, and we will get you an answer.
            </TopHeader>
          </HeaderWrapper>
          <FormWrapper>
            <Form>
              {/* <CaptchaUsage
                ref={captchaRef}
                sitekey={CAPTCHA_SITE_KEY}
                action={captchaActions.ASK_US_ANYTHING.SUBMIT}
                verifyCallback={captchaVerifyCallback}
              /> */}
              <InputGroup>
                <InputWrapper>
                  <TextArea
                    fullWidth
                    errorBorders={isFieldError('question')}
                    type="text"
                    placeholder="How much does it cost to upgrade my kitchen in Alexandria, VA?"
                    {...fields.question}
                    onBlur={activateValidation('question')}
                  />
                </InputWrapper>
                <InputWrapper>
                  <Input
                    fullWidth
                    errorBorders={isFieldError('email')}
                    type="text"
                    placeholder="Put your email here"
                    {...fields.email}
                    onBlur={activateValidation('email')}
                  />
                </InputWrapper>
              </InputGroup>
              <ResponseWrapper>
                <>
                  <ResponseBlock>
                    {askResponse != ''
                      ? askResponse
                      : 'The answer will appear here!\n\nPowered by openAI. More questions, just ask away!'}
                  </ResponseBlock>
                  {isLoading ? <LoadingSpinner /> : null}
                </>
              </ResponseWrapper>
              <SendButtonWrapper>
                <Button
                  title={'Ask'}
                  disabled={
                    isFieldError('question') ||
                    isFieldError('email') ||
                    !isFormFilled ||
                    isLoading
                  }
                  onClick={submitAskUsForm}
                />
              </SendButtonWrapper>
            </Form>
          </FormWrapper>
        </Wrapper>
      </WrapperSection>
      <GetAppWrapper>
        <GetApp
          hasTitle
          hasArrows
          isCentered
          entryPoint={analyticsPageTypes.ASK_US_ANYTHING}
          appName={'HO'}
        />
      </GetAppWrapper>
    </>
  );
};

const validate = values => {
  const errors = {};

  if (!values.question || values?.question.trim() === '') {
    errors.question = 'required';
  }

  if (!values.email) {
    errors.email = 'required';
  } else if (values.email && values.email.indexOf(' ') >= 0) {
    errors.email = 'wrongFormat';
  } else if (values.email && values.email.indexOf('@') < 0) {
    errors.email = 'wrongFormat';
  }

  return errors;
};

const mapStateToProps = state => ({
  isLoading: askUsAnythingLoading(state),
  askResponse: askUsAnythingResponse(state)
});

const mapDispatchToProps = {
  askUsAnything
};

export default reduxForm({
  form: 'askUsAnything',
  fields: ['question', 'email'],
  validate
})(connect(mapStateToProps, mapDispatchToProps)(AskUsAnything));
