import React from 'react';
import { Text } from 'components';
import { NUMBER_OF_JOBS } from 'common/constants/content';
import styles from 'common/enums/styles';
import { HeaderContainer } from './styled';

const TopBar = () => (
  <HeaderContainer>
    <Text text1 color={styles.colors.WHITE_NEW}>
      {/* {NUMBER_OF_JOBS} jobs with 98% 5-star ratings in two markets: the
      Washington, DC metro and the Charlotte metro. */}
      We serve thousands of homeowners and pros in the Washington, DC Metro
      (DMV) and San Francisco-Bay Area. Dobby is the highest rated home services
      app on the App Store.
    </Text>
  </HeaderContainer>
);
export default TopBar;
