import React, { useState, useRef, useEffect } from 'react';

import {
  Answer,
  AnswerDescription,
  AnswerLink,
  Container,
  Question,
  QuestionBlock,
  QuestionHeader,
  QuestionHeaderIcon,
  QuestionList,
  Title,
  TitleWrapper,
  Wrapper
} from './styled';

const Accordion = ({
  items,
  title,
  type,
  titleColor,
  dobbyCare,
  withSpace
}) => {
  const activeMenuRef = useRef();
  const activeAnswerRef = useRef();
  const menuRef = useRef();
  const [activeQuestionItem, setActiveQuestionItem] = useState(null);

  useEffect(() => {
    if (activeQuestionItem) {
      setActiveQuestionItem({
        index: activeQuestionItem?.index,
        height: activeAnswerRef.current?.scrollHeight || 'auto'
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeQuestionItem?.index]);

  useEffect(() => {
    if (
      menuRef.current &&
      activeMenuRef.current?.getBoundingClientRect().top < window.innerHeight
    ) {
      const menuCenter = menuRef.current?.getBoundingClientRect().width / 2;
      const { left: activeMenuLeft, width } =
        activeMenuRef.current?.getBoundingClientRect() || {};
      const left = menuCenter < activeMenuLeft ? width : -width;
      menuRef.current.scrollBy({
        behavior: 'smooth',
        left
      });
    }
  }, []);

  const setQuestionItem = index => {
    if (index === activeQuestionItem?.index) {
      setActiveQuestionItem(null);
    } else {
      setActiveQuestionItem({
        index,
        height: 0
      });
    }
  };
  return (
    <Wrapper withSpace={withSpace} type={type}>
      {title ? (
        <TitleWrapper>
          <Title titleColor={titleColor}>{title}</Title>
        </TitleWrapper>
      ) : null}
      <Container>
        <QuestionList dobbyCare={dobbyCare}>
          {items.qa.map((item, index) => (
            <QuestionBlock dobbyCare={dobbyCare} key={`question-item-${index}`}>
              <QuestionHeader onClick={() => setQuestionItem(index)}>
                <Question
                  center={index === activeQuestionItem?.index && dobbyCare}>
                  {item.question}
                </Question>
                <QuestionHeaderIcon>
                  {index === activeQuestionItem?.index ? (
                    <svg
                      width="19"
                      height="3"
                      viewBox="0 0 19 3"
                      fill="none"
                      display={dobbyCare ? 'none' : 'block'}
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M18.9688 2.42188H10.8749H9.08019H0.96875V0.421875H9.08019H10.8749H18.9688V2.42188Z"
                        fill="#404242"
                      />
                    </svg>
                  ) : (
                    <svg
                      width="19"
                      height="19"
                      viewBox="0 0 19 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M9.08019 18.2363H10.8749V10.1425H18.9688V8.33019H10.8749V0.21875H9.08019V8.33019H0.96875V10.1425H9.08019V18.2363Z"
                        fill={dobbyCare ? '#969696' : 'black'}
                      />
                    </svg>
                  )}
                </QuestionHeaderIcon>
              </QuestionHeader>
              <Answer
                collapsed={index !== activeQuestionItem?.index}
                height={
                  index === activeQuestionItem?.index
                    ? activeQuestionItem?.height
                    : 0
                }
                ref={
                  index === activeQuestionItem?.index ? activeAnswerRef : null
                }>
                {typeof item.answer === 'string' ? (
                  <AnswerDescription>{item.answer}</AnswerDescription>
                ) : (
                  item.answer
                )}
                {item.link?.url && (
                  <AnswerLink href={item.link.url}>
                    {item.link.text}
                    {'.'}
                  </AnswerLink>
                )}
              </Answer>
            </QuestionBlock>
          ))}
        </QuestionList>
      </Container>
    </Wrapper>
  );
};

export default Accordion;
