import actionTypes from 'common/enums/actionTypes';

const defaultState = {
  isNavigationOpened: false
};

function reducer(state = defaultState, action) {
  switch (action.type) {
    case actionTypes.OPEN_NAVIGATION: {
      return {
        isNavigationOpened: true
      };
    }

    case actionTypes.CLOSE_NAVIGATION: {
      return {
        isNavigationOpened: false
      };
    }

    default:
      return state;
  }
}

// selectors
export const isNavigationOpened = state => state.navigation.isNavigationOpened;

export default reducer;
