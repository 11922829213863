import styled, { createGlobalStyle } from 'styled-components';
import styles from 'common/enums/styles';
import { device } from 'common/enums/devices';

import HKGroteskLight from 'static/fonts/HKGrotesk-Light.otf';
import HKGroteskLightItalic from 'static/fonts/HKGrotesk-LightItalic.otf';
import HKGroteskRegular from 'static/fonts/HKGrotesk-Regular.otf';
import HKGroteskRegularItalic from 'static/fonts/HKGrotesk-Italic.otf';
import HKGroteskSemiBold from 'static/fonts/HKGrotesk-SemiBold.otf';
import HKGroteskSemiBoldItalic from 'static/fonts/HKGrotesk-SemiBoldItalic.otf';
import HKGroteskMedium from 'static/fonts/HKGrotesk-Medium.otf';
import HKGroteskMediumItalic from 'static/fonts/HKGrotesk-MediumItalic.otf';
import HKGroteskBold from 'static/fonts/HKGrotesk-Bold.otf';
import HKGroteskBoldItalic from 'static/fonts/HKGrotesk-BoldItalic.otf';
import HKGroteskExtraBold from 'static/fonts/HKGrotesk-ExtraBold.otf';
import HKGroteskBlack from 'static/fonts/HKGrotesk-Black.otf';

export const GlobalStyle = createGlobalStyle`
    @font-face {
        font-family: HKGrotesk;
        src: url(${HKGroteskLight}) format('opentype');
        font-weight: 100 300;
        font-style: normal;
    }
    @font-face {
        font-family: HKGrotesk;
        src: url(${HKGroteskLightItalic}) format('opentype');
        font-weight: 100 300;
        font-style: italic;
    }
    @font-face {
        font-family: HKGrotesk;
        src: url(${HKGroteskRegular}) format('opentype');
        font-weight: 400;
        font-style: normal;
    }
    @font-face {
        font-family: HKGrotesk;
        src: url(${HKGroteskRegularItalic}) format('opentype');
        font-weight: 400;
        font-style: italic;
    }
    @font-face {
        font-family: HKGrotesk;
        src: url(${HKGroteskMedium}) format('opentype');
        font-weight: 500;
        font-style: normal;
    }
    @font-face {
        font-family: HKGrotesk;
        src: url(${HKGroteskMediumItalic}) format('opentype');
        font-weight: 500;
        font-style: italic;
    }
    @font-face {
      font-family: HKGrotesk;
      src: url(${HKGroteskSemiBold}) format('opentype');
      font-weight: 600;
      font-style: normal;
  }
  @font-face {
      font-family: HKGrotesk;
      src: url(${HKGroteskSemiBoldItalic}) format('opentype');
      font-weight: 600;
      font-style: italic;
  }
    @font-face {
        font-family: HKGrotesk;
        src: url(${HKGroteskBold}) format('opentype');
        font-weight: bold;
        font-style: normal;
    }
    @font-face {
        font-family: HKGrotesk;
        src: url(${HKGroteskBoldItalic}) format('opentype');
        font-weight: bold;
        font-style: italic;
    }
    @font-face {
        font-family: HKGrotesk;
        src: url(${HKGroteskExtraBold}) format('opentype');
        font-weight: 800;
        font-style: normal;
    }
    @font-face {
        font-family: HKGrotesk;
        src: url(${HKGroteskBlack}) format('opentype');
        font-weight: 900;
        font-style: normal;
    }

    * {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        -webkit-tap-highlight-color: transparent;
    }
    img {
        max-width: 100%;
        border: 0;
        vertical-align: middle;
    }
    html {
        overflow-y: auto;
        scroll-snap-type: y mandatory;
        padding: env(safe-area-inset);
        margin: 0;
        background-color: ${styles.colors.WHITE_NEW_NEW};
    }
    body {
        font-family: 'HKGrotesk', sans-serif;
        margin: 0;
        color: ${styles.colors.DARK_BLUE};
        font-size: 14px;
        line-height: 1.2;
        @media screen and ${device.LAPTOP} {
            font-size: 16px;
        }
    }
    @supports(padding:max(0px)) {
      body {
        padding-left: min(0vmin, env(safe-area-inset-left));
        padding-right: min(0vmin, env(safe-area-inset-right));
      }
    }
   
    h1, h2, h3, h4, h5 {
      font-family: 'HKGrotesk', sans-serif;
      margin-top: 0;
      margin-bottom: 10px;
      letter-spacing: 1px;
      color: ${styles.colors.DARK_BLUE};
      line-height: 1.2;
      font-size: 20px;
      font-weight: 700;
    }

    h1 {
      font-size: 36px;
      @media screen and ${device.LAPTOP} {
          font-size: 50px;
      }
    }

    h2 {
        font-size: 24px;
        @media screen and ${device.LAPTOP} {
            font-size: 32px;
        }
    }

    button {
        font-weight: 600;
        font-size: 18px;
        line-height: 1.2;
        @media screen and ${device.LAPTOP} {
            font-weight: 700;
            font-size: 20px;
        }
    }

    a, strong, span, p, pre {
      font-family: 'HKGrotesk', sans-serif;
    }

    p, pre {
      margin-top: 0;
      font-weight: 400;
      font-size: 14px;
      line-height: 1.2;
      color: ${styles.colors.DARK_BLUE};
      @media screen and ${device.LAPTOP} {
        font-size: 16px;
        font-weight: 500;
      }
    }
    
    ul {
        list-style-type: none;
    }

    textarea,
    input:matches(
    [type="email"],
    [type="number"],
    [type="password"],
    [type="search"],
    [type="tel"],
    [type="text"],
    [type="url"]
    ) {
        -webkit-appearance: none;
    }

    .grecaptcha-badge { 
        visibility: hidden;
    }
`;

export const Content = styled.div`
  flex: 1;
`;
