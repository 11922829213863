import styled from 'styled-components/macro';
import styles from 'common/enums/styles';
import { device } from 'common/enums/devices';

export const Container = styled.section`
  position: relative;
  color: ${styles.colors.DARK_BLUE};
  background-color: ${styles.colors.NUDE};
  padding: 56px 5px;
  overflow: hidden;
  @media screen and ${device.LAPTOP} {
    padding: 80px 5px;
    display: grid;
    align-content: baseline;
    justify-items: center;
  }
  @media screen and ${device.DESKTOP} {
    padding: 144px 5px;
  }
`;

export const Icon = styled.img`
  display: none;
  position: absolute;
  width: ${({ num }) =>
    num === 1
      ? '11%'
      : num === 2
      ? '22%'
      : num === 3
      ? '23%'
      : num === 4
      ? '33%'
      : 0};
  height: auto;
  top: ${({ num }) => ([1, 3].includes(num) ? 0 : 'auto')};
  left: ${({ num }) => (num === 1 ? 0 : num === 2 ? '14%' : 'auto')};
  bottom: ${({ num }) => (num === 2 ? 0 : num === 4 ? '-9%' : 'auto')};
  right: ${({ num }) => (num === 3 ? '7%' : num === 4 ? '-16%' : 'auto')};
  max-width: ${({ num }) =>
    num === 1
      ? '480px'
      : num === 2
      ? '553px'
      : num === 3
      ? '620px'
      : num === 4
      ? '881px'
      : 0};
  z-index: 1;
  @media screen and ${device.LAPTOP_L} {
    display: block;
  }
  @media screen and (min-width: 1920px) {
    width: ${({ num }) =>
      num === 1
        ? '18%'
        : num === 2
        ? '18%'
        : num === 3
        ? '22%'
        : num === 4
        ? '500px'
        : 0};
    ${({ num }) =>
      num === 4 &&
      `
      bottom: -11%;
      right: 0%;
    `};
  }
`;
export const Title = styled.h2`
  font-weight: 700;
  font-size: 30px;
  line-height: 1.2;
  text-align: center;
  letter-spacing: 1px;
  @media screen and ${device.LAPTOP} {
    font-size: 50px;
    line-height: 1.3;
  }
`;

export const TextWrapper = styled.div`
  margin: auto;
  margin-top: 14px;
  text-align: center;
  max-width: 859px;
  width: 92%;
  width: 84%;
  @media screen and ${device.LAPTOP} {
    margin-top: 18px;
  }
`;

export const Download = styled.div`
  display: grid;
  grid-auto-flow: row;
  justify-items: center;
  justify-content: center;
  width: 83.7%;
  margin: auto;
  margin-top: 60px;
  @media screen and ${device.LAPTOP} {
    grid-auto-flow: column;
    justify-items: start;
    grid-template-rows: none;
    justify-content: space-between;
    display: none;
  }
`;

export const GetAppWrap = styled.div`
  text-align: center;
  margin-bottom: 0;
  margin-top: 60px;
  @media screen and ${device.LAPTOP_L} {
    display: none;
  }
`;
export const Timeline = styled.div`
  width: 62vw;
  max-width: 250px;
  margin: auto;
  @media screen and (min-width: 760px) {
    width: 250px;
  }
`;
export const TLImage = styled.img`
  width: 100%;
`;

export const TLCard = styled.article`
  position: absolute;
  z-index: 1;
  width: 218px;
  top: 59px;
  left: -16px;
  @media screen and (min-width: 1250px) {
    top: 34px;
  }
`;

export const TLPoint = styled.div`
  position: absolute;
  width: 0;
  height: 0;
  border-radius: 50%;
  z-index: 1;
  :nth-of-type(2),
  :nth-of-type(4) {
    top: 93%;
    article {
      top: 172px;
      @media screen and (min-width: 1250px) {
        top: 34px;
      }
    }
  }
  :first-of-type {
    top: 0;
    left: 0%;
  }
  :nth-of-type(2) {
    left: 24.8%;
  }
  :nth-of-type(3) {
    top: 0;
    left: 49.7%;
  }
  :nth-of-type(4) {
    left: 74.6%;
  }
  :nth-of-type(5) {
    top: 0;
    left: 99.5%;
  }
`;
export const TLTitle = styled.p`
  font-size: 20px;
  font-weight: 700;
`;
export const TLNumber = styled.div`
  position: absolute;
  left: -50%;
  top: -50%;
  transform: translate(-50%, -50%);
  display: grid;
  justify-content: center;
  align-content: center;
  width: 40px;
  height: 40px;
  background: ${styles.colors.WHITE_NEW};
  border-radius: 50%;
  border: 1px solid ${styles.colors.YELLOW};
  font-size: 26px;
  color: ${styles.colors.GREEN};
`;
export const TLText = styled.p`
  margin-top: 9px;
  line-height: 1.5;
`;
export const ButtonBox = styled.div`
  display: none;
  justify-items: center;
  @media screen and ${device.LAPTOP} {
    display: grid;
    margin-top: 350px;
  }
  @media screen and (min-width: 1250px) {
    margin-top: 228px;
  }
`;

export const TimelineDesktopWrapper = styled.div`
  display: none;
  @media screen and ${device.LAPTOP} {
    display: block;
    margin-top: 98px;
    padding-right: 197px;
    padding-left: 14px;
  }
`;
export const TimelineDesktop = styled.div`
  @media screen and ${device.LAPTOP} {
    position: relative;
    display: grid;
    justify-content: center;
    max-width: 1044px;
    width: 71vw;
  }
  @media screen and (min-width: 1250px) {
    width: 76vw;
  }
  @media screen and ${device.LAPTOP_L} {
    width: 100%;
  }
`;

export const Card = styled.div`
  position: relative;
  border-top: 1px dashed;
  border-color: #000 !important;
  margin: 0;
  padding: 20px;
  height: 211px;
  counter-increment: section;
  position: relative;
  min-height: 255px;
  :before {
    position: absolute;
    display: grid;
    justify-content: center;
    align-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    padding: 0;
    font-size: 28px;
    font-weight: 500;
    border: 1px solid ${styles.colors.YELLOW};
    color: ${styles.colors.GREEN};
    background: ${styles.colors.WHITE_NEW};
  }
  p {
    font-size: 16px;
    line-height: 1.5;
    white-space: normal;
  }
  :last-of-type {
    padding-bottom: 0;
    height: auto;
  }
  :nth-child(even):before {
    content: counter(section);
    border-radius: 50%;
    padding: 10px;
    height: 20px;
    width: 20px;
    text-align: center;
    box-shadow: 0.5px 0.5px 4px rgba(0, 0, 0, 0.25);
  }
  :nth-child(odd):before {
    content: counter(section);
    position: absolute;
    border-radius: 50%;
    padding: 10px;
    height: 20px;
    width: 20px;
    text-align: center;
    box-shadow: 0.5px 0.5px 4px rgba(0, 0, 0, 0.25);
  }
  :nth-child(even) {
    border-left: 1px dashed;
    border-top-left-radius: 22px;
    border-bottom-left-radius: 22px;
    margin-right: 30px;
    padding-top: 36px;
    padding-right: 0;
    h3 {
      white-space: normal;
    }
    div {
      width: 193px;
      transform: translateX(20px);
      white-space: pre-wrap;
      text-align: right;
    }
  }
  :nth-child(odd) {
    border-right: 1px dashed;
    border-top-right-radius: 22px;
    border-bottom-right-radius: 22px;
    margin-left: 15px;
    padding-top: 36px;
    padding-left: 0;
    div {
      width: 224px;
      transform: translateX(-29px);
      white-space: pre-wrap;
    }
  }
  div {
    font-weight: 500;
  }
  :first-child {
    p {
    }
    :before {
      top: 30px;
      left: -35px;
      margin-top: -54px;
    }
  }
  :nth-child(2),
  :nth-child(4) {
    margin-right: 4px;
    :before {
      top: -24px;
      left: calc(100% - 18px);
    }
  }
  :nth-child(3):before {
    left: -36px;
    margin-top: -58px;
  }
  :nth-child(5):before {
    left: -36px;
    margin-top: -44px;
  }
  :last-child {
    margin-left: 0;
    margin-right: 16px;
    border: none;
    min-height: auto;
    div {
      width: 200px;
      transform: translateX(-15px);
    }
    :before {
      top: 20px;
      left: -20px;
    }
  }
`;
