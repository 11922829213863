import styles from '../../common/enums/styles';
import styled, { keyframes } from 'styled-components';
import { device } from '../../common/enums/devices';

export const Wrapper = styled.div`
  position: absolute;
  background-color: ${styles.colors.DARK_BLUE2};
  z-index: 5;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding-top: 72px;
  @media screen and ${device.TABLET} {
    display: none;
  }
`;

export const TopSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 12px;
  input {
    text-overflow: ellipsis;
    flex: 1;
    height: 48px;
    width: 100%;
    box-shadow: none;
    box-sizing: border-box;
    border: none;
    color: #ffffff;
    font-size: 16px;
    line-height: 24px;
    padding-left: 12px;
    background-color: transparent;
    &::placeholder {
      color: ${styles.colors.GRAY_NEW};
      line-height: 1.5;
    }
    &:hover,
    &:focus {
      outline: none;
    }
  }
`;

export const IconButton = styled.div`
  height: 48px;
  width: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Divider = styled.div`
  margin: 0 16px;
  height: 1px;
  background-color: #82829a;
`;

export const AutocompleteList = styled.div`
  width: 100%;
  margin-top: 22px;
  padding: 0 20px;
  overflow: scroll;
`;

export const AutocompleteListItem = styled.div`
  p {
    color: #ffffff;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    padding: 9px 4px;
    cursor: pointer;
  }
  :hover,
  :focus {
    p {
      color: #7371fc;
    }
  }
`;
