import styled from 'styled-components/macro';
import styles from 'common/enums/styles';
import { device } from 'common/enums/devices';

export const Section = styled.section`
  display: grid;
  justify-content: center;
  padding: ${({ paddingMobile }) => paddingMobile};
  background-color: ${({ hasWhiteBackground }) =>
    hasWhiteBackground ? styles.colors.WHITE : styles.colors.NUDE};
  @media screen and ${device.LAPTOP} {
    padding: ${({ paddingDesktop }) => paddingDesktop};
  }
`;
