import mixpanel from 'mixpanel-browser';
import routes from 'common/enums/routes';
import { analyticsPageTypes } from 'common/enums/pages';
import { solialMedia, customRedirectUTMParams } from 'common/enums/links';

export const trackDownloadAppLinkUse = ({ pathname, socialMediaParams }) => {
  let pageParams = {};
  let eventName = '';

  if (
    [routes.DOWNLOAD_DOBBY_APP, routes.DOWNLOAD_DOBBY_PRO_APP].includes(
      pathname
    )
  ) {
    pageParams =
      pathname === routes.DOWNLOAD_DOBBY_APP
        ? {
            APP_NAME: 'HO',
            PAGE_NAME: analyticsPageTypes.DOWNLOAD_APP
          }
        : {
            APP_NAME: 'SP',
            PAGE_NAME: analyticsPageTypes.DOWNLOAD_PRO_APP
          };

    eventName = `${pageParams.APP_NAME} download ${
      navigator.userAgent.indexOf('Android') !== -1 ? 'Android' : 'iOS'
    }`;
  } else {
    pageParams.PAGE_NAME = pathname;
    eventName = analyticsPageTypes.IN_APP_REDIRECT;
  }

  const eventParams = {
    'Entry Point': pageParams.PAGE_NAME,
    utm_source: socialMediaParams.UTM_SOURCE,
    utm_campaign: socialMediaParams.UTM_CAMPAIGN,
    utm_medium: socialMediaParams.UTM_MEDIUM
  };
  mixpanel.track(eventName, eventParams);
};

export const getSocialMediaParams = ({ search, pathname }) => {
  const params = new URLSearchParams(search);
  const utmSourceQuery = params.get('utm_source');
  const utmCampaignQuery = params.get('utm_campaign');
  const utmMediumQuery = params.get('utm_medium');

  const socialMediaType = params.get('ref');

  if (socialMediaType) {
    const shortcutMediaParams = Object.values(solialMedia).find(
      ({ TYPE }) => TYPE === socialMediaType
    );
    if (shortcutMediaParams) {
      return shortcutMediaParams;
    }
  }

  const hasAnyUtmParam = Boolean(
    utmSourceQuery || utmCampaignQuery || utmMediumQuery
  );

  if (
    Object.keys(customRedirectUTMParams).includes(pathname) &&
    !hasAnyUtmParam
  ) {
    const customUTMParam = customRedirectUTMParams[pathname];
    if (customUTMParam) {
      return customUTMParam;
    }
  }

  return {
    ...solialMedia.DEFAULT,
    ...(socialMediaType ? { UTM_SOURCE: socialMediaType } : {}),
    ...(utmSourceQuery ? { UTM_SOURCE: utmSourceQuery } : {}),
    ...(utmCampaignQuery ? { UTM_CAMPAIGN: utmCampaignQuery } : {}),
    ...(utmMediumQuery ? { UTM_MEDIUM: utmMediumQuery } : {})
  };
};

export const prepareAppLink = ({ pathname, search, socialMediaParams }) => {
  if (
    [routes.DOWNLOAD_DOBBY_APP, routes.DOWNLOAD_DOBBY_PRO_APP].includes(
      pathname
    )
  ) {
    return `https://hellodobby.com/?utm_source=${socialMediaParams.UTM_SOURCE}&utm_medium=${socialMediaParams.UTM_MEDIUM}&utm_campaign=${socialMediaParams.UTM_CAMPAIGN}`;
  }

  const params = new URLSearchParams(search);
  const utmSourceQuery = params.get('utm_source');
  const utmCampaignQuery = params.get('utm_campaign');
  const utmMediumQuery = params.get('utm_medium');

  const hasAnyUtmParam = Boolean(
    utmSourceQuery || utmCampaignQuery || utmMediumQuery
  );
  const customUtmParams = hasAnyUtmParam
    ? ''
    : `${search ? '&' : '?'}utm_source=${
        socialMediaParams.UTM_SOURCE
      }&utm_medium=${socialMediaParams.UTM_MEDIUM}&utm_campaign=${
        socialMediaParams.UTM_CAMPAIGN
      }`;
  const preparedPath = pathname.substring(
    pathname?.startsWith('/pro_app') ? 8 : 4
  );
  return `https://hellodobby.com${preparedPath}${search}${customUtmParams}`;
};
