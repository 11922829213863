import styled from 'styled-components';
import { device } from 'common/enums/devices';
import styles from 'common/enums/styles';

export const Background = styled.div`
  align-items: center;
  background: ${({ backgroundColor }) => backgroundColor || 'none'};
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  height: ${({ backgroundSize = 30, backgroundSizeMobile }) =>
    backgroundSizeMobile || backgroundSize}px;
  justify-content: center;
  width: ${({ backgroundSize = 30, backgroundSizeMobile }) =>
    backgroundSizeMobile || backgroundSize}px;
  @media screen and ${device.LAPTOP} {
    height: ${({ backgroundSize = 30 }) => backgroundSize}px;
    width: ${({ backgroundSize = 30 }) => backgroundSize}px;
  }
`;

export const Image = styled.img`
  color: ${({ color }) => color || '#FFF'};
  height: ${({ size = 20 }) => size}px;
  width: ${({ size = 20 }) => size}px;
`;

export const Svg = styled.svg`
  color: ${({ color }) => color || '#FFF'};
  height: ${({ height = 20, heightMobile }) => heightMobile || height}px;
  width: ${({ width = 20, widthMobile }) => widthMobile || width}px;
  @media screen and ${device.LAPTOP} {
    height: ${({ height = 20 }) => height}px;
    width: ${({ width = 20 }) => width}px;
  }
`;

export const AppStoreSVG = styled.svg`
  width: 190px;
  height: 56px;
  border-radius: ${({ modal }) => (modal ? '10px' : '0')};
  background-color: ${({ modal }) => (modal ? `${styles.colors.PURPLE}` : '')};
  border: ${({ modal }) =>
    modal ? `1px solid ${styles.colors.PURPLE}` : 'none'};
  justify-content: center;
  a:hover & {
    border-color: ${({ highlightColor }) => highlightColor};
    path {
      fill: ${({ highlightColor }) => highlightColor};
    }
    path:first-child {
      fill: ${({ backgroundColor }) => backgroundColor};
    }
  }
`;

export const GooglePlaySVG = styled.svg`
  width: 190px;
  height: 56px;
  border-radius: ${({ modal }) => (modal ? '10px' : '0')};
  background-color: ${({ modal }) => (modal ? `${styles.colors.PURPLE}` : '')};
  border: ${({ modal }) =>
    modal ? `1px solid ${styles.colors.PURPLE}` : 'none'};
  a:hover & {
    path {
      fill: ${({ highlightColor }) => highlightColor};
    }
    path:first-child {
      fill: ${({ backgroundColor }) => backgroundColor};
    }

    path:nth-child(2),
    path:nth-child(3),
    path:nth-child(4),
    path:nth-child(5),
    path:nth-child(6),
    path:nth-child(7),
    path:nth-child(8) {
      stroke: ${({ highlightColor }) => highlightColor};
    }

    path:nth-child(10),
    path:nth-child(11),
    path:nth-child(12),
    path:nth-child(13) {
      stroke: ${({ backgroundColor }) => backgroundColor};
    }
  }
`;
