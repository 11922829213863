import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Amplify } from 'aws-amplify';

import { addGlowMagicLink, signIn, userUtmTrack } from 'store/actions/auth';
import { APP_ENV } from 'common/constants/app';
import { appEnvs } from 'common/enums/app';
import { amplifyConfigCustomer } from 'common/enums/config';

export const useAuthRedirect = ({ search }) => {
  const dispatch = useDispatch();
  const [returnTo, setReturnTo] = useState(null);

  useEffect(() => {
    const params = new URLSearchParams(search);
    setReturnTo(params.get('returnTo'));
  }, [search]);

  useEffect(() => {
    Amplify.configure(
      APP_ENV === appEnvs.PRODUCT
        ? amplifyConfigCustomer.product
        : amplifyConfigCustomer.staging
    );
  }, []);

  const handleLinkReceived = ({ magicLink }) => {
    window.location.href = magicLink;
  };

  const handleSendMagicLink = user => {
    if (!user?.id) {
      return;
    }

    dispatch(
      addGlowMagicLink({
        customAuthSub: `${user.id}`,
        ...(user.email ? { email: user.email } : {}),
        isEmailVerified: user.email_is_verified,
        phoneNumber: user.phone_number,
        firstName: user.given_name,
        lastName: user.family_name,
        ...(user.profile_image_thumbnail
          ? { profilePictureUrl: user.profile_image_thumbnail }
          : {}),
        returnTo: returnTo || '/feed',
        onSuccess: handleLinkReceived
      })
    );

    if (!user.lead_source) {
      dispatch(
        userUtmTrack({
          utm_source: 'Add Glow'
        })
      );
    }
  };

  const handleSubmitPhoneNumber = ({ phoneNumberCode, phone }) => {
    // it makes sign in or sign up in case of error
    dispatch(
      signIn({
        phoneNumberCode,
        phone,
        onSuccess: handleSendMagicLink
      })
    );
  };

  return {
    returnTo,
    onSendMagicLink: handleSendMagicLink,
    onSubmitPhoneNumber: handleSubmitPhoneNumber
  };
};
